UsersIndex

<template>
  <div id="wrapper">
    <SidebarView :branch="true" :all-branch="true" :branch-visible="true" />
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <TopbarView />
        <div class="container-fluid">

          <!-- begin row  -->
          <div class="card card-shape home-box">
            <div class="card-header d-flex flex-row align-items-center justify-content-between">
              <h6 class="m-0 fw-bold text-green">All Branch</h6>
              <b-button class="fw-semibold akkurate-green-btn" size="sm" type="button" href="/branch/create"
                variant="primary">
                <span class="akkurate-small"><i class="fas fa-plus"></i> Add Branch</span>
              </b-button>
            </div>
            <div class="card-body">
              <div class="card card-shape home-box mb-3 border border-success">
                <div class="card-body py-2">
                  <branch-filter :form="form" :filterTable="filterTable"></branch-filter>
                </div>
              </div>
              <vue-good-table styleClass="vgt-table bordered striped" ref="branchTable" mode="remote"
                @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange" :totalRows="totalRecords" :isLoading.sync="isLoading"
                :pagination-options="{
                  enabled: true,
                  perPage: serverParams.perPage,
                }" :columns="columns" :rows="branches">
                <!-- Add your custom delete button column -->

                <template slot="table-row" slot-scope="props">

                  <span class="d-flex" v-if="props.column.field == 'action'">
                    <!-- <a title="View" class="btn btn-sm akkurate-warning text-white" @click="confirmDeleteUser(props.row)">
                                    <span class="akkurate-small"><font-awesome-icon  class="text-white" :icon="['fas', 'eye']" /> </span> 
                                    </a> -->
                    <branch-view :data="props.row"></branch-view>
                    <a title="Edit" class="btn btn-sm akkurate-primary text-white"
                      :href="`/branch/${props.row.id}/edit`">
                      <span class="akkurate-small"><font-awesome-icon :icon="['fas', 'edit']" /></span>
                    </a>
                    <a title="Delete" class="btn btn-sm akkurate-danger-btn text-white"
                      @click="confirmDeleteUser(props.row)">
                      <span class="akkurate-small"><font-awesome-icon class="text-white" :icon="['fas', 'trash']" />
                      </span>
                    </a>
                  </span>
                  <span v-else-if="props.column.field == 'fullname'">
                    {{ props.row?.managerfk?.first_name }} {{ props.row?.managerfk?.surname }}
                  </span>
                  <span v-else-if="props.column.field == 'branch_status'">
                    <span v-if="props.row.status == '1'">
                      Active
                    </span>
                    <span v-else>
                      Inactive
                    </span>
                  </span>
                </template>
              </vue-good-table>
            </div>
          </div>

          <!-- end row  -->
        </div>
      </div>
      <footer></footer>
    </div>
    <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
  </div>
</template>

<script>

import BeatLoaderComponent from "@/views/components/Loader/BeatLoaderComponent.vue";
import SidebarView from '@/views/main/components/Sidebar.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import Footer from '@/views/main/components/Footer.vue';
import ApiService from "@/core/services/api.service";
import BranchFilter from "../forms/BranchFilter.vue";
import BranchView from '../view/BranchView.vue'
// import $ from 'jquery';
// import 'datatables.net';
// import config from '@/config.js'
// import JwtService from "@/core/services/jwt.service";

export default {
  components: {
    BeatLoaderComponent,
    SidebarView,
    TopbarView,
    Footer,
    BranchFilter,
    BranchView
  },
  mounted() {
    let token = localStorage.getItem('token');
    if (!token) {
      this.$router.push({ path: '/login' });
    }
  },
  data() {
    return {
      totalRecords: 0,
      searchFilter: "",
      isLoading: true,
      form: {
        user: [],
        region: [],
        status: [],
      },
      columns: [
        {
          label: 'Code',
          field: 'code',
          type: 'text',
        },
        {
          label: 'Branch Name',
          field: 'name',
          type: 'text',
        },
        {
          label: 'Manager',
          field: 'fullname',
          type: 'text',
        },
        {
          label: 'Address',
          field: 'address',
          type: 'text',
        },
        {
          label: 'Mobile',
          field: 'mobile',
          type: 'text',
        },
        {
          label: 'Region',
          field: 'regionfk.name',
          type: 'text',
        },
        {
          label: 'Status',
          field: 'branch_status',
          type: 'text',
        },
        {
          label: 'Action',
          field: 'action',
          html: true,
          //   formatFn: this.renderActions,
        },
      ],
      branches: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {
        },
        sort: [
          {
            field: '',
            type: ''
          }
        ],
        page: 1,
        perPage: 20
      }
    };
  },
  async created() {
    this.loadItems()
  },
  methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [{
          type: params.sortType,
          field: this.columns[params.columnIndex].field,
        }],
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    onSearch(event) {
      // console.log(event)
      this.loadItems(event.searchTerm)
    },
    // load items is what brings back the rows from server
    async loadItems(searchFilter = null) {
      this.isLoading = true;
      await ApiService.post("/settings/branches/paginate",
        {
          "page": this.serverParams.page,
          "per_page": this.serverParams.perPage,
          "filter": searchFilter,
          "region_id": this.form?.region?.id,
          "manager_id": this.form?.user?.id,
          "status": this.form?.status?.value
        })
        .then(response => {
          // console.log(response.data.data)
          this.branches = response.data.data;
          this.isLoading = false;
          // this.per_page = response.data.meta.per_page;
          // console.log(response.data.meta.per_page)
          this.totalRecords = response.data.meta.total;

        }).catch((error) => {
          this.isLoading = false;
        })
        .finally(resp => {
          this.isLoading = false
        });
    },

    confirmDeleteUser(user) {

      swal.fire({
        title: "Disable User",
        text: `Are you sure you want to disable ${user.username}?`,
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
        allowOutsideClick: false
      }).then(result => {
        if (result.value) {
          //   window.location.reload();
        }
      });

    },
    filterTable() {
      console.log(this.form.status);
      this.loadItems();
    }
  }


};
</script>

<!-- New step!
     Add Multiselect CSS. Can be added as a static asset or inside a component. -->
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
