import { render, staticRenderFns } from "./Star.vue?vue&type=template&id=24f1f5e1"
import script from "./Star.vue?vue&type=script&lang=js"
export * from "./Star.vue?vue&type=script&lang=js"
import style0 from "./Star.vue?vue&type=style&index=0&id=24f1f5e1&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports