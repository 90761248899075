<template>
    <footer class="bg-white sticky-footer">
                <div class="container my-auto">
                    <div class="text-center my-auto copyright"><span>Copyright © Akkurate 2023</span></div>
                </div>
            </footer>
</template>
<script>
 export default {
    name:'Footer'
 }

</script>
