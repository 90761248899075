<template>
    <div class="row">
           <div class="col-12 col-md-6">
           <label class="akkurate-dark" for="reference_name">Name of reference</label>
               <b-form-group
               id="reference_name"
               label-for="reference_name">
               <b-form-input
               class="mb-3 mt-2 field-container fields"
               type="text"
               placeholder="Enter First Name"
                v-model="form.reference_name"
               ></b-form-input>
               <errors v-if="form.errors.reference_name">
                  {{ form.errors.reference_name[0] }}
                </errors>
               </b-form-group>
           </div>
          
           <div class="col-12 col-md-6">
               <label class="akkurate-dark" for="reference_mobile">Phone Number</label>
               <b-form-group
               id="reference_mobile"
               label-for="reference_mobile">
               <b-form-input
               class="mb-3 mt-2 field-container fields"
               type="number"
               placeholder="Enter Phone Number Eg. 0244411100"
                v-model="form.reference_mobile"
               required
               ></b-form-input>
               <errors v-if="form.errors.reference_mobile">
                  {{ form.errors.reference_mobile[0] }}
                </errors>
               </b-form-group>
           </div>


           <div class="col-12 col-md-6">
               <label class="akkurate-dark" for="reference_email">Email</label>
               <b-form-group
               id="reference_email"
               label-for="reference_email">
               <b-form-input
               class="mb-3 mt-2 field-container fields"
               type="email"
               placeholder="Enter Email Address"
                v-model="form.reference_email"
               ></b-form-input>
               <errors v-if="form.errors.reference_email">
                  {{ form.errors.reference_email[0] }}
                </errors>
               </b-form-group>
           </div>
           
           <div class="col-12 col-md-6">
               <b-form-group
                label-for="reference_address"
                class="mb-2">
                <template #label>
                  <label class="akkurate-dark" for="reference_address"> Home Address </label>
                </template>
                  <b-form-textarea
                  class="mt-2 fields"
                  id="reference_address"
                  v-model="form.reference_address"
                  placeholder="Enter Home Address..."
                ></b-form-textarea>
              </b-form-group>
              
           </div>

    </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import ApiService from "@/core/services/api.service";
export default {
  name: "EmergencyContactDetails",
  props: ['form', 'add_or_edit'],
  components: {
    Multiselect
  },
  data() {
    return {
      status: '',
    }
  },
  methods: {
    
  }
}
</script>