<template>
  <div class="container-fluid">
    <div class="row">
      <h5 class="akkurate-dark mb-0">Financial Performance</h5>
    </div>
    <hr class="py-0" />
    <div class="row mt-3">
      <div class="col mb-2">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
            </b-card>
          </template>

          <div class="card border-start-success card-shape home-box">
            <div class="card-content h-100">
              <div class="card-body">
                <div class="media d-flex align-items-center">
                  <div class="align-self-center">
                    <i class="icon-speech warning font-large-2 float-left"></i>
                  </div>
                  <div class="media-body text-right">
                    <p class="fw-bold akkurate-gray">Total Assests</p>
                    <h3 class="akkurate-gray small">
                      {{ convertNumberToCurrency(total_assest) }}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>

      <div class="col mb-2">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
            </b-card>
          </template>
          <div class="card border-start-success card-shape home-box">
            <div class="card-content h-100">
              <div class="card-body">
                <div class="media d-flex align-items-center">
                  <div class="align-self-center">
                    <i class="icon-speech warning font-large-2 float-left"></i>
                  </div>
                  <div class="media-body text-right">
                    <p class="akkurate-green fw-bold">Total Liability</p>
                    <h3 class="akkurate-green small">
                      {{ convertNumberToCurrency(total_liability) }}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>

      <div class="col mb-2">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
            </b-card>
          </template>
          <div class="card border-start-success card-shape home-box">
            <div class="card-content">
              <div class="card-body">
                <div class="media d-flex">
                  <div class="align-self-center">
                    <i class="icon-speech warning font-large-2 float-left"></i>
                  </div>
                  <div class="media-body text-right">
                    <p class="akkurate-danger fw-bold">Net Profit</p>
                    <h3 class="akkurate-danger small">
                      {{ convertNumberToCurrency(net_profit) }}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>

      <div class="col mb-2">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
            </b-card>
          </template>
          <div class="card border-start-success card-shape home-box">
            <div class="card-content">
              <div class="card-body">
                <div class="media d-flex">
                  <div class="align-self-center">
                    <i class="icon-speech warning font-large-2 float-left"></i>
                  </div>
                  <div class="media-body text-right">
                    <p class="akkurate-green fw-bold">Return on Assests</p>
                    <h3 class="akkurate-greens small">
                      {{ convertNumberToCurrency(return_on_assests) }}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>

      <div class="col mb-2">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
            </b-card>
          </template>
          <div class="card border-start-success card-shape home-box">
            <div class="card-content">
              <div class="card-body">
                <div class="media d-flex">
                  <div class="align-self-center">
                    <i class="icon-speech warning font-large-2 float-left"></i>
                  </div>
                  <div class="media-body text-right">
                    <p class="akkurate-skyblue fw-bold">Return on Equity</p>
                    <h3 class="akkurate-skyblue small">
                      {{ convertNumberToCurrency(return_on_equity) }}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>

      <div class="row">
        <h5 class="akkurate-dark my-3">Investment Portfolio</h5>
      </div>

      <hr class="py-0" />

      <div class="col-xl-3 col-sm-6 col-12">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box dashboard-green-bg">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
            </b-card>
            <b-card class="card-shape home-box mt-4 dashboard-yellow-bg">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
            </b-card>
            <b-card class="card-shape home-box mt-4 dashboard-red-bg">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
            </b-card>
          </template>

          <div class="card border-start-success card-shape home-box">
            <div class="card-content">
              <div class="card-body dashboard-green-bg">
                <div class="media d-flex">
                  <div class="align-self-center">
                    <i class="icon-speech warning font-large-2 float-left"></i>
                  </div>
                  <div class="media-body text-right">
                    <p class="text-white">Total Investment Value</p>
                    <h3 class="text-white">
                      {{ convertNumberToCurrency(total_investment) }}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card border-start-success card-shape home-box mt-4">
            <div class="card-content">
              <div class="card-body dashboard-yellow-bg">
                <div class="media d-flex">
                  <div class="align-self-center">
                    <i class="icon-speech warning font-large-2 float-left"></i>
                  </div>
                  <div class="media-body text-right">
                    <p class="text-white">Investment Income</p>
                    <h3 class="text-white">
                      {{ convertNumberToCurrency(investment_income) }}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card border-start-success card-shape home-box mt-4">
            <div class="card-content">
              <div class="card-body dashboard-red-bg">
                <div class="media d-flex">
                  <div class="align-self-center">
                    <i class="icon-speech warning font-large-2 float-left"></i>
                  </div>
                  <div class="media-body text-right">
                    <p class="text-white">Investment Return</p>
                    <h3 class="text-white">{{ investment_returns }} %</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>

      <div class="col-xl-3 col-sm-6 col-12">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="30%" height="20%"></b-skeleton>
              <b-skeleton width="50%" height="20%"></b-skeleton>
              <b-skeleton width="90%" height="20%"></b-skeleton>
            </b-card>
          </template>
          <div class="card mb-4 card-shape home-box">
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h6 class="m-0 fw-bold akkurate-dark">Investment Allocation</h6>
            </div>
            <div class="card-body default-chart-size">
              <div class="pie-chart-area">
                <InvestmentAllocationPieChart :chartData="chartData" />
              </div>
              <hr />
              <div class="d-flex flex-wrap">
                <span
                  class="me-2 akkurate-small"
                  v-for="(field, index) in color_value"
                  :key="index"
                >
                  <font-awesome-icon
                    :icon="['fas', 'circle']"
                    :style="{ color: field.color }"
                  />
                  {{ field.name }}
                </span>
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>
      <div class="col-xl-6 col-sm-6 col-12">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="30%" height="20%"></b-skeleton>
              <b-skeleton width="50%" height="20%"></b-skeleton>
              <b-skeleton width="90%" height="20%"></b-skeleton>
            </b-card>
          </template>
          <div class="card mb-4 card-shape home-box">
            <div
              class="card-header py-3 d-flex flex-row align-items-center justify-content-between"
            >
              <h6 class="m-0 fw-bold akkurate-dark">
                Financial Performance Chart
              </h6>
            </div>
            <div class="card-body default-chart-size">
              <div>
                <PerformanceLineChart :lineChartData="lineChartData" />
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>
    </div>
  </div>
</template>
<script>
import DoughnutChart from "../Charts/DoughnutChart.vue";
import LineChart from "../Charts/LineChart.vue";
import ApiService from "@/core/services/api.service";
import InvestmentAllocationPieChart from "../Charts/InvestmentAllocationPieChart.vue";
import PerformanceLineChart from "../Charts/PerformanceLineChart.vue";
export default {
  components: {
    DoughnutChart,
    LineChart,
    InvestmentAllocationPieChart,
    PerformanceLineChart,
  },
  data() {
    return {
      total_assest: 0,
      total_liability: 0,
      net_profit: 0,
      return_on_assests: 0,
      return_on_equity: 0,
      total_investment: 0,
      investment_income: 0,
      investment_returns: 0,
      color_value: [],

      chartData: {
        labels: [],
        datasets: [],
      },
      lineChartData: {
        labels: [],
        datasets: [],
      },
      loading: false,
    };
  },
  created() {
    // Call your API here
    this.getGenMangDashboardApi();
  },
  methods: {
    async getGenMangDashboardApi() {
      // Your API call logic goes here
      //   console.log("GEN Man API called!");
      this.loading = true;
      await ApiService.query("/dashboard/treasurer",{
        params:{
            startDate: "2023-01-26",
            endDate: "2024-03-03"
        }
      })
        .then((response) => {
          // console.log(response);
          this.total_assest = response.data.total_assest;
          this.total_liability = response.data.total_assest;
          this.net_profit = response.data.net_profit;
          this.return_on_assests = response.data.return_on_assests;
          this.return_on_equity = response.data.return_on_equity;
          this.total_investment = response.data.total_investment;
          this.investment_income = response.data.investment_income;
          this.investment_returns = response.data.investment_returns;
          this.allocate_name = response.data.investment_allocation.allocation_name;
          this.allocate_value = response.data.investment_allocation.allocation_value;

          var duration = response.data.performance_chart.total_assest.weeks;
          var total_perform_assest_value = response.data.performance_chart.total_assest.weeklySums;
          var total_perform_liability_value = response.data.performance_chart.total_liability.weeklySums;
          var total_perform_capital_value = response.data.performance_chart.total_capital.weeklySums;
          var total_perform_income_value = response.data.performance_chart.total_income.weeklySums;
          var total_perform_expenses_value = response.data.performance_chart.total_expenses.weeklySums;
          var total_perform_surplus_value = response.data.performance_chart.total_surplus.weeklySums;
        //   console.log(total_perform_assest.weeks);
          var colors = [
            "#41B883",
            "#E46651",
            "#00D8FF",
            "#DD1B16",
            "#FFD700",
            "#7FFF00",
            "#9932CC",
            "#FFA07A",
            "#8A2BE2",
            "#FF69B4",
            "#00CED1",
            "#FF4500",
          ];
          this.color_value = this.allocate_name.map((names, index) => ({
            name: names,
            color: colors[index],
          }));
          this.chartData = {
            labels: this.allocate_name,
            datasets: [
              {
                backgroundColor: colors,
                data: this.allocate_value,
              },
            ],
          };
          this.lineChartData = {
            labels: duration,
            datasets: [
              {
                label: "Assest",
                data: total_perform_assest_value,
                fill: false,
                borderColor: "#01a790",
                backgroundColor: "#01a790",
                borderWidth: 2,
              },
              {
                label: "Liability",
                data: total_perform_liability_value,
                fill: false,
                borderColor: "#E46651",
                backgroundColor: "#E46651",
                borderWidth: 2,
              },
              {
                label: "Capital",
                data: total_perform_capital_value,
                fill: false,
                borderColor: "#DD1B16",
                backgroundColor: "#DD1B16",
                borderWidth: 2,
              },
              {
                label: "Income",
                data: total_perform_income_value,
                fill: false,
                borderColor: "#FF69B4",
                backgroundColor: "#FF69B4",
                borderWidth: 2,
              },
              {
                label: "Expenses",
                data: total_perform_expenses_value,
                fill: false,
                borderColor: "#41B883",
                backgroundColor: "#41B883",
                borderWidth: 2,
              },
              {
                label: "Surplus",
                data: total_perform_surplus_value,
                fill: false,
                borderColor: "#FF4500",
                backgroundColor: "#FF4500",
                borderWidth: 2,
              },
            ],
           
          };
          // this.isLoading = false;`
        })
        .catch(function (error) {
          // this.isLoading = false;
          // return error;
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    convertNumberToCurrency(number) {
      const formattedCurrency = number?.toLocaleString("en-US", {
        style: "currency",
        currency: "GHS", // Change this to your desired currency code (e.g., 'EUR', 'GBP', 'JPY', etc.)
      });
      return formattedCurrency;
    },
  },
};
</script>