<template>
 <div class="container">
        <div class="row vh-100">
            <div class="col-md-6 d-md-flex align-items-md-center">
                <img class="img-fluid img-visibility" src="../../assets/img/login.jpg">
            </div>
            <LoginForm />
        </div>
    </div>
</template>
<script>
import LoginForm from './Forms/LoginForm.vue'
export default {
    name: 'LoginView',
    components: {
        LoginForm
    },
    
}

</script>