<template>
    <div>
        <a title="Approval" class="btn btn-sm akkurate-green-btn text-white" v-b-modal="String(data.id + 'approval')">
            <span class="akkurate-small"> <font-awesome-icon :icon="['fas', 'circle-check']" /></span>
        </a>
       <!-- <b-button class="akkurate-green-btn" >Advance Search</b-button> -->
       <b-modal 
        :id="String(data.id + 'approval')"
        size="lg"
        @ok="handleOk">
        <template #modal-title>
            <p class="m-0 akkurate-green fw-bold">Standing Order Approval</p>
        </template>
        <div class="row">
            <div class="col-md-6 mb-3">
                <label class="akkurate-dark" for="status">Status</label>
                <b-form-group
                    label-for="status"
                    id="status"
                    name="status"
                    :state="validateState('status')"
                    data-vv-as="Status">
                    <multiselect
                        id="status"
                        name="status"
                        class="mt-2 field-container"
                        v-validate="{ required: true }"
                        v-model="form.status"
                        :options="status_options">
                    </multiselect>
                </b-form-group>
                <b-form-invalid-feedback >{{ veeErrors.first('status') }}</b-form-invalid-feedback>
            </div>
            <div class="col-md-6">
                <label class="akkurate-dark" for="application_by">Application By</label>
                <b-form-group
                    label-for="application_by"
                    class="mb-3">
                    <b-form-input
                        id="application_by"
                        name="application_by"
                        class="mt-2 field-container fields"
                        v-model="form.application_by"
                        v-validate="{ required: false }"
                        :state="validateState('application_by')"
                        data-vv-as="application_by"
                        disabled
                        type="text"
                    ></b-form-input>
                    <b-form-invalid-feedback>{{ veeErrors.first('application_by') }}</b-form-invalid-feedback>
                </b-form-group>
            </div>
            <div class="col-md-6">
                <label class="akkurate-dark" for="user">User</label>
                <b-form-group
                    label-for="user"
                    class="mb-3">
                    <b-form-input
                        id="user"
                        name="user"
                        class="mt-2 field-container fields"
                        v-model="form.user"
                        v-validate="{ required: false }"
                        :state="validateState('user')"
                        data-vv-as="user"
                        type="text"
                        diabled
                    ></b-form-input>
                    <b-form-invalid-feedback>{{ veeErrors.first('user') }}</b-form-invalid-feedback>
                </b-form-group>
            </div>
           
        </div>
        <template #modal-footer="{ ok }">
               <div class="w-100">
               <!-- begin loader -->
               <!-- <beat-loader-component 
               class="float-left"
               v-if="isLoading"
               :isLoading="isLoading"
               ></beat-loader-component> -->
               <!-- end loader -->
               <!-- Emulate built in modal footer ok and cancel button actions -->
                <!-- <b-button @click="$bvModal.hide('advance_search')" type="button" class="akkurate-grey-btn btn-size float-right border-0">Close</b-button> -->
                <b-button @click="ok()" class="akkurate-green-btn btn-size me-2 float-right border-0"><font-awesome-icon class="me-2" :icon="['fas', 'save']" />Save</b-button>     
               </div>
           </template>

       </b-modal>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
export default {
    props: ['data'],
    components: {
        Multiselect
    },
    data() {
        return {
            form: {
                mandate: "",
                mode: "Cash",
            },
            status_options: ['Pending','Running'],
        }
    },
    methods: {
        validateState(ref) {
            if (
                this.veeFields[ref] &&
                (this.veeFields[ref].dirty || this.veeFields[ref].validated)
            ) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        resetForm() {
            this.form = {
                mandate: null,
                mode: null
            };

            this.$nextTick(() => {
                this.$validator.reset();
            });
        },
        handleOk(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
        },
        handleSubmit() {
            this.$validator.validateAll().then(result => {
                if (!result) {
                    return;
                }

                alert("Form submitted!");
            });
        },
    }
}
</script>