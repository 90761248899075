<template>
  <div>
    <!-- <a class="dropdown-item" v-b-modal="data.account_number + transaction">Cheque</a> -->
    <b-button title="View" class="btn btn-sm akkurate-warning text-white" v-b-modal="data.code + transaction">
      <span class="akkurate-small"><font-awesome-icon :icon="['fas', 'eye']" /></span>
    </b-button>
    <b-modal :id="data.code + transaction" ref="modal" v-model="showModal" size="xl">
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h5 class="akkurate-green fw-bold">Transaction view</h5>
        <b-button variant="white" @click="close()"> &times; </b-button>
      </template>
      <form>
        <div class="row">
          <div class="card-body akkurate-card-light-bg">
            <div class="row">
              <div class="col-md-3 col-12 text-center">
                <img src="@/assets/img/user-avater.jpg" style="height: 200px" class="img-fluid" alt="user-profile" />
                <p class="mt-2 akkurate-green fw-bold">Preview</p>
              </div>
              <div class="col-md-3 col-12 text-center">
                <img v-if="imageLoaded" v-bind:src="frontImgUrl" alt="Image" @load="handleImageLoad"
                  style="height: 200px" class="img-fluid text-center" />
                <img v-else src="@/assets/img/Ghana-Card-Front.png" alt="Default Image" style="height: 200px"
                  class="img-fluid text-center" />
                <p class="mt-2 akkurate-green fw-bold">ID Card - Front</p>
              </div>
              <div class="col-md-3 col-12 text-center">
                <img v-if="imageLoaded" v-bind:src="backImgUrl" alt="Image" @load="handleImageLoad"
                  style="height: 200px" class="img-fluid text-center" />
                <img v-else src="@/assets/img/Ghana-Card-Front.png" alt="Default Image" style="height: 200px"
                  class="img-fluid text-center" />
                <p class="mt-2 akkurate-green fw-bold">ID Card - Back</p>
              </div>
              <div class="col-md-3 col-12 akkurate-green">
                <h6 class="mb-0 fw-bold">Customer Name</h6>
                <p class="mb-2">{{ data.customer?.title }} {{ getFullName }}</p>
                <h6 class="mb-0 fw-bold">Mobile</h6>
                <p class="mb-2">{{ data.customer?.mobile }}</p>
                <h6 class="mb-0 fw-bold">Email</h6>
                <p class="mb-2">{{ data.customer?.email }}</p>
                <h6 class="mb-0 fw-bold">Address</h6>
                <p class="mb-2">{{ data.customer?.address }}</p>
                <div class="d-flex">
                  <!-- <p class="fw-bold">TEL: <span class="fw-light me-3"> {{ form.customer?.mobile  }}</span></p> -->
                  <!-- <p class="fw-bold">STAFF ID: <span class="fw-light">9858958</span></p> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-card-group deck>
              <b-card>
                <template #header>
                  <div class="py-1">
                    <h6 class="m-0 fw-bold text-green">Transaction Details</h6>
                  </div>
                </template>
                <b-card-body>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <label class="akkurate-dark" for="title">Code</label>
                      <b-form-group id="title" label-for="title">
                        <b-form-input class="mb-3 mt-2 field-container fields" type="text" v-model="data.code"
                          disabled></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-12 col-md-4">
                      <label class="akkurate-dark" for="title">Fullname</label>
                      <b-form-group id="title" label-for="title">
                        <b-form-input class="mb-3 mt-2 field-container fields" type="text" v-model="getFullName"
                          disabled></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-12 col-md-4">
                      <label class="akkurate-dark">Transaction Mode</label>
                      <b-form-group>
                        <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                          v-model="data.transaction_mode" disabled></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-12 col-md-4">
                      <label class="akkurate-dark">Transaction Type</label>
                      <b-form-group>
                        <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                          v-model="data.transaction_type" disabled></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-12 col-md-4">
                      <label class="akkurate-dark">Transfer Amount</label>
                      <b-form-group>
                        <b-form-input class="mb-3 mt-2 field-container fields" type="text" v-model="trans_amt"
                          disabled></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-12 col-md-4">
                      <label class="akkurate-dark" for="search_term">Account Balance</label>
                      <b-form-group id="search_term" label-for="search_term">
                        <b-form-input class="mb-3 mt-2 field-container fields" type="text" v-model="balance"
                          disabled></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-12 col-md-4">
                      <label class="akkurate-dark" for="search_term">Account Number</label>
                      <b-form-group id="search_term" label-for="search_term">
                        <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                          v-model="data.account.account_number" disabled></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-12 col-md-4">
                      <label class="akkurate-dark" for="reg_date">Registration Date</label>
                      <b-form-group id="reg_date" label-for="reg_date">
                        <b-form-input class="mb-3 mt-2 field-container fields" type="text" v-model="data.trans_date"
                          disabled></b-form-input>
                      </b-form-group>
                    </div>

                    <div class="col-12 col-md-4">
                      <label class="akkurate-dark">Transaction Status</label>
                      <b-form-group>
                        <b-form-input class="mb-3 mt-2 field-container fields" type="text" v-model="data.trans_status"
                          disabled></b-form-input>
                      </b-form-group>
                    </div>

                    <div class="col-12 col-md-4">
                      <label class="akkurate-dark">Created At</label>
                      <b-form-group>
                        <b-form-input class="mb-3 mt-2 field-container fields" type="text" v-model="data.created_at"
                          disabled></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-md-8">
                      <label class="akkurate-dark" for="marital_status">Mandate</label>
                      <div class="row" style="border: 1px solid #ccc !important">
                        <div class="col-md-6">
                          <b-form-group>
                            <b-form-radio name="status" value="1">ANY OF THE FOLLOWING</b-form-radio>
                            <b-form-radio name="status" value="2">ANY TWO OF THE FOLLOWING</b-form-radio>
                            <b-form-radio name="status" value="4">ALL OF THE FOLLOWING</b-form-radio>
                            <b-form-radio name="status" value="5">THE PRINCIPAL SIGNATORY AND ANY
                              OTHER</b-form-radio>
                          </b-form-group>
                        </div>
                        <div class="col-md-6">
                          <!-- <ol>
                            <li>COLLINS AMOAKO ADDAE - PRINCIPAL</li>
                            <li>ERIC OWUSU ABABIO</li>
                            <li>FRED MACLEAN</li>
                            <li>JUSTUS AIDOO</li>
                            <li>KWAKU SINTIM</li>
                          </ol> -->
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3 text-center">
                      <img src="@/assets/img/signature.png" style="height: 200px" class="img-fluid"
                        alt="user-profile" />
                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </b-card-group>
          </div>
        </div>
      </form>
      <template #modal-footer>
        <div class="w-100">
          <!-- begin loader -->
          <!-- <beat-loader-component 
               class="float-left"
               v-if="isLoading"
               :isLoading="isLoading"
               ></beat-loader-component> -->
          <!-- end loader -->
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <!-- <div class="d-none">{{ getView }}</div> -->
          <b-button @click="$bvModal.hide(account_number + transaction)" type="button"
            class="akkurate-green-btn modal-btn-width ms-2 float-right"><span class="small"> Okay</span></b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import SidebarView from "@/views/main/components/Sidebar.vue";
import TopbarView from "@/views/main/components/Topbar.vue";
// import ApiService from "@/core/services/api.service";

export default {
  components: {
    SidebarView,
    TopbarView,
    Multiselect,
  },
  data() {
    return {
      showModal: false,
      trans_amt: this.data.currency + " " + this.data.trans_amount,
      balance: this.data.currency + " " + this.data.account.account_balance,
      imageLoaded: false,
      frontImgUrl: "",
      backImgUrl: "",
    };
  },
  props: ["data", "transaction"],
  computed: {
    getFullName() {
      return this.data.customer.first_name + " " + this.data.customer.last_name;
    }
  },
  methods: {
    handleImageLoad() {
      if (this.form.customer?.gh_card_front_path) {
        this.imageLoaded = true;
        this.frontImgUrl = this.form.customer?.gh_card_front_path;
      }
      if (this.form.customer?.gh_card_back_path) {
        this.imageLoaded = true;
        this.backImgUrl = this.form.customer?.gh_card_back_path;
      }
    },
    // getAccountView() {
    //     //   getFromServer(this.serverParams).then(response => {
    //     //      this.totalRecords = response.totalRecords;
    //     //      this.rows = response.rows;
    //     //   });
    //     this.isLoading = true;
    //     ApiService.get("/accounts/view/" + this.account_number)
    //         .then(response => {
    //             console.log(response.data.account)
    //             this.form = response.data.account;
    //             this.customer = response.data.account.customer
    //             this.full_name = this.customer.first_name + ' ' + this.customer.last_name;
    //             this.branch = response.data.account.branch;
    //             // this.per_page = response.data.meta.per_page;
    //             // console.log(response.data.meta.per_page)
    //             // this.totalRecords = response.data.meta.total;
    //         }).catch(() => {
    //             // console.log(error);
    //         })
    //         .finally(() => {
    //             // this.isLoading = false
    //         });
    // },
  },
};
</script>