<template>
    <div class="row">
        <div class="col-md-12">
                <vue-good-table
                    styleClass="vgt-table bordered table-dropdown striped"
                    mode="remote"
                    ref="attachTable"
                    :columns="columns"
                    :rows="rows">
        </vue-good-table>
        </div>

       
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
    components: {
        Multiselect
    },
    data() {
        return {
            type: 'Car',
            type_options: ['Car', 'House', 'Salary'],
            columns: [
                {
                    label: 'Description',
                    field: 'description',
                },
                {
                    label: 'Value',
                    field: 'value',
                },
                {
                    label: 'Reason',
                    field: 'reason',
                },
                {
                    label: 'Reason',
                    field: 'reason',
                },
            ],
            rows: [
                { description: 'N/A', value: '', reason: '' },
                { description: 'N/A', value: '', reason: '' },
                { description: 'N/A', value: '', reason: '' },
            ]
        }
    }

}
</script>