<template>
    <div>
        <a title="Reedemed" class="btn btn-sm akkurate-blue text-white" v-b-modal="String(data.id)">
            <span class="akkurate-small"> <font-awesome-icon :icon="['fas', 'arrow-rotate-left']" /></span>
        </a>
       <!-- <b-button class="akkurate-green-btn" >Advance Search</b-button> -->
       <b-modal 
        :id="String(data.id)"
        size="lg"
        @ok="handleOk">
        <template #modal-title>
            <p class="m-0 akkurate-blue-text fw-bold">Fixed Term Deposit Redemption</p>
        </template>
        <div class="row">
            <div class="col-md-6 mb-3">
                <label class="akkurate-dark" for="mandate">Mandate</label>
                <b-form-group
                    label-for="mandate"
                    id="mandate"
                    name="mandate"
                    :state="validateState('mandate')"
                    data-vv-as="Mandate">
                    <multiselect
                        id="mandate"
                        name="mandate"
                        class="mt-2 field-container"
                        v-validate="{ required: true }"
                        v-model="form.mandate"
                        :options="mandate_options">
                    </multiselect>
                </b-form-group>
                <b-form-invalid-feedback >{{ veeErrors.first('mandate') }}</b-form-invalid-feedback>
            </div>
            <div class="col-md-6 mb-3">
                <label class="akkurate-dark" for="mode">Mode</label>
                <b-form-group
                    label-for="mode"
                    id="mode"
                    name="mode"
                    :state="validateState('mode')"
                    data-vv-as="Mode">
                    <multiselect 
                        id="mode"
                        name="mode"
                        class="mt-2 field-container" 
                        v-validate="{ required: true }"
                        v-model="form.mode" 
                        @select="selectedMode"
                        :options="mode_options">
                    </multiselect>
                </b-form-group>
                <b-form-invalid-feedback >{{ veeErrors.first('mode') }}</b-form-invalid-feedback>
            </div>
            <div class="col-md-6">
                <label class="akkurate-dark" for="amount">Amount</label>
                <b-form-group
                    label-for="amount"
                    class="mb-3">
                    <b-form-input
                        id="amount"
                        name="amount"
                        class="mt-2 field-container fields"
                        v-model="form.amount"
                        v-validate="{ required: true }"
                        :state="validateState('amount')"
                        data-vv-as="amount"
                        type="text"
                    ></b-form-input>
                    <b-form-invalid-feedback>{{ veeErrors.first('amount') }}</b-form-invalid-feedback>
                </b-form-group>
            </div>
            <div class="col-md-6 mb-3" v-show="transfer">
                <label class="akkurate-dark" for="account">Account</label>
                <b-form-group
                    label-for="account"
                    id="account"
                    name="account"
                    :state="validateState('account')"
                    data-vv-as="mode">
                    <multiselect 
                        id="account"
                        name="account"
                        class="mt-2 field-container" 
                        v-validate="{ required: true }"
                        v-model="form.account" 
                        :options="account_options">
                    </multiselect>
                </b-form-group>
                <b-form-invalid-feedback >{{ veeErrors.first('account') }}</b-form-invalid-feedback>
            </div>
            <div class="col-md-6" v-show="transfer">
                <label class="akkurate-dark" for="note">Note</label>
                <b-form-group
                    label-for="note"
                    class="mb-3">
                    <b-form-textarea
                        id="note"
                        name="note"
                        class="mt-2 fields"
                        :state="validateState('note')"
                        v-validate="{ required: true }"
                        v-model="form.note"
                        data-vv-as="note"
                        placeholder="Enter Note..."
                    ></b-form-textarea>
                    <b-form-invalid-feedback >{{ veeErrors.first('note') }}</b-form-invalid-feedback>
                </b-form-group>
            </div>
            <div class="col-md-6" v-show="credit_and_momo">
                <label class="akkurate-dark" for="mobile">Mobile No.</label>
                <b-form-group
                    label-for="mobile"
                    class="mb-3">
                    <b-form-input
                        id="mobile"
                        name="mobile"
                        :state="validateState('mobile')"
                        v-validate="{ required: true }"
                        data-vv-as="mobile"
                        class="mt-2 field-container fields"
                        type="text"
                    ></b-form-input>
                    <b-form-invalid-feedback >{{ veeErrors.first('mobile') }}</b-form-invalid-feedback>
                </b-form-group>
            </div>
            <div class="col-md-6" v-show="credit_and_momo">
                <label class="akkurate-dark" for="reference">Reference</label>
                <b-form-group
                    label-for="reference"
                    class="mb-3">
                    <b-form-input
                        id="reference"
                        name="reference"
                        :state="validateState('reference')"
                        v-validate="{ required: true }"
                        data-vv-as="reference"
                        class="mt-2 field-container fields"
                        type="text"
                    ></b-form-input>
                    <b-form-invalid-feedback >{{ veeErrors.first('reference') }}</b-form-invalid-feedback>
                </b-form-group>
            </div>
        </div>
        <template #modal-footer="{ ok }">
               <div class="w-100">
               <!-- begin loader -->
               <!-- <beat-loader-component 
               class="float-left"
               v-if="isLoading"
               :isLoading="isLoading"
               ></beat-loader-component> -->
               <!-- end loader -->
               <!-- Emulate built in modal footer ok and cancel button actions -->
                <!-- <b-button @click="$bvModal.hide('advance_search')" type="button" class="akkurate-grey-btn btn-size float-right border-0">Close</b-button> -->
                <b-button @click="ok()" class="akkurate-blue btn-size me-2 float-right border-0"><font-awesome-icon class="me-2" :icon="['fas', 'save']" />Pay</b-button>     
               </div>
           </template>

       </b-modal>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
export default {
    props: ['data'],
    components: {
        Multiselect
    },
    data() {
        return {
            form: {
                mandate: "",
                mode: "Cash",
            },
            mandate_options: ['Pay Interest Only'],
            mode_options: ['Cash', 'Transfer', 'Direct Credit', 'Momo Pay'],
            account_options: ['Saving'],
            transfer: false,
            credit_and_momo: false,
        }
    },
    methods: {
        validateState(ref) {
            if (
                this.veeFields[ref] &&
                (this.veeFields[ref].dirty || this.veeFields[ref].validated)
            ) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        resetForm() {
            this.form = {
                mandate: null,
                mode: null
            };

            this.$nextTick(() => {
                this.$validator.reset();
            });
        },
        handleOk(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
        },
        handleSubmit() {
            this.$validator.validateAll().then(result => {
                if (!result) {
                    return;
                }

                alert("Form submitted!");
            });
        },
        selectedMode(index) {
            console.log(index);
            switch (index) {
                case "Cash":
                    this.transfer = false;
                    this.credit_and_momo = false;
                    break;
                case "Transfer":
                    this.transfer = true;
                    this.credit_and_momo = false;
                    break;
                case "Direct Credit":
                    this.transfer = false;
                    this.credit_and_momo = true;
                    break;
                case "Momo Pay":
                    this.transfer = false;
                    this.credit_and_momo = true;
                    break;

            }
        }
    }
}
</script>