<template>
    <div id="wrapper">
      <SidebarView :journal="true" :add-journal="true" :journal-visible="true" />
      <div class="d-flex flex-column" id="content-wrapper">
        <div id="content">
          <TopbarView />
          <b-container>
            <div class="general-error d-flex justify-content-center pt-2 pb-2">
                  <errors v-if="form.message">
                    {{ form.message }}
                  </errors>
              </div>
              <div class="row mb-4">
                <div class="col-12 col-md-12">
                  <div class="card card-shape home-box">
                    <div
                      class="card-header py-3 d-flex flex-row align-items-center">
                      <h6 class="m-0 fw-bold text-green">Create Journal Transfer</h6>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="searchMember">
                            <b-row class="my-3">
                                <b-col class="akkurate-dark">
                                   MEMBER NAME : {{ field.member_name }}  {{ field.telephone }}
                                </b-col>
                            </b-row>
                            <b-row cols="4">
                                <b-col class="mt-auto">
                                    <div>
                                        <b-form-group class="akkurate-dark" label="Entry type" v-slot="{ ariaDescribedby }">
                                            <b-form-radio class="akkurate-dark" @change="entryClicked" v-model="selected" :aria-describedby="ariaDescribedby" name="type" value="RECEIPT_ENTRY">Receipt Entry</b-form-radio>
                                            <b-form-radio class="akkurate-dark" @change="entryClicked" v-model="selected" :aria-describedby="ariaDescribedby" name="type" value="PAYMENT_ENTRY">Payment Entry</b-form-radio>
                                        </b-form-group>
                                    </div>
                                </b-col>
                                <b-col class="mt-auto">
                                    <b-form-group label-for="code">
                                        <template #label>
                                            <label class="akkurate-dark" for="code">Member code<star></star></label>
                                        </template>
                                        <multiselect
                                            class="field-container mt-2"
                                            v-model="code"
                                            :options="code_option"
                                            label="name"
                                            selectLabel=""
                                            deselectLabel=""
                                            >
                                        </multiselect>
                                    </b-form-group>
                                </b-col>
                                <b-col class="mt-auto">
                                    <b-form-group id="search" label-for="search">
                                        <template #label>
                                            <label class="akkurate-dark" for="code">Search<star></star></label>
                                        </template>
                                        <b-input-group class="">
                                        <!-- <template #prepend>
                                            <b-input-group-text class="py-0 px-2 h-100 bg-white"
                                            ><font-awesome-icon
                                                class="icon_color"
                                                :icon="['fas', 'search']"
                                            /></b-input-group-text>
                                        </template> -->
                                        <b-form-input
                                            class="field-container fields mt-2"
                                            type="text"
                                            v-model="search"
                                            placeholder="Search "
                                            required
                                        ></b-form-input>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                                <b-col class="mt-auto">
                                    <button type="submit" class="btn akkurate-green-btn text-white w-100">
                                        <font-awesome-icon class="me-2" :icon="['fas', 'search']" /> Search
                                    </button>
                                </b-col>
                            </b-row>
                        </form>
                        <form  @submit.prevent="submitJournalTransfer" class="mt-5">
                            <b-row cols="4" class="">
                                <b-col>
                                    <label class="akkurate-dark" for="product"
                                        >{{ source }} <star></star
                                    ></label>
                                    <b-form-group id="product" label-for="product">
                                        <multiselect
                                        class="mt-2 field-container"
                                        v-model="form.product"
                                        :options="accounts_options"
                                        label="name"
                                        selectLabel=""
                                        @select="accountSelected"
                                        deselectLabel=""
                                        >
                                        </multiselect>
                                        <errors v-if="form.errors.product">
                                        {{ form.errors.product[0] }}
                                        </errors>
                                    </b-form-group>
                                </b-col>
                                <b-col class="mt-auto">
                                    <b-form-group id="amount" label-for="amount">
                                        <template #label>
                                            <label class="akkurate-dark" for="code">Amount<star></star></label>
                                        </template>
                                        <b-input-group class="">
                                        <b-form-input
                                            class="field-container fields mt-2"
                                            type="text"
                                            v-model="form.amount"
                                            placeholder="amount "
                                            required
                                        ></b-form-input>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <label class="akkurate-dark" for="coa"
                                        >{{ destination }} <star></star
                                    ></label>
                                    <b-form-group id="coa" label-for="coa">
                                        <multiselect
                                        class="mt-2 field-container"
                                        v-model="form.coa"
                                        :options="coa_subhead_options"
                                        label="name"
                                        selectLabel=""
                                        @select="accountSelected"
                                        :custom-label="customLabel"
                                        deselectLabel=""
                                        :preselect-first="true"
                                        >
                                        </multiselect>
                                        <errors v-if="form.errors.coa">
                                        {{ form.errors.coa[0] }}
                                        </errors>
                                    </b-form-group>
                                </b-col>
                                <b-col class="mt-auto">
                                    <button type="submit" class="btn akkurate-green-btn text-white w-100">
                                        <font-awesome-icon class="me-2" :icon="['fas', 'forward']" /> Transfer
                                    </button>
                                </b-col>
                            </b-row>
                        </form>
                      
                        
                      <!-- <journal-form :form="form" :branch_options="branch_options"></journal-form> -->
                    </div>
                  </div>
                </div>
              </div>
            </b-container>
        </div>
        <footer></footer>
      </div>
    </div>
  </template>
<script>
import SidebarView from "@/views/main/components/Sidebar.vue";
import Footer from "@/views/main/components/Footer.vue";
import TopbarView from "@/views/main/components/Topbar.vue";
// import JournalForm from '../forms/JournalForm.vue'
// import AddAccount from '../forms/modal/AddAccount.vue'
import ApiService from "@/core/services/api.service";
import Multiselect from "vue-multiselect";

export default {
    name: "JournalCreate",
    mounted() {
        this.getVoucherDropdown()
    },
    components: {
        SidebarView,
        Footer,
        TopbarView,
        // JournalForm,
        // AddAccount,
        Multiselect,
    },
    data() {
        return {
            ...this.initialState(),
            coa_subhead_options: [],
            branch_options: [],
            add_or_edit: 'add',
            save: "Save",
            isSaving: false,
            acc_error: "",
            search: '',
            selected: 'RECEIPT_ENTRY',
            code: { name: "Cust. Code", value: "filter" },
            source: "Source",
            destination: "Destination",
            code_option: [
                { name: "Cust. Code", value: "filter" },
                { name: "Account #", value: "account_number" },
                // { name: "Filter", value: "filter" },
                { name: "Mobile", value: "phone_number" },
            ],
            accounts_options: [],
            coa_subhead: "",
            coa_subhead_options: [],
            is_searching: false,
            field: {
                member_name: '',
                telephone: ''
            }
        };
    },
    methods: {
        async searchMember() {
            // console.log("Member Search clicked")
            // console.log({code: this.code, search: this.search})
            this.is_searching = true;
            this.search_btn = "processing...";
            let ac = "";
            let filter = "";
            let phone = "";
            if (this.code?.value == "account_number") {
                ac = this.search.toString();
                this.search.toString();
            } else if (this.code?.value == "filter") {
                filter = this.search.toString();
            } else if (this.code?.value == "phone_number") {
                phone = this.search.toString();
            } else {
                filter = this.search.toString();
            }

            await ApiService.post("/accounts/paginate", {
                page: 1,
                per_page: 10,
                filter: filter,
                branch_id: "",
                account_type_id: "",
                account_number: ac,
                phone_number: phone,
                start_date: "",
                end_date: "",
                status: "", //Inactive,	Active, Dormant, Closed
            })
                .then((response) => {

                    if (response.data.data.length > 0) {
                        console.log(response.data.data);
                        this.field.member_name = response.data?.data[0]?.customer?.first_name + " " + response.data?.data[0]?.customer?.last_name;
                        this.field.telephone = response.data?.data[0]?.customer.mobile;
                        this.accounts_options = response.data.data.map((index) => {
                            return {
                                name: index.account_number + " - " + index?.account_type?.name + " - " + index?.customer?.first_name + " " + index?.customer?.last_name,
                                product_type: index?.account_type?.name,
                                account_number: index.account_number,
                                customer_name: index?.customer?.first_name + " " + index?.customer?.last_name,
                                customer_mobile: index?.customer?.mobile,
                            };
                        });
                        console.log(this.accounts_options)
                        // this.form.account_number = response.data?.data[0]?.account_number;
                        // this.field.product = response.data?.data[0]?.account_type?.name;
                    } else {
                        swal.fire({
                            icon: "error",
                            title: "Not Found",
                            text: "This account was not found please try again!!!",
                            showCancelButton: false, // Show the "Cancel" button
                            confirmButtonText: "OK", // Customize the text for the "OK" button
                            buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                            customClass: {
                                confirmButton:
                                    "btn akkurate-green-btn modal-btn-width text-white me-2", // Custom class for the "OK" button
                            },
                            // footer: '<a href="#">Why do I have this issue?</a>'
                        });
                    }
                    this.is_searching = false;
                    this.search_btn = "Search";
                })
                .catch((error) => {
                    this.is_searching = false;
                    this.search_btn = "Search";
                });
        },
        accountSelected(event) {
            this.field.product = event?.product_type
            this.field.member_name = event?.customer_name
            this.field.telephone = event?.customer_mobile

            // const value = this.accounts_options.find(event.name);
        },
        async getVoucherDropdown() {
            this.$Progress.start();
            await ApiService.get('/journals/dropdown')
                .then((response) => {
                    this.$Progress.finish();
                    console.log(response);
                    this.coa_subhead_options = response.data.coa_subheads.map(index => {
                        return {
                            name: index.name,
                            code: index.code
                        }
                    })
                    // this.branch_options = response.data.branches;
                }).catch((error) => {
                    this.$Progress.fail();
                    // console.log(error);
                })
        },
        customLabel({ name, code }) {
            return `${name} - (${code})`
        },

        entryClicked(event) {
            if (event === 'Receipt') {
                this.source = "Source"
                this.destination = "Destination"
            } else if (event == "Payment") {
                this.source = "Destination"
                this.destination = "Source"
            } else {
                this.source = "Source"
                this.destination = "Destination"
            }
        },

        submitJournalTransfer() {
            this.isSaving = true;
            this.$Progress.start();
            this.save = "Saving..."
            // console.log(this.form);
            console.log(this.form)
            ApiService.post("/journals/transfer/store", this.form)
                .then((response) => {
                    this.isSaving = false;
                    this.$Progress.finish();
                    this.save = "Save"
                    toast.fire({
                        icon: "success",
                        title: response.data.message,
                    });

                    this.form = this.initialState().form;
                    this.isSaving = false;
                })
                .catch((error) => {
                    this.isSaving = false;
                    this.$Progress.fail();
                    this.save = "Save"
                    // console.log(error);
                    if (error.response.data) {
                        this.form.errors = error.response.data.message;
                    }

                    //error message
                    if (error.response.data.message) {
                        this.form.message = error.response.data.message;
                    }
                    // console.log(error.response.data)
                });
        },


        initialState() {
            return {
                form: {
                    amount: "",
                    recorded_date: new Date(),
                    narration: "",
                    coa: "",
                    product: "",
                    errors: [],
                },
                
            }
        }
    },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>