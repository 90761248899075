<template>
    <div id="wrapper">
        <SidebarView :loan="true" :loan-scheme="true" :loan-visible="true" />
        <div class="d-flex flex-column" id="content-wrapper">
            <div id="content">
                <TopbarView />
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="card card-shape home-box">
                                <div class="card-header d-flex flex-row align-items-center justify-content-between">
                                    <h6 class="m-0 fw-bold text-green">All Schemes</h6>
                                    <b-button class="btn btn-sm akkurate-green-btn" href="/loans/scheme/create"
                                        variant="primary">
                                        <span class="akkurate-small"> <i class="fas fa-plus"></i> Add Scheme</span>
                                    </b-button>
                                </div>
                                <div class="card-body">
                                    <vue-good-table styleClass="vgt-table bordered table-dropdown striped" mode="remote"
                                        ref="memberTable" :columns="columns" :rows="rows" :isLoading.sync="isLoading"
                                        :totalRows="totalRecords" :sort-options="{
                                            enabled: false
                                        }" :pagination-options="{
                                        enabled: true,
                                        perPage: serverParams.perPage,
                                    }" @on-search="onSearch" @on-page-change="onPageChange"
                                        @on-sort-change="onSortChange" @on-column-filter="onColumnFilter"
                                        @on-per-page-change="onPerPageChange">
                                        <!-- Add your custom delete button column -->
                                        <template slot="table-row" slot-scope="props">
                                            <span class="d-flex" v-if="props.column.field == 'action'">
                                                <!-- <a title="View" class="btn btn-sm akkurate-warning text-white" @click="confirmDeleteUser(props.row)">
                                            <span class="akkurate-small"> <font-awesome-icon :icon="['fas', 'eye']" /></span> 
                                        </a> -->
                                                <scheme-view :data="props.row"></scheme-view>

                                                <a title="Edit" class="btn btn-sm akkurate-primary text-white broder-0"
                                                    :href="`/loans/scheme/${props.row.id}/edit`">
                                                    <span class="akkurate-small"><font-awesome-icon
                                                            :icon="['fas', 'edit']" /></span>
                                                </a>
                                                <a title="Edit"
                                                    class="btn btn-sm akkurate-danger-btn text-white broder-0"
                                                    @click="confirmDeleteUser(props.row)">
                                                    <span class="akkurate-small"><font-awesome-icon
                                                            :icon="['fas', 'trash']" /></span>
                                                </a>
                                            </span>
                                        </template>
                                    </vue-good-table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import ApiService from "@/core/services/api.service";
import SidebarView from '@/views/main/components/Sidebar.vue';
import TopbarView from '@/views/main/components/Topbar.vue';

import SchemeView from "../view/SchemeView.vue";
export default {
    components: {
        SidebarView,
        TopbarView,
        SchemeView
    },
    data() {
        return {
            totalRecords: 0,
            searchFilter: "",
            isLoading: true,
            columns: [
                {
                    label: '#',
                    field: 'id',
                    type: 'text',
                },

                {
                    label: 'Name',
                    field: 'name',
                    type: 'text',
                },
                {
                    label: 'Interest Rate',
                    field: 'interest_rate',
                    type: 'text',
                },
                {
                    label: 'Min Principal',
                    field: 'min_principal',
                    type: 'text',
                },
                {
                    label: 'Max Principal',
                    field: 'max_principal',
                    type: 'text',
                },
                {
                    label: 'Min Duration',
                    field: 'min_duration',
                    type: 'text',
                },
                {
                    label: 'Max Duration',
                    field: 'max_duration',
                    type: 'text',
                },
                {
                    label: 'Action',
                    field: 'action'
                },
            ],
            rows: [],
            serverParams: {
                // a map of column filters example: {name: 'john', age: '20'}
                columnFilters: {
                },
                sort: [
                    {
                        field: '', // example: 'name'
                        type: '' // 'asc' or 'desc'
                    }
                ],
                page: 1, // what page I want to show
                perPage: 20 // how many items I'm showing per page
            },
        }
    },
    methods: {
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },

        onPageChange(params) {
            this.updateParams({ page: params.currentPage });
            this.loadItems();
        },

        onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage });
            this.loadItems();
        },

        onSortChange(params) {
            this.updateParams({
                sort: [{
                    type: params.sortType,
                    field: this.columns[params.columnIndex].field,
                }],
            });
            this.loadItems();
        },

        onColumnFilter(params) {
            this.updateParams(params);
            this.loadItems();
        },
        onSearch(event) {
            // console.log(event)
            this.loadItems(event.searchTerm)
        },
        // load items is what brings back the rows from server
        async loadItems(searchFilter = null) {
            //   getFromServer(this.serverParams).then(response => {
            //      this.totalRecords = response.totalRecords;
            //      this.rows = response.rows;
            //   });
            this.isLoading = true;
            await ApiService.get("loans/loanProductSchemes",
                {
                    "page": this.serverParams.page,
                    "per_page": this.serverParams.perPage,
                    "filter": searchFilter,
                })
                .then(response => {
                    console.log(response.data.data);
                    this.rows = response.data.data;
                    // this.per_page = response.data.meta.per_page;
                    // console.log(response.data.meta.per_page)
                    this.totalRecords = response.data.meta.total;

                }).catch(() => {
                    // console.log(error);
                })
                .finally(() => {
                    // this.isLoading = false
                });
        }
    }

}
</script>