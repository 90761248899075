<template>
    <div class="container-fluid">
        <div class="row">
            <h5 class="akkurate-dark mb-0">Summary</h5>
        </div>
        <hr class="py-0" />
        <div class="row">
            <div class="col-md-6 col-xl-3 mb-4">
                <div class="card border-start-info py-2 card-shape home-box card-top-dashboard">
                    <div class="card-body">
                        <div class="row align-items-center no-gutters">
                            <div class="col-12 col-md-12">
                                <div class="text-uppercase fw-bold text-sm mb-1 akkurate-green"><span>Loan
                                        Repayments</span></div>
                            </div>
                            <div class="col-md-6 col-6">
                                <div class="text-uppercase text-xs mb-1 akkurate-green"><span>Count</span></div>
                            </div>
                            <div class="col-md-6 col-6">
                                <div class="text-uppercase fw-bold text-xs mb-1 akkurate-green"><span>{{
                                    successful_repayments_count }}</span></div>
                            </div>
                            <div class="col-md-6 col-6">
                                <div class="text-uppercase text-xs mb-1 akkurate-green"><span>Amount</span></div>
                            </div>
                            <div class="col-md-6 col-6">
                                <div class="text-uppercase fw-bold text-xs mb-1 akkurate-green"><span>GHS {{
                                    formatNumber(successful_repayments_amount) }}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-xl-3 mb-4">
                <div class="card border-start-success py-2 card-shape home-box card-top-dashboard">
                    <div class="card-body">
                        <div class="row align-items-center no-gutters">
                            <div class="col-12 col-md-12">
                                <div class="text-uppercase fw-bold text-sm mb-1 akkurate-danger"><span>Disbursed
                                        Loans</span></div>
                            </div>
                            <div class="col-md-6 col-6">
                                <div class="text-uppercase text-xs mb-1 akkurate-danger"><span>Count</span></div>
                            </div>
                            <div class="col-md-6 col-6">
                                <div class="text-uppercase fw-bold text-xs mb-1 akkurate-danger"><span>{{
                                    disbursed_loans_count }}</span></div>
                            </div>
                            <div class="col-md-6 col-6">
                                <div class="text-uppercase text-xs mb-1 akkurate-danger"><span>Amount</span></div>
                            </div>
                            <div class="col-md-6 col-6">
                                <div class="text-uppercase fw-bold text-xs mb-1 akkurate-danger"><span>GHS {{
                                    disbursed_loans_amount }}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-xl-3 mb-4">
                <div class="card border-start-info py-2 card-shape home-box card-top-dashboard">
                    <div class="card-body">
                        <div class="row align-items-center no-gutters">
                            <div class="col-12 col-md-12">
                                <div class="text-uppercase fw-bold text-sm mb-1 akkurate-gray"><span>Loans
                                        Requested</span></div>
                            </div>
                            <div class="col-md-6 col-6">
                                <div class="text-uppercase text-xs mb-1 akkurate-gray"><span>Count</span></div>
                            </div>
                            <div class="col-md-6 col-6">
                                <div class="text-uppercase fw-bold text-xs mb-1 akkurate-gray"><span>{{
                                    total_loans_requests_count }}</span></div>
                            </div>
                            <div class="col-md-6 col-6">
                                <div class="text-uppercase text-xs mb-1 akkurate-gray"><span>Amount</span></div>
                            </div>
                            <div class="col-md-6 col-6">
                                <div class="text-uppercase fw-bold text-xs mb-1 akkurate-gray"><span>GHS {{
                                    formatNumber(total_loan_requests_amount) }}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-xl-3 mb-4">
                <div class="card border-start-info py-2 card-shape home-box card-top-dashboard">
                    <div class="card-body">
                        <div class="row align-items-center no-gutters">
                            <div class="col-12 col-md-12">
                                <div class="text-uppercase fw-bold text-sm mb-1 akkurate-blue-text"><span>Pending
                                        Loans</span></div>
                            </div>
                            <div class="col-md-6 col-6">
                                <div class="text-uppercase text-xs mb-1 akkurate-blue-text"><span>Count</span></div>
                            </div>
                            <div class="col-md-6 col-6">
                                <div class="text-uppercase fw-bold text-xs mb-1 akkurate-blue-text"><span>{{
                                    pending_loans_count }}</span></div>
                            </div>
                            <div class="col-md-6 col-6">
                                <div class="text-uppercase text-xs mb-1 akkurate-blue-text"><span>Amount</span></div>
                            </div>
                            <div class="col-md-6 col-6">
                                <div class="text-uppercase fw-bold text-xs mb-1 akkurate-blue-text"><span>GHS {{
                                    formatNumber(pending_loans_amount) }}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <h5 class="akkurate-dark mb-0">Loan Products</h5>
        </div>
        <hr class="py-0" />

        <div class="row">
            <div v-for="(loan_product, index) in loan_products" :key="index" class="col-md-6 col-xl-3 mb-4">
                <div class="card border-start-success py-2 card-shape home-box card-top-dashboard">
                    <div class="card-body">
                        <div class="row align-items-center no-gutters">
                            <div class="col-12 col-md-12">
                                <div class="text-uppercase fw-bold text-sm mb-1 akkurate-green">
                                    <span>{{ loan_product.name }}</span>
                                </div>
                            </div>
                            <div class="col-md-6 col-6">
                                <div class="text-uppercase text-xs mb-1 akkurate-green"><span>Count</span></div>
                            </div>
                            <div class="col-md-6 col-6">
                                <div class="text-uppercase fw-bold text-xs mb-1 akkurate-green">
                                    <span>{{ loan_product.loan_count }}</span>
                                </div>
                            </div>
                            <div class="col-md-6 col-6">
                                <div class="text-uppercase text-xs mb-1 akkurate-green"><span>Principal Disbursed</span>
                                </div>
                            </div>
                            <div class="col-md-6 col-6">
                                <div class="text-uppercase fw-bold text-xs mb-1 akkurate-green"><span>GHS
                                        {{ formatNumber(loan_product.loan_sum_principal_amount) }}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-xl-6 col-sm-6 col-12">
                <b-skeleton-wrapper :loading="loading">
                    <template #loading>
                        <b-card class="card-shape home-box">
                            <b-skeleton width="85%" height="20%"></b-skeleton>
                            <b-skeleton width="70%" height="20%"></b-skeleton>
                            <b-skeleton width="30%" height="20%"></b-skeleton>
                            <b-skeleton width="50%" height="20%"></b-skeleton>
                            <b-skeleton width="90%" height="20%"></b-skeleton>
                        </b-card>
                    </template>
                    <div class="card mb-4 card-shape home-box ">
                        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 class="m-0 fw-bold akkurate-dark">
                                Financial Performance Chart
                            </h6>
                        </div>
                        <div class="card-body default-chart-size">
                            <div>
                                <PerformanceLineChart :lineChartData="lineChartData" />
                            </div>
                        </div>
                    </div>
                </b-skeleton-wrapper>
            </div>
            <div class="col-xl-3 col-sm-6 col-12">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="30%" height="20%"></b-skeleton>
              <b-skeleton width="50%" height="20%"></b-skeleton>
              <b-skeleton width="90%" height="20%"></b-skeleton>
            </b-card>
          </template>
          <div class="card mb-4 card-shape home-box" style="height: 385px;">
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h6 class="m-0 fw-bold akkurate-dark">Financial Position</h6>
            </div>
            <div class="card-body default-chart-size">
              <div class="pie-chart-area">
                <DoughnutChart :chartData="chartDataBalanceSheet" />
              </div>
              <hr />
              <div class="row">
                <div class="col akkurate-blue-text">
                  <div class="small">Assests</div>
                  <div class="akkurate-small">Today:{{ assest_today }}</div>
                  <div class="akkurate-small">Month:{{ assest_month }}</div>
                  <div class="akkurate-small">YTD:{{ assest_year }}</div>
                </div>
                <div class="col akkurate-green">
                  <div class="small">Liability & Capital</div>
                  <div class="akkurate-small">{{ lib_today }}</div>
                  <div class="akkurate-small">{{ lib_month }}</div>
                  <div class="akkurate-small">{{ liability_capital_year }}</div>
                </div>
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>
      <div class="col-xl-3 col-sm-6 col-12">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="30%" height="20%"></b-skeleton>
              <b-skeleton width="50%" height="20%"></b-skeleton>
              <b-skeleton width="90%" height="20%"></b-skeleton>
            </b-card>
          </template>
          <div class="card mb-4 card-shape home-box">
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h6 class="m-0 fw-bold akkurate-dark">Income & Expenses</h6>
            </div>
            <div class="card-body default-chart-size">
              <div class="pie-chart-area">
                <DoughnutChart :chartData="chartDataIncome" />
              </div>
              <hr />
              <div class="row">
                <div class="col akkurate-danger">
                  <div class="small">Expenses</div>
                  <div class="akkurate-small">Today: {{ expenses_today }}</div>
                  <div class="akkurate-small">Month: {{ expenses_month }}</div>
                  <div class="text-xs">YTD:{{ expenses_year }}</div>
                </div>
                <div class="col akkurate-green">
                  <div class="small">Income</div>
                  <div class="akkurate-small"> {{ income_today }}</div>
                  <div class="akkurate-small">{{ income_month }}</div>
                  <div class="text-xs">{{ income_year }}</div>
                </div>
                <div class="col akkurate-blue-text">
                  <div class="small">P/L</div>
                  <div class="akkurate-small">{{ profit_loss_today }}</div>
                  <div class="akkurate-small">{{ profit_loss_month }}</div>
                  <div class="text-xs">{{ profit_loss_year }}</div>
                </div>
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>
        </div>
    </div>


</template>
<script>
import DoughnutChart from '../Charts/DoughnutChart.vue'
import ApiService from "@/core/services/api.service";
import PerformanceLineChart from "../Charts/PerformanceLineChart.vue";
export default {
    components: {
        DoughnutChart,
        PerformanceLineChart
    },
    props: {
        showDashboard: {
            type: Boolean,
            default: false
        }
    },
    created() {
        // Call your API here
        this.getDashboardApi();
    },
    data() {
        return {
            roles: "",
            loading: false,
            isLoading: false,
            loan_products: [],
            disbursed_loans_count: "",
            disbursed_loans_amount: "",
            total_loans_requests_count: "",
            total_loan_requests_amount: "",
            pending_loans_count: "",
            pending_loans_amount: "",
            successful_repayments_count: "",
            successful_repayments_amount: "",
            balance_sheet_name: [],
            balance_sheet_value: [],
            color_value: [],
            assest: 0,
            liability_capital: 0,
            expenses: 0,
            income: 0,
            profit_loss: 0,
            lineChartData: {
                labels: [],
                datasets: [],
            },
            chartDataBalanceSheet: {
                labels: [],
                datasets: [],
            },
            chartDataIncome: {
                labels: [],
                datasets: [],
            },
            assest_year: 0,
            assest_today: 0,
            lib_year: 0,
            liability_capital_year: 0,
            expenses_year: 0,
            income_year: 0,
            profit_loss_year: 0,
            lib_today: 0,
            capital_today: 0,
            liability_capital_today: 0,
            income_today: 0,
            expenses_today: 0,
            profit_loss_today: 0,
            assest_month: 0,
            lib_month: 0,
            liability_capital_month: 0,
            income_month: 0,
            expenses_month: 0,
            profit_loss_month: 0,
        };

    },
    methods: {
        async getDashboardApi() {
            // Your API call logic goes here
            console.log("loan officer API called!");
            this.loading = true;
            await ApiService.get("/dashboard/supervisor")
                .then((response) => {
                    // console.log(this.roles);
                    // this.isLoading = false;
                    this.loading = false;
                    const res = response.data;
                    this.loan_products = res.loan_products;
                    this.disbursed_loans_count = res.disbursed_loans_count;
                    this.disbursed_loans_amount = res.disbursed_loans_amounts;
                    this.total_loans_requests_count = res.total_loans_requests_count;
                    this.total_loan_requests_amount = res.total_loan_requests_amount;
                    this.pending_loans_count = res.pending_loans_count;
                    this.pending_loans_amount = res.pending_loans_amount;
                    this.successful_repayments_count = res.successful_repayments_count;
                    this.successful_repayments_amount = res.successful_repayments_amount;



                    var balance_sheet_name = []
                    var balance_sheet_value = []

                    var profit_loss_name = []
                    var profit_loss_value = []


                    // Populate the arrays
                    for (const [key, value] of Object.entries(res.ps?.balance_sheet_year)) {
                        balance_sheet_name.push(key);
                        balance_sheet_value.push(value);
                    }

                    // Populate the arrays
                    for (const [key, value] of Object.entries(res.ps?.profit_loss_year)) {
                        profit_loss_name.push(key);
                        profit_loss_value.push(value);
                    }
                    console.log(balance_sheet_name)
                    console.log(balance_sheet_value)
                    console.log(profit_loss_name)
                    console.log(profit_loss_value)
                    this.chartDataBalanceSheet = {
                        labels: balance_sheet_name,
                        datasets: [
                            {
                                backgroundColor: ["#41B883", "#E46651", "#00D8FF"],
                                data: balance_sheet_value,
                            },
                        ],
                    };
                    this.chartDataIncome = {
                        labels: profit_loss_name,
                        datasets: [
                            {
                                backgroundColor: ["#41B883", "#E46651", "#00D8FF"],
                                data: profit_loss_value,
                            },
                        ],
                    };
                    //Year to Date
                    this.assest_year = res.ps?.balance_sheet_year.total_assets
                    this.lib_year = res.ps?.balance_sheet_year.total_liabilities;
                    this.capital_year = res.ps?.balance_sheet_year.total_capital
                    this.liability_capital_year = this.lib_year + this.capital_year;

                    this.income_year = res.ps?.profit_loss_year.total_income;
                    this.expenses_year = res.ps?.profit_loss_year.total_expenses;
                    this.profit_loss_year = res.ps?.profit_loss_year.profit_and_loss;

                    //Today
                    this.assest_today = res.ps?.balance_sheet_today.total_assets;
                    this.lib_today = res.ps?.balance_sheet_today.total_liabilities;
                    this.capital_today = res.ps?.balance_sheet_today.total_capital;
                    this.liability_capital_today = this.lib_today + this.capital_today;

                    this.income_today = res.ps?.profit_loss_today.total_income;
                    this.expenses_today = res.ps?.profit_loss_today.total_expenses;
                    this.profit_loss_today = res.ps?.profit_loss_today.profit_and_loss;

                    //Month
                    this.assest_month = res.ps?.balance_sheet_month.total_assets;
                    this.lib_month = res.ps?.balance_sheet_month.total_liabilities;
                    this.capital_month = res.ps?.balance_sheet_month.total_capital;
                    this.liability_capital_month = this.lib_month + this.capital_month;

                    this.income_month = res.ps?.profit_loss_month.total_income;
                    this.expenses_month = res.ps?.profit_loss_month.total_expenses;
                    this.profit_loss_month = res.ps?.profit_loss_month.profit_and_loss;


                    var duration = res.performance_chart.total_assest.weeks;
                    var total_perform_assest_value = res.performance_chart.total_assest.weeklySums;
                    var total_perform_liability_value = res.performance_chart.total_liability.weeklySums;
                    var total_perform_capital_value = res.performance_chart.total_capital.weeklySums;
                    var total_perform_income_value = res.performance_chart.total_income.weeklySums;
                    var total_perform_expenses_value = res.performance_chart.total_expenses.weeklySums;
                    var total_perform_surplus_value = res.performance_chart.total_surplus.weeklySums;
                    this.lineChartData = {
                        labels: duration,
                        datasets: [
                            {
                                label: "Assest",
                                data: total_perform_assest_value,
                                fill: false,
                                borderColor: "#01a790",
                                backgroundColor: "#01a790",
                                borderWidth: 2,
                            },
                            {
                                label: "Liability",
                                data: total_perform_liability_value,
                                fill: false,
                                borderColor: "#E46651",
                                backgroundColor: "#E46651",
                                borderWidth: 2,
                            },
                            {
                                label: "Capital",
                                data: total_perform_capital_value,
                                fill: false,
                                borderColor: "#DD1B16",
                                backgroundColor: "#DD1B16",
                                borderWidth: 2,
                            },
                            {
                                label: "Income",
                                data: total_perform_income_value,
                                fill: false,
                                borderColor: "#FF69B4",
                                backgroundColor: "#FF69B4",
                                borderWidth: 2,
                            },
                            {
                                label: "Expenses",
                                data: total_perform_expenses_value,
                                fill: false,
                                borderColor: "#41B883",
                                backgroundColor: "#41B883",
                                borderWidth: 2,
                            },
                            {
                                label: "Surplus",
                                data: total_perform_surplus_value,
                                fill: false,
                                borderColor: "#FF4500",
                                backgroundColor: "#FF4500",
                                borderWidth: 2,
                            },
                        ],
                    };

                })
                .catch(function (error) {
                    this.loading = false;
                    // this.isLoading = false;
                    // return error;
                });
        }
    },
};
</script>