<template>
  <div class="">
    <!-- <div class="row mb-3">
      <div class="col-md-6">
        <div>
          <b-button >Download Excel Format</b-button>
        </div>
      </div>
      <div class="col-md-6">
      </div>
    </div> -->
    <div class="row">
      <div class="col-md-4">
        <label class="akkurate-dark small" for="debit" v-if="deposit_or_withdrawal == 'Deposit'">GL Debit <star></star>
        </label>
        <label class="akkurate-dark small" v-else for="debit">GL Credit<star></star></label>
        <multiselect class="mt-2 field-container" v-model="form.gl_debit" :options="gl_options"
          :custom-label="fullGlName" placeholder="Select COA Account" selectLabel="" deselectLabel=""
          @select="onSelect">
        </multiselect>
      </div>
      <div class="col-md-4">
        <label class="akkurate-dark small" for="phone">Choose File<star></star></label>
        <input type="file" class="form-control mt-2" @change="handleFileUpload" ref="fileInput" accept=".xlsx,.xls" />
      </div>
      <div class="col-md-2 mt-auto">
        <label class="akkurate-dark small" for="total_count">Total Records</label>
        <div class="mt-2">{{ total_count }}</div>
      </div>
      <div class="col-md-2 mt-auto">
        <label class="akkurate-dark small" for="total_a">Total Amount<star></star></label>
        <div class="mt-2">{{ total_amount }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import * as XLSX from "xlsx";
export default {
  components: {
    Multiselect,
  },
  props: ["form", "gl_options", "deposit_or_withdrawal"],
  data() {
    return {
      transaction_option: ["Deposit", "Withdraw"],
      total_count: 0,
      total_amount: 0,
      // total_count: 0,
      // total_amount: 0,
      // jsonData: null,
      //   debit_option: ["Account One", "Account Two"],
      //   credit_option: ["Account One", "Account Two"],
    };
  },
  methods: {
    fullGlName({ code, name, coabranch }) {
      var f_name = `${code} - ${name}`

      if (coabranch) {
        f_name += ` - ${coabranch.name}`
      }
      return f_name;
    },
    onSelect(option) {
      // console.log(option);
      if (this.jsonArray && this.jsonArray.length > 0) {
        this.jsonArray.forEach((element, index) => {
          element['gl_debit'] = option.code + ' - ' + option.name;
        });

        this.jsonArray = this.jsonArray;
        this.$emit('showExcelUploads', this.jsonArray);
      }

    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
          // Convert to JSON
          const headers = jsonData[0];
          const jsonDataArray = [];
          for (let i = 1; i < jsonData.length; i++) {
            const row = jsonData[i];
            const obj = {};
            for (let j = 0; j < headers.length; j++) {
              const key = headers[j].replace(/\s/g, "_").toLowerCase(); // Replace spaces with underscores
              let value = null;
              if (row[j]) {
                value = String(row[j]); // Convert value to string
              }
              obj[key] = value;

              if (this.form.gl_debit) {
                obj["gl_debit"] = this.form.gl_debit.code + ' - ' + this.form.gl_debit.name;
              }
            }
            this.total_amount += Number(obj.amount)
            jsonDataArray.push(obj);
          }
          this.jsonArray = jsonDataArray;
          this.total_count = this.jsonArray.length
          // this.jsonData = JSON.stringify(jsonDataArray, null, 2);
          this.$emit('showExcelUploads', jsonDataArray);

        };
        reader.readAsArrayBuffer(file);
      }
      else {
        this.$emit('showExcelUploads', []);
      }
    },
    transferData() {
      this.$refs.fileInput.value = null;
      this.$emit('saveBulkDeposit', this.jsonArray);

    }
  },
};
</script>