<template>
  <div class="container-fluid">
    <div class="row">
      <h5 class="akkurate-dark mb-0">Pending Listing</h5>
    </div>
    <hr class="py-0" />
    <div class="row">
      <div class="col-12 col-md-4 mb-4">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
            </b-card>
          </template>
          <div class="card border-start-success py-2 card-shape home-box">
            <div class="card-body">
              <div class="row align-items-center no-gutters">
                <div class="col-12 col-md-12">
                  <div class="text-uppercase fw-bold text-sm mb-1 akkurate-skyblue">
                    <span>Pending Journal</span>
                  </div>
                </div>
                <div class="col-md-4 col-4">
                  <div class="text-uppercase text-xs mb-1 akkurate-skyblue">
                    <span>Today</span>
                  </div>
                </div>
                <div class="col-md-2 col-2">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-skyblue">
                    <span>{{ pending_journal?.today_count }}</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-skyblue">
                    <span>{{ convertNumberToCurrency(pending_journal?.today_amount) }}</span>
                  </div>
                </div>
                <div class="col-md-4 col-4">
                  <div class="text-uppercase text-xs mb-1 akkurate-skyblue">
                    <span>Month</span>
                  </div>
                </div>
                <div class="col-md-2 col-2">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-skyblue">
                    <span>{{ pending_journal?.month_count }}</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-skyblue">
                    <span> {{ convertNumberToCurrency(pending_journal?.month_amount) }}</span>
                  </div>
                </div>
                <div class="col-md-4 col-4">
                  <div class="text-uppercase text-xs mb-1 akkurate-skyblue">
                    <span>Total</span>
                  </div>
                </div>
                <div class="col-md-2 col-2">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-skyblue">
                    <span>{{ pending_journal?.total_count }}</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-skyblue">
                    <span>{{ convertNumberToCurrency(pending_journal?.total_amount) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>

      </div>
      <div class="col-12 col-md-4 mb-4">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
            </b-card>
          </template>
          <div class="card border-start-success py-2 card-shape home-box">
            <div class="card-body">
              <div class="row align-items-center no-gutters">
                <div class="col-12 col-md-12">
                  <div class="text-uppercase fw-bold text-sm mb-1 akkurate-skyblue">
                    <span>Pending Voucher</span>
                  </div>
                </div>
                <div class="col-md-4 col-4">
                  <div class="text-uppercase text-xs mb-1 akkurate-skyblue">
                    <span>Today</span>
                  </div>
                </div>
                <div class="col-md-2 col-2">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-skyblue">
                    <span>{{ pending_voucher?.today_count }}</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-skyblue">
                    <span>{{ convertNumberToCurrency(pending_voucher?.today_amount) }}</span>
                  </div>
                </div>
                <div class="col-md-4 col-4">
                  <div class="text-uppercase text-xs mb-1 akkurate-skyblue">
                    <span>Month</span>
                  </div>
                </div>
                <div class="col-md-2 col-2">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-skyblue">
                    <span>{{ pending_voucher.month_count }}</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-skyblue">
                    <span>{{ convertNumberToCurrency(pending_voucher.month_amount) }}</span>
                  </div>
                </div>
                <div class="col-md-4 col-4">
                  <div class="text-uppercase text-xs mb-1 akkurate-skyblue">
                    <span>Total</span>
                  </div>
                </div>
                <div class="col-md-2 col-2">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-skyblue">
                    <span>{{ pending_voucher?.total_count }}</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-skyblue">
                    <span>{{ convertNumberToCurrency(pending_voucher?.total_amount) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>

      </div>
      <div class="col-12 col-md-4 mb-4">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
            </b-card>
          </template>
          <div class="card border-start-success py-2 card-shape home-box">
            <div class="card-body">
              <div class="row align-items-center no-gutters">
                <div class="col-12 col-md-12">
                  <div class="text-uppercase fw-bold text-sm mb-1 akkurate-skyblue">
                    <span>Pending Loans</span>
                  </div>
                </div>
                <div class="col-md-4 col-4">
                  <div class="text-uppercase text-xs mb-1 akkurate-skyblue">
                    <span>Today</span>
                  </div>
                </div>
                <div class="col-md-2 col-2">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-skyblue">
                    <span>{{ pending_loans?.today_count }}</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-skyblue">
                    <span>{{ convertNumberToCurrency(pending_loans?.today_amount) }}</span>
                  </div>
                </div>
                <div class="col-md-4 col-4">
                  <div class="text-uppercase text-xs mb-1 akkurate-skyblue">
                    <span>Month</span>
                  </div>
                </div>
                <div class="col-md-2 col-2">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-skyblue">
                    <span>{{ pending_loans?.month_count }}</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-skyblue">
                    <span>{{ convertNumberToCurrency(pending_loans?.month_amount) }}</span>
                  </div>
                </div>
                <div class="col-md-4 col-4">
                  <div class="text-uppercase text-xs mb-1 akkurate-skyblue">
                    <span>Total</span>
                  </div>
                </div>
                <div class="col-md-2 col-2">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-skyblue">
                    <span>{{ pending_loans?.total_count }}</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-skyblue">
                    <span>{{ convertNumberToCurrency(pending_loans?.total_amount) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>

      </div>
    </div>
    <div class="row">
      <h5 class="akkurate-dark mb-0">Approved Listing</h5>
    </div>
    <hr class="py-0" />
    <div class="row">
      <div class="col-12 col-md-4 mb-4">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
            </b-card>
          </template>
          <div class="card border-start-success py-2 card-shape home-box">
            <div class="card-body">
              <div class="row align-items-center no-gutters">
                <div class="col-12 col-md-12">
                  <div class="text-uppercase fw-bold text-sm mb-1 akkurate-green">
                    <span>Approved Journal</span>
                  </div>
                </div>
                <div class="col-md-4 col-4">
                  <div class="text-uppercase text-xs mb-1 akkurate-green">
                    <span>Today</span>
                  </div>
                </div>
                <div class="col-md-2 col-2">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-green">
                    <span>{{ approved_journal?.today_count }}</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-green">
                    <span>{{ convertNumberToCurrency(approved_journal?.today_amount) }}</span>
                  </div>
                </div>
                <div class="col-md-4 col-4">
                  <div class="text-uppercase text-xs mb-1 akkurate-green">
                    <span>Month</span>
                  </div>
                </div>
                <div class="col-md-2 col-2">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-green">
                    <span>{{ approved_journal?.month_count }}</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-green">
                    <span>{{ convertNumberToCurrency(approved_journal?.month_amount) }}</span>
                  </div>
                </div>
                <div class="col-md-4 col-4">
                  <div class="text-uppercase text-xs mb-1 akkurate-green">
                    <span>Total</span>
                  </div>
                </div>
                <div class="col-md-2 col-2">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-green">
                    <span>{{ approved_journal?.total_count }}</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-green">
                    <span>{{ convertNumberToCurrency(approved_journal?.total_amount) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>

      </div>
      <div class="col-12 col-md-4 mb-4">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
            </b-card>
          </template>
          <div class="card border-start-success py-2 card-shape home-box">
            <div class="card-body">
              <div class="row align-items-center no-gutters">
                <div class="col-12 col-md-12">
                  <div class="text-uppercase fw-bold text-sm mb-1 akkurate-green">
                    <span>Approved Voucher</span>
                  </div>
                </div>
                <div class="col-md-4 col-4">
                  <div class="text-uppercase text-xs mb-1 akkurate-green">
                    <span>Today</span>
                  </div>
                </div>
                <div class="col-md-2 col-2">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-green">
                    <span>{{ approved_voucher?.today_count }}</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-green">
                    <span>{{ convertNumberToCurrency(approved_voucher?.today_amount) }}</span>
                  </div>
                </div>
                <div class="col-md-4 col-4">
                  <div class="text-uppercase text-xs mb-1 akkurate-green">
                    <span>Month</span>
                  </div>
                </div>
                <div class="col-md-2 col-2">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-green">
                    <span>{{ approved_voucher?.month_count }}</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-green">
                    <span>{{ convertNumberToCurrency(approved_voucher?.month_amount) }}</span>
                  </div>
                </div>
                <div class="col-md-4 col-4">
                  <div class="text-uppercase text-xs mb-1 akkurate-green">
                    <span>Total</span>
                  </div>
                </div>
                <div class="col-md-2 col-2">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-green">
                    <span>{{ approved_voucher?.total_count }}</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-green">
                    <span>{{ convertNumberToCurrency(approved_voucher?.total_amount) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>

      </div>
      <div class="col-12 col-md-4 mb-4">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
            </b-card>
          </template>
          <div class="card border-start-success py-2 card-shape home-box">
            <div class="card-body">
              <div class="row align-items-center no-gutters">
                <div class="col-12 col-md-12">
                  <div class="text-uppercase fw-bold text-sm mb-1 akkurate-green">
                    <span>Approved Loans</span>
                  </div>
                </div>
                <div class="col-md-4 col-4">
                  <div class="text-uppercase text-xs mb-1 akkurate-green">
                    <span>Today</span>
                  </div>
                </div>
                <div class="col-md-2 col-2">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-green">
                    <span>{{ approved_loans?.today_count }}</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-green">
                    <span>{{ convertNumberToCurrency(approved_loans?.today_amount) }}</span>
                  </div>
                </div>
                <div class="col-md-4 col-4">
                  <div class="text-uppercase text-xs mb-1 akkurate-green">
                    <span>Month</span>
                  </div>
                </div>
                <div class="col-md-2 col-2">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-green">
                    <span>{{ approved_loans?.month_count }}</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-green">
                    <span>{{ convertNumberToCurrency(approved_loans?.month_amount) }}</span>
                  </div>
                </div>
                <div class="col-md-4 col-4">
                  <div class="text-uppercase text-xs mb-1 akkurate-green">
                    <span>Total</span>
                  </div>
                </div>
                <div class="col-md-2 col-2">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-green">
                    <span>{{ approved_loans?.total_count }}</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-green">
                    <span>{{ convertNumberToCurrency(approved_loans?.total_amount) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>

      </div>
    </div>
    <div class="row">


      <div class="col-12 col-md-3 col-xl-4">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
            </b-card>
          </template>

          <div class="card mb-4 card-shape home-box">
            <div class="card-header py-3">
              <div class="row">
                <div class="col-12">
                  <h6 class="m-0 fw-bold akkurate-dark">
                    Memeber Account Listing
                  </h6>
                </div>
              </div>
            </div>
            <div class="card-body py-0 card-height">
              <b-table-simple responsive v-for="(item, value) in items" :key="value">
                <b-thead>
                  <b-tr>
                    <b-th width="200">{{ item.branch_name }}</b-th>
                    <b-th>Today</b-th>
                    <b-th>Month</b-th>
                    <b-th>Total</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td>Active</b-td>
                    <b-td>{{ item.today.today_active }}</b-td>
                    <b-td>{{ item.monthly.monthly_active }}</b-td>
                    <b-td>{{ item.total.total_active }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Inactive</b-td>
                    <b-td>{{ item.today.today_inactive }}</b-td>
                    <b-td>{{ item.monthly.monthly_inactive }}</b-td>
                    <b-td>{{ item.total.total_inactive }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Dormant</b-td>
                    <b-td>{{ item.today.today_dormant }}</b-td>
                    <b-td>{{ item.monthly.monthly_dormant }}</b-td>
                    <b-td>{{ item.total.total_dormant }}</b-td>
                  </b-tr>
                </b-tbody>
                <!-- <b-tfoot>
                  <b-tr>
                    <b-th>Heading 1</b-th>
                    <b-th>Heading 2</b-th>
                    <b-th>Heading 3</b-th>
                    <b-th>Heading 4</b-th>
                  </b-tr>
                </b-tfoot> -->
              </b-table-simple>
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>
      <div class="col-md-4 col-xl-4 mb-4">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="30%" height="20%"></b-skeleton>
            </b-card>
          </template>
          <div class="card mb-4 card-shape home-box">
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h6 class="m-0 fw-bold akkurate-dark">Branch Summary</h6>
            </div>
            <div class="card-body card-height">
              <div>
                <bar-chart :labels="labels" :data="data" />
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>
      <div class="col-md-4 col-xl-4 mb-4">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box mb-4">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
            </b-card>
          </template>
          <div class="card mb-4 card-shape home-box">
            <div class="card-header py-3">
              <div class="row">
                <div class="col-6">
                  <h6 class="m-0 fw-bold akkurate-dark">Collection Tracker</h6>
                </div>
              </div>
              <div>
              </div>
            </div>
            <div class="card-body session-table-height">
              <b-table class="small" :fields="collect_fields" :items="collect_items" striped responsive
                :per-page="perPage" :current-page="currentPage" small>
              </b-table>
            </div>
            <div class="card-footer">
              <b-pagination class="text-end" v-model="currentPage" :total-rows="rows" :per-page="perPage"
                  aria-controls="daily-control-tb" align="right">
                </b-pagination>
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>
      <div class="col-md-4 col-xl-4 mb-4">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box mb-4">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
            </b-card>
          </template>
          <div class="card mb-4 card-shape home-box">
            <div class="card-header py-3">
              <div class="row">
                <div class="col-6">
                  <h6 class="m-0 fw-bold akkurate-dark">USSD Tracker</h6>
                </div>
              </div>
              <div>
              </div>
            </div>
            <div class="card-body">
              <b-table class="small" :fields="ussd_fields" :items="ussd_items" hover striped
                responsive small></b-table>
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import BarChart from "../Charts/BarChart.vue";
export default {
  components: {
    BarChart
  },
  data() {
    return {
      loading: false,
      labels: [],
      data: [],
      fields: [],
      collect_items: [],
      perPage: 5,
      currentPage: 1,
      items: [],
      pending_journal: {},
      pending_voucher: {},
      pending_loans: {},
      approved_journal: {},
      approved_voucher: {},
      approved_loans: {},
      ussd_fields: [
        {
          key: "type",
          label: "USSD Usage"
        },
        {
          key: "today",
          lable: "Today"
        },
        {
          key: "month",
          label: "Month"
        },
        {
          key: "year",
          label: "YTD"
        }
      ],
      ussd_items: [],


    };
  },
  created() {
    // Call your API here
    this.getOperationApi();
  },
  computed: {
    rows() {
      return this.collect_items.length;
    },
  },
  methods: {
    async getOperationApi() {
      // Your API call logic goes here
      //   console.log("GEN Man API called!");
      this.loading = true;
      await ApiService.query("/dashboard/operations")
        .then((response) => {
          console.log(response.data);
          this.items = response.data.branch;
          this.collect_items = response.data.collection_tracker;
          this.pending_journal = response.data.pending_journal;
          this.pending_voucher = response.data.pending_voucher;
          this.pending_loans = response.data.pending_loans;
          this.approved_journal = response.data.approved_journal;
          this.approved_voucher = response.data.approved_voucher;
          this.approved_loans = response.data.approved_loans;
          this.labels = response.data.branches.branch_names;
          this.data = response.data.branches.branch_total;
          this.ussd_items = response.data.ussd_summary;

          // this.fields = branch;
        })
        .catch(function (error) {
          // this.isLoading = false;
          // return error;
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    convertNumberToCurrency(number) {
      const formattedCurrency = number?.toLocaleString("en-US", {
        style: "currency",
        currency: "GHS", // Change this to your desired currency code (e.g., 'EUR', 'GBP', 'JPY', etc.)
      });
      return formattedCurrency;
    },
  },
};
</script>
<style scoped>
.session-table-height {
  height: 230px;
}
.card-height{
  height: 310px;
}

</style>