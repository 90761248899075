<template>
    <div>
        <a title="View Pictures" class="btn btn-sm akkurate-warning text-white" v-b-modal="String(data.id)">
            <span class="akkurate-small"> <font-awesome-icon :icon="['fas', 'eye']" /></span> 
        </a>
       <b-modal :id="String(data.id)" size="lg" @shown="showPictures">
        <template #modal-title>
            <p class="akkurate-green fw-bold">Pictures</p>
        </template>
        <div class="container">
            <div class="row">
                <b-carousel
                    id="carousel-1"
                    v-model="slide"
                    :interval="0"
                    controls
                    background="#ababab"
                    img-width="1024"
                    img-height="480"
                    style="text-shadow: 1px 1px 2px #333;"
                    @sliding-start="onSlideStart"
                    @sliding-end="onSlideEnd"
                    >
                    <!-- Text slides with image -->
                    <!-- <b-carousel-slide
                        caption="First slide"
                        text="Nulla vitae elit libero, a pharetra augue mollis interdum."
                        img-src="https://picsum.photos/1024/480/?image=52"
                    ></b-carousel-slide> -->

                    <!-- Slides with custom text -->
                    <!-- <b-carousel-slide img-src="https://picsum.photos/1024/480/?image=54">
                        <h1>Hello world!</h1>
                    </b-carousel-slide> -->

                    <!-- Slides with image only -->
                    
                    <b-carousel-slide v-for="(field,index) in this.data" :key="index" :img-src="`https://ggbccuapi.akkuratebank.com/${field.file_name}`">
                        <h4>ID : {{ field.id }}</h4>
                        <h4>{{ index + 1 }} of {{ data.length }}</h4>
                    </b-carousel-slide>

                    <!-- Slides with img slot -->
                    <!-- Note the classes .d-block and .img-fluid to prevent browser default image alignment -->
                    <!-- <b-carousel-slide>
                        <template #img>
                        <img
                            class="d-block img-fluid w-100"
                            width="1024"
                            height="480"
                            src="https://picsum.photos/1024/480/?image=55"
                            alt="image slot"
                        >
                        </template>
                    </b-carousel-slide> -->

                    <!-- Slide with blank fluid image to maintain slide aspect ratio -->
                    <!-- <b-carousel-slide caption="Blank Image" img-blank img-alt="Blank image">
                        <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eros felis, tincidunt
                        a tincidunt eget, convallis vel est. Ut pellentesque ut lacus vel interdum.
                        </p>
                    </b-carousel-slide> -->
                    </b-carousel>
            </div>
        </div>
        <template #modal-footer>
                    <div class="w-100">
                    <!-- begin loader -->
                    <!-- <beat-loader-component 
                    class="float-left"
                    v-if="isLoading"
                    :isLoading="isLoading"
                    ></beat-loader-component> -->
                    <!-- end loader -->
                    <!-- Emulate built in modal footer ok and cancel button actions -->
                    <b-button @click="$bvModal.hide(String(data.id))" type="submit" class="akkurate-grey me-2 btn-size  float-right"><font-awesome-icon class="me-2" :icon="['fas', 'close']" />Close</b-button>     
                    </div>
        </template>
       </b-modal>
    </div>
</template>
<script>
export default {
    props: ['data'],
    data() {
        return {
            slide: 0,
            sliding: null
        }
    },
    methods: {
        onSlideStart(slide) {
            this.sliding = true
        },
        onSlideEnd(slide) {
            this.sliding = false
        },
        showPictures() {
            this.data.map(index => {
                console.log(index.file_name);
            })
            
        }
    }

}
</script>