<template>
  <div>
    <b-button size="sm" class="fw-semibold akkurate-green-btn" v-b-modal.add-account>
        <span class="akkurate-small">
            <i class="fas fa-plus"></i> 
            Add Acounts
        </span>
    </b-button>
    <b-modal
      id="add-account"
      title="Add Account"
      ref="modal"
      size="lg"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk">
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-6">
            <b-form-group
              label-for="coa-head"
              invalid-feedback="Account type is required"
              :state="validateState('account_type')"
              class="mb-3"
            >
              <template #label>
                <label class="akkurate-dark" for="account_type"
                  >Account type: <star></star
                ></label>
              </template>
              <multiselect
                :disabled="add_or_edit == 'edit'"
                v-validate="{ required: true }"
                v-model="form.account_type"
                name="account_type"
                :state="validateState('account_type')"
                placeholder="Search or select"
                :options="account_types"
                class="mt-2 field-container"
                label="name"
                required
              ></multiselect>
              <errors v-if="form.errors.account_type">
                {{ form.errors.account_type[0] }}
              </errors>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label-for="account_balance" class="mb-3">
              <template #label>
                <label class="akkurate-dark" for="account_balance"
                  >Opening Balance <star></star
                ></label>
              </template>
              <b-form-input
                id="account_balance"
                v-model="form.account_balance"
                v-validate="{ required: true }"
                :state="validateState('account_balance')"
                name="account_balance"
                class="mt-2 field-container fields"
                type="number"
                value="0"
                disabled
              ></b-form-input>
              <b-form-invalid-feedback>
                The Balance field is required
              </b-form-invalid-feedback>
              <errors v-if="form.errors.account_balance">
                {{ form.errors.account_balance[0] }}
              </errors>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label-for="status"
              invalid-feedback="Status Type is required"
              :state="validateState('status')"
              class="mb-3"
            >
              <template #label>
                <label class="akkurate-dark" for="status"
                  >Status <star></star
                ></label>
              </template>
              <multiselect
                name="status"
                :disabled="add_or_edit == 'edit'"
                v-validate="{ required: true }"
                :state="validateState('status')"
                v-model="form.status"
                :options="status_options"
                class="mt-2 field-container"
              ></multiselect>
              <b-form-invalid-feedback>
                The Status field is required
              </b-form-invalid-feedback>
              <errors v-if="form.errors.status">
                {{ form.errors.status[0] }}
              </errors>
            </b-form-group>
          </div>
        </div>
      </form>
      <template #modal-footer="{ ok }">
        <div class="w-100">
          <!-- begin loader -->
          <!-- <beat-loader-component 
                class="float-left"
                v-if="isLoading"
                :isLoading="isLoading"
                ></beat-loader-component> -->
          <!-- end loader -->
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <b-button
            @click="$bvModal.hide('add-account')"
            type="button"
            class="akkurate-grey-btn me-2 float-right"
            >Close</b-button
          >
          <b-button
            @click="ok()"
            type="submit"
            class="akkurate-green-btn me-2 float-right"
            >Add</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import ApiService from "@/core/services/api.service";
export default {
  name: "AddAccount",
  components: {
    Multiselect,
  },
  props: ["account_types"],
  mounted() {
    // this.loadPageOptions();
  },
  data() {
    return {
      form: {
        account_id: "",
        account_balance: "",
        account_type: "",
        status: "Active",
        errors: [],
      },
      // account_types: [],
      status_options: ["Active", "Inactive"],
      isSaving: false,
      add_or_edit: "add",
    };
  },
  methods: {
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    resetModal() {
      this.form.account_id = "";
      this.form.account_balance = "";
      this.form.account_type = "";
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    // loadPageOptions() {
    //     this.$Progress.start();
    //     this.isLoading = true;
    //     ApiService.post("/accounts/account-types/dropdown")
    //         .then((response) => {
    //             console.log(response.data.data);
    //             this.account_types = response.data.data;
    //             // this.branches = response.data.branches;
    //             this.isLoading = false;
    //             // $emit('coa-head-saved', response.data)
    //             this.$Progress.finish();
    //         })
    //         .catch((error) => {
    //             this.isLoading = false;
    //             this.$Progress.fail();
    //         });
    // },
    handleSubmit() {
      // Exit when the form isn't valid
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }
        console.log("Saving....");
        this.isSaving = true;
        // this.$Progress.start();
        this.form.errors = [];
        // this.form
        // ApiService.post("",
        //     this.form
        // )
        //     .then((response) => {
        //         this.isSaving = false;
        //         this.$Progress.finish();

        //         toast.fire({
        //             icon: "success",
        //             title: response.data.message,
        //         });

        //         this.$emit("account-type-saved", response.data.data);

        //         // Hide Modal Manually
        //         this.$nextTick(() => {
        //             this.$bvModal.hide('account-type')
        //         })

        //         // window.location.reload()
        //         //   this.isSaving = false;
        //         //   this.form.phone_number = "";
        //         //   this.form.email = "";
        //         //   this.form.selected_payment_method = "";
        //     })
        //     .catch((error) => {
        //         this.isSaving = false;
        //         this.$Progress.fail();
        //         this.form.errors = [];
        //         if (error.response.data.errors) {
        //             console.log(error.response.data.errors)
        //             this.form.errors = error.response.data.errors;
        //         }
        //         // console.log(error.response.data)
        //     });
        this.form.account_balance = 0;
        this.$emit("addAccount", this.form);
        this.$bvModal.hide("add-account");
      });
      // Hide the modal manually
    },
  },
};
</script>