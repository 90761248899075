<template>
  <div class="container-fluid">
    <div class="row">
      <h5 class="akkurate-dark mb-0">Today's Transactions</h5>
    </div>
    <hr class="py-0" />
    <div class="row mb-3">
      <div class="col-xl-3 col-sm-6 col-12">
        <div class="card border-start-success card-shape home-box">
          <div class="card-content">
            <div class="card-body akkurate-green-bg">
              <div class="media d-flex">
                <div class="align-self-center">
                  <i class="icon-speech warning font-large-2 float-left"></i>
                </div>
                <div class="media-body text-right">
                  <h3 class="text-white">
                    GHS {{ todays_transactions.deposits }}
                  </h3>
                  <span class="text-white">Successful Deposits</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-sm-6 col-12">
        <div class="card border-start-success card-shape home-box">
          <div class="card-content">
            <div class="card-body akkurate-warning-bg">
              <div class="media d-flex">
                <div class="align-self-center">
                  <i class="icon-speech warning font-large-2 float-left"></i>
                </div>
                <div class="media-body text-right">
                  <h3 class="text-white">
                    GHS {{ todays_transactions.withdrawals }}
                  </h3>
                  <span class="text-white">Successful Withdrawals</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-sm-6 col-12">
        <div class="card border-start-success card-shape home-box">
          <div class="card-content">
            <div class="card-body akkurate-gray-bg">
              <div class="media d-flex">
                <div class="align-self-center">
                  <i class="icon-speech warning font-large-2 float-left"></i>
                </div>
                <div class="media-body text-right">
                  <h3 class="text-white">
                    GHS {{ todays_transactions.loan_repayments }}
                  </h3>
                  <span class="text-white">Successful Loan Repayments</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-sm-6 col-12">
        <div class="card border-start-success card-shape home-box">
          <div class="card-content">
            <div class="card-body akkurate-danger-bg">
              <div class="media d-flex">
                <div class="align-self-center">
                  <i class="icon-speech warning font-large-2 float-left"></i>
                </div>
                <div class="media-body text-right">
                  <h3 class="text-white">
                    GHS {{ todays_transactions.loan_repayments }}
                  </h3>
                  <span class="text-white">Successful Loan Disbursements</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <h5 class="akkurate-dark mb-0">Financial Transactions</h5>
    </div>
    <hr class="py-0" />
    <div class="row">
      <LineChartGenerator
       v-if="isLoading" 
       id="lineChart" 
       :options="chartOptions" 
       :data="chartData" />
    </div>
  </div>
</template>
<script>
import DoughnutChart from "../Charts/DoughnutChart.vue";
import LineChart from "../Charts/LineChart.vue";
import DepositTrendLineChart from "../Charts/DepositTrendLineChart.vue";
import ApiService from "@/core/services/api.service";
import { Line as LineChartGenerator } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);

export default {
  components: {
    DoughnutChart,
    LineChart,
    DepositTrendLineChart,
    LineChartGenerator,
  },
  // props:['show'],
  props: {
    showDashboard: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    // Call your API here
    this.getBoardChairman();
  },
  data() {
    return {
      roles: "",
      isLoading: true,
      loan_products: [],
      todays_transactions: {},
      chartData: {
        datasets: [],
      },
      chartOptions: {
        responsive: true,
      }
    };
  },
  methods: {
    async getBoardChairman() {
      await ApiService.get("/dashboard/board-chairman")
        .then((response) => {
          this.loan_products = response.data.loan_products;
          this.disbursed_loans_count = response.data.disbursed_loans_count;
          this.todays_transactions = response.data.today;
          this.chartData = {
            labels: response.data.labels,
            datasets: [
              {
                label: "Successful Deposits",
                data: response.data.deposits_dataset,
                borderColor: "#01a790",
                backgroundColor: "#01a790",
                borderWidth: 2,
              },
              {
                label: "Withdrawals",
                data: response.data.withdrawals_dataset,
                borderColor: "#dc3545",
                backgroundColor: "#dc3545",
                borderWidth: 2,
              },
              {
                label: "Successful Withdrawals",
                data: response.data.withdrawals_dataset,
                fill: false,
                borderColor: "#EE0000",
                backgroundColor: "#EE0000",
                borderWidth: 2,
              },
              {
                label: "Successful Loan Repayments",
                data: response.data.loan_repayments_dataset,
                fill: false,
                borderColor: "#94BA23",
                backgroundColor: "#94BA23",
                borderWidth: 2,
              },
            ],
          };
        })
        .catch(function (error) {
          // this.isLoading = false;
          // return error;
        });
    },
  },
  //   mounted() {
  //     let token = localStorage.getItem("token");
  //     if (!token) {
  //       this.$router.push({ path: "/login" });
  //     }
  //   },
};
</script>
<style>
.card-top-dashboard {
  min-height: 140px;
}
</style>