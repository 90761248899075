<template>
    <div>
       <a v-b-modal="String(data.id + 'advice')" class="dropdown-item" @click.prevent href="">Advice</a>
       <!-- <b-button class="akkurate-green-btn" >Advance Search</b-button> -->
       <b-modal 
        :id="String(data.id + 'advice')"
        @shown="modalShown"
        size="xl"
        @ok="handleOk">
        <template #modal-title>
            <p class="m-0 akkurate-green fw-bold">Investment Advice</p>
        </template>
        <div>
            <div class="container">
                <div class="row">
                    <!-- <div class="col-12">
                        <beat-loader-component
                        v-if="isLoading"
                        :isLoading="isLoading"
                        ></beat-loader-component>
                    </div> -->
                    <div class="col-md-4 col-4 d-flex align-items-center flex-column bd-highlight">
                        <img class="img-fluid img-visibility statement-logo" :src="require('@/assets/img/default_logo.png')" >
                        <p class="mt-2 akkurate-green fw-bold mt-auto p-2 bd-highlight">INVESTMENT ADVICE</p>
                    </div>
                    <div class="d-flex align-items-end flex-column bd-highlight col-md-8 col-8 text-end">
                        <p class="fw-bold text-uppercase mb-0">{{ institution.name }}</p>
                        <p class="fw-bold">{{ institution.motto }}</p>
                        <p class="mb-0 akkurate-small">{{ institution.postal }}</p>
                        <p class="mb-0 akkurate-small">{{ institution.telephone }}</p>
                        <p class="akkurate-small">{{ institution.email }}</p>
                        <p class="fw-bold text-end mt-auto p-2 bd-highlight text-uppercase">{{ new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'long', year: 'numeric' }).format(Date.now()) }}</p>
                    </div> 
                </div>
            </div>
            <div class="py-2 text-center akkurate-skyblue-bg">
                <span class="text-white fw-bold">
                    INVESTMENT ADVICE
                </span>
            </div>
            <div class="container mt-3">
                <div class="row">
                    <div class="col-md-3 col-12 text-center">
                        <img src="@/assets/img/user-avater.jpg" style="height:200px" class="img-fluid" alt="user-profile"/>
                        <p class="mt-2 akkurate-green fw-bold">Preview</p>
                    </div>
                    <div class="col-md-9 col-12 mt-3">
                        <div class="row">
                            <div class="col-md-6">
                                <h6 class="fw-bold akkurate-skyblue">{{ getFullName }}</h6>
                            </div>
                            <div class="col-md-6 text-end">
                                <h6><span class="fw-bold akkurate-skyblue">Investment Code</span>  {{ data.code }}</h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="row">
                                    <div class="col-md-8">
                                        <p class="small mb-0 akkurate-skyblue">Status</p>
                                    </div>
                                    <div class="col-md-4">
                                        <p class="small mb-0 akkurate-skyblue">{{ data.status }}</p>
                                    </div>
                                    <div class="col-md-8">
                                        <p class="small mb-0 akkurate-skyblue">Interest Rate</p>
                                    </div>
                                    <div class="col-md-4">
                                        <p class="small mb-0 akkurate-skyblue">{{ data.interest_rate }}</p>
                                    </div>
                                    <div class="col-md-8">
                                        <p class="small mb-0 akkurate-skyblue">Tenure</p>
                                    </div>
                                    <div class="col-md-4">
                                        <p class="small mb-0 akkurate-skyblue">{{ data.tenor }}</p>
                                    </div>
                                    <div class="col-md-8">
                                        <p class="small mb-0 akkurate-skyblue">Interest Expected</p>
                                    </div>
                                    <div class="col-md-4">
                                        <p class="small mb-0 akkurate-skyblue">{{ data.interest_expected }}</p>
                                    </div>
                                    <div class="col-md-8">
                                        <p class="small mb-0 akkurate-skyblue">Interest Paid</p>
                                    </div>
                                    <div class="col-md-4">
                                        <p class="small mb-0 akkurate-skyblue"></p>
                                    </div>
                                    <div class="col-md-8">
                                        <p class="small mb-0 akkurate-skyblue">Interest Due</p>
                                    </div>
                                    <div class="col-md-4">
                                        <p class="small mb-0 akkurate-skyblue"></p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="row">
                                    <div class="col-md-8">
                                        <p class="small mb-0 akkurate-skyblue">Last Redemption Date</p>
                                    </div>
                                    <div class="col-md-4">
                                        <p class="small mb-0 akkurate-skyblue">{{ data.status }}</p>
                                    </div>
                                    <div class="col-md-8">
                                        <p class="small mb-0 akkurate-skyblue">Current Duration</p>
                                    </div>
                                    <div class="col-md-4">
                                        <p class="small mb-0 akkurate-skyblue">{{ data.status }}</p>
                                    </div>
                                    <div class="col-md-8">
                                        <p class="small mb-0 akkurate-skyblue">Total Principal</p>
                                    </div>
                                    <div class="col-md-4">
                                        <p class="small mb-0 akkurate-skyblue">{{ data.principal_amount }}</p>
                                    </div>
                                    <div class="col-md-8">
                                        <p class="small mb-0 akkurate-skyblue">Principal Paid</p>
                                    </div>
                                    <div class="col-md-4">
                                        <p class="small mb-0 akkurate-skyblue">{{ data.status }}</p>
                                    </div>
                                    <div class="col-md-8">
                                        <p class="small mb-0 akkurate-skyblue">Principal Bal</p>
                                    </div>
                                    <div class="col-md-4">
                                        <p class="small mb-0 akkurate-skyblue">{{ data.status }}</p>
                                    </div>
                                    <div class="col-md-8">
                                        <p class="small mb-0 akkurate-skyblue">Maturity Value</p>
                                    </div>
                                    <div class="col-md-4">
                                        <p class="small mb-0 akkurate-skyblue"></p>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-md-4 mt-auto">
                                <div class="row">
                                    <div class="col-md-8">
                                        <p class="small mb-0 akkurate-skyblue">Share Bal</p>
                                    </div>
                                    <div class="col-md-4">
                                        <p class="small mb-0 akkurate-skyblue">{{ data.status }}</p>
                                    </div>
                                    <div class="col-md-8">
                                        <p class="small mb-0 akkurate-skyblue">Saving Bal</p>
                                    </div>
                                    <div class="col-md-4">
                                        <p class="small mb-0 akkurate-skyblue">{{ data.status }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    </div>
                </div>
                <div class="row trans_details">
                    <b-table striped hover :items="items" :fields="fields" :busy="isBusy">
                        <template #table-busy>
                            <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Loading...</strong>
                            </div>
                        </template>
                    </b-table>
                </div>
            </div>
        </div>
        <template #modal-footer="{ ok }">
               <div class="w-100">
               <!-- begin loader -->
               <!-- <beat-loader-component 
               class="float-left"
               v-if="isLoading"
               :isLoading="isLoading"
               ></beat-loader-component> -->
               <!-- end loader -->
               <!-- Emulate built in modal footer ok and cancel button actions -->
                <!-- <b-button @click="$bvModal.hide('advance_search')" type="button" class="akkurate-grey-btn btn-size float-right border-0">Close</b-button> -->
                <b-button @click="ok()" class="akkurate-green-btn btn-size me-2 float-right border-0"><font-awesome-icon class="me-2" :icon="['fas', 'save']" />Save</b-button>     
               </div>
        </template>

       </b-modal>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import ApiService from '@/core/services/api.service';
export default {
    props: ['data', 'institution'],
    components: {
        Multiselect
    },
    data() {
        return {
            form: {
                mandate: "",
                mode: "Cash",
            },
            isBusy: false,
            status_options: ['Pending', 'Running'],
            fields: [
                {
                    key: 'code',
                    label: "FD CODE"
                },
                {
                    key: 'scheme_name',
                    label: "Scheme Name"
                },
                {
                    key: 'status',
                    label: 'Status',

                },
                {
                    key: 'principal_amount',
                    label: 'Principal',

                },
                {
                    key: 'interest_rate',
                    label: 'Rate',

                },
                {
                    key: 'start_date',
                    label: 'Start Date',

                },
                {
                    key: 'tenor',
                    label: 'Tenure',

                },
                {
                    key: 'maturity_date',
                    label: 'Maturity Date',

                },
                {
                    key: 'duration',
                    label: 'Duration',

                },
                {
                    key: 'int_value',
                    label: 'INT.VALUE',

                },
                {
                    key: 'current_value',
                    label: 'Current Value',

                },
            ],
            items: [
                // { fd_code: 412, scheme_name: 'sample text', status: 'Running', principal: 'sample text', rate: 'sample text', start_date: 'sample text', tenor: 'sample text', maturity_date: 'sample text', duration: "smaple text", int_value: 'sample text', current_value: 'sample text' },
            ]
        }
    },
    computed: {
        getFullName() {
            var first_name = this.data?.customer?.first_name != null ? this.data?.customer?.first_name : "";
            var middlename = this.data?.customer?.middlename != null ? this.data?.customer?.middlename : "";
            var last_name = this.data?.customer?.last_name != null ? this.data?.customer?.last_name : "";

            return first_name + ' ' + middlename + ' ' + last_name;
        }
    },
    methods: {
        validateState(ref) {
            if (
                this.veeFields[ref] &&
                (this.veeFields[ref].dirty || this.veeFields[ref].validated)
            ) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        resetForm() {
            this.form = {
                mandate: null,
                mode: null
            };

            this.$nextTick(() => {
                this.$validator.reset();
            });
        },
        handleOk(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
        },
        handleSubmit() {
            this.$validator.validateAll().then(result => {
                if (!result) {
                    return;
                }

                alert("Form submitted!");
            });
        },
        async getFDCustomers() {
            this.isBusy = true;
            await ApiService.query('/investments/fixed-term-deposits', {
                params: {
                    "page": "",
                    "per_page": "",
                    "branch_ids": "", //ie fixed deposit number
                    "start_date": "",
                    "end_date": "",
                    "status": "", // "COMPLETED", "PENDING", "FAILED"
                    "customer_code": this.data.customer.id,
                }
            }).then((response) => {
                this.isBusy = false;
                console.log(response.data.data);
                this.items = response.data.data;
                // this.rows = response.data.data;
            }).catch((error) => {
                this.isBusy = false;
                console.log(error);
            });
        },
        modalShown(){
            this.getFDCustomers();
        }
    }
}
</script>