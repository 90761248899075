<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-4 col-sm-4 col-12">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="30%" height="20%"></b-skeleton>
              <b-skeleton width="50%" height="20%"></b-skeleton>
              <b-skeleton width="90%" height="20%"></b-skeleton>
            </b-card>
          </template>
          <div class="card mb-4 card-shape home-box">
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h6 class="m-0 fw-bold akkurate-dark">Income & Expenses</h6>
            </div>
            <div class="card-body default-chart-size">
              <div class="pie-chart-area">
                <DoughnutChart :chartData="chartDataIncome" />
              </div>
              <hr />
              <div class="row">
                <div class="col akkurate-danger">
                  <div class="small">Expenses</div>
                  <div class="akkurate-small">Today: {{ expenses_today }}</div>
                  <div class="akkurate-small">Month: {{ expenses_month }}</div>
                  <div class="text-xs">YTD:{{ expenses_year }}</div>
                </div>
                <div class="col akkurate-green">
                  <div class="small">Income</div>
                  <div class="akkurate-small"> {{ income_today }}</div>
                  <div class="akkurate-small">{{ income_month }}</div>
                  <div class="text-xs">{{ income_year }}</div>
                </div>
                <div class="col akkurate-blue-text">
                  <div class="small">P/L</div>
                  <div class="akkurate-small">{{ profit_loss_today }}</div>
                  <div class="akkurate-small">{{ profit_loss_month }}</div>
                  <div class="text-xs">{{ profit_loss_year }}</div>
                </div>
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>
      <div class="col-md-4 col-xl-4 mb-4">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="30%" height="20%"></b-skeleton>
              <b-skeleton width="50%" height="20%"></b-skeleton>
              <b-skeleton width="90%" height="20%"></b-skeleton>
            </b-card>
          </template>
          <div class="card mb-4 card-shape home-box"  style="height: 385px;">
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h6 class="m-0 fw-bold akkurate-dark">Branch Summary</h6>
            </div>
            <div class="card-body card-height">
              <div>
                <bar-chart :labels="labels" :data="data" />
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>
      <div class="col-md-4 col-xl-4 mb-4">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box mb-4">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
            </b-card>
          </template>
          <div class="card mb-4 card-shape home-box"  style="height: 385px;">
            <div class="card-header py-3">
              <div class="row">
                <div class="col-6">
                  <h6 class="m-0 fw-bold akkurate-dark">Collection Tracker</h6>
                </div>
              </div>
              <div>
              </div>
            </div>
            <div class="card-body session-table-height">
              <b-table class="small" :fields="collect_fields" :items="collect_items" striped responsive
                :per-page="perPage" :current-page="currentPage" small>
              </b-table>
            </div>
            <div class="card-footer">
              <b-pagination class="text-end" v-model="currentPage" :total-rows="rows" :per-page="perPage"
                aria-controls="daily-control-tb" align="right">
              </b-pagination>
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>


      <div class="col-md-8 col-xl-8">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
            </b-card>
          </template>

          <div class="card mb-4 card-shape home-box">
            <div class="card-header py-3">
              <div class="row">
                <div class="col-6">
                  <h6 class="m-0 fw-bold akkurate-dark">Financial Highlight</h6>
                </div>
              </div>
            </div>
            <div class="card-body py-0">
              <b-table class="small" :fields="fields" :items="items" striped responsive small>
                <template #thead-top>
                  <b-tr>
                    <b-th width="40%"><span>{{ assets }}</span></b-th>
                    <b-th></b-th>
                    <b-th></b-th>
                    <b-th></b-th>
                    <b-th></b-th>
                  </b-tr>
                </template>
                <template #custom-foot>
                  <b-tr>
                    <b-th>TOTAL</b-th>
                    <b-th>{{ getActualAmount }}</b-th>
                    <b-th>100%</b-th>
                    <b-th>{{ getStandardAmount }}</b-th>
                    <b-th>100%</b-th>
                  </b-tr>
                </template>
              </b-table>
            </div>
            <div class="card-body py-0">
              <b-table class="small" :fields="fields" :items="lib_items" striped responsive small>
                <template #thead-top>
                  <b-tr>
                    <b-th width="40%"><span>{{ liability }}</span></b-th>
                    <b-th></b-th>
                    <b-th></b-th>
                    <b-th></b-th>
                    <b-th></b-th>
                  </b-tr>
                </template>
                <template #custom-foot>
                  <b-tr>
                    <b-th>TOTAL</b-th>
                    <b-th>{{ getActualAmountLib }}</b-th>
                    <b-th>100%</b-th>
                    <b-th>{{ getStandardAmountLib }}</b-th>
                    <b-th>100%</b-th>
                  </b-tr>
                </template>
              </b-table>
            </div>
          </div>

        </b-skeleton-wrapper>
      </div>

      <div class="col-md-4 col-xl-4 mb-4">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box mb-4">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
            </b-card>
            <b-card class="card-shape home-box mb-4">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
            </b-card>
          </template>
          <div class="card mb-4 card-shape home-box">
            <div class="card-header py-3">
              <div class="row">
                <div class="col-6">
                  <h6 class="m-0 fw-bold akkurate-dark">Controls Statistic</h6>
                </div>
              </div>
              <div>
              </div>
            </div>
            <div class="card-body">
              <b-table class="small session-table-height" :fields="member_total_fields" :items="member_total_items"
                hover striped responsive small></b-table>
            </div>
            <div class="card-footer">
              <div class="d-flex justify-content-between">
                <span class="small">Total</span>
                <span class="small">{{ total_shares }}</span>
                <span class="small">{{ total_savings }}</span>
              </div>
            </div>
          </div>
          <div class="card mb-4 card-shape home-box">
            <div class="card-header py-3">
              <div class="row">
                <div class="col-6">
                  <h6 class="m-0 fw-bold akkurate-dark">USSD Tracker</h6>
                </div>
              </div>
              <div>
              </div>
            </div>
            <div class="card-body">
              <b-table class="small" :fields="ussd_fields" :items="ussd_items" hover striped responsive small></b-table>
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import DoughnutChart from "../Charts/DoughnutChart.vue";
import BarChart from "../Charts/BarChart.vue";
export default {
  components: {
    DoughnutChart,
    BarChart,
  },
  data() {
    return {
      loading: false,
      labels: [],
      data: [],
      fields: [
        {
          key: "display_name",
          label: "Particulars",
        },
        {
          key: "actual",
          label: "Actual Amount",
        },
        {
          key: "actual_percent",
          label: "%",
        },
        {
          key: "standard_amount",
          label: "Standard Amount",
        },

        {
          key: "standard_percent",
          label: "%",
        },
      ],
      collect_fields: [
        {
          key: "name",
          label: "Name",
        },
        {
          key: "today_balance",
          label: "Today",
        },
        {
          key: "ytd_balance",
          label: "YTD",
        },
      ],
      perPage: 5,
      currentPage: 1,
      items: [],
      lib_items: [],
      collect_items: [],
      assets: "",
      liability: "",
      profit_loss_name: "",
      profit_loss_value: "",
      chartDataIncome: {
        labels: [],
        datasets: [],
      },
      member_total_items: [],
      member_total_fields: [
        {
          key: "state",
          label: "Total members"
        },

        {
          key: "shares",
          label: "Shares"
        },
        {
          key: "savings",
          label: "All Savings"
        }
      ],
      ussd_fields: [
        {
          key: "type",
          label: "USSD Usage"
        },
        {
          key: "today",
          lable: "Today"
        },
        {
          key: "month",
          label: "Month"
        },
        {
          key: "year",
          label: "YTD"
        }
      ],
      ussd_items: [],
      total_savings: 0,
      total_shares: 0,
      expenses_year: 0,
      income_year: 0,
      profit_loss_year: 0,
      income_today: 0,
      expenses_today: 0,
      profit_loss_today: 0,
    };
  },
  created() {
    // Call your API here
    this.getAuditorApi();
  },
  computed: {
    rows() {
      return this.collect_items.length;
    },
    getActualAmount() {
      let sum = 0;
      this.items.forEach((index) => {
        sum += Number(index.actual);
      });
      return sum.toFixed(2);
    },
    getActualAmountLib() {
      let sum = 0;
      this.lib_items.forEach((index) => {
        sum += Number(index.actual);
      });
      return sum.toFixed(2);
    },
    getStandardAmount() {
      let sum = 0;
      this.items.forEach((index) => {
        sum += Number(index.standard_amount);
      });
      return sum.toFixed(2);
    },
    getStandardAmountLib() {
      let sum = 0;
      this.lib_items.forEach((index) => {
        sum += Number(index.standard_amount);
      });
      return sum.toFixed(2);
    },
  },
  methods: {
    async getAuditorApi() {
      // Your API call logic goes here
      //   console.log("GEN Man API called!");
      this.loading = true;
      await ApiService.query("/dashboard/accountant")
        .then((response) => {
          // console.log(response.data);
          const res = response.data;

          this.labels = res.branches.branch_names;
          this.data = res.branches.branch_total;
          this.items = res.financial_highlight.assets;
          this.lib_items = res.financial_highlight.liabilities;
          this.assets = 'ASSET';
          this.liability = ' LIABILITY/EQUITY';
          this.collect_items = res.collection_tracker;

        
          var profit_loss_name = []
          var profit_loss_value = []

          // Populate the arrays
          for (const [key, value] of Object.entries(res?.ps.profit_loss_year)) {
            profit_loss_name.push(key);
            profit_loss_value.push(value);
          }
          console.log(this.profit_loss_value)

          this.chartDataIncome = {
            labels: profit_loss_name,
            datasets: [
              {
                backgroundColor: ["#018673", "#dc3545", "#0165d1"],
                data: profit_loss_value,
              },
            ],
          };

          //Year
          this.income_year = res.ps?.profit_loss_year.total_income;
          this.expenses_year = res.ps?.profit_loss_year.total_expenses;
          this.profit_loss_year = res.ps?.profit_loss_year.profit_and_loss;

          //Today
          this.income_today = res.ps?.profit_loss_today.total_income;
          this.expenses_today = res.ps?.profit_loss_today.total_expenses;
          this.profit_loss_today = res.ps?.profit_loss_today.profit_and_loss;


          this.member_total_items = response.data.member_stat.control_summary;
          this.total_shares = response.data.member_stat.total_shares;
          this.total_savings = response.data.member_stat.total_savings;
          this.ussd_items = response.data.ussd_summary;
        }).catch(function (error) {
          // this.isLoading = false;
          // return error;
          this.loading = false;
        }).finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.session-table-height {
  height: 230px;
}

.card-height {
  height: 310px;
}


.text-blue {
  color: #0165d1;
}
</style>