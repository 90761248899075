<template>  
    <div id="wrapper">
        <SidebarView :mobile="true" />
        <div class="d-flex flex-column" id="content-wrapper">
            <div id="content">
                <TopbarView />
                <MobileMenu />
                <b-container>
                    <b-row>
                        <div>
                        <b-tabs justified>
                            <b-tab title="Overview" active>
                                <div class="card">
                                    <div class="card-body">
                                        <form>
                                        <overview-form :form="retrived" :roles="roles"></overview-form>
                                        <hr />
                                        <div class="row my-4">
                                            <div class="col-12 col-md-12 text-center">
                                                <b-button type="submit"  variant="primary" class="akkurate-green-btn"><font-awesome-icon class="me-2" :icon="['fas', 'save']" />Update</b-button>
                                            </div>
                                        </div>
                                        </form>
                                    </div>
                                </div>
                            </b-tab>
                            <b-tab title="Change Password">
                                <div class="card">
                                    <div class="card-body">
                                        <form>
                                            <change-password></change-password>
                                            <hr class="mt-4"/>
                                            <div class="row my-4">
                                                <div class="col-12 col-md-12 text-center">
                                                    <b-button type="submit"  variant="primary" class="akkurate-green-btn">Update</b-button>
                                                </div>
                                            </div>
                                        </form>
                                    
                                    </div>
                                </div>
                                </b-tab>
                             </b-tabs>
                        </div>
                    </b-row>
                </b-container>
              
            </div>
            <footer></footer>
        </div>

    </div>
    

</template>
<script>
import SidebarView from '@/views/main/components/Sidebar.vue';
import Footer from '@/views/main/components/Footer.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import OverviewForm from '../forms/Overview';
import ChangePassword from '../forms/ChangePassword';
import ApiService from "@/core/services/api.service";
import MobileMenu from '@/views/main/components/MobileMenu.vue';
export default {
    name: "MobileProfile",
    components: {
        SidebarView,
        Footer,
        TopbarView,
        OverviewForm,
        ChangePassword,
        MobileMenu
    },
    data: () => ({
        retrived: [],
        roles: []
    }),
    mounted() {
        this.getUserProfile();
    },
    methods: {
        async getUserProfile() {
            const response = await ApiService.get('users/profile')
                .then(function (success) {
                    if (success.status == 200) {
                        return success.data;
                    }
                }).catch(function (error) {
                    return error;
                });
            if (response != undefined) {
                this.retrived = response.user
                this.roles = response.user.roles[0]
            }

        }
    }
}
</script>