<template>
    <div class="row">
        <div class="col-12 col-md-4">
            <label class="akkurate-dark" for="amount">Amount</label>
            <b-form-group id="amount" label-for="amount">
                <b-form-input class="mb-3 mt-2 field-container fields" type="number" placeholder="Enter Amount"
                    required></b-form-input>
            </b-form-group>
        </div>
        <div class="col-12 col-md-4">
            <label class="akkurate-dark" for="loan-re-date">Loan Kind</label>
            <b-form-group id="loan-re-date" label-for="loan-re-date">
                <multiselect class="mt-2 field-container" v-model="payment" :options="payment_options"></multiselect>
            </b-form-group>
        </div>
        <div class="col-12 col-md-4">
            <label class="akkurate-dark" for="loan-re-date">Loan Scheme</label>
            <b-form-group id="loan-re-date" label-for="loan-re-date">
                <multiselect class="mt-2 field-container" v-model="payment" :options="payment_options"></multiselect>
            </b-form-group>
        </div>
        <div class="col-12 col-md-4">
            <label class="akkurate-dark" for="amount">Interest Rate</label>
            <b-form-group id="amount" label-for="amount">
                <b-form-input class="mb-3 mt-2 field-container fields" type="number" placeholder="Enter Interest Rate"
                    required></b-form-input>
            </b-form-group>
        </div>
        <div class="col-12 col-md-4">
            <label class="akkurate-dark" for="loan-re-date">Interest Method</label>
            <b-form-group id="loan-re-date" label-for="loan-re-date">
                <multiselect class="mt-2 field-container" v-model="payment" :options="payment_options"></multiselect>
            </b-form-group>
        </div>
        <div class="col-12 col-md-4">
            <label class="akkurate-dark" for="loan-re-date">Interest Rate Frequency</label>
            <b-form-group id="loan-re-date" label-for="loan-re-date">
                <multiselect class="mt-2 field-container" v-model="payment" :options="payment_options"></multiselect>
            </b-form-group>
        </div>
        <div class="col-12 col-md-4">
            <label class="akkurate-dark" for="amount">Tenure</label>
            <b-form-group id="amount" label-for="amount">
                <b-form-input class="mb-3 mt-2 field-container fields" type="number" placeholder="Enter Tenure"
                    required></b-form-input>
            </b-form-group>
        </div>

        <div class="col-12 col-md-4">
            <label class="akkurate-dark" for="amount">Interest Start Date</label>
            <b-form-group id="amount" label-for="amount">
                <b-form-input class="mb-3 mt-2 field-container fields" type="date" required></b-form-input>
            </b-form-group>
        </div>
        <div class="col-12 col-md-4">
            <label class="akkurate-dark" for="maturity">Maturity Date</label>
            <b-form-group id="maturity" label-for="maturity">
                <b-form-input class="mb-3 mt-2 field-container fields" type="date" required></b-form-input>
            </b-form-group>
        </div>
        <div class="col-12 col-md-4">
            <label class="akkurate-dark" for="amount">Tenure Interval</label>
            <b-form-group id="amount" label-for="amount">
                <b-form-input class="mb-3 mt-2 field-container fields" type="number" placeholder="Enter Tenure"
                    required></b-form-input>
            </b-form-group>
        </div>

        <div class="col-12 col-md-4">
            <label class="akkurate-dark" for="loan-re-date">Interest Rate Frequency</label>
            <b-form-group id="loan-re-date" label-for="loan-re-date">
                <multiselect class="mt-2 field-container" v-model="payment" :options="payment_options"></multiselect>
            </b-form-group>
        </div>

        <div class="col-12 col-md-4">
            <label class="akkurate-dark" for="amount">No. Of Payment</label>
            <b-form-group id="amount" label-for="amount">
                <b-form-input class="mb-3 mt-2 field-container fields" type="number" placeholder="Enter Tenure"
                    required></b-form-input>
            </b-form-group>
        </div>

        <div class="col-12 col-md-4">
            <label class="akkurate-dark" for="amount">Expected Interest</label>
            <b-form-group id="amount" label-for="amount">
                <b-form-input class="mb-3 mt-2 field-container fields" type="number" placeholder="Enter Tenure"
                    required></b-form-input>
            </b-form-group>
        </div>

        <div class="col-12 col-md-4">
            <label class="akkurate-dark" for="amount">Penalty Interest Rate %</label>
            <b-form-group id="amount" label-for="amount">
                <b-form-input class="mb-3 mt-2 field-container fields" type="number" placeholder="Enter Tenure"
                    required></b-form-input>
            </b-form-group>
        </div>

        <div class="col-12 col-md-4">
            <label class="akkurate-dark" for="loan_reason">Reason For Loan</label>
            <b-form-group id="loan_reason" label-for="loan_reason">
                <multiselect class="mt-2 field-container" v-model="payment" :options="payment_options"></multiselect>
            </b-form-group>
        </div>

        <div class="col-12 col-md-4">
            <label class="akkurate-dark" for="loan_reason">GL Debit</label>
            <b-form-group id="loan_reason" label-for="loan_reason">
                <multiselect class="mt-2 field-container" v-model="payment" :options="payment_options"></multiselect>
            </b-form-group>
        </div>

        <div class="col-12 col-md-4">
            <label class="akkurate-dark" for="amount">Reason For Write-Off</label>
            <b-form-group id="amount" label-for="amount">
                <b-form-input class="mb-3 mt-2 field-container fields" type="number" placeholder="Enter Tenure"
                    required></b-form-input>
            </b-form-group>
        </div>

        <div class="col-12 col-md-4">
            <label class="akkurate-dark small" for="apv_doc">Upload Aprroval Document</label>
            <input type="file" class="form-control mt-2" id="apv_doc">
        </div>
        <div class="col-12 text-end">
            <b-button @click="clearForm" class="akkurate-gray-btn me-2 btn-size"><font-awesome-icon class="me-2"
                    :icon="['fas', 'close']" />Clear</b-button>
            <button type="submit" :disabled="isSaving"
                class="btn akkurate-green-btn text-white btn-size me-2"><font-awesome-icon class="me-2"
                    :icon="['fas', 'save']" />{{ save }}</button>

        </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import ApiService from "@/core/services/api.service";
export default {
    components: {
        Multiselect,
    },
    data() {
        return {
            payment: "Cash",
            payment_options: ["Cash", "Momo"],
            save:"Save",
            isSaving:false
        }
    },
    methods: {
        validateState(ref) {
            if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        handleOk(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault();
            // Trigger submit handler
            this.handleSubmit()
        },
        clearForm() {
            this.form = this.initialState().form; // Call the initialState method to get the default form
        },
        handleSubmit() {
            // Exit when the form isn't valid
            this.$validator.validateAll().then(result => {
                if (!result) {
                    // console.log(result);
                    return;
                }
                swal.fire({
                    title: 'Success',
                    text: 'Transaction Successfully Saved',
                    confirmButtonColor: "#018673",
                    icon: 'success',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    showCancelButton: true, // Show the "Cancel" button
                    confirmButtonText: 'OK', // Customize the text for the "OK" button
                    cancelButtonText: 'Print', // Text for the custom button
                    buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                    customClass: {
                        confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                        cancelButton: 'btn akkurate-ash modal-btn-width text-white' // Custom class for the custom button
                    }
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$bvModal.hide('loan_repayment');
                    } else if (result.isDismissed) {
                    }
                });

            });
            // Hide the modal manually

        },
    }

}
</script>