<template>
  <div id="wrapper">
    <SidebarView :reports="true" />
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <TopbarView />
        <div class="container">
          <div class="card card-shape home-box">
            <div class="card-header">
              <div class="row align-items-center">
                <div class="col-6">
                  <h6 class="m-0 fw-bold text-green">Daily Product Customer</h6>
                </div>
                <div class="col-6 text-end">
                  <b-form-select
                    v-model="selected_num"
                    @change="changePerPageNumber"
                    :options="numbers_options">
                  </b-form-select>
                  <b-button @click="generateExcel" class="btn-sm me-2 ms-5 akkurate-green-btn akkurate-small">
                    <font-awesome-icon :icon="['fas', 'file-excel']"/>
                  </b-button>
                  <report-filter :form="form" @filterAction="filterAction" :filter="filter"></report-filter>
                  <b-button @click="printPDF" class="btn-sm me-2 akkurate-green-btn akkurate-small">
                    <font-awesome-icon :icon="['fas', 'book']"/>
                  </b-button>
                  <b-button @click="downloadPDF" class="btn-sm akkurate-green-btn akkurate-small">
                    <font-awesome-icon :icon="['fas', 'download']"/>
                  </b-button>
                </div>
              </div>
            </div>
            <div class="card-body pt-0">
              <div class="row" ref="pdfContent">
                <div class="col-12 col-md-12">
                  <statement-institution
                    :institution="institution"
                    :title="'DAILY PRODUCT CUSTOMERS'">
                  </statement-institution>
                </div>
                <hr/>
                <div class="row mb-2">
                  <div class="col-12 small">
                    Branch(es) : {{ branch_list }}
                  </div>
                </div>
                <div class="col-12 col-md-12 trans_details">
                  <b-table
                    id="daily-control-tb"
                    :items="items"
                    :fields="fields"
                    striped
                    ref="tableRef"
                    responsive="sm"
                    :per-page="perPage"
                    :busy.sync="isBusy"
                    :current-page="currentPage"
                    small
                  >
                  <b-table-column field="reg_date" label="Reg.Date" style="width: 200px"></b-table-column>

                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                      </div>
                    </template>
                  </b-table>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 text-end">
                  <b-pagination
                    class="text-end"
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    aria-controls="daily-control-tb"
                    align="right"
                  >
                   
                  </b-pagination>
                  <!-- <p class="mt-3">Current Page: {{ currentPage }}</p> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer></footer>
    </div>
    <a class="d-inline scroll-to-top" href="#page-top">
      <i class="fas fa-angle-up"></i>
    </a>
  </div>
</template>
<script>
import SidebarView from "@/views/main/components/Sidebar.vue";
import TopbarView from "@/views/main/components/Topbar.vue";
import Footer from "@/views/main/components/Footer.vue";
import ApiService from "@/core/services/api.service";
import html2pdf from "html2pdf.js";
import StatementInstitution from "@/views/main/components/institution/StatementInstitution.vue";
import Multiselect from "vue-multiselect";
import ReportFilter from "../form/ReportFilter.vue";
import ExcelJS from 'exceljs';
export default {
  components: {
    SidebarView,
    TopbarView,
    Footer,
    StatementInstitution,
    Multiselect,
    ReportFilter
  },
  mounted() {
    this.getCustomerProduct();
    this.getInstitution();
    this.getProductDropdown();
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },

  data() {
    return {
      form: {
        start_date: this.getBeginningOfYear(),
        end_date: new Date().toISOString().substr(0, 10),
        branches: [],
        products: [],
        branch_options: [],
        product_options: [],
        users: [],
        users_options: [],
        status: [],
        coa: [],
        coa_options: [],
        terms: "Periodic",
        terms_options: ["YTD", "Periodic"],
        categories: [],
      },
      filter: {
        branches: true,
        products: true,
        users: false,
        status: false,
        condition: false,
        terms: false,
        coa: false,
        voucher_status: false
      },
      search: "Search",
      isBusy: false,
      isSearching: false,
      gender: "",
      gender_options: ["Male", "Female"],
      selected_num: "10",
      numbers_options: [10, 50, 100, 500, "All"],
      isLoadingInstitution: false,
      institution: "",
      branch_list:"",
      fields: [
        {
          key: "customer",
          label: "Customer",
        },
        {
          key: "account_number",
          label: "Account number",
        },
        {
          key: "mobile",
          label: "Mobile",
        },
        {
          key: "reg_date",
          label: "Reg.Date",
          width: "150px"
        },

        {
          key: "balance",
          label: "Balance (GHS)",
        },
        {
          key: "address",
          label: "Address",
        },
        {
          key: "created_by",
          label: "Created By",
        },
      ],
      perPage: 20,
      currentPage: 1,
      items: [],
    };
  },
  methods: {
    async getProductDropdown() {
      this.isBusy = true;
      this.search = "Processing...";
      await ApiService.query("/reports/controls/products/dropdown")
        .then((response) => {
          this.isBusy = false;
          this.search = "Search";
          const res = response.data
          this.form.branch_options = res.branches;
          this.form.product_options = res.products;
        })
        .catch((error) => {
          console.log(error);
          this.isBusy = false;
          this.search = "Search";
        });
    },
    async getCustomerProduct() {
      this.isBusy = true;
      this.search = "Processing...";
      this.isSearching = true;
      await ApiService.query("/reports/controls/customers/products", {
        params: {
          startDate: this.form.start_date,
          endDate: this.form.end_date,
          product_type_ids: this.form.products.map(index => index.id),
          branch_ids: this.form.branches.map(branch => branch.id),
        },
      })
        .then((response) => {
          this.items = [];
          this.isBusy = false;
          this.search = "Search";
          this.isSearching = false;
          this.$Progress.start();
          let res = response.data?.accounts.map((index) => {
            const first_name = index.customer?.first_name != null ? index.customer?.first_name : "";
            const middlename = index.customer?.middlename != null ? index.customer?.middlename : "";
            const last_name = index.customer?.last_name != null ? index.customer?.last_name : "";
            const mobile = index.customer?.mobile != null ? index.customer?.mobile : "";
            const address = index.customer?.address != null ? index.customer?.address : "";
            return {
              customer: first_name + " " + middlename + " " + last_name,
              account_number: index.account_number,
              mobile: mobile,
              reg_date: index.created_at,
              balance: index.account_balance,
              address: address,
              created_by: index.created_by_user?.first_name,
            };
          });
          this.items = res;
          this.branch_list =  this.form.branches.map(branch => branch.name).join(", ");
        })
        .catch((error) => {
          this.isBusy = false;
          this.isSearching = false;
          this.search = "Search";
          console.log(error);
          this.$Progress.fail();
        }).finally(() => {
          this.$Progress.finish();
        });
    },
    downloadPDF() {
      const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
      // console.log("here");
      const pdfOptions = {
        margin: [0.5, 0.2, 0.8, 0.2], //top, left, buttom, right
        filename: "statement.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        pagebreak: { mode: "avoid-all" },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      html2pdf()
        .from(content)
        .set(pdfOptions)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          var totalPages = pdf.internal.getNumberOfPages();
          var insit_name = this.institution.name;
          var insit_tele = this.institution.telephone;
          var insit_email = this.institution.email;
          var insit_motto = this.institution.motto;

          for (var i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(8);
            pdf.setTextColor(150);
            pdf.text(
              [
                "For Inquiries, Please Contact " +
                insit_name +
                ", Customer Service on " +
                insit_tele +
                "",
                "or email us on " + insit_email + " " + insit_motto + "",
                "Page " + i + " of " + totalPages,
              ],
              pdf.internal.pageSize.getWidth() / 2,
              pdf.internal.pageSize.getHeight() - 0.6,
              { align: "center" }
            );
          }
        }).save();
    },
    printPDF() {
      const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
      // console.log("here");
      const pdfOptions = {
        margin: [0.5, 0.2, 0.8, 0.2], //top, left, buttom, right
        filename: "statement.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        pagebreak: { mode: "avoid-all" },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      html2pdf()
        .from(content)
        .set(pdfOptions)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          var totalPages = pdf.internal.getNumberOfPages();
          var insit_name = this.institution.name;
          var insit_tele = this.institution.telephone;
          var insit_email = this.institution.email;
          var insit_motto = this.institution.motto;

          for (var i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(8);
            pdf.setTextColor(150);
            pdf.text(
              [
                "For Inquiries, Please Contact " +
                insit_name +
                ", Customer Service on " +
                insit_tele +
                "",
                "or email us on " + insit_email + " " + insit_motto + "",
                "Page " + i + " of " + totalPages,
              ],
              pdf.internal.pageSize.getWidth() / 2,
              pdf.internal.pageSize.getHeight() - 0.6,
              { align: "center" }
            );
          }
          // Get the blob of the PDF
          return pdf.output("blob");
        })
        .then((pdfBlob) => {
          // Create a URL for the PDF blob
          var pdfUrl = URL.createObjectURL(pdfBlob);

          // Open the URL in a new tab
          const printWindow = window.open(
            pdfUrl,
            "_blank",
            "width=800,height=600"
          );
          printWindow.onload = () => {
            printWindow.print();
            URL.revokeObjectURL(pdfUrl);
          };
        })
        .catch((error) => {
          console.error("Error generating PDF:", error);
        });
    },
    filterAction() {
      this.changePerPageNumber(this.perPage);
    },
    changePerPageNumber(data) {
      if (data == "All") {
        this.perPage = this.items.length;
        this.getCustomerProduct();
      } else {
        this.perPage = data;
        this.getCustomerProduct();
      }
    },
    async getInstitution() {
      this.isLoadingInstitution = true;
      await ApiService.get("/settings/institution")
        .then((response) => {
          this.institution = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoadingInstitution = false;
        });
    },
    getOneMonthBeforeDate() {
      // Get today's date
      let currentDate = new Date();

      // Remove one month to the current date
      currentDate.setMonth(currentDate.getMonth() - 1);

      // Format the date as 'YYYY-MM-DD'
      return currentDate.toISOString().substr(0, 10);
    },

    async generateExcel() {
      const fileUrl = '/REPORT_TEMPLATE.xlsx'; // Path to the file in the public folder

      try {
        // Fetch the Excel file from the public URL
        const response = await fetch(fileUrl);
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        const arrayBuffer = await response.arrayBuffer();

        // Load the file into ExcelJS
        const wb = new ExcelJS.Workbook();
        await wb.xlsx.load(arrayBuffer);

        // Access the first sheet
        const ws = wb.getWorksheet(1); // Get the first worksheet

        // Define header row
        const header = [
          "Customer",
          "Account number",
          "Mobile",
          "Reg.Date",
          "Balance (GHS)",
          "Address",
          "Created By",
        ];

        // Add header row starting from row 13
        const startingRow = 13;
        let headerRow = ws.getRow(startingRow);
        headerRow.values = header;
        headerRow.font = { bold: true };
        headerRow.commit(); // Commit the row to the worksheet

        // Add individual data rows
        let currentRow = startingRow + 1;

        // Process each item
        this.items.forEach(item => {
          const row = ws.getRow(currentRow);
          // Map item values to the corresponding header columns
          row.values = [
            item.customer,
            item.account_number,
            item.mobile,
            item.reg_date,
            item.balance,
            item.address,
            item.created_by,
          ];
          row.commit();
          currentRow++;
        });

        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Create a download link and simulate a click to download the file
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'Modified_REPORT_TEMPLATE.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (err) {
        console.error('Error fetching, modifying, or downloading the Excel file:', err.message);
      }
    }
  },
};
</script>

<style>
/* Customize the pagination page number color */
.pagination {
  --bs-pagination-active-bg: rgb(1, 134, 115) !important;
  --bs-pagination-active-border-color: rgb(1, 134, 115) !important;
  --bs-pagination-focus-box-shadow: none !important
}

.page-link {
  color: rgb(1, 134, 115) !important;
}

.page-link:hover {
  color: rgb(1, 134, 115) !important;
}

.page-link.active,
.active>.page-link {
  color: #fff !important;
}
</style>