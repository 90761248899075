<template>
  <div>
    <b-button class="akkurate-green-btn akkurate-small" id="show-btn" @click="showModal"><font-awesome-icon
        class="text-lg" :icon="['fas', 'forward']" /></b-button>
    <b-modal id="edit-chart-of-acc" ref="coa" size="lg" @show="resetModal" @hidden="resetModal" @ok="handleOk">
      <template #modal-title>
        <div class="akkurate-green small fw-bold">Transfer CoaSubhead</div>
      </template>
      <div class="fst-italic akkurate-danger">Note: You are about to transfer COA Head <span class="fw-bold">{{
        coa_head.display_name }}</span> of Subhead <span class="fw-bold">{{ subhead.name }}</span> to</div>
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <div class="row mt-3">
          <input type="hidden" name="old_subhead_id" :value="subhead.id" />
          <div class="col-md-6">
            <b-form-group label-for="coa_head" class="mb-3" invalid-feedback="COA head is a required select"
              :state="validateState('coa_head')">
              <template #label>
                <label class="akkurate-dark" for="name">COA Head <star></star></label>
              </template>
              <div class="">
                <multiselect :disabled="add_or_edit == 'edit'" v-validate="{ required: true }"
                  v-model="form.account_category" label="display_name" name="coa_head" :state="validateState('coa_head')"
                  placeholder="Search or select" :options="coa_heads" class="mt-2 field-container"
                  data-vv-as="COA_HEAD" required></multiselect>
                <errors v-if="form.errors.coa_head">
                  {{ form.errors.coa_head[0] }}
                </errors>
              </div>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label-for="branch" class="mb-3">
              <template #label>
                <label class="akkurate-dark" for="acc_name">Account Name <star></star></label>
              </template>
              <b-form-input id="acc_name" name="acc_name" v-validate="{ required: true }"
                :state="validateState('acc_name')" class="mt-2 field-container fields" :class="errorState"
                placeholder="Enter account name" v-model="form.account_name" required
                data-vv-as="Account name"></b-form-input>
              <b-form-invalid-feedback>
                {{ veeErrors.first('acc_name') }}
              </b-form-invalid-feedback>
              <errors v-if="form.errors.account_name">
                {{ form.errors.account_name[0] }}
              </errors>
            </b-form-group>
          </div>
            <!-- Balance -->
            <div class="col-md-6">
            <b-form-group label-for="branch" class="mb-3">
              <template #label>
                <label class="akkurate-dark" for="acc_name">Balance</label>
              </template>
              <b-form-input id="balance" class="mt-2 field-container fields" v-model="form.balance"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label-for="descripton" class="mb-3">
              <template #label>
                <label class="akkurate-dark" for="address">Description <star></star></label>
              </template>
              <b-form-textarea class="mt-2 fields" name="desc" v-validate="{ required: true }"
                :state="validateState('desc')" v-model="form.description" id="textarea-lazy"
                placeholder="Enter Description..." data-vv-as="Description" required lazy-formatter></b-form-textarea>
              <b-form-invalid-feedback>
                {{ veeErrors.first('desc') }}
              </b-form-invalid-feedback>
              <errors v-if="form.errors.description">
                {{ form.errors.description[0] }}
              </errors>
            </b-form-group>
          </div>
        </div>


      </form>
      <template #modal-footer="{ ok }">
        <div class="w-100">
          <!-- begin loader -->
          <beat-loader-component class="float-left" v-if="isLoading" :isLoading="isLoading"></beat-loader-component>
          <!-- end loader -->
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <b-button @click="$bvModal.hide('chart-of-acc')" type="button"
            class="border-0 akkurate-grey-btn btn-size me-2 float-right"><font-awesome-icon class="me-2"
              :icon="['fas', 'close']" />Close</b-button>
          <b-button @click="ok()" type="submit" class="akkurate-green-btn btn-size me-2 float-right"><font-awesome-icon
              class="me-2" :icon="['fas', 'save']" />Save</b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import ApiService from "@/core/services/api.service";
export default {
  props: ["coa_head", "account_types", "branches", "subhead","coa_heads"],
  components: {
    Multiselect
  },
  data() {
    return {
      isLoading: false,
      form: {
        coa_head: "",
        account_number: "",
        account_name: "",
        description: "",
        // account_type: "",
        // decrease_by: "",
        // increase_by: "",
        errors: [],
        balance: 0,
      },
      errorState: "",
      // account_types: [],
      account_categories: ["STANDARD", "USER", "PRODUCT"],
      debit_or_credit: ["Debit", "Credit"],
      // branches: [],
      isSaving: false,

      add_or_edit: "add",
    };
  },
  methods: {
    showModal() {
      this.$refs['coa'].show()
    },
    hideModal() {
      this.$refs['coa'].hide()
    },
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    resetModal() {
      this.form.coa_head = this.coa_head;
      this.form.account_number = ""
      this.form.account_name = ""
      this.form.description = ""
      this.form.account_type = ""
      this.form.account_category = ""
      this.form.balance = 0
      this.errorState = ''
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      this.$validator.validateAll().then(result => {
        if (!result) {
          return;
        }
        this.isSaving = true;
        //   this.$Progress.start();
        //   this.form
        this.form.errors = [];
        ApiService.post("/finance/chart-of-accounts/transferCOASubType", this.form)
          .then((response) => {
            this.isSaving = false;
            //   this.$Progress.finish();



            //   this.$emit("account-type-saved", customer);
            toast.fire({
              icon: "success",
              title: response.data.message,
            });

            this.$emit("coa-sub-head-saved", response.data)

            this.$nextTick(() => {
              this.$bvModal.hide('chart-of-acc')
            })

            //   window.location.reload();
          })
          .catch((error) => {
            // console.log(error)
            if (error.response.data.errors) {
              this.form.errors = error.response.data.errors;
              this.errorState = 'is-invalid'
            }
            this.isSaving = false;
            // console.log(error.response.data)
          });
      });
    },
  }
}
</script>