<template>
  <Doughnut :options="chartOptions" :data="chartData" id="chartId" />
</template>

<script>
import { Doughnut } from "vue-chartjs";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  name: "DoughnutChart",
  components: {
    Doughnut,
  },
  props: {
    chartData: {
      labels: [],
      datasets: [],
    },
    chartId: {
      type: String,
      default: "doughnut-chart",
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => { },
    },

  },
  data() {
    return {
      // chartData: {
      //   labels: ['VueJs', 'EmberJs', 'ReactJs', 'AngularJs'],
      //   datasets: [
      //     {
      //       backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16'],
      //       data: [40, 20, 80, 10]
      //     }
      //   ]
      // },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "top",
            display: false,
          },
        },
      },
    };
  },
};
</script>