<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6 col-xl-6 mb-4">
                <b-skeleton-wrapper :loading="loading">
                    <template #loading>
                        <b-card class="card-shape home-box mb-4">
                            <b-skeleton width="85%" height="20%"></b-skeleton>
                            <b-skeleton width="70%" height="20%"></b-skeleton>
                            <b-skeleton width="70%" height="20%"></b-skeleton>
                        </b-card>
                    </template>
                    <b-card header-tag="header" footer-tag="footer">
                        <template #header>
                            <h6 class="m-0 fw-bold akkurate-dark">KYC Compliance Analysis Available</h6>
                        </template>
                        <b-table-simple striped responsive small>
                            <b-thead>
                                <b-tr>
                                    <b-th width="50%">KYC</b-th>
                                    <b-th>Females</b-th>
                                    <b-th>Males</b-th>
                                    <b-th>Groups</b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <b-tr>
                                    <td>Passport Picture</td>
                                    <td>{{ passport_female_available }}</td>
                                    <td>{{ passport_male_available }}</td>
                                    <td>{{ passport_group_available }}</td>
                                </b-tr>
                                <b-tr>
                                    <td>Signature</td>
                                    <td>{{ signature_female_available }}</td>
                                    <td>{{ signature_male_available }}</td>
                                    <td>{{ signature_group_available }}</td>
                                </b-tr>
                                <b-tr>
                                    <td>Ghana Card</td>
                                    <td>{{ gc_female_available }}</td>
                                    <td>{{ gc_male_available }}</td>
                                    <td>{{ gc_group_available }}</td>
                                </b-tr>
                                <b-tr>
                                    <td>Address</td>
                                    <td>{{ address_female_available }}</td>
                                    <td>{{ address_male_available }}</td>
                                    <td>{{ address_group_available }}</td>
                                </b-tr>
                                <b-tr>
                                    <td>Mobile Number</td>
                                    <td>{{ mobile_female_available }}</td>
                                    <td>{{ mobile_male_available }}</td>
                                    <td>{{ mobile_group_available }}</td>
                                </b-tr>
                                <b-tr>
                                    <td>Contact Person</td>
                                    <td>{{ beneficiaries_female_available }}</td>
                                    <td>{{ beneficiaries_male_available }}</td>
                                    <td>{{ beneficiaries_group_available }}</td>
                                </b-tr>
                                <b-tr>
                                    <td>Digital Address</td>
                                    <td>{{ digital_address_female_available }}</td>
                                    <td>{{ digital_address_male_available }}</td>
                                    <td>{{ digital_address_group_available }}</td>
                                </b-tr>
                            </b-tbody>
                            <b-tfoot>
                                <b-tr>
                                    <b-td class="fw-bold py-0">
                                        Total
                                    </b-td>
                                    <b-td class="fw-bold">{{ total_available_females }}</b-td>
                                    <b-td class="fw-bold">{{ total_available_males }}</b-td>
                                    <b-td class="fw-bold">{{ total_available_groups }}</b-td>
                                </b-tr>
                            </b-tfoot>

                        </b-table-simple>
                    </b-card>
                </b-skeleton-wrapper>
            </div>
            <div class="col-md-6 col-xl-6 mb-4">
                <b-skeleton-wrapper :loading="loading">
                    <template #loading>
                        <b-card class="card-shape home-box mb-4">
                            <b-skeleton width="85%" height="20%"></b-skeleton>
                            <b-skeleton width="70%" height="20%"></b-skeleton>
                            <b-skeleton width="70%" height="20%"></b-skeleton>
                        </b-card>
                    </template>
                    <b-card header-tag="header" footer-tag="footer">
                        <template #header>
                            <h6 class="m-0 fw-bold akkurate-dark">KYC Compliance Analysis Unavailable</h6>
                        </template>
                        <b-table-simple striped responsive small>
                            <b-thead>
                                <b-tr>
                                    <b-th width="50%">KYC</b-th>
                                    <b-th>Females</b-th>
                                    <b-th>Males</b-th>
                                    <b-th>Groups</b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <b-tr>
                                    <td>Passport Picture</td>
                                    <td>{{ passport_female_unavailable }}</td>
                                    <td>{{ passport_male_unavailable }}</td>
                                    <td>{{ passport_group_unavailable }}</td>
                                </b-tr>
                                <b-tr>
                                    <td>Signature</td>
                                    <td>{{ signature_female_unavailable }}</td>
                                    <td>{{ signature_male_unavailable }}</td>
                                    <td>{{ signature_group_unavailable }}</td>
                                </b-tr>
                                <b-tr>
                                    <td>Ghana Card</td>
                                    <td>{{ gc_female_unavailable }}</td>
                                    <td>{{ gc_male_unavailable }}</td>
                                    <td>{{ gc_group_unavailable }}</td>
                                </b-tr>
                                <b-tr>
                                    <td>Address</td>
                                    <td>{{ address_female_unavailable }}</td>
                                    <td>{{ address_male_unavailable }}</td>
                                    <td>{{ address_group_unavailable }}</td>
                                </b-tr>
                                <b-tr>
                                    <td>Mobile Number</td>
                                    <td>{{ mobile_female_unavailable }}</td>
                                    <td>{{ mobile_male_unavailable }}</td>
                                    <td>{{ mobile_group_unavailable }}</td>
                                </b-tr>
                                <b-tr>
                                    <td>Contact Person</td>
                                    <td>{{ beneficiaries_female_unavailable }}</td>
                                    <td>{{ beneficiaries_male_unavailable }}</td>
                                    <td>{{ beneficiaries_group_unavailable }}</td>
                                </b-tr>
                                <b-tr>
                                    <td>Digital Address</td>
                                    <td>{{ digital_address_female_unavailable }}</td>
                                    <td>{{ digital_address_male_unavailable }}</td>
                                    <td>{{ digital_address_group_unavailable }}</td>
                                </b-tr>
                            </b-tbody>
                            <b-tfoot>
                                <b-tr>
                                    <b-td class="fw-bold py-0">
                                        Total
                                    </b-td>
                                    <b-td class="fw-bold">{{ total_unavailable_females }}</b-td>
                                    <b-td class="fw-bold">{{ total_unavailable_males }}</b-td>
                                    <b-td class="fw-bold">{{ total_unavailable_groups }}</b-td>
                                </b-tr>
                            </b-tfoot>

                        </b-table-simple>
                    </b-card>
                </b-skeleton-wrapper>
            </div>

            <div class="col-md-8 col-xl-8 mb-4">
                <b-skeleton-wrapper :loading="loading">
                    <template #loading>
                        <b-card class="card-shape home-box mb-4">
                            <b-skeleton width="85%" height="20%"></b-skeleton>
                            <b-skeleton width="70%" height="20%"></b-skeleton>
                            <b-skeleton width="70%" height="20%"></b-skeleton>
                        </b-card>
                    </template>
                    <div class="card mb-4 card-shape home-box">
                        <div class="card-header py-3">
                            <div class="row">
                                <div class="col-6">
                                    <h6 class="m-0 fw-bold akkurate-dark">Members Account Analysis</h6>
                                </div>
                            </div>
                            <div>
                            </div>
                        </div>
                        <div class="card-body session-table-height">
                            <b-table-simple striped responsive small>
                                <b-thead>
                                    <b-tr>
                                        <b-th width="50%">MEMBERS</b-th>
                                        <b-th>Females</b-th>
                                        <b-th>Males</b-th>
                                        <b-th>Groups</b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <b-tr>
                                        <td>Pending</td>
                                        <td>{{ pending_female }}</td>
                                        <td>{{ pending_male }}</td>
                                        <td>{{ pending_group }}</td>
                                    </b-tr>
                                    <b-tr>
                                        <td>Active</td>
                                        <td>{{ active_female }}</td>
                                        <td>{{ active_male }}</td>
                                        <td>{{ active_group }}</td>
                                    </b-tr>
                                    <b-tr>
                                        <td>Inactive</td>
                                        <td>{{ inactive_female }}</td>
                                        <td>{{ inactive_male }}</td>
                                        <td>{{ inactive_group }}</td>
                                    </b-tr>
                                    <b-tr>
                                        <td>Dormant</td>
                                        <td>{{ dormant_female }}</td>
                                        <td>{{ dormant_male }}</td>
                                        <td>{{ dormant_group }}</td>
                                    </b-tr>

                                </b-tbody>
                                <b-tfoot>
                                    <b-tr>
                                        <b-td class="fw-bold py-0">
                                            Total Membership
                                        </b-td>
                                        <b-td class="fw-bold">{{ total_female }}</b-td>
                                        <b-td class="fw-bold">{{ total_male }}</b-td>
                                        <b-td class="fw-bold">{{ total_group }}</b-td>
                                    </b-tr>
                                </b-tfoot>

                            </b-table-simple>
                        </div>

                    </div>
                </b-skeleton-wrapper>
            </div>
            <div class="col-md-4 col-xl-4 mb-4">
                <b-skeleton-wrapper :loading="loading">
                    <template #loading>
                        <b-card class="card-shape home-box mb-4">
                            <b-skeleton width="85%" height="20%"></b-skeleton>
                            <b-skeleton width="70%" height="20%"></b-skeleton>
                            <b-skeleton width="70%" height="20%"></b-skeleton>
                        </b-card>
                    </template>
                    <div class="card mb-4 card-shape home-box">
                        <div class="card-header py-3">
                            <div class="row">
                                <div class="col-6">
                                    <h6 class="m-0 fw-bold akkurate-dark">USSD Tracker</h6>
                                </div>
                            </div>
                            <div>
                            </div>
                        </div>
                        <div class="card-body">
                            <b-table class="small" :fields="ussd_fields" :items="ussd_items" hover striped responsive
                                small></b-table>
                        </div>
                    </div>
                </b-skeleton-wrapper>
            </div>

        </div>
    </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import DoughnutChart from "../Charts/DoughnutChart.vue";
import BarChart from "../Charts/BarChart.vue";
export default {
    components: {
        DoughnutChart,
        BarChart,
    },
    data() {
        return {
            loading: false,
            active_male: 0,
            active_female: 0,
            active_group: 0,
            inactive_male: 0,
            inactive_female: 0,
            inactive_group: 0,
            dormant_male: 0,
            dormant_female: 0,
            dormant_group: 0,
            total_female: 0,
            total_male: 0,
            total_group: 0,
            total_active: 0,
            total_inactive: 0,
            total_dormant: 0,
            pending_female: 0,
            pending_male: 0,
            pending_group: 0,
            passport_male_available: 0,
            passport_female_available: 0,
            passport_group_available: 0,
            passport_male_unavailable: 0,
            passport_female_unavailable: 0,
            passport_group_unavailable: 0,

            signature_male_available: 0,
            signature_female_available: 0,
            signature_group_available: 0,
            signature_male_unavailable: 0,
            signature_female_unavailable: 0,
            signature_group_unavailable: 0,

            gc_male_available: 0,
            gc_female_available: 0,
            gc_group_available: 0,
            gc_male_unavailable: 0,
            gc_female_unavailable: 0,
            gc_group_unavailable: 0,

            address_male_available: 0,
            address_female_available: 0,
            address_group_available: 0,
            address_male_unavailable: 0,
            address_female_unavailable: 0,
            address_group_unavailable: 0,

            mobile_male_available: 0,
            mobile_female_available: 0,
            mobile_group_available: 0,
            mobile_male_unavailable: 0,
            mobile_female_unavailable: 0,
            mobile_group_unavailable: 0,

            digital_address_male_available: 0,
            digital_address_female_available: 0,
            digital_address_group_available: 0,
            digital_address_male_unavailable: 0,
            digital_address_female_unavailable: 0,
            digital_address_group_unavailable: 0,

            beneficiaries_male_available: 0,
            beneficiaries_female_available: 0,
            beneficiaries_group_available: 0,

            beneficiaries_male_unavailable: 0,
            beneficiaries_female_unavailable: 0,
            beneficiaries_group_unavailable: 0,
            ussd_fields: [
                {
                    key: "type",
                    label: "USSD Usage"
                },
                {
                    key: "today",
                    lable: "Today"
                },
                {
                    key: "month",
                    label: "Month"
                },
                {
                    key: "year",
                    label: "YTD"
                }
            ],
            ussd_items: [],
            total_available_males: 0,
            total_available_females: 0,
            total_available_groups: 0,

            total_unavailable_males: 0,
            total_unavailable_females: 0,
            total_unavailable_groups: 0,

        };
    },
    created() {
        // Call your API here
        this.getFrontDeskApi();
    },
    computed: {

    },
    methods: {
        async getFrontDeskApi() {
            // Your API call logic goes here
            //   console.log("GEN Man API called!");
            this.loading = true;
            await ApiService.query("/dashboard/front-desk-officer")
                .then((response) => {
                    this.loading = false;
                    const res = response.data;
                    this.active_female = res.active_female;
                    this.active_male = res.active_male;
                    this.active_group = res.active_group;
                    this.inactive_female = res.inactive_female;
                    this.inactive_male = res.inactive_male;
                    this.inactive_group = res.inactive_group;
                    this.dormant_female = res.dormant_female;
                    this.dormant_male = res.dormant_male;
                    this.dormant_group = res.dormant_group;
                    this.pending_female = res.pending_female;
                    this.pending_male = res.pending_male;
                    this.pending_group = res.pending_group;
                    this.total_female = Number(this.active_female) + Number(this.inactive_female) + Number(this.dormant_female) + Number(this.pending_female);
                    this.total_male = Number(this.active_male) + Number(this.inactive_male) + Number(this.dormant_male) + Number(this.pending_male);
                    this.total_group = Number(this.active_group) + Number(this.inactive_group) + Number(this.dormant_group) + Number(this.pending_group);

                    this.passport_male_available = res.passport_male_available
                    this.passport_female_available = res.passport_female_available
                    this.passport_group_available = res.passport_group_available

                    this.passport_male_unavailable = res.passport_male_unavailable
                    this.passport_female_unavailable = res.passport_female_unavailable
                    this.passport_group_unavailable = res.passport_group_unavailable

                    this.signature_male_available = res.signature_male_available
                    this.signature_female_available = res.signature_female_available
                    this.signature_group_available = res.signature_group_available

                    this.signature_male_unavailable = res.signature_male_unavailable
                    this.signature_female_unavailable = res.signature_female_unavailable
                    this.signature_group_unavailable = res.signature_group_unavailable

                    this.gc_male_available = res.gc_male_available
                    this.gc_female_available = res.gc_female_available
                    this.gc_group_available = res.gc_group_available

                    this.gc_male_unavailable = res.gc_male_unavailable
                    this.gc_female_unavailable = res.gc_female_unavailable
                    this.gc_group_unavailable = res.gc_group_unavailable

                    this.address_male_available = res.address_male_available
                    this.address_female_available = res.address_female_available
                    this.address_group_available = res.address_group_available

                    this.address_male_unavailable = res.address_male_unavailable
                    this.address_female_unavailable = res.address_female_unavailable
                    this.address_group_unavailable = res.address_group_unavailable

                    this.mobile_male_available = res.mobile_male_available
                    this.mobile_female_available = res.mobile_female_available
                    this.mobile_group_available = res.mobile_group_available

                    this.mobile_male_unavailable = res.mobile_male_unavailable
                    this.mobile_female_unavailable = res.mobile_female_unavailable
                    this.mobile_group_unavailable = res.mobile_group_unavailable

                    this.digital_address_male_available = res.digital_address_male_available
                    this.digital_address_female_available = res.digital_address_female_available
                    this.digital_address_group_available = res.digital_address_group_available

                    this.digital_address_male_unavailable = res.digital_address_male_unavailable
                    this.digital_address_female_unavailable = res.digital_address_female_unavailable
                    this.digital_address_group_unavailable = res.digital_address_group_unavailable

                    this.beneficiaries_male_available = res.beneficiaries.with_beneficiaries.Male
                    this.beneficiaries_female_available = res.beneficiaries.with_beneficiaries.Female
                    this.beneficiaries_group_available = res.beneficiaries.with_beneficiaries.Group

                    this.beneficiaries_male_unavailable = res.beneficiaries.without_beneficiaries.Male
                    this.beneficiaries_female_unavailable = res.beneficiaries.without_beneficiaries.Female
                    this.beneficiaries_group_unavailable = res.beneficiaries.without_beneficiaries.Group
                    this.ussd_items = res.ussd_summary;

                    this.total_available_males = Number(this.passport_male_available) + Number(this.signature_male_available) + Number(this.gc_male_available) + Number(this.address_male_available) + Number(this.mobile_male_available) + Number(this.digital_address_male_available) + Number(this.beneficiaries_male_available)
                    this.total_available_females = Number(this.passport_female_available) + Number(this.signature_female_available) + Number(this.gc_female_available) + Number(this.address_female_available) + Number(this.mobile_female_available) + Number(this.digital_address_female_available) + Number(this.beneficiaries_female_available)
                    this.total_available_groups = Number(this.passport_group_available) + Number(this.signature_group_available) + Number(this.gc_group_available) + Number(this.address_group_available) + Number(this.mobile_group_available) + Number(this.digital_address_group_available) + Number(this.beneficiaries_group_available)

                    this.total_unavailable_males = Number(this.passport_male_unavailable) + Number(this.signature_male_unavailable) + Number(this.gc_male_unavailable) + Number(this.address_male_unavailable) + Number(this.mobile_male_unavailable) + Number(this.digital_address_male_unavailable) + Number(this.beneficiaries_male_unavailable)
                    this.total_unavailable_females = Number(this.passport_female_unavailable) + Number(this.signature_female_unavailable) + Number(this.gc_female_unavailable) + Number(this.address_female_unavailable) + Number(this.mobile_female_unavailable) + Number(this.digital_address_female_unavailable) + Number(this.beneficiaries_female_unavailable)
                    this.total_unavailable_groups = Number(this.passport_group_unavailable) + Number(this.signature_group_unavailable) + Number(this.gc_group_unavailable) + Number(this.address_group_unavailable) + Number(this.mobile_group_unavailable) + Number(this.digital_address_group_unavailable) + Number(this.beneficiaries_group_unavailable)

                }).catch(function (error) {
                    // this.isLoading = false;
                    // return error;
                    this.loading = false;
                }).finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>

<style scoped>
.session-table-height {
    height: 230px;
}

.card-height {
    height: 310px;
}


.text-blue {
    color: #0165d1;
}
</style>