<template>
    <div id="wrapper">
            <SidebarView :fixed_deposit="true"/>
            <div class="d-flex flex-column" id="content-wrapper">
                <div id="content">
                    <TopbarView />
                    <div class="container">
                        <div class="card card-shape home-box">
                            <div class="card-header py-3 d-flex flex-row align-items-center">
                                <h6 class="m-0 fw-bold text-green">Fixed Term Deposit</h6>
                            </div>
                            <div class="card-body" style="background-color: #eaeff2; border-bottom: 1px solid rgb(1, 134, 115);">
                                <basic-search :filter="filter" :basicSearch="basicSearch" :action="action"></basic-search>
                            </div>
                            <div class="card-body">
                                <search :form="form"></search>
                            </div>
                            <div class="card-body">
                                <advance-search @adv_search="advanceSearch"></advance-search>
                            </div>
                            <div class="card-body">
                                <div>
                                    <vue-good-table
                                        mode="remote"
                                        @on-page-change="onPageChange"
                                        @on-sort-change="onSortChange"
                                        @on-column-filter="onColumnFilter"
                                        @on-per-page-change="onPerPageChange"
                                        :totalRows="totalRecords"
                                        :isLoading.sync="isLoading"
                                        :pagination-options="{enabled: true,
                                            perPage: serverParams.perPage,}"
                                        :columns="columns"
                                        :rows="rows">
                                        <!-- Add your custom delete button column -->
                                        <template slot="table-row" slot-scope="props">
                                            <span v-if="props.column.field == 'duration'">
                                                <div class="akkurate-dark">{{calculateDuration(new Date(props.row.expected_start_date), new Date(props.row.maturity_date)) }}</div>
                                            </span>
                                            <span class="d-flex" v-else-if="props.column.field == 'action'">
                                                <!-- <a title="View" class="btn btn-sm akkurate-warning text-white">
                                                    <span class="akkurate-small"> <font-awesome-icon :icon="['fas', 'eye']" /></span> 
                                                </a> -->
                                                <!-- <a title="Print" class="btn btn-sm akkurate-ash text-white">
                                                    <span class="akkurate-small"> <font-awesome-icon :icon="['fas', 'book']" /></span> 
                                                </a> -->  <div class="dropdown no-arrow d-inline">
                                                    <!-- <a class="dropdown-toggle" href="#" role="button" id="dropdownMenu"
                                                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                                                    </a> -->
                                                    <a title="Print" class="btn btn-sm akkurate-ash text-white dropdown-toggle" id="dropdownMenu" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <span class="akkurate-small"> 
                                                            <font-awesome-icon :icon="['fas', 'book']" />
                                                        </span>
                                                    </a>
                                                    <div class="dropdown-menu shadow animated--fade-in"
                                                        aria-labelledby="dropdownMenu">
                                                        <div class="dropdown-header">Reports</div>
                                                        <advice :data="props.row" :institution="institution"></advice>
                                                        <certificate :data="props.row" :institution="institution"></certificate>
                                                    </div>
                                                </div>
                                                <!-- <a title="Approve" class="btn btn-sm akkurate-green-btn text-white">
                                                      <span class="akkurate-small"> <font-awesome-icon :icon="['fas', 'circle-check']" /></span>
                                                </a> -->
                                                <approval :data="props.row"></approval>
                                                <reedemed :data="props.row"></reedemed>
                                            </span>
                                        </template>
                                    </vue-good-table>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <footer></footer>
            </div>
        <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
    </div>
</template>

<script>
import SidebarView from '@/views/main/components/Sidebar.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import Footer from '@/views/main/components/Footer.vue';
import BasicSearch from './form/BasicSearch.vue'
import Search from './form/Search.vue'
import AdvanceSearch from './form/AdvanceSearch.vue';
import ApiService from '@/core/services/api.service';
import Reedemed from './form/modal/Reedemed.vue'
import Approval from './form/modal/Approval.vue'
import Advice from './reports/Advice.vue'
import Certificate from './reports/Certificate.vue';
export default {
    components: {
        SidebarView,
        TopbarView,
        Footer,
        BasicSearch,
        Search,
        AdvanceSearch,
        Reedemed,
        Approval,
        Advice,
        Certificate,
        // DepositForm
    },
    created() {
        this.loadItems();
        this.getInsistution();
    },
    data() {
        return {
            form: {
                payment: "Cash",
            },
            institution: "",
            filter: {
                code: "",
                search: "",
            },
            advSearch: {},
            action: {
                search_btn: "Search",
                disabled_search: false,
            },
            columns: [
                {
                    label: 'FD Code',
                    field: 'code',
                },
                {
                    label: 'Scheme Name',
                    field: 'fixed_deposit_scheme.name',
                },
                {
                    label: 'Principal',
                    field: 'principal_amount',
                    type: 'string',
                },
                {
                    label: 'Rate',
                    field: 'interest_rate',
                    type: 'string',
                },
                {
                    label: 'Start Date',
                    field: 'expected_start_date',
                    type: 'string',
                },
                {
                    label: 'Tenure',
                    field: 'tenor',
                    type: 'string',
                },
                {
                    label: 'Maturity Date',
                    field: 'maturity_date',
                    type: 'string',
                },
                {
                    label: 'Duration',
                    field: 'duration',
                    type: 'string',
                },
                {
                    label: 'INT.VALUS',
                    field: 'int_value',
                    type: 'string',
                },
                {
                    label: 'Current Value',
                    field: 'current_value',
                    type: 'string',
                },
                {
                    label: 'Action',
                    field: 'action',
                },
            ],
            rows: [],
            isLoading: false,
            totalRecords: 0,
            serverParams: {
                columnFilters: {
                },
                sort: [
                    {
                        field: '',
                        type: ''
                    }
                ],
                page: 1,
                perPage: 20
            }

        }
    },
    methods: {
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },

        onPageChange(params) {
            this.updateParams({ page: params.currentPage });
            this.loadItems();
        },

        onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage });
            this.loadItems();
        },

        onSortChange(params) {
            this.updateParams({
                sort: [{
                    type: params.sortType,
                    field: this.columns[params.columnIndex].field,
                }],
            });
            this.loadItems();
        },

        onColumnFilter(params) {
            this.updateParams(params);
            this.loadItems();
        },

        // load items is what brings back the rows from server
        async loadItems() {
            await ApiService.query('/investments/fixed-term-deposits', {
                params: {
                    "page": this.serverParams.page,
                    "per_page": this.serverParams.perPage,
                    "branch_ids": this.advSearch.branch, //ie fixed deposit number
                    "start_date": this.advSearch.start_date,
                    "end_date": this.advSearch.end_date,
                    "status": this.advSearch.status, // "COMPLETED", "PENDING", "FAILED"
                    "customer_code": this.advSearch.customer_code
                }
            }).then((response) => {
                // console.log(response.data.data);
                this.rows = response.data.data;
            }).catch((error) => {
                console.log(error);
            });
        },
        basicSearch() {
            this.getSingleFD();
        },
        async getSingleFD() {
            this.action.search_btn = "Processing...."
            this.action.disabled_search = true;
            await ApiService.get(`/investments/fixed-term-deposits/${this.filter.code}`)
                .then((response) => {
                    // console.log(response.data.data);
                    let payment = this.form.payment;
                    this.form = response.data.data
                    let first_name = this.form?.customer?.first_name != null ? this.form?.customer?.first_name : "";
                    let last_name = this.form?.customer?.last_name != null ? this.form?.customer?.last_name : "";
                    this.form.customer_name = first_name + ' ' + last_name;
                    this.form.payment = payment;
                    this.action.search_btn = "Search"
                    this.action.disabled_search = false;
                }).catch((error) => {
                    this.action.search_btn = "Search"
                    this.action.disabled_search = false;
                    console.log(error);
                })
        },
        advanceSearch(data) {
            this.advSearch = data;
            // console.log(this.advSearch);
            this.loadItems();
        },
        async getInsistution() {
            await ApiService.get('/settings/institution').then((response) => {
                // console.log(response);
                this.institution = response.data.data;
            }).catch((error) => {
                // console.log(error);
            })
        },
        calculateDuration(startDate, endDate) {
            // Convert both dates to milliseconds
            const startMillis = startDate.getTime();
            const endMillis = endDate.getTime();

            // Calculate the difference in milliseconds
            let durationMillis = Math.abs(endMillis - startMillis);

            // Calculate the duration in days, hours, minutes, and seconds
            const millisecondsInDay = 1000 * 60 * 60 * 24;
            const days = Math.floor(durationMillis / millisecondsInDay);
            durationMillis %= millisecondsInDay;
            // const hours = Math.floor(durationMillis / (1000 * 60 * 60));
            // durationMillis %= (1000 * 60 * 60);
            // const minutes = Math.floor(durationMillis / (1000 * 60));
            // durationMillis %= (1000 * 60);
            // const seconds = Math.floor(durationMillis / 1000);

            // Return an object with the duration components
            return days;
            // return {
            //     days: days,
            //     hours: hours,
            //     minutes: minutes,
            //     seconds: seconds
            // };
        }
    }

}

</script>