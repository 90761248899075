<template>
    <div id="wrapper">
        <SidebarView :loanImports="true" :loan="true" :loan-visible="true" />
        <div class="d-flex flex-column" id="content-wrapper">
            <div id="content">
                <TopbarView />

                <div class="container-fluid">
                    <!-- <div class="card card-shape home-box">
              
            </div> -->
                    <div>
                        <b-card no-body class="card-shape home-box">
                            <b-tabs card>
                                <b-tab title="Loan Imports" active>
                                    <LoanImports />
                                </b-tab>
                                <b-tab title="Repayment Imports">
                                    <RepaymentImports />
                                </b-tab>
                            </b-tabs>
                        </b-card>
                    </div>
                </div>
            </div>
            <footer></footer>
        </div>
        <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
    </div>
</template>
<script>
import SidebarView from "@/views/main/components/Sidebar.vue";
import TopbarView from "@/views/main/components/Topbar.vue";
import Footer from "@/views/main/components/Footer.vue";
import LoanImports from "./LoanImports.vue";
import RepaymentImports from "./RepaymentImports.vue";

export default {
    components: {
        SidebarView,
        TopbarView,
        Footer,
        RepaymentImports,
        LoanImports
    },
};
</script>