<template>
    <div>
        <div class="row"  v-for="(field, index) in picImports" :key="index">
            <div class="col-md-9">
                <div>
                    <p>Previews</p>
                    <div v-if="field.previewData">
                        <img class="imageCardPreviewWrapper" :src="field.previewData" alt="File Preview" />
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="akkurate-dark small">Choose File</div>
                    <div class="border mt-2">
                        <label class="m-0 label akkurate-green-btn">
                            <input class="d-none" type="file" @change="handleFileChange(index, $event)" />
                            <span class="akkurate-small">Browse</span>
                        </label>
                        
                        <span class="ms-2">{{ field.picture_name }}</span>
                    </div> 
                </div>
                
            </div>
            <div  class="col-md-3 mt-auto">
                <a type="button" @click="removeField(index)" class="btn akkurate-danger-btn text-white"><span class="small">
                    <i class="fas fa-trash"></i></span>
                </a>
            </div>
        </div>
        <div class="mt-2">
            <a @click="addPicField" type="button" class="btn akkurate-green-btn text-white"><span class="small"> Add Picture</span></a>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            picImports: [{ picture_name: "No file chosen" , previewData:null}],
        }
    },
    methods: {
        addPicField() {
            this.picImports.push({ picture_name: "No file chosen" , previewData:null});
        },
        removeField(index) {
            this.picImports.splice(index, 1);
        },
        handleFileChange(index, event) {
            var file = event.target.files[0];
            if (file) {
                this.$set(this.picImports, index, {
                    picture_name: file ? file.name : "No file chosen",
                    previewData: file ? URL.createObjectURL(file) : null
                });
                this.$emit('getPictures', this.getFiles());
            }

        },
        getFiles() {
            const allFiles = [];
            this.$el.querySelectorAll('input[type="file"]').forEach(inputElement => {
                // console.log(inputElement.files[0]);
                if (inputElement.files.length > 0) {
                    var pictures = inputElement.files[0];
                    allFiles.push(pictures);
                }
            });
            return allFiles;
        },
        emptyPicture(){
            this.picImports = [{ document_name: "No file chosen" }];
        }
    }
}

</script>