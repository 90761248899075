
<!-- import EditAccountTypeVue from '../../member/accounts/account-type/edit/EditAccountType.vue'; -->
<template>
    <div v-if="form.status != 'REJECTED'" id="wrapper">
        <SidebarView />
        <div class="d-flex flex-column" id="content-wrapper">
            <div id="content">
                <TopbarView />
                <div class="container-fluid">
                    <form @submit.prevent="approveJournal">
                    <div class="row">
                        <div class="col-md-12">
                            <button :disabled="isSaving" type="submit" class="btn akkurate-green-btn text-white btn-size mb-4">
                                <font-awesome-icon class="me-2" :icon="['fas', 'save']" /> Approve
                            </button>
                        </div>
                        <div class="col-md-12">
                        <b-card-group deck>
                            <b-card >
                                <template #header>
                                    <div class="py-1">
                                        <h6 class="m-0 fw-bold text-green">Approve Journal</h6>
                                    </div>
                                </template>
                                <b-card-body>
                                    <div class="row">
                                    <div class="col-12 col-md-4">
                                        <label class="akkurate-dark" for="recorded_date">Recorded Date</label>
                                            <b-form-group
                                            id="recorded_date"
                                            label-for="recorded_date">
                                            <b-form-input
                                            class="mb-3 mt-2 field-container fields"
                                            type="text"
                                            v-model="form.recorded_date"
                                            disabled
                                            ></b-form-input>
                                        </b-form-group>
                                    </div>
                                    
                                    <div class="col-12 col-md-4">
                                        <label class="akkurate-dark" for="code">Journal Code</label>
                                            <b-form-group
                                            id="code"
                                            label-for="code">
                                            <b-form-input
                                            class="mb-3 mt-2 field-container fields"
                                            type="text"
                                            v-model="form.code"
                                            disabled
                                            ></b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <label class="akkurate-dark" for="amount_requested">Amount Requested</label>
                                            <b-form-group
                                            id="amount_requested"
                                            label-for="amount_requested">
                                            <b-form-input
                                            class="mb-3 mt-2 field-container fields"
                                            type="text"
                                            disabled
                                            v-model="form.amount_requested"
                                            ></b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <label class="akkurate-dark" for="amount_approved">Amount Approved</label>
                                            <b-form-group
                                            id="amount_approved"
                                            label-for="amount_approved">
                                            <b-form-input
                                            class="mb-3 mt-2 field-container fields"
                                            type="text"
                                            required
                                            v-model="form.amount_approved"
                                            ></b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <label class="akkurate-dark" for="status">Status</label>
                                            <b-form-group
                                            id="status"
                                            label-for="status">
                                            <b-form-input
                                            class="mb-3 mt-2 field-container fields"
                                            type="text"
                                            v-model="form.status"
                                            disabled
                                            ></b-form-input>
                                        </b-form-group>
                                    </div>
                                    
                                    <!-- <div class="col-12 col-md-4">
                                        <label class="akkurate-dark" for="reason">Reason</label>
                                            <b-form-group
                                            id="reason"
                                            label-for="reason">
                                            <b-form-textarea
                                            class="mt-2 fields"
                                            id="reason"
                                            v-model="form.reason"
                                            disabled
                                            ></b-form-textarea>
                                        </b-form-group>
                                    </div> -->

                                    <!-- <div class="col-12 col-md-4">
                                        <label class="akkurate-dark" for="receipt_name">Receipt Name</label>
                                            <b-form-group
                                            id="receipt_name"
                                            label-for="receipt_name">
                                            <b-form-input
                                            class="mb-3 mt-2 field-container fields"
                                            type="text"
                                            v-model="form.receipt_name"
                                            disabled
                                            ></b-form-input>
                                        </b-form-group>
                                    </div> -->

                                    <!-- <div class="col-12 col-md-4">
                                        <label class="akkurate-dark" for="receipt_location">Receipt Location</label>
                                            <b-form-group
                                            id="receipt_location"
                                            label-for="receipt_location">
                                            <b-form-input
                                            class="mb-3 mt-2 field-container fields"
                                            type="text"
                                            v-model="form.receipt_location"
                                            disabled
                                            ></b-form-input>
                                        </b-form-group>
                                    </div> -->

                                    <div class="col-12 col-md-4">
                                        <label class="akkurate-dark" for="narration">Narration</label>
                                            <b-form-group
                                            id="narration"
                                            label-for="narration">
                                            <b-form-textarea
                                            class="mt-2 fields"
                                            id="narration"
                                            v-model="form.narration"
                                            disabled
                                            ></b-form-textarea>
                                        </b-form-group>
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <label class="akkurate-dark" for="branch">Branch</label>
                                            <b-form-group
                                            id="branch"
                                            label-for="branch">
                                            <b-form-input
                                            class="mb-3 mt-2 field-container fields"
                                            type="text"
                                            v-model="form.branch.name"
                                            disabled
                                            ></b-form-input>
                                        </b-form-group>
                                    </div>
                                    </div>
                                </b-card-body>
                                

                            </b-card>
                            </b-card-group>
                        </div>
                        <div class="col-md-12 mt-4">
                            <div class="card">
                                <div class="card-header">
                                        <h6 class="m-0 fw-bold text-green">Accounts</h6>
                                </div>
                                <div class="card-body">
                                    <div>
                                        <vue-good-table
                                        :columns="columns"
                                        :rows="form.vouch_journ_coa_accounts">
                                            <!-- Add your custom delete button column -->
                                        <template slot="table-row" slot-scope="props">
                                            <span v-if="props.column.field == 'action'">
                                                <update-approve-account :data="props.row" :coa_subhead_options="coa_subhead_options" @addAccount="addAccount"></update-approve-account>
                                                <!-- <a title="Delete" class="btn btn-sm akkurate-danger text-white" @click="confirmDeleteUser(props.row)">
                                                    <span class="akkurate-small"><font-awesome-icon  :icon="['fas', 'trash']" /></span>
                                                </a> -->
                                            </span>
                                        </template>
                                    </vue-good-table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mt-3">
                            <button :disabled="isSaving" type="submit" class="btn akkurate-green-btn text-white btn-size mb-4">
                                <font-awesome-icon class="me-2" :icon="['fas', 'save']" /> Approve
                            </button>
                        </div>
                    </div>
                    </form>
                </div>
                </div>
            </div>
        </div>
</template>
<script>
import SidebarView from '@/views/main/components/Sidebar.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import ApiService from "@/core/services/api.service";
import UpdateApproveAccount from './modal/UpdateApproveAccount.vue'
// import EditNextOfKin from '../edit/modal/EditNextOfKin.vue'

export default {
    components: {
        SidebarView,
        TopbarView,
        UpdateApproveAccount
    },
    mounted() {
        // this.loadPageOptions();
        this.getMemberView();

    },
    data() {
        return {
            ...this.initiateState(),
            isSaving: false,




            //Accounts
            columns: [
                {
                    label: '#',
                    field: 'id',
                },
                {
                    label: 'COA',
                    field: 'coa_subhead.name',
                },
                // {
                //     label: 'Requested Amount',
                //     field: 'amount_requested',
                // },
                // {
                //     label: 'Amount Approved',
                //     field: 'amount_approved',
                // },
                {
                    label: 'Dr Amount',
                    field: 'dr_amount',
                },
                {
                    label: 'Cr Amount',
                    field: 'cr_amount',
                },
                {
                    label: 'Dr Approved',
                    field: 'dr_amount_approved',
                },
                {
                    label: 'Cr Approved',
                    field: 'cr_amount_approved',
                },
                {
                    label: 'Action',
                    field: 'action',

                }
            ],
        };
    },
    methods: {
        async getMemberView() {
            this.$Progress.start();
            await ApiService.get("/journals/view/" + this.$route.params.id)
                .then(response => {
                    let data = response.data.data;
                    this.form = {
                        id: data.id,
                        recorded_date: data.recorded_date,
                        code: data.code,
                        amount_requested: data.amount_requested,
                        amount_approved: data.amount_requested,
                        status: data.status,
                        narration: data.narration,
                        branch: data.branch,
                        vouch_journ_coa_accounts: data.vouch_journal_coaaccounts.map((index) => {
                            return {
                                id: index.id,
                                vouch_journ_id: index.vouch_journ_id,
                                coa_subhead_id: index.coa_subhead_id,
                                amount_requested: index.amount_requested,
                                amount_approved: index.amount_approved,
                                dr_amount: index.dr_amount,
                                cr_amount: index.cr_amount,
                                dr_amount_approved: index.dr_amount,
                                cr_amount_approved: index.cr_amount,
                                coa_subhead: index.coa_sub_head,
                            }
                        }),
                    }
                    // console.log(this.form);
                    this.branches = response.data.branches;
                    this.coa_subhead_options = response.data.coa_subheads;
                    this.$Progress.finish();
                }).catch(() => {
                    this.$Progress.fail();
                    // console.log(error);
                })
        },
        addAccount(acc) {
            // console.log(acc);
            var account = [acc];
            // this.form.vouch_journ_coa_accounts.push(index);
            account.forEach(item_2 => {
                var index = this.form.vouch_journ_coa_accounts.findIndex(item_1 => item_1.id === item_2.id);
                if (index !== -1) {
                    if (item_2.dr_amount_approved == 0) {
                        this.form.vouch_journ_coa_accounts[index].amount_approved = item_2.cr_amount_approved;
                        this.form.vouch_journ_coa_accounts[index].cr_amount_approved = item_2.cr_amount_approved;
                    } else {
                        this.form.vouch_journ_coa_accounts[index].amount_approved = item_2.dr_amount_approved;
                        this.form.vouch_journ_coa_accounts[index].dr_amount_approved = item_2.dr_amount_approved;
                    }
                    // this.form.vouch_journ_coa_accounts[index].amount_approved = item_2.dr_amount_approved;
                    // this.form.vouch_journ_coa_accounts[index].amount_approved = item_2.cr_amount_approved;
                    this.form.vouch_journ_coa_accounts[index].coa_subhead = item_2.coa_subhead;
                }
            });
            // console.log(this.form);
        },
        async approveJournal() {
            // console.log(this.form);
            this.$Progress.start();
            this.isSaving = true;
            console.log(this.form);
            await ApiService.post(`/journals/approve/${this.form.id}`, this.form)
                .then((response) => {
                    this.isSaving = false;
                    this.$Progress.finish();
                    toast.fire({
                        icon: "success",
                        title: response.data.message,
                    });
                    this.isSaving = false;
                    // this.form.reset();
                    this.$router.push({ path: '/journal' });
                })
                .catch((error) => {
                    console.log(error);
                    this.isSaving = false;
                    this.$Progress.fail();
                    toast.fire({
                        icon: "error",
                        title: error.response.data.message,
                    });
                    // if (error.response.data.errors) {
                    //     this.form.errors = error.response.data.errors;
                    // }
                });
        },

        initiateState() {
            return {
                form: {
                    id: "",
                    recorded_date: "",
                    code: "",
                    type: "",
                    amount_requested: "",
                    amount_approved: "",
                    dr_amount_approved: "",
                    cr_amount_approved: "",
                    status: "",
                    narration: "",
                    branch: {},
                    vouch_journ_coa_accounts: [],
                    // coa_subheads:[],

                },
                branches: [],
                coa_subhead_options: [],

            }
        }

    }
}
</script>