<template>
    <div class="row">
        <div class="col-9">
            <div class="row" v-for="(denominations, index) in denominations">
                <div class="col-4">
                    <b-form-group id="denomin" label-for="denomin">
                        <multiselect id="denom_amount" v-model="denominations.denom_amount" :options="denom_options"
                            name="denom_amount" @select="handleInput(index)" :allow-empty="false" selectLabel=""
                            deselectLabel="" class="mt-2 field-container" required></multiselect>
                    </b-form-group>
                </div>

                <div class="col-4">
                    <b-form-group label-for="no_of_den" class="mb-2">
                        <b-form-input id="no_of_den" name="no_of_den" v-model="denominations.denom_frequency"
                            @input="handleInput(index)" class="mt-2 field-container fields" type="text"></b-form-input>
                        <b-form-invalid-feedback>
                            The No Of Den field is required
                        </b-form-invalid-feedback>
                    </b-form-group>
                </div>

                <div class="col-3">
                    <b-form-group label-for="total" class="mb-2">
                        <!-- <template #label>
            <label class="akkurate-dark small" for="total">Total</label>
            </template> -->
                        <b-form-input id="total" name="total" class="mt-2 field-container fields"
                            v-model="denominations.sub_total" type="text" disabled></b-form-input>
                    </b-form-group>
                </div>
                <div class="col col-md-1 mt-auto" v-if="index != 0">
                    <a class="akkurate-danger-btn btn text-white mb-2" type="button" @click="removeField(index)"><span
                            class="small">
                            <i class="fas fa-trash"></i></span></a>
                </div>
                <div class="col col-md-1" v-else></div>
            </div>

        </div>
        <div class="col-3 mt-2">
            <!-- <b-button @click="addField" type="button" class="btn btn-sm akkurate-green-btn text-white"><span class="small"> Add
                    Demonination</span></b-button> -->
            <b-button class="btn btn-sm akkurate-green-btn" @click="addField" variant="primary">
                <span class="akkurate-small"> <i class="fas fa-plus"></i> Demonination</span>
            </b-button>
        </div>
    </div>

</template>

<script>
import Multiselect from "vue-multiselect";
export default {
    name: "Denomination",
    props: ['denominations'],
    components: {
        Multiselect,
    },
    data() {
        return {
            denom_options: ['1', '2', '5', '10', '20', '50', '100', '200'],
        }
    },
    methods: {
        handleInput(index) {
            const denom_amount = Number(this.denominations[index].denom_amount);
            const denom_frequency = Number(this.denominations[index].denom_frequency);
            this.denominations[index].sub_total = denom_amount * denom_frequency;

            // this.$emit('denominations',this.denominations)
        },
        removeField(index) {
            this.denominations.splice(index, 1);
        },
        addField() {
            this.denominations.push({ denom_amount: '1', denom_frequency: '', sub_total: '' });
        },
    }
}
</script>