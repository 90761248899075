<template>
    <div>
      <b-modal 
          size="xxl" 
          ref="showVoucherReceipt"
          @shown="modalShown">
          <template #modal-title>
              <span class="akkurate-green fw-bold">Voucher Payment</span>
          </template>
          <div class="container-fluid" ref="pdfContent">
              <!-- First Receipt -->
              <div class="row">
                <div class="col-auto d-flex align-items-center flex-column bd-highlight">
                    <img class="img-fluid img-visibility statement-logo" :src="require('@/assets/img/default_logo.png')">
                    <p class="akkurate-green fw-bold mt-auto pt-2 bd-highlight mb-0">HEAD OFFICE</p>
                </div>
                <div class="bd-highlight col mt-auto">
                    <p class="fw-bold text-uppercase mb-0">{{ report_data?.institution?.name }}</p>
                    <p class="fw-bold">{{ report_data?.institution?.motto }}</p>
                    <p class="mb-0 akkurate-small">{{ report_data?.institution?.postal }}</p>
                    <p class="mb-0 akkurate-small">{{ report_data?.institution?.telephone }}</p>
                    <p class="akkurate-small">{{ report_data?.institution?.email }}</p>
                    <div class="row">
                        <div class="col-6 mt-auto">
                            <p class="fw-bold akkurate-small text-start mt-auto mb-0 bd-highlight text-uppercase">REFERENCE NUMBER:</p>
                        </div>
                        <div class="col-6 mt-auto">
                            <p class="fw-bold akkurate-small text-end mb-0 mt-auto bd-highlight text-uppercase">{{ new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'long', year: 'numeric' }).format(Date.now()) }}</p>
                        </div>
                    </div>
                </div> 
              </div>
             <div class="row mt-4">
                <div class="col-6">
                    <p class="mb-0 text-start small fw-bold">Narration: staff of resourceful trs donation for regional manager send - off</p>
                </div>
                <div class="col-6">
                    <p class="mb-0 text-end small fw-bold">Cheque#:</p>
                </div>
             </div>
             <div class="row mt-3">
                <div class="col-12">
                    <b-table class="small" responsive striped hover :items="items">
                        <template #custom-foot>
                            <b-tr>
                            <b-th>Total</b-th>
                            <b-th colspan="3"></b-th>
                            <b-th>200</b-th>
                            </b-tr>
                      </template>
                    </b-table>
                </div>
             </div>
             <div class="row">
                <div class="col-6">
                    <p>Prepared by</p>
                    <div class="mt-4">____________________</div>
                    <div class="mt-2 fw-bold">PAULINA ESSILIFE</div>
                    <div class="akkurate-small">Branch Manager</div>
                    <div class="akkurate-small">21/12/2023</div>
                </div>
                <div class="col-6 text-end">
                    <p>Approved by</p>
                    <div class="mt-4">____________________</div>
                </div>
             </div>

             <div class="row mt-3">
                <hr class="mb-0"/>
                <div class="py-2 col-md-12 text-center akkurate-danger">
                    PAYEE
                </div>
                <hr class="mb-0"/>
                <div class="col-12">
                    <div class="mt-4">____________________</div>
                    <div class="mt-2 fw-bold">SAMMUEL O. KODUAH</div>
                    <div class="akkurate-small">2332589855889</div>
                    <div class="akkurate-small">21/12/2023</div>
                </div>

             </div>

        
             
          </div>
          <template #modal-footer>
                 <div class="w-100">
                 <!-- begin loader -->
                 <!-- <beat-loader-component 
                 class="float-left"
                 v-if="isLoading"
                 :isLoading="isLoading"
                 ></beat-loader-component> -->
                 <!-- end loader -->
                 <!-- Emulate built in modal footer ok and cancel button actions -->
                 <b-button @click="generatePDF" type="button" class="akkurate-green-btn download-btn ms-2 float-right"><span class="small"><font-awesome-icon :icon="['fas', 'book']" /> Print</span></b-button>
                 <b-button @click="exportToPDF" class="akkurate-green-btn download-btn float-right"><span class="small"><font-awesome-icon :icon="['fas', 'save']" /> Download</span></b-button>     
                 </div>
          </template>     
      </b-modal>
     
  </div>
  </template>
  <script>
  import html2pdf from "html2pdf.js";
  import StatementInstitution from '@/views/main/components/institution/StatementInstitution.vue'
  export default {
      components:{
          StatementInstitution
      },
      props: ['report_data'],
      data() {
          return {
              isPrinting: false,
              items: [
                { gl_code: 55004, name: 'Donation', type: 'Expenses', debit:'', credit:'' },
                { gl_code: 10011, name: 'Cash On Hand - Paulina', type: 'Assests', debit:'', credit: 200 },
              
            ]
              // currentModal: false,
          }
      },
      methods: {
          showModal() {
              this.$refs['showVoucherReceipt'].show()
          },
          hideModal() {
              this.$refs['showVoucherReceipt'].hide()
          },

  
          exportToPDF() {
              const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
              // console.log("here");
              const pdfOptions = {
                  margin: 10,
                  filename: 'generated.pdf',
                  image: { type: 'jpeg', quality: 0.98 },
                  html2canvas: { scale: 2, useCROS:true },
                  jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
              };
              // html2pdf().from(content).set(pdfOptions).outputPdf((pdf) => {
  
              // });
              html2pdf().from(content).set(pdfOptions).save();
          },
          generatePDF() {
              // Define the element to convert to a PDF
              const element = this.$refs.pdfContent; // Replace with your element's ID or selector
              // console.log(elementToConvert);
  
              // Define the options for html2pdf
              const pdfOptions = {
                  margin: 10,
                  filename: 'document.pdf',
                  image: { type: 'jpeg', quality: 0.98 },
                  html2canvas: { scale: 2, useCROS:true },
                  jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
                  debug: true, // Enable debugging
              };
              html2pdf().from(element).set(pdfOptions).outputPdf('blob').then((pdf) => {
                  const blob = new Blob([pdf], { type: 'application/pdf' });
                  const url = URL.createObjectURL(blob);
                  // console.log(pdf);
                  const printWindow = window.open(url, '_blank', 'width=800,height=600');
                  printWindow.onload = () => {
                      printWindow.print();
                      URL.revokeObjectURL(url);
                  };
              });
          },
          modalShown() {
            // this.items = this.report_data.coa_accounts.map(index => {
            //     'gl_code' : index.vouch_journ_id,
            //     'name' : 

            // });
          },
  
      }
  
  }
  </script>