<template>
    <div id="wrapper">
        <SidebarView :institution="true" />
        <div class="d-flex flex-column" id="content-wrapper">
            <div id="content">
                <TopbarView />
                <b-container>
                    <div class="row">
                        <!-- <div class="col-12 col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h6 class="m-0 fw-bold text-green">Update Insititute Details</h6>
                                  
                                </div>
                                <div class="card-body">
                                   
                                    <hr />
                                   
                                </div>
                            </div>
                            </div> -->

                        <div class="col-12 col-md-12">
                            <div class="card card-shape home-box">
                                <div class="card-header d-flex flex-row align-items-center">
                                    <h6 class="m-0 fw-bold text-green">Update Institution Details</h6>
                                </div>
                                <div class="card-body">
                                    <b-form class="form" method="PUT" @submit.prevent="updateInsitution">
                                        <!-- <loading-spinner v-if="isLoading" /> -->
                                        <insitution-form v-if="!isLoading" :errors="errors" :form="form" :coa_subheads_options="coa_subheads_options"></insitution-form>
                                        <hr />
                                        <div class="text-center py-3">
                                            <SaveButton :isSaving="isSaving" />
                                        </div>
                                    </b-form>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-container>
            </div>
            <footer></footer>
        </div>

    </div>

</template>
<script>
// import BeatLoaderComponent from "@/views/components/Loader/BeatLoaderComponent.vue";

// import ApiService from "@/core/services/api.service";
import SidebarView from '@/views/main/components/Sidebar.vue';
import Footer from '@/views/main/components/Footer.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import InsitutionForm from '../form/InsitutionForm.vue'
import ApiService from "@/core/services/api.service";
import SaveButton from '@/views/main/components/SaveButton.vue';


export default {
    name: "UpdateInsitition",
    components: {
        SidebarView,
        Footer,
        TopbarView,
        InsitutionForm,
        SaveButton
    },
    data() {
        return {
            form: {
                name: "",
                alias: "",
                motto: "",
                url: "",
                address: "",
                postal: "",
                email: "",
                telephone: "",
                mobile: "",
                currency: "",
                currency_code: "",
                logo: "",
                send_sms: "",
                bank_coasubhead_id: "",
                accountant_name: "",
                accountant_phone: "",
                general_manager_name: "",
                general_manager_phone: "",
                board_treasurer_name: "",
                board_treasurer_phone: "",
                board_chairman_name: "",
                board_chairman_phone: "",
                loan_committee_name: "",
                loan_committee_phone: "",
                supervisory_committee_name: "",
                supervisory_committee_phone: "",
                board_secretary_name:"",
                board_secretary_phone:"",
                bank_coasubhead: {},
                // coa_subheads_options: [],
                errors: [],
            },
            coa_subheads_options:[],
            errors: [],
            isSaving: false,
            isLoading:false,

        }
    },
    created() {
        this.loadInsitutionDetails();
    },

    methods: {
        async loadInsitutionDetails() {
            this.$Progress.start();
            this.isLoading = true;
            await ApiService.get("/settings/institution")
                .then((response) => {
                    // console.log(response.data.data.bank_coa); 
                    const res = response.data;
                    console.log(res);
                    this.coa_subheads_options = res.coa_subheads;
                    
                    this.form.name = res.data.name;
                    this.form.alias = res.data.alias;
                    this.form.motto = res.data.motto;
                    this.form.url = res.data.url;
                    this.form.address = res.data.address;
                    this.form.postal = res.data.postal;
                    this.form.email = res.data.email;
                    this.form.telephone = res.data.telephone;
                    this.form.mobile = res.data.mobile;
                    this.form.currency = res.data.currency;
                    this.form.send_sms = res.data.send_sms;
                    this.form.currency_code = res.data.currency_code;
                    this.form.logo = res.data.logo;
                    this.form.bank_coasubhead = res.data.bank_coa
                    
                   
                    this.form.accountant_name = res.data.accountant_name
                    this.form.accountant_phone = res.data.accountant_phone
                    this.form.general_manager_name = res.data.general_manager_name
                    this.form.general_manager_phone = res.data.general_manager_phone
                    this.form.board_treasurer_name = res.data.board_treasurer_name
                    this.form.board_treasurer_phone = res.data.board_treasurer_phone
                    this.form.board_chairman_name = res.data.board_chairman_name
                    this.form.board_chairman_phone = res.data.board_chairman_phone
                    this.form.loan_committee_name = res.data.loan_committee_name
                    this.form.loan_committee_phone = res.data.loan_committee_phone
                    this.form.supervisory_committee_name = res.data.supervisory_committee_name
                    this.form.supervisory_committee_phone = res.data.supervisory_committee_phone
                    this.form.board_secretary_name = res.data.board_secretary_name
                    this.form.board_secretary_phone = res.data.board_secretary_phone
                    this.isLoading = false;
                    this.$Progress.finish();
                    // console.log(this.isLoading);
                    // console.log(this.coa_subheads_options);
                })
                .catch((error) => {
                    this.isLoading = false;
                    this.$Progress.fail();
                });
        },
        async updateInsitution() {
            this.isSaving = true;
            this.$Progress.start()
            this.form.bank_coasubhead_id = this.form.bank_coasubhead?.id;
            await ApiService.put("/settings/institution/update", this.form)
                .then((response) => {
                    this.isLoadingSaving = false;
                    // console.log(response);

                    toast.fire({
                        icon: "success",
                        title: response.data.message,
                    });
                    this.isSaving = false;
                    // this.form = this.initialState().form;
                    this.$Progress.finish();
                })
                .catch((error) => {
                    this.isSaving = false;
                    this.$Progress.fail();
                    if (error.response.data.errors) {
                        this.errors = error.response.data.errors;
                    }
                    else {
                        this.errors = [];
                    }
                });
        },
    }
}

</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>