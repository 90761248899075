<template>
    <div class="row mt-3">
        <div class="col-md-6">
            <label class="akkurate-dark" for="topup_amount">Topup Amount <star></star></label>
            <b-form-group id="topup_amount" label-for="topup_amount">
                <b-form-input name="topup_amount" class="mt-2 field-container fields" type="text"
                    placeholder="Enter Amount" v-model="form.topup_amount" v-validate="{ required: true }"
                    :state="validateState('topup_amount')" aria-describedby="topup_amount" data-vv-as="Amount"
                    required></b-form-input>
                <b-form-invalid-feedback>{{ veeErrors.first('topup_amount')
                    }}</b-form-invalid-feedback>
                <errors v-if="form.errors.topup_amount">
                    {{ form.errors.topup_amount[0] }}
                </errors>
            </b-form-group>
        </div>
        <div class="col-md-6">
            <b-form-group label-for="reason" class="mb-2">
                <template #label>
                    <label class="akkurate-dark" for="reason">Reason <star></star></label>
                </template>
                <b-form-textarea class="mt-2 fields" id="reason" name="reason" v-model="form.reason"
                    placeholder="Enter Reason..." v-validate="{ required: true }" :state="validateState('reason')"
                    aria-describedby="reason" data-vv-as="Reason" required></b-form-textarea>
                <b-form-invalid-feedback>{{ veeErrors.first('reason')
                    }}</b-form-invalid-feedback>
                <errors v-if="form.errors.reason">
                    {{ form.errors.reason[0] }}
                </errors>
            </b-form-group>
        </div>
        <hr />
        <div class="col-12 text-end">
            <b-button @click="clearForm" class="akkurate-gray-btn me-2 btn-size"><font-awesome-icon class="me-2"
                    :icon="['fas', 'close']" />Clear</b-button>
            <button type="submit" :disabled="isSaving"
                class="btn akkurate-green-btn text-white btn-size me-2"><font-awesome-icon class="me-2"
                    :icon="['fas', 'save']" />{{ save }}</button>

        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            ...this.initialState(),
            isLoading: false,
            filter: {
                loan_code: "",
            },
            loan: {
                status: "",
                tenor: "",
                interest_rate: "",
                interest_method: "",
                expected_interest: "",
                interest_balance: "",
                interest_paid: "",
                interest_balance: "",
                loan_number: "",
                principal_amount: "",
                principal_paid: "",
                principal_balance: "",
                principal_due: "",
                loan_product: "",
                account: "",
                start_date: "",
                last_payment: "",
                interest_due: "",
            },
            search: "Search",
            save:"Save",
            isSearching: false,
            isSaving: false,
            total: "",
        }
    },
    methods: {
        validateState(ref) {
            if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        handleOk(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        async handleSubmit() {
            this.$validator.validateAll().then(result => {
                if (!result) {
                    return;
                }
                this.storeTopup();

            });

        },
        async storeTopup() {
            this.isSaving = true;
            this.$Progress.start();
            this.form.loan_number = this.filter.loan_code;
            await ApiService.post("/loans/loanTopUp", this.form)
                .then((response) => {
                    this.isLoadingSaving = false;
                    this.$Progress.finish();
                    toast.fire({
                        icon: "success",
                        title: response.data.message,
                    });
                    this.isSaving = false;
                    // this.form.reset();
                    this.form = this.initialState().form;
                    this.$bvModal.hide('topup')
                })
                .catch((error) => {
                    this.isSaving = false;
                    this.$Progress.fail();

                    if (error.response.data.errors) {
                        this.form.errors = error.response.data.errors;
                    }
                    if (error.response.status == 400) {
                        swal.fire({
                            icon: "error",
                            title: error.response?.statusText,
                            text: error.response?.data?.message,
                            showCancelButton: false, // Show the "Cancel" button
                            confirmButtonText: 'OK', // Customize the text for the "OK" button
                            buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                            customClass: {
                                confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                            }
                            // footer: '<a href="#">Why do I have this issue?</a>'
                        });
                    }
                });
        },
        filterLoan() {
            this.search = "processing...";
            this.isSearching = true;
            this.isLoading = true;
            ApiService.get(`/loans/${this.filter.loan_code}`)
                .then((response) => {
                    // console.log(response.data.data)
                    this.loan = response.data.data;
                    this.search = "Search";
                    this.isSearching = false;
                    this.isLoading = false;
                }).catch((error) => {
                    this.search = "Search";
                    this.isSearching = false;
                    this.isLoading = false;
                    if (error.response.status == 404) {
                        swal.fire({
                            icon: "error",
                            title: error.response.statusText,
                            text: "Something went wrong!",
                            showCancelButton: false, // Show the "Cancel" button
                            confirmButtonText: 'OK', // Customize the text for the "OK" button
                            buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                            customClass: {
                                confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                            }
                            // footer: '<a href="#">Why do I have this issue?</a>'
                        });
                    }
                })
        },
        clearForm() {
            this.form = this.initialState().form; // Call the initialState method to get the default form
        },
        initialState() {
            return {
                form: {
                    loan_number: "",
                    errors: [],
                    source: "web"
                },
            }
        },
    },
}
</script>