<template>
    <div>
     <div class="row" v-for="(field, index) in docImports" :key="index">
         <div class="col-md-9">
             <!-- <div>
                 <p>Previews</p>
                 <div v-if="field.previewData">
                     <img class="imageCardPreviewWrapper" :src="field.previewData" alt="File Preview" />
                 </div>
             </div> -->
             <div class="col-md-12">
                 <div class="akkurate-dark small">Choose File</div>
                 <div class="border mt-2"> 
                     <label class="m-0 label akkurate-green-btn">
                         <input class="d-none" type="file" @change="handleFileChange(index, $event)" />
                         <span class="akkurate-small">Browse</span>
                     </label>
                     <span class="ms-2">{{ field.document_name }}</span>
                 </div> 
             </div>
             
         </div>
         <div  class="col-md-3 mt-auto">
             <a type="button"  @click="removeField(index)" class="btn akkurate-danger-btn text-white"><span class="small"> <i class="fas fa-trash"></i></span></a>
         </div>
     </div>
     <div class="mt-2">
            <a @click="addDocField" type="button" class="btn akkurate-green-btn text-white"><span class="small"> Add Document</span></a>
        </div>
        <!-- <button @click="emptyDocument" type="button" class="btn akkurate-green-btn text-white">Empty Docs</button> -->
    </div>
 </template>
 
<script>
export default {
    data() {
        return {
            docImports: [{ document_name: "No file chosen" }],
        }
    },
    methods: {
        addDocField() {
            this.docImports.push({ document_name: "No file chosen" });
        },
        removeField(index) {
            this.docImports.splice(index, 1);
        },
        handleFileChange(index,event) {
            var file = event.target.files[0];
            // console.log(file);  
            if (file) {
                this.$set(this.docImports, index, {
                    document_name: file ? file.name : "No file chosen",
                    // previewData: file ? URL.createObjectURL(file) : null
                });
                this.$emit('getDocument', this.getFiles());
                // console.log(this.docImports);
                // this.$emit('emptyDocs', this.docImports);
            }
        },
        addFileInput() {
            this.fileInputs.push({});
        },
        getFiles() {
            const allFiles = [];
            this.$el.querySelectorAll('input[type="file"]').forEach(inputElement => {
                if (inputElement.files.length > 0) {
                    var documents = inputElement.files[0];
                    allFiles.push(documents);
                }
            });
            return allFiles;
        },
        emptyDocument(){
            this.docImports = [{ document_name: "No file chosen" }];
        }

    }
}

</script>