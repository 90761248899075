<template>
    <div class="row">
        <div class="col-12 col-md-3">
            <label class="akkurate-dark" for="principal_amount">Principal Amount <star></star></label>
            <b-form-group id="principal_amount" label-for="principal_amount">
                <b-form-input class="mb-3 mt-2 field-container fields" type="number"
                    placeholder="Enter Principal Amount" v-model="form.principal_amount" required
                    step="any"></b-form-input>
            </b-form-group>
        </div>
        <div class="col-12 col-md-3">
            <b-form-group label-for="interest_method" class="mb-3">
                <template #label>
                    <label class="akkurate-dark small" for="interest_method">Interest Method <star></star></label>
                </template>
                <multiselect v-model="form.interest_method" :options="interest_method_options" selectLabel=""
                    deselectLabel="" :show-labels="false" @input="onChange" class="mt-2 field-container"
                    placeholder="Pick a value">
                </multiselect>
            </b-form-group>
        </div>
        <div class="col-12 col-md-3">
            <label class="akkurate-dark" for="interest_rate">Interest Rate <star></star></label>
            <b-form-group id="pinterest_rate" label-for="interest_rate">
                <b-form-input class="mb-3 mt-2 field-container fields" type="number" placeholder="Enter Interest Rate"
                    v-model="form.interest_rate" required step="any"></b-form-input>
            </b-form-group>
        </div>
        <div class="col-12 col-md-3">
            <b-form-group label-for="interest_rate_frequency" class="mb-3">
                <template #label>
                    <label class="akkurate-dark small" for="interest_rate_frequency">Interest Rate Frequency <star>
                        </star></label>
                </template>
                <multiselect v-model="form.interest_rate_frequency" :options="interest_rate_frequency_options"
                    selectLabel="" deselectLabel="" :show-labels="false" class="mt-2 field-container"
                    placeholder="Select interest rate frequency">
                </multiselect>
            </b-form-group>
        </div>
        <div class="col-12 col-md-3">
            <label class="akkurate-dark">Tenure <star></star></label>
            <b-form-group>
                <b-form-input id="tenor" name="tenor" class="mt-2 field-container fields" type="number"
                    placeholder="Enter Tenure" v-model="form.tenor" step="any"></b-form-input>
            </b-form-group>
        </div>

        <div class="col-12 col-md-3">
            <label class="akkurate-dark">Tenure Interval <star></star></label>
            <b-form-group>
                <multiselect v-model="form.tenor_interval" placeholder="Select tenure interval"
                    :options="tenor_interval_options" selectLabel="" deselectLabel="" class="mt-2 field-container"
                    :allow-empty="false">
                </multiselect>
            </b-form-group>
        </div>

        <div class="col-12 col-md-3">
            <label class="akkurate-dark" for="interest_start_date">Interest Start Date <star></star></label>
            <b-form-group id="interest_start_date" label-for="interest_start_date">
                <b-form-input class="mb-3 mt-2 field-container fields" type="date" v-model="form.interest_start_date"
                    required></b-form-input>
            </b-form-group>
        </div>


        <div class="col-12 col-md-3">
            <label class="akkurate-dark" for="repayment">Repayment Cycle <star></star></label>
            <multiselect class="mt-2 field-container" placeholder="Select repayment cycle"
                v-model="form.repayment_cycle" :options="repayment_options">
            </multiselect>
        </div>

        <div class="col-12 col-md-3" v-show="hybird">
            <label class="akkurate-dark" for="fixed_interest_rate">Fixed Interest Rate <star></star></label>
            <b-form-group id="fixed_interest_rate" label-for="fixed_interest_rate">
                <b-form-input class="mb-3 mt-2 field-container fields" type="number"
                    v-model="form.fixed_interest_rate"></b-form-input>
            </b-form-group>
        </div>

        <div class="col-12 col-md-3" v-show="hybird">
            <label class="akkurate-dark" for="fixed_interest_rate_frequency">Fixed Interest Rate Frequency <star></star>
                </label>
            <multiselect class="mt-2 field-container" v-model="form.fixed_interest_rate_frequency"
                :options="fixed_interest_rate_frequency_options">
            </multiselect>
        </div>

        <div class="col-12 col-md-3" v-show="hybird">
            <label class="akkurate-dark" for="fixed_interval">Fixed Interval <star></star></label>
            <multiselect class="mt-2 field-container" v-model="form.fixed_interval" :options="fixed_interval_options">
            </multiselect>
        </div>

        <div class="col-12 col-md-3" v-show="hybird">
            <label class="akkurate-dark" for="fixed_duration">Fixed Duration <star></star></label>
            <b-form-group id="fixed_duration" label-for="fixed_duration">
                <b-form-input class="mb-3 mt-2 field-container fields" type="number"
                    v-model="form.fixed_duration"></b-form-input>
            </b-form-group>
        </div>

        <div class="col-12 col-md-3" v-show="hybird">
            <label class="akkurate-dark" for="variable_interest_rate">Variable Interest Rate <star></star></label>
            <b-form-group id="variable_interest_rate" label-for="variable_interest_rate">
                <b-form-input class="mb-3 mt-2 field-container fields" type="number"
                    v-model="form.variable_interest_rate"></b-form-input>
            </b-form-group>
        </div>

        <div class="col-12 col-md-3" v-show="hybird">
            <label class="akkurate-dark" for="variable_interest_rate_frequency">Variable Interest Rate Frequency <star>
                </star>
            </label>
            <multiselect class="mt-2 field-container" v-model="form.variable_interest_rate_frequency"
                :options="variable_interest_rate_frequency_options">
            </multiselect>
        </div>
        <div class="col-12 col-md-3" v-show="hybird">
            <label class="akkurate-dark" for="variable_interval">Variable Interval <star></star></label>
            <multiselect class="mt-2 field-container" v-model="form.variable_interval"
                :options="variable_interval_options">
            </multiselect>
        </div>

        <div class="col-12 col-md-3" v-show="hybird">
            <label class="akkurate-dark" for="variable_duration">Variable Duration <star></star></label>
            <b-form-group id="variable_duration" label-for="variable_duration">
                <b-form-input class="mb-3 mt-2 field-container fields" type="number"
                    v-model="form.variable_duration"></b-form-input>
            </b-form-group>
        </div>

    </div>
</template>

<script>
// import ApiService from '@/core/services/api.service'
import Multiselect from 'vue-multiselect'
export default {
    props: ['form'],
    components: {
        Multiselect
    },
    data() {
        return {
            interest_method_options: ['straight-line', 'reducing-balance', 'hybrid'],
            interest_rate_frequency_options: ['Daily', 'Weekly', 'Monthly', 'Annually'],
            tenor_interval_options: ['Days', 'Weeks', 'Months', 'Years'],
            repayment_options: ['Daily', 'Weekly', 'Monthly', 'Annually'],
            fixed_interest_rate_frequency_options: ['Daily', 'Weekly', 'Monthly', 'Annually'],
            fixed_interval_options: ['Days', 'Weeks', 'Months', 'Years'],
            variable_interest_rate_frequency_options: ['Daily', 'Weekly', 'Monthly', 'Annually'],
            variable_interval_options: ['Days', 'Weeks', 'Months', 'Years'],
            hybird: false,
        }
    },
    methods: {
        onChange(value) {
            if (value == 'hybrid') {
                this.hybird = true
            } else {
                this.hybird = false
            }
        },
        // loanCalculator() {
        //     ApiService.post('/loans/loanCalculator', this.form)
        //         .then((response) => {
        //             console.log(response);
        //         }).catch((error) => {
        //             console.log(error);
        //         })
        // }
    }

}

</script>