<template>
    <div>
        <errors v-if="error_message" style="text-align: center;">
            {{ error_message }}
        </errors>
        <form @submit.prevent="saveLoanTerm()">
            <div class="card">
                <div class="card-header">
                    <div class="d-flex align-items-center justify-content-between">
                        <h6 class="m-0 fw-bold text-green">Loan Term</h6>
                        <b-button class="btn btn-sm akkurate-green-btn" type="submit" variant="primary">
                            <span class="akkurate-small"> <i class="fas fa-plus"></i> {{ save }}</span>
                        </b-button>
                    </div>

                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12 col-md-4 mb-2">
                            <b-form-group label-for="interest_method">
                                <template #label>
                                    <label class="akkurate-dark small" for="interest_method">Interest Method <star>
                                        </star>
                                    </label>
                                </template>
                                <multiselect v-model="form.interest_method" :options="interest_method_options"
                                    selectLabel="" deselectLabel="" :show-labels="false" @select="interestMethod"
                                    class="mt-2 field-container" placeholder="Pick a value">
                                </multiselect>
                                <errors v-if="form.errors.interest_method">
                                    {{ form.errors.interest_method[0] }}
                                </errors>
                            </b-form-group>
                        </div>

                        <div class="col-12 col-md-4 mb-2">
                            <b-form-group label-for="amount">
                                <template #label>
                                    <label class="akkurate-dark small" for="amount">Amount <star></star></label>
                                </template>
                                <b-form-input id="amount" name="amount" class="mt-2 field-container fields"
                                    type="number" v-model="form.principal_amount"></b-form-input>
                                <errors v-if="form.errors.principal_amount">
                                    {{ form.errors.principal_amount[0] }}
                                </errors>
                            </b-form-group>
                        </div>

                        <div class="col-12 col-md-4 mb-2" v-show="hybrid">
                            <b-form-group label-for="interest_rate">
                                <template #label>
                                    <label class="akkurate-dark small" for="interest_rate">Interest Rate % <star></star>
                                    </label>
                                </template>
                                <b-form-input id="interest_rate" name="interest_rate"
                                    class="mt-2 field-container fields" type="number" step="any"
                                    v-model="form.interest_rate"></b-form-input>
                                <errors v-if="form.errors.interest_rate">
                                    {{ form.errors.interest_rate[0] }}
                                </errors>

                            </b-form-group>
                        </div>


                        <div class="col-12 col-md-4 mb-2" v-show="hybrid">
                            <b-form-group label-for="interest_rate_frequency">
                                <template #label>
                                    <label class="akkurate-dark small" for="interest_rate_frequency">Interest Rate
                                        Frequency
                                        <star></star>
                                    </label>
                                </template>
                                <multiselect v-model="form.interest_rate_frequency"
                                    :options="interest_rate_frequency_options" selectLabel="" deselectLabel=""
                                    :show-labels="false" class="mt-2 field-container" placeholder="Pick a value">
                                </multiselect>
                                <errors v-if="form.errors.interest_rate_frequency">
                                    {{ form.errors.interest_rate_frequency[0] }}
                                </errors>
                            </b-form-group>
                        </div>

                        <div class="col-12 col-md-4 mb-2">
                            <b-form-group label-for="interest_start_date">
                                <template #label>
                                    <label class="akkurate-dark small" for="interest_start_date">Interest Start Date
                                        <star>
                                        </star>
                                    </label>
                                </template>
                                <b-form-input id="interest_start_date" name="interest_start_date"
                                    class="mt-2 field-container fields" type="date"
                                    v-model="form.interest_start_date"></b-form-input>
                                <errors v-if="form.errors.interest_start_date">
                                    {{ form.errors.interest_start_date[0] }}
                                </errors>
                            </b-form-group>
                        </div>

                        <div class="col-12 col-md-4 mb-2" v-show="straight_line">
                            <b-form-group label-for="expected_interest">
                                <template #label>
                                    <label class="akkurate-dark small" for="expected_interest">Expected Interest <star>
                                        </star>
                                    </label>
                                </template>
                                <b-form-input id="expected_interest" name="expected_interest"
                                    class="mt-2 field-container fields" type="number"
                                    v-model="form.expected_interest"></b-form-input>
                                <errors v-if="form.errors.expected_interest">
                                    {{ form.errors.expected_interest[0] }}
                                </errors>
                            </b-form-group>
                        </div>

                        <div class="col-12 col-md-4 mb-2" v-show="straight_line">
                            <b-form-group label-for="fixed_interest_rate">
                                <template #label>
                                    <label class="akkurate-dark small" for="fixed_interest_rate">Fixed Interest Rate
                                        <star>
                                        </star>
                                    </label>
                                </template>
                                <b-form-input id="fixed_interest_rate" name="fixed_interest_rate"
                                    class="mt-2 field-container fields" type="number"
                                    v-model="form.fixed_interest_rate"></b-form-input>
                                <errors v-if="form.errors.fixed_interest_rate">
                                    {{ form.errors.fixed_interest_rate[0] }}
                                </errors>
                            </b-form-group>
                        </div>

                        <div class="col-12 col-md-4 mb-2" v-show="straight_line">
                            <b-form-group label-for="fixed_interest_rate_frequency">
                                <template #label>
                                    <label class="akkurate-dark small" for="fixed_interest_rate_frequency">Fixed
                                        Interest Rate
                                        Frequency <star></star></label>
                                </template>
                                <multiselect v-model="form.fixed_interest_rate_frequency"
                                    :options="fixed_interest_rate_frequency_options" selectLabel="" deselectLabel=""
                                    :show-labels="false" class="mt-2 field-container" placeholder="Pick a value">
                                </multiselect>
                                <errors v-if="form.errors.fixed_interest_rate_frequency">
                                    {{ form.errors.fixed_interest_rate_frequency[0] }}
                                </errors>
                            </b-form-group>

                        </div>

                        <div class="col-12 col-md-4 mb-2" v-show="straight_line">
                            <b-form-group label-for="fixed_interval">
                                <template #label>
                                    <label class="akkurate-dark small" for="fixed_interval">Fixed Interval <star></star>
                                    </label>
                                </template>
                                <multiselect v-model="form.fixed_interval" :options="fixed_interval_options"
                                    selectLabel="" deselectLabel="" :show-labels="false" class="mt-2 field-container"
                                    placeholder="Pick a value">
                                </multiselect>
                                <errors v-if="form.errors.fixed_interval">
                                    {{ form.errors.fixed_interval[0] }}
                                </errors>
                            </b-form-group>
                        </div>

                        <div class="col-12 col-md-4 mb-2" v-show="straight_line">
                            <b-form-group label-for="fixed_duration">
                                <template #label>
                                    <label class="akkurate-dark small" for="fixed_duration">Fixed Duration<star></star>
                                    </label>
                                </template>
                                <b-form-input id="fixed_duration" name="fixed_duration"
                                    class="mt-2 field-container fields" type="number"
                                    v-model="form.fixed_duration"></b-form-input>
                                <errors v-if="form.errors.fixed_duration">
                                    {{ form.errors.fixed_duration[0] }}
                                </errors>
                            </b-form-group>
                        </div>

                        <div class="col-12 col-md-4 mb-2" v-show="straight_line">
                            <b-form-group label-for="fixed_duration">
                                <template #label>
                                    <label class="akkurate-dark small" for="variable_interest_rate">Variable Interest
                                        Rate<star>
                                        </star></label>
                                </template>
                                <b-form-input id="variable_interest_rate" name="variable_interest_rate"
                                    class="mt-2 field-container fields" type="number"
                                    v-model="form.variable_interest_rate"></b-form-input>
                                <errors v-if="form.errors.variable_interest_rate">
                                    {{ form.errors.variable_interest_rate[0] }}
                                </errors>
                            </b-form-group>
                        </div>

                        <div class="col-12 col-md-4 mb-2" v-show="straight_line">
                            <b-form-group label-for="variable_interest_rate_frequency">
                                <template #label>
                                    <label class="akkurate-dark small" for="variable_interest_rate_frequency">Variable
                                        Interest
                                        Rate Frequency <star></star></label>
                                </template>
                                <multiselect v-model="form.variable_interest_rate_frequency"
                                    :options="variable_interest_rate_frequency_options" selectLabel="" deselectLabel=""
                                    :show-labels="false" class="mt-2 field-container" placeholder="Pick a value">
                                </multiselect>
                                <errors v-if="form.errors.variable_interest_rate_frequency">
                                    {{ form.errors.variable_interest_rate_frequency[0] }}
                                </errors>
                            </b-form-group>
                        </div>

                        <div class="col-12 col-md-4 mb-2" v-show="straight_line">
                            <b-form-group label-for="variable_interval">
                                <template #label>
                                    <label class="akkurate-dark small" for="variable_interval">Variable Interval <star>
                                        </star>
                                    </label>
                                </template>
                                <multiselect v-model="form.variable_interval" :options="variable_interval_options"
                                    selectLabel="" deselectLabel="" :show-labels="false" class="mt-2 field-container"
                                    placeholder="Pick a value">
                                </multiselect>
                                <errors v-if="form.errors.variable_interval">
                                    {{ form.errors.variable_interval[0] }}
                                </errors>
                            </b-form-group>

                        </div>

                        <div class="col-12 col-md-4 mb-2" v-show="straight_line">
                            <b-form-group label-for="variable_duration">
                                <template #label>
                                    <label class="akkurate-dark small" for="variable_duration">Variable Duration <star>
                                        </star>
                                    </label>
                                </template>
                                <b-form-input id="variable_duration" name="variable_duration"
                                    class="mt-2 field-container fields" type="number"
                                    v-model="form.variable_duration"></b-form-input>
                                <errors v-if="form.errors.variable_duration">
                                    {{ form.errors.variable_duration[0] }}
                                </errors>
                            </b-form-group>
                        </div>

                        <div class="col-12 col-md-4 mb-2">
                            <label class="akkurate-dark small">Tenure <star></star></label>
                            <b-form-group>
                                <b-form-input id="tenor" name="tenor" class="mt-2 field-container fields" type="number"
                                    v-model="form.tenor"></b-form-input>
                                <errors v-if="form.errors.tenor">
                                    {{ form.errors.tenor[0] }}
                                </errors>
                            </b-form-group>
                        </div>

                        <div class="col-12 col-md-4 mb-2">
                            <b-form-group label-for="tenor_interval">
                                <template #label>
                                    <label class="akkurate-dark small" for="tenor_interval">Tenure Interval <star>
                                        </star>
                                    </label>
                                </template>
                                <multiselect v-model="form.tenor_interval" :options="tenor_interval_options"
                                    selectLabel="" deselectLabel="" :show-labels="false" class="mt-2 field-container"
                                    placeholder="Pick a value">
                                </multiselect>
                                <errors v-if="form.errors.tenor_interval">
                                    {{ form.errors.tenor_interval[0] }}
                                </errors>
                            </b-form-group>
                        </div>

                        <div class="col-12 col-md-4 mb-2">
                            <b-form-group label-for="repayment_cycle">
                                <template #label>
                                    <label class="akkurate-dark small" for="repayment_cycle">Repayment Cycle <star>
                                        </star>
                                    </label>
                                </template>
                                <multiselect v-model="form.repayment_cycle" :options="repayment_cycle_options"
                                    selectLabel="" deselectLabel="" :show-labels="false" class="mt-2 field-container"
                                    placeholder="Pick a value">
                                </multiselect>
                                <errors v-if="form.errors.repayment_cycle">
                                    {{ form.errors.repayment_cycle[0] }}
                                </errors>
                            </b-form-group>
                        </div>

                        <div class="col-12 col-md-4 mb-2">
                            <b-form-group label-for="maturity_date">
                                <template #label>
                                    <label class="akkurate-dark small" for="maturity_date">Maturity Date</label>
                                </template>
                                <b-form-input id="maturity_date" name="maturity_date" v-model="form.maturity_date"
                                    class="mt-2 field-container fields" type="date" disabled></b-form-input>
                                <errors v-if="form.errors.maturity_date">
                                    {{ form.errors.maturity_date[0] }}
                                </errors>
                            </b-form-group>
                        </div>

                        <div class="col-12 col-md-4 mb-2">
                            <b-form-group label-for="penalty_rate">
                                <template #label>
                                    <label class="akkurate-dark small" for="penalty_rate">Penalty Rate <star></star>
                                    </label>
                                </template>
                                <b-form-input id="penalty_rate" name="penalty_rate" class="mt-2 field-container fields"
                                    type="number" v-model="form.penalty_rate"></b-form-input>
                                <errors v-if="form.errors.penalty_rate">
                                    {{ form.errors.penalty_rate[0] }}
                                </errors>
                            </b-form-group>
                        </div>

                        <div class="col-12 col-md-4 mb-2">
                            <b-form-group label-for="no_of_payment">
                                <template #label>
                                    <label class="akkurate-dark small" for="reason">Reason <star></star></label>
                                </template>
                                <b-form-textarea class="mt-2 fields" id="reason" v-model="form.reason"
                                    placeholder="Enter Reason..."></b-form-textarea>
                                <errors v-if="form.errors.reason">
                                    {{ form.errors.reason[0] }}
                                </errors>
                            </b-form-group>
                        </div>

                        <div class="col-12 col-md-4">
                            <label class="akkurate-dark small" for="purpose">Purpose</label>
                            <b-form-group id="type" label-for="purpose" class="akkurate-dark">
                                <multiselect class="field-container mt-2 mb-3" v-model="form.purpose" name="purpose"
                                    placeholder="Search or select a Purpose" :options="purpose_options">
                                </multiselect>
                            </b-form-group>
                        </div>

                    </div>

                </div>
            </div>
        </form>


    </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Multiselect from "vue-multiselect";
export default {
    components: {
        Multiselect
    },
    props: ['account_info', 'product_info', 'guarantors'],
    data() {
        return {
            ...this.initialState(),
            interest_method_options: ["straight-line", "reducing-balance", "hybrid"],
            interest_rate_frequency_options: ['Daily', 'Weekly', 'Monthly', 'Annually'],
            fixed_interest_rate_frequency_options: ['Daily', 'Weekly', 'Monthly', 'Annually'],
            fixed_interval_options: ['Days', 'Weeks', 'Months', 'Years'],
            variable_interest_rate_frequency_options: ['Daily', 'Weekly', 'Monthly', 'Annually'],
            variable_interval_options: ['Days', 'Weeks', 'Months', 'Years'],
            tenor_interval_options: ['Days', 'Weeks', 'Months', 'Years'],
            repayment_cycle_options: ['Daily', 'Weekly', 'Monthly', 'Annually'],
            purpose_options: ['Education', 'Agriculture', 'Trading', 'Realestate', 'Car', 'Health', 'Marriage', 'Funeral', 'Litigation', 'Other Purpose'],
            straight_line: false,
            reducing_balance: false,
            hybrid: true,
            save: "Forward for approval",
            isSaving: false,
            error_message: "",
        }
    },
    methods: {
        saveLoanTerm() {
            this.isSaving = true;
            this.$Progress.start();
            this.save = "Forwarding..."
            this.form.account_number = this.account_info.account_number;
            this.form.loanproduct_id = this.product_info.id;
            this.form.guarantors = this.guarantors;
            ApiService.post('/loans', this.form)
                .then((response) => {
                    this.isLoadingSaving = false;
                    this.$Progress.finish();
                    this.save = "Forward for approval"
                    toast.fire({
                        icon: "success",
                        title: response.data.message,
                    });
                    this.isSaving = false;
                    let loan_id = response.data?.data?.id;
                    this.$emit('loan_no', loan_id);
                    this.$emit('loanSaved');
                    this.form = this.initialState().form;
                    this.error_message = "";

                }).catch((error) => {
                    this.isSaving = false;
                    this.$Progress.fail();
                    this.save = "Forward for approval"
                    if (error.response.data.errors) {
                        this.form.errors = error.response.data.errors;
                        // console.log(error)
                    }

                    if (error.response.data.message) {
                        this.error_message = error.response.data.message;
                    }
                });
        },
        initialState() {
            return {
                form: {
                    account_number: "",
                    loanproduct_id: "",
                    interest_method: "straight-line", // ['straight_line', 'reducing-balance', 'hybrid']
                    principal_amount: "",
                    interest_rate: "",
                    interest_rate_frequency: "", // ['Daily', 'Weekly', 'Monthly', 'Annually']
                    interest_start_date: "",
                    expected_interest: "",
                    fixed_interest_rate: "",
                    fixed_interest_rate_frequency: "", //['Daily', 'Weekly', 'Monthly', 'Annually']
                    fixed_interval: "", //['Days', 'Weeks', 'Months', 'Years']
                    fixed_duration: "",
                    variable_interest_rate: "",
                    variable_interest_rate_frequency: "", //['Daily', 'Weekly', 'Monthly', 'Annually']
                    variable_interval: "", //['Days', 'Weeks', 'Months', 'Years']
                    variable_duration: "",
                    tenor: "", // integer 
                    tenor_interval: "", //['Days', 'Weeks', 'Months', 'Years']
                    repayment_cycle: "", // ['Daily', 'Weekly', 'Monthly', 'Annually']
                    maturity_date: "",
                    penalty_rate: "", // Penalty fee
                    reason: "",
                    credit_officer: 1, // "User ID"
                    recovery_officer: 2, // "User ID"
                    // insurance: "", // integer
                    // supervisor_approval_limit: "", // integer
                    // branch_approval_limit: "", // integer
                    errors: []
                }

            }
        },
        interestMethod(value) {
            // console.log(value);
            switch (value) {
                case 'straight-line':
                    this.straight_line = false;
                    this.hybrid = true;
                    break;
                case 'reducing-balance':
                    this.straight_line = true;
                    this.hybrid = true;
                    break;
                case 'hybrid':
                    this.straight_line = true;
                    this.hybrid = false;
                    break;
            }
        }
    },
    computed: {
        // Computed property that manipulates the original data
        // interestAmount() {
        //   // Example: Transforming the original value to uppercase
        //   if(this.form.principal_amount && this.form.interest_rate)
        //   {
        //       return  (this.form.principal_amount * this.form.interest_rate * 1) / 100;
        //   }
        //   else
        //   {
        //     return "";
        //   }
        // },
    },
}
</script>