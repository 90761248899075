
import LoginView from './views/authentication/Login.vue';
import ForgetView from './views/authentication/Forget.vue';
import SentView from './views/authentication/Sent.vue';
import TwofaView from './views/authentication/Twofa.vue';
// import RegistrationView from './views/authentication/Registration.vue';
import HomeView from './views/main/Home.vue';

/*user-management-start----------*/
import UserManagement from '@/views/main/pages/user-management/UserManagement.vue'
import UserIndex from '@/views/main/pages/user-management/index/UsersIndex.vue'
import UserCreate from '@/views/main/pages/user-management/create/UsersCreate.vue'
import UsersEdit from '@/views/main/pages/user-management/edit/UsersEdit.vue'
import PermissionsUpdate from '@/views/main/pages/user-management/permissions/PermissionsUpdate.vue'
/*user-management-end----------*/

/*user-profile-start----------*/
import ProfileUpdate from '@/views/main/pages/user-profile/index/Profile'
/*user-profile-end----------*/


/*branch-management-start----------*/
import BranchManagement from '@/views/main/pages/branch/BranchManagement'
import BranchIndex from '@/views/main/pages/branch/index/BranchIndex'
import BranchCreate from '@/views/main/pages/branch/create/CreateBranch'
import BranchEdit from '@/views/main/pages/branch/edit/BranchEdit'
/*branch-management-end----------*/

/*member-management-start----------*/
import MemberManagement from '@/views/main/pages/member/MemberManagement'
import MemberIndex from '@/views/main/pages/member/index/MemberIndex'
import MemberCreate from '@/views/main/pages/member/create/CreateMember'
import MemberEdit from '@/views/main/pages/member/edit/MemberEdit.vue'
import AccountCreate from '@/views/main/pages/member/accounts/create/AccountCreate.vue'
import AccountIndex from '@/views/main/pages/member/accounts/index/AccountIndex.vue'
import ImportFiles from '@/views/main/pages/member/imports/ImportFiles.vue'
import CreateAccountTypes from '@/views/main/pages/member/accounts/account-type/create/CreateAccountType'
import EditAccountType from '@/views/main/pages/member/accounts/account-type/edit/EditAccountType.vue'
import AccountTypeIndex from '@/views/main/pages/member/accounts/account-type/index/AccountTypeIndex.vue'
// import MemberView from '@/views/main/pages/member/view/MemberView.vue'
import MemberViewPage from '@/views/main/pages/member/view/MemberViewPage.vue'
/*member-management-start----------*/

/*chart-of-account-start----------*/
import ChartOfAccounts from '@/views/main/pages/finance/chart-of-accounts/ChartOfAccounts.vue'
import ChartOfAccountsIndex from "@/views/main/pages/finance/chart-of-accounts/index/ChartOfAccountsIndex.vue"
import AccountTypes from "@/views/main/pages/finance/account-types/index/AccountTypes.vue"
/*chart-of-account-end----------*/

/* insitution-management-start--------------- */
import InstitutionManagement from '@/views/main/pages/institution/InstitutionManagement.vue'
import InstitutionCreate from '@/views/main/pages/institution/create/InstitutionCreate.vue'
/* insitution-management-end--------------- */

/* financial-year-start--------------- */
import FinancialYearManagement from '@/views/main/pages/financial-year/FinancialYearManagement.vue'
import FinancialYear from '@/views/main/pages/financial-year/index/FinancialYearIndex.vue'
/* financial-year-end--------------- */

/* Loan ----------------------*/
import LoanManagement from '@/views/main/pages/loan/LoanManagement.vue'
import LoanApplication from '@/views/main/pages/loan/application/index/LoanApplication.vue'
import LoanCalculator from '@/views/main/pages/loan/calculator/index/LoanIndex.vue'
import AllLoanRepayments from '@/views/main/pages/loan/repayment/AllLoanRepayments.vue'
import LoanScheme from '@/views/main/pages/loan/scheme/index/LoanScheme.vue'
import SchemeCreate from '@/views/main/pages/loan/scheme/create/SchemeCreate.vue'
import SchemeEdit from '@/views/main/pages/loan/scheme/edit/SchemeEdit.vue'
import LoanIndex from '@/views/main/pages/loan/index/LoanIndex.vue'

// import CreateLoanApplication from '@/views/main/pages/loan/loan-application/form/application/create/CreateApplication.vue'

/* Loan-calculator-end ----------------------*/

/* Transactions start -------------------------------*/
import TransactionManagement from '@/views/main/pages/transactions/TransactionManagement.vue'
import Deposit from '@/views/main/pages/transactions/deposit-withdrawal/Deposit.vue'
import Withdrawal from '@/views/main/pages/transactions/deposit-withdrawal/Withdrawal.vue'
// import DepositWithdrawal from '@/views/main/pages/transactions/deposit-withdrawal/DepositWithdrawal.vue'
// import DwView from '@/views/main/pages/transactions/deposit-withdrawal/view/DwView.vue';
import BulkDeposits from '@/views/main/pages/transactions/bulk-deposit-withdrawal/BulkDeposits.vue'
import AllDepositWithdrawal from '@/views/main/pages/transactions/all-deposit-withdrawal/AllDepositWithdrawal.vue'
/* Transactions end -------------------------------*/

// Investment Start-----------------------------
import FixedDeposit from '@/views/main/pages/investment/fixed-deposit/FixedDeposit.vue'
// Investment End-----------------------------

// Standing Order Start-----------------------------
import StandingOrder from '@/views/main/pages/standing-order/StandingOrder.vue'
// Standing Order End-----------------------------

/* Voucher Start -------------------------------------*/
import VoucherManagement from '@/views/main/pages/voucher/VoucherManagement.vue'
import VoucherIndex from '@/views/main/pages/voucher/index/VoucherIndex.vue'
import VoucherCreate from '@/views/main/pages/voucher/create/VoucherCreate.vue'
import ApproveVoucher from '@/views/main/pages/voucher/forms/Approve.vue'
/* Voucher End -------------------------------------*/

/*Journal Start------------------------------------*/
import JournalManagement from '@/views/main/pages/journal/JournalManagement.vue'
import JournalIndex from '@/views/main/pages/journal/index/JournalIndex.vue'
import JournalCreate from '@/views/main/pages/journal/create/JournalCreate.vue'
import ApproveJournal from '@/views/main/pages/journal/forms/Approve.vue'
import JournalTransfer from './views/main/pages/journal/transfer/JournalTransfer.vue';
import JournalTransferCreate from './views/main/pages/journal/transfer/create/JournalTransferCreate.vue';

/* Voucher End -------------------------------------*/


/* Reports----------------------------------- */
import ReportsManagement from '@/views/main/pages/reports/ReportsManagement.vue'
import Reports from '@/views/main//pages/reports/Reports.vue'
// Controls -----------------------------------------------------
import DailyProductsCustomers from '@/views/main/pages/reports/controls/DailyProductsCustomers.vue'
import DailyProductsCustomersbyRo from '@/views/main/pages/reports/controls/DailyProductsCustomersbyRo.vue'
import CustomerControlBalance from '@/views/main/pages/reports/controls/CustomerControlBalance.vue'
import MemberStatisticReport from '@/views/main/pages/reports/controls/MemberStatisticReport.vue'
import ProductBalanceTerm from '@/views/main/pages/reports/controls/ProductBalanceTerm.vue'
import CustomerListing from '@/views/main/pages/reports/controls/CustomerListing.vue'
import Performance from '@/views/main/pages/reports/controls/Performance.vue'
// Loan -------------------------------------------------
import Aging from '@/views/main/pages/reports/loan/Aging.vue'
import LoanRepay from '@/views/main/pages/reports/loan/LoanRepay.vue';
import Delinquency from '@/views/main/pages/reports/loan/Delinquency.vue';
import OpenClose from '@/views/main/pages/reports/loan/OpenClose.vue';
import LoanDisbursed from '@/views/main/pages/reports/loan/LoanDisbursed.vue';
import BulkRepaymentUpload from './views/main/pages/loan/bulk/BulkRepayment.vue';
import LoanImports from './views/main/pages/loan/imports/ImportFiles.vue';

// Statistics
import AccountStatistics from '@/views/main/pages/reports/statistics/AccountStatistics.vue';
// import ReceiptList from '@/views/main/pages/reports/statistics/ReceiptList.vue'
// import PaymentList from '@/views/main/pages/reports/statistics/PaymentList.vue'

//Financials
import DetailedPayment from './views/main/pages/reports/financials/DetailedPayment.vue';
import DetailedReceipt from './views/main/pages/reports/financials/DetailedReceipt.vue';
import TrialBalYearToDate from '@/views/main/pages/reports/financials/trial_balance/TrialBalYearToDate.vue'
import ChartOfAccountReport from '@/views/main/pages/reports/financials/ChartOfAccountReport.vue'
import SingleChartOfAccountReport from '@/views/main/pages/reports/financials/SingleChartOfAccountReport.vue'
import COAHeadsReport from '@/views/main/pages/reports/financials/COAHeadsReport.vue'
import BalanceSheet from './views/main/pages/reports/financials/BalanceSheet.vue';
import BalanceSheetSummary from './views/main/pages/reports/financials/BalanceSheetSummary.vue';
import IncomeStatement from './views/main/pages/reports/financials/IncomeStatement.vue';
import ReceiptsPayments from './views/main/pages/reports/financials/ReceiptsPayments.vue';
import IncomeStatementSummary from './views/main/pages/reports/financials/IncomeStatementSummary.vue';
import DetailedCustomerReceiptsReport from './views/main/pages/reports/financials/DetailedCustomerReceiptsReport.vue';
import DetailedCustomerPaymentsReport from './views/main/pages/reports/financials/DetailedCustomerPaymentsReport.vue';
import BulkWithdrawals from './views/main/pages/transactions/bulk-deposit-withdrawal/BulkWithdrawals.vue';
import JournalReport from './views/main/pages/reports/financials/Journal';
import VoucherReport from './views/main/pages/reports/financials/Voucher';
import UserCoaTracker from './views/main/pages/reports/controls/UserCoaTransactionTracker.vue';
// import DetailedReceiptAndPayment from './views/main/pages/reports/financials/DetailedReceiptAndPayment.vue';

//Mobile
import MobileDeposit from './views/main/pages/transactions/mobile/Deposit.vue'
import MobileWithdrawal from './views/main/pages/transactions/mobile/Withdrawal.vue'
import MobileProfile from '@/views/main/pages/user-profile/index/MobileProfile'
import History from './views/main/pages/transactions/mobile/History';
import MobileMember from './views/main/pages/member/mobile/MobileMember';
import ExpectedLoanRepayments from './views/main/pages/reports/loan/ExpectedLoanRepayments.vue';
import COAImports from './views/main/pages/finance/imports/ImportFiles.vue';
import RegionsImport from './views/main/pages/branch/regions/RegionsImport.vue';
import AccountImports from './views/main/pages/member/accounts/imports/AccountImports.vue';


export default [
  {
    path: '/',
    component: HomeView
  },
  {
    path: '/login',
    component: LoginView,
    meta: { title: 'Login' }
  },
  // {
  //   path: '/register',
  //   component: RegistrationView
  // },
  {
    path: '/forget',
    component: ForgetView
  },
  {
    path: '/sent',
    component: SentView
  },
  {
    path: '/2fa',
    component: TwofaView
  },
  {
    path: "/users",
    component: UserManagement,
    children: [
      {
        path: "/users",
        name: "users.index",
        component: UserIndex
      },
      {
        path: "/users/create",
        name: "users.create",
        component: UserCreate
      },
      {
        path: "/users/:id/edit",
        name: "users.edit",
        component: UsersEdit
      },
      {
        path: "/users/permissions",
        name: "users.permissions",
        component: PermissionsUpdate
      },
      {
        path: "/users/profile",
        name: "users.profile",
        component: ProfileUpdate
      },
      // {
      //   path: "/branch",
      //   name: "branch.index",
      //   component: BranchIndex
      // },
      // {
      //   path: "/branch/create",
      //   name: "branch.create",
      //   component: BranchCreate
      // },
      // {
      //   path: "/settings/member",
      //   name: "setting.members",
      //   component: MemberIndex
      // },
      // {
      //   path: "/member/create",
      //   name: "members.create",
      //   component: MemberCreate
      // },

    ]
  },
  {
    path: "/branch",
    component: BranchManagement,
    children: [
      {
        path: "/branch",
        name: "branch.index",
        component: BranchIndex
      },
      {
        path: "/branch/create",
        name: "branch.create",
        component: BranchCreate
      },
      {
        path: "/branch/:id/edit",
        name: "branch.edit",
        component: BranchEdit
      },
      {
        path: "/region/imports",
        name: "region.imports",
        component: RegionsImport
      },
    ]
  },
  {
    path: "/members",
    component: MemberManagement,
    children: [
      {
        path: "/members",
        name: "member.index",
        component: MemberIndex
      },
      {
        path: "/members/create",
        name: "members.create",
        component: MemberCreate
      },
      {
        path: "/members/:id/edit",
        name: "members.edit",
        component: MemberEdit
      },
      {
        path: "/members/account/create",
        name: "account.create",
        component: AccountCreate
      },
      {
        path: "/members/account",
        name: "account.index",
        component: AccountIndex
      },
      {
        path: "/members/import",
        name: "member.import",
        component: ImportFiles
      },
      {
        path: "/members/account-type",
        name: "member.account-type",
        component: AccountTypeIndex
      },
      {
        path: "/members/account-type/create",
        name: "member.account-type.create",
        component: CreateAccountTypes
      },
      {
        path: "/members/view/:id",
        name: "member.view",
        component: MemberViewPage
      },
      {
        path: "/members/account-type/:id/edit",
        name: "memeber.edit",
        component: EditAccountType
      },
      {
        path: "/mobile/member",
        name: "mobile.member",
        component: MobileMember
      },
      {
        path: "/members/account/imports",
        name: "members.account.imports",
        component: AccountImports
      },
    ]
  },


  {
    path: "/chart-of-accounts",
    component: ChartOfAccounts,
    children: [
      {
        path: "/chart-of-accounts",
        name: "chart_of_accounts.index",
        component: ChartOfAccountsIndex
      },
      {
        path: "/chart-of-accounts/account-types",
        name: "account_types.index",
        component: AccountTypes
      },
      {
        path: "/chart-of-accounts/import",
        name: "coa.import",
        component: COAImports
      },
      // {
      //   path: "/chart-of-accounts/sub/import",
      //   name: "coasub.import",
      //   component: COASubImports
      // },
      // {
      //   path: "/users/create",
      //   name: "users.create",
      //   component:  UserCreate
      // }
    ]
  },
  {
    path: "/institution",
    component: InstitutionManagement,
    children: [
      {
        path: "/institution",
        name: "institution.edit",
        component: InstitutionCreate
      },
    ]
  },
  {
    path: "/financial-year",
    component: FinancialYearManagement,
    children: [
      {
        path: "/financial-year",
        name: "financial-year.index",
        component: FinancialYear
      },
    ]
  },

  {
    path: "/loan",
    component: LoanManagement,
    children: [
      {
        path: "/loans/application",
        name: "loans.application",
        component: LoanApplication
      },
      {
        path: "/loans/calculator",
        name: "loans.calculator",
        component: LoanCalculator
      },
      {
        path: "/loans/repayments",
        name: "loans.repayments",
        component: AllLoanRepayments
      },

      {
        path: "/loans/scheme",
        name: "loans.scheme",
        component: LoanScheme
      },

      {
        path: "/loans/scheme/create",
        name: "loans.scheme.create",
        component: SchemeCreate
      },
      {
        path: "/loans/scheme/:id/edit",
        name: "loans.scheme.edit",
        component: SchemeEdit
      },
      {
        path: "/loans",
        name: "loan.index",
        component: LoanIndex,

      },
      {
        path: "/loans/import",
        name: "loan.import",
        component: LoanImports,

      },
      // {
      //   path: "/loans/bulk/repayments/upload",
      //   name: "loan.bulk.repayments.upload",
      //   component: BulkRepaymentUpload,

      // },

      // {
      //   path: "/loan-application/create",
      //   name: "loan.application.create",
      //   component: CreateLoanApplication
      // },
    ]
  },
  {
    path: "/voucher",
    component: VoucherManagement,
    children: [
      {
        path: "/voucher",
        name: "voucher",
        component: VoucherIndex,
      },
      {
        path: "/voucher/create",
        name: "voucher.create",
        component: VoucherCreate,
      },
      {
        path: "/voucher/approve/:id",
        name: "voucher.approve",
        component: ApproveVoucher,
      }
    ]
  },
  {
    path: "/journal",
    component: JournalManagement,
    children: [
      {
        path: "/journal",
        name: "journal",
        component: JournalIndex,
      },
      {
        path: "/journal/create",
        name: "journal.create",
        component: JournalCreate,
      },
      {
        path: "/journal/approve/:id",
        name: "journal.approve",
        component: ApproveJournal,
      },
      {
        path: "/journal/transfer",
        name: "journal.transfer",
        component: JournalTransfer,
      },
      {
        path: "/journal/transfer/create",
        name: "journal.transfer.create",
        component: JournalTransferCreate,
      },
    ]
  },
  {
    path: "/transactions",
    component: TransactionManagement,
    children: [
      {
        path: "/transactions/deposit",
        name: "transactions.deposit",
        component: Deposit
      },
      {
        path: "/transactions/withdrawal",
        name: "transactions.withdrawal",
        component: Withdrawal
      },
      // {
      //   path: "/transactions/deposit-withdrawal",
      //   name: "transactions.deposit-withdraw",
      //   component: DepositWithdrawal
      // },
      {
        path: "/transactions/deposits/bulk",
        name: "transactions.bulk-deposit-withdraw",
        component: BulkDeposits
      },
      {
        path: "/transactions/withdrawals/bulk",
        name: "transactions.withdrawals.bulk",
        component: BulkWithdrawals
      },
      {
        path: "/transactions/all-deposit-withdrawal",
        name: "transactions.all-deposit-withdraw",
        component: AllDepositWithdrawal
      },
      
      {
        path: "/mobile/deposit",
        name: "mobile.deposit",
        component: MobileDeposit
      },
      {
        path: "/mobile/withdrawal",
        name: "mobile.withdrawal",
        component: MobileWithdrawal
      },
      {
        path: "/mobile/history",
        name: "mobile.history",
        component: History
      },
      {
        path: "/mobile/profile",
        name: "mobile.profile",
        component: MobileProfile
      },
    ]
  },
  {
    path: "/investment/fixed-deposit",
    name: "investment.fixed-deposit",
    component: FixedDeposit
  },
  {
    path: "/standing-order",
    name: "standing-order",
    component: StandingOrder
  },
  {
    path: "/reports",
    name: "reports",
    component: ReportsManagement,
    children: [
      {
        path: "/reports",
        name: "reports.index",
        component: Reports
      },
      {
        path: "/reports/trial-balance",
        name: "reports.trial_balance",
        component: TrialBalYearToDate
      },
      {
        path: "/reports/coa",
        name: "reports.coa",
        component: ChartOfAccountReport
      },
      
      {
        path: "/reports/financials/single-coa",
        name: "reports.single_coa",
        component: SingleChartOfAccountReport
      },
      {
        path: "/reports/financials/coa/heads/summary",
        name: "reports.coa_heads.summary",
        component: COAHeadsReport
      },
      {
        path: "/reports/financials/receipts-payments",
        name: "reports.finance.receipts_payments",
        component: ReceiptsPayments
      },
      {
        path: "/reports/financials/receipts/members",
        name: "reports.finance.receipts.customers",
        component: DetailedCustomerReceiptsReport
      },
      {
        path: "/reports/financials/payments/members",
        name: "reports.finance.payments.customers",
        component: DetailedCustomerPaymentsReport
      },
      {
        path: "/reports/financials/balance-sheet",
        name: "reports.balance_sheet",
        component: BalanceSheet
      },
      {
        path: "/reports/financials/balance-sheet/summary",
        name: "reports.balance_sheet.summary",
        component: BalanceSheetSummary
      },
      {
        path: "/reports/financials/income-statement",
        name: "reports.income_statement",
        component: IncomeStatement
      },
      {
        path: "/reports/financials/income-statement/summary",
        name: "reports.income_statement.summary",
        component: IncomeStatementSummary
      },
      {
        path: "/reports/products/customers",
        name: "reports.daily_products_customers",
        component: DailyProductsCustomers
      },
      {
        path: "/reports/products/customers/ro",
        name: "reports.daily_products_customers_ro",
        component: DailyProductsCustomersbyRo
      },
      {
        path: "/reports/customer/control/balance",
        name: "reports.customer_control_balance",
        component: CustomerControlBalance
      },
      {
        path: "/reports/member/statistic",
        name: "reports.member_statistic",
        component: MemberStatisticReport
      },
      {
        path: "/reports/product/balance/term",
        name: "reports.product_balance_term",
        component: ProductBalanceTerm
      },
      {
        path: "/reports/customer/listing",
        name: "reports.customer_listing",
        component: CustomerListing
      },
      {
        path: "/reports/performance/analysis",
        name: "reports.performance_analysis",
        component: Performance
      },
      {
        path: "/reports/aging",
        name: "reports.aging",
        component: Aging
      },
      {
        path: "/reports/loan/repayment",
        name: "reports.loan_repayment",
        component: LoanRepay
      },
      {
        path: "/reports/loans/expected-repayments",
        name: "reports.expected_loan_repayment",
        component: ExpectedLoanRepayments
      },
      {
        path: "/reports/loan/disbursed",
        name: "reports.loan_disbursed",
        component: LoanDisbursed
      },
      {
        path: "/reports/delinquency",
        name: "reports.delinquency",
        component: Delinquency
      },
      {
        path: "/reports/open/close",
        name: "reports.open_close",
        component: OpenClose
      },
      {
        path: "/reports/account/statistics",
        name: "reports.account_statistics",
        component: AccountStatistics
      },
      // {
      //   path: "/reports/receipt/listing",
      //   name: "reports.receipt_listing",
      //   component: ReceiptList
      // },
      // {
      //   path: "/reports/payment/listing",
      //   name: "reports.payment_listing",
      //   component: PaymentList
      // },
      {
        path: "/reports/detailed/receipt",
        name: "reports.detailed_receipt",
        component: DetailedReceipt
      },
      {
        path: "/reports/detailed/payment",
        name: "reports.detailed_payment",
        component: DetailedPayment
      },
      {
        path: "/reports/voucher",
        name: "reports.voucher",
        component: VoucherReport
      },
      {
        path: "/reports/journal",
        name: "reports.journal",
        component: JournalReport
      },
      {
        path: "/reports/controls/user-coa-tracker",
        name: "reports.user_coa_tracker",
        component: UserCoaTracker
      },
      // {
      //   path: "/reports/detailed/receiptandpayment",
      //   name: "reports.detailed_receipt_and_payment",
      //   component: DetailedReceiptAndPayment
      // },
    ]
  },


]