<template>
    <button type="submit" :disabled="isSaving" class="btn akkurate-green-btn text-white btn-size">
        <font-awesome-icon class="me-2" :icon="['fas', 'save']" /> Save
    </button>
</template>

<script>
export default {
    props:["isSaving"]
}
</script>