<template>
    <form @submit.prevent="basicSearch">
        <div class="row">
            <div class="col">
                <label class="akkurate-dark small">Standing Order Code</label>
                <b-form-group id="code" label-for="code">
                    <b-form-input class="mb-3 mt-2 field-container fields" type="number" placeholder="Enter Code"
                        v-model="filter.code" required></b-form-input>
                </b-form-group>
            </div>
            <div class="d-grid gap-2 col-md-2 mt-auto d-flex align-content-center justify-content-center mb-3">
                <b-button type="submit" class="akkurate-green-btn akkurate-auth-size w-100"><i
                        class="fas fa-search me-2"></i>Search</b-button>
                <b-button size="sm" @click="show_more" title="show more filters" class="ms-2">
                    <i class="fas fa-bars akkurate-small"></i>
                </b-button>
            </div>
            <!-- <div class="mt-auto col-md-3 col-3">
                <b-button type="submit" :disabled="action.disabled_search"  class="akkurate-green-btn w-100 mb-3"><font-awesome-icon class="small" :icon="['fas', 'search']" /> {{ action.search_btn }}</b-button>
            </div> -->
        </div>
        <div class="row" v-if="more_details">
            <div class="col">
                <label class="akkurate-dark small" for="end_date">Branch</label>
                <b-form-group label-for="branch" class="mb-3">
                    <multiselect class="field-container mt-2" v-model="filter.branch" :options="branch_options">
                    </multiselect>
                </b-form-group>
            </div>
            <div class="col">
                <label class="akkurate-dark small" for="end_date">Status</label>
                <b-form-group label-for="status" class="mb-3">
                    <multiselect class="field-container mt-2" v-model="filter.status" :options="status_options">
                    </multiselect>
                </b-form-group>
            </div>
            <div class="col">
                <label class="akkurate-dark small" for="end_date">Start Date</label>
                <b-form-group label-for="from_date" class="mb-3">
                    <b-form-input id="from_date" v-model="filter.from_date" name="from_date"
                        class="mt-2 field-container fields" type="date" required></b-form-input>
                </b-form-group>
            </div>
            <div class="col">
                <label class="akkurate-dark small" for="end_date">End Date</label>
                <b-form-group label-for="to_date" class="mb-3">
                    <b-form-input id="to_date" v-model="filter.to_date" name="to_date"
                        class="mt-2 field-container fields" type="date" required></b-form-input>
                </b-form-group>
            </div>
        </div>
    </form>

</template>
<script>
import Multiselect from 'vue-multiselect'
import AdvanceSearch from './AdvanceSearch.vue';
export default {
    props: ['filter', 'basicSearch', 'action'],
    data(){
        return {
            more_details: false,
            branch_options: [],
            status_options: [],
        }

    }, 
    components: {
        Multiselect,
        AdvanceSearch,
    },
    methods:{
        show_more() {
            this.more_details = !this.more_details
        },
    }
}
</script>