<template>
    <div class="row">
        <div class="col-12 text-center">
            <h5 class="fst-italic fw-bold mb-4 akkurate-green">Please note: Investment Starts running after receiving final approval</h5>
        </div>

        <div class="col-12 col-md-3">
                <b-form-group
                id="mobile_number"
                label-for="mobile_number">
                <img class="img-fluid img-visibility  w-100 h-100" src="@/assets/img/user-avater.jpg">
                </b-form-group>
        </div>
        <div class="col-md-9">
            <div class="row">
                <div class="col-12 col-md-4">
                    <label class="akkurate-dark" for="customer_name">Customer Name</label>
                    <b-form-group
                    id="customer_name"
                    label-for="customer_name">
                        <b-form-input
                            class="mb-3 mt-2 field-container fields"
                            type="text"
                            placeholder="Enter Customer Name"
                            v-model="form.customer_name"
                            required
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-4">
                    <label class="akkurate-dark" for="customer_code">Customer Code</label>
                        <b-form-group
                        id="customer_code"
                        label-for="customer_code">
                            <b-form-input
                            class="mb-3 mt-2 field-container fields"
                            type="text"
                            placeholder="Enter Customer Code"
                            v-model="form.code"
                            required
                            ></b-form-input>
                        </b-form-group>
                </div>
                <div class="col-12 col-md-4">
                    <label class="akkurate-dark" for="mobile_number">Mobile Number</label>
                        <b-form-group
                        id="mobile_number"
                        label-for="mobile_number">
                            <b-form-input
                                class="mb-3 mt-2 field-container fields"
                                type="text"
                                placeholder="Enter Mobile Number"
                                v-model="getMobile"
                                required
                            ></b-form-input>
                        </b-form-group>
                </div>
                <div class="col-12 col-md-4">
                    <label class="akkurate-dark" for="principal-amt">Scheme Name</label>
                    <b-form-group
                    id="loan-re-date"
                    label-for="loan-re-date">
                        <multiselect class="mt-2 field-container" 
                            v-model="form.scheme" 
                            :options="scheme_options"
                            label="name">
                        </multiselect>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-4">
                    <label class="akkurate-dark" for="principal_amt">Principal Amount</label>
                    <b-form-group
                    id="principal_amt"
                    label-for="principal_amt">
                        <b-form-input
                        class="mb-3 mt-2 field-container fields"
                        type="text"
                        placeholder="Enter Principal Amount"
                        v-model="form.principal_amount"
                        required
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-4">
                    <label class="akkurate-dark" for="interest_rate">Interest Rate</label>
                    <b-form-group
                    id="interest_rate"
                    label-for="interest_rate">
                        <b-form-input
                            class="mb-3 mt-2 field-container fields"
                            type="text"
                            v-model="form.interest_rate"
                            placeholder="Enter Interest Rate"
                            required
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-4">
                    <label class="akkurate-dark" for="principal-amt">Start Date</label>
                    <b-form-group
                    id="loan_code"
                    label-for="loan_code">
                        <b-form-input
                        class="mb-3 mt-2 field-container fields"
                        type="date"
                        v-model="form.start_date"
                        placeholder="Enter Date"
                        required
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-4">
                    <label class="akkurate-dark" for="maturity_date">Maturity Date</label>
                    <b-form-group
                    id="maturity_date"
                    label-for="maturity_date">
                        <b-form-input
                            class="mb-3 mt-2 field-container fields"
                            type="date"
                            placeholder="Enter Code"
                            v-model="form.maturity_date"
                            disabled
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-4">
                    <label class="akkurate-dark" for="tenor">Tenure (Days)</label>
                    <b-form-group
                    id="tenor"
                    label-for="tenor">
                        <b-form-input
                        class="mb-3 mt-2 field-container fields"
                        type="number"
                        v-model="form.tenor"
                        placeholder="Enter Code"
                        disabled
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-4">
                    <label class="akkurate-dark" for="interest_expectedinterest_expected">Interest Expected</label>
                    <b-form-group
                    id="interest_expected"
                    label-for="interest_expected">
                        <b-form-input
                        class="mb-3 mt-2 field-container fields"
                        type="number"
                        placeholder="Enter Interest Expected"
                        v-model="form.interest_expected"
                        disabled
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-4">
                    <label class="akkurate-dark" for="total">Total Value</label>
                    <b-form-group
                    id="total"
                    label-for="total">
                        <b-form-input
                        class="mb-3 mt-2 field-container fields"
                        type="number"
                        placeholder="Enter Total Value"
                        v-model="getTotal"
                        disabled
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-4">
                    <label class="akkurate-dark" for="payment">Payment Mode</label>
                    <b-form-group
                    id="payment"
                    label-for="payment">
                    <multiselect 
                    class="mt-2 field-container" 
                    v-model="form.payment"
                    @select="getPaymentMode" 
                    :options="payment_options">
                    </multiselect>
                    </b-form-group>
                </div>

                <div class="col-12 col-md-4" v-show="transfer_or_cheque">
                    <label class="akkurate-dark" for="product">Product</label>
                    <b-form-group
                    id="product"
                    label-for="product">
                    <multiselect 
                    class="mt-2 field-container" 
                    v-model="form.product"
                    :options="product_options"
                    label="name">
                    </multiselect>
                    </b-form-group>
                </div>

                <div class="col-12 col-md-4" v-show="transfer">
                    <label class="akkurate-dark" for="balance">Balance</label>
                    <b-form-group
                    id="balance"
                    label-for="balance">
                    <b-form-input
                        class="mb-3 mt-2 field-container fields"
                        type="number"
                        placeholder="Enter Balance"
                        v-model="form.balance"
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-4" v-show="cheque">
                    <label class="akkurate-dark" for="cheque">Cheque No</label>
                    <b-form-group
                    id="cheque"
                    label-for="cheque">
                    <b-form-input
                        class="mb-3 mt-2 field-container fields"
                        type="number"
                        placeholder="Enter Cheque Number"
                        v-model="form.cheque"
                        ></b-form-input>
                    </b-form-group>
                </div>

                <div class="col-12 col-md-4" v-show="direct_momo">
                    <label class="akkurate-dark" for="mobile_number">Mobile Number</label>
                    <b-form-group
                    id="mobile_number"
                    label-for="mobile_number">
                    <b-form-input
                        class="mb-3 mt-2 field-container fields"
                        type="number"
                        placeholder="Enter Mobile Number"
                        v-model="form.mobile_number"
                        ></b-form-input>
                    </b-form-group>
                </div>

                <div class="col-12 col-md-4" v-show="direct_momo">
                    <label class="akkurate-dark" for="transaction_id">Transaction ID</label>
                    <b-form-group
                    id="transaction_id"
                    label-for="transaction_id">
                    <b-form-input
                        class="mb-3 mt-2 field-container fields"
                        type="number"
                        placeholder="Enter Transaction ID"
                        v-model="form.transaction_id"
                        ></b-form-input>
                    </b-form-group>
                </div>


            </div>

        </div>
        
        <div class="mt-auto col-12 text-end">
            <b-button href="" class="akkurate-green-btn mb-3 btn-size">Add New</b-button>
        </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import ApiService from '@/core/services/api.service';
export default {
    components: {
        Multiselect
    },
    props: ['form'],
    data() {
        return {
            search: "Account Number",
            scheme_options: ["Account Number", "Code", "ID"],
            payment_options: ["Cash", "Transfer", "Cheque", "Direct momo debit"],
            product_options:[],
            transfer_or_cheque : false,
            transfer: false,
            cheque:false,
            direct_momo: false,
        }
    },
    created() {
        this.getFDScheme();
        this.getProduct();
    },
    computed: {
        getMobile() {
            return this.form?.customer?.mobile != null ? this.form?.customer?.mobile : "";
        },
        getTotal() {
            return this.form?.principal_amount + this.form?.interest_expected;
        }
    },
    methods: {
        async getFDScheme() {
            await ApiService.get('/investments/fixed-term-deposit-schemes')
                .then((response) => {
                    this.scheme_options = response.data.data;
                }).catch((error) => {
                    console.log(error);
                })
        },
        async getProduct(){
            await ApiService.get('/investments/fixed-term-deposits/dropdown')
                .then((response) => {
                    this.product_options = response.data.coa_subheads;
                    // console.log(response.data.coa_subheads);
                }).catch((error) => {
                    console.log(error);
                })
        },
        getPaymentMode(mode) {
            switch (mode) {
                case "Cash":
                    this.transfer_or_cheque = false;
                    this.transfer = false;
                    this.cheque = false;
                    this.direct_momo = false
                    break;
                case "Transfer":
                    this.transfer_or_cheque = true;
                    this.transfer = true;
                    this.cheque = false;
                    this.direct_momo = false;
                    break;
                case "Cheque":
                    this.transfer_or_cheque = true;
                    this.transfer = false;
                    this.cheque = true;
                    this.direct_momo = false;
                    break;
                case "Direct momo debit":
                    this.transfer_or_cheque = false;
                    this.transfer = false;
                    this.cheque = false;
                    this.direct_momo = true;
                    break;
            }
        }

    }
}
</script>