<template>
    <div>
        <b-button title="View" class="btn btn-sm akkurate-warning text-white" v-b-modal="String(data.id)">
            <span class="akkurate-small"> <font-awesome-icon :icon="['fas', 'eye']" /></span> 
        </b-button>
       <b-modal
        :id="String(data.id)"
        ref="modal"
        size="xl"
        @show="ModalOpened">
        <template #modal-title>
                <p class="m-0 fw-bold text-green">Voucher View</p>
        </template>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <beat-loader-component
                        v-if="isLoading"
                        :isLoading="isLoading"
                    ></beat-loader-component>
                </div>
                <div class="col-12 col-md-4">
                    <label class="akkurate-dark" for="search_term">Beneficiary Name</label>
                        <b-form-group
                        id="search_term"
                        label-for="search_term">
                        <b-form-input
                        class="mb-3 mt-2 field-container fields"
                        type="text"
                        v-model="data.beneficiary_name"
                        read-only
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-4">
                    <label class="akkurate-dark" for="beneficiary_phone">Beneficiary Phone</label>
                        <b-form-group
                        id="beneficiary_phone"
                        label-for="beneficiary_phone">
                        <b-form-input
                        class="mb-3 mt-2 field-container fields"
                        type="text"
                        v-model="data.beneficiary_phone"
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-4">
                    <label class="akkurate-dark" for="cheque_number">Cheque Number</label>
                        <b-form-group
                        id="cheque_number"
                        label-for="cheque_number">
                        <b-form-input
                        class="mb-3 mt-2 field-container fields"
                        type="text"
                        v-model="data.cheque_number"
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-6">
                    <label class="akkurate-dark" for="recorded_date">Recorded Date</label>
                        <b-form-group
                        id="recorded_date"
                        label-for="recorded_date">
                        <b-form-input
                        class="mb-3 mt-2 field-container fields"
                        type="text"
                        v-model="data.recorded_date"
                        disabled
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-6">
                    <label class="akkurate-dark" for="recorded_date">Journal Code</label>
                        <b-form-group
                        id="recorded_date"
                        label-for="recorded_date">
                        <b-form-input
                        class="mb-3 mt-2 field-container fields"
                        type="text"
                        v-model="data.code"
                        disabled
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-6">
                    <label class="akkurate-dark" for="recorded_date">Amount Requested</label>
                        <b-form-group
                        id="recorded_date"
                        label-for="recorded_date">
                        <b-form-input
                        class="mb-3 mt-2 field-container fields"
                        type="text"
                        v-model="data.amount_requested"
                        disabled
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-6">
                    <label class="akkurate-dark" for="recorded_date">Amount Approved</label>
                        <b-form-group
                        id="recorded_date"
                        label-for="recorded_date">
                        <b-form-input
                        class="mb-3 mt-2 field-container fields"
                        type="text"
                        v-model="data.amount_approved"
                        disabled
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-6">
                    <label class="akkurate-dark" for="recorded_date">Status</label>
                        <b-form-group
                        id="recorded_date"
                        label-for="recorded_date">
                        <b-form-input
                        class="mb-3 mt-2 field-container fields"
                        type="text"
                        v-model="data.status"
                        disabled
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-6">
                    <label class="akkurate-dark" for="recorded_date">Narration</label>
                        <b-form-group
                        id="recorded_date"
                        label-for="recorded_date">
                        <b-form-input
                        class="mb-3 mt-2 field-container fields"
                        type="text"
                        v-model="data.narration"
                        disabled
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-4">
                    <label class="akkurate-dark" for="branch">Branch</label>
                        <b-form-group
                        id="branch"
                        label-for="branch">
                        <b-form-input
                        class="mb-3 mt-2 field-container fields"
                        type="text"
                        v-model="getBranch"
                        disabled
                        ></b-form-input>
                    </b-form-group>
                </div>
                   
        </div>
        <div class="row">
            <div>
                <b-table :fields="fields" striped hover :items="items">
                </b-table>
            </div>
        </div>
        </div>
        
          <template #modal-footer="{ ok }">
               <div class="w-100">
               <!-- begin loader -->
               <!-- <beat-loader-component 
               class="float-left"
               v-if="isLoading"
               :isLoading="isLoading"
               ></beat-loader-component> -->
               <!-- end loader -->
               <!-- Emulate built in modal footer ok and cancel button actions -->
               <b-button @click="$bvModal.hide(String(data.id))" type="button" class="akkurate-grey-btn btn-size float-right border-0"><font-awesome-icon class="me-2" :icon="['fas', 'close']" />Close</b-button>
               <!-- <b-button @click="ok()" type="submit" class="akkurate-green-btn btn-size me-2 float-right border-0">Add</b-button>      -->
               </div>
           </template>
           
       </b-modal>
    </div>
</template>
<script>
import ApiService from "@/core/services/api.service";
import BeatLoaderComponent from "@/views/components/Loader/BeatLoaderComponent.vue";
export default {
    name: "VoucherView",
    props: ['data'],
    components: {
        BeatLoaderComponent,
    },
    data() {
        return {
            form: {
                branch: []
            },
            isLoading: false,
            fields: [
                { key: 'id', label: '#' },
                { key: 'amount_requested', label: 'Amt Req' },
                { key: 'amount_approved', label: 'Amt App' },
                { key: 'dr_amount', label: 'Debit Amt' },
                { key: 'cr_amount', label: 'Credit Amt' },
                { key: 'dr_amount_approved', label: 'Debit Amt App' },
                { key: 'cr_amount_approved', label: 'Credit Amt App' },
                { key: 'coa_sub_head', label: 'COA Subhead' },
                
            ],
            items: [],
        }
    },
    computed:{
        getBranch(){
            if(this.data.branch){
                return this.data.branch.name;
            }
            return "";
           
        }
    },
    methods: {
        async getJournalView() {
            this.isLoading = true;
            // console.log("Start")
            // this.items = [];
            // this.details = {};
            //  this.search = "processing";
            //  this.isSearching = true;
            // console.log(this.data);
            await ApiService.get(`/vouchers/view/${this.data.id}`).then((response) => {
                const account = [];
                this.form = response.data.data;
                this.form.vouch_journal_coaaccounts.map((index) => {
                    account.push({
                        'id':index.id,
                        'amount_approved':index.amount_approved,
                        'amount_requested':index.amount_requested,
                        'dr_amount':index.dr_amount,
                        'cr_amount':index.cr_amount,
                        'dr_amount_approved':index.dr_amount_approved,
                        'cr_amount_approved':index.cr_amount_approved,
                        'coa_sub_head':index.coa_sub_head.name,
                    })
                });
                this.items = account
                console.log(account)
                // console.log(response.data.data.vouch_journal_coaaccounts);
                this.isLoading = false;
                // this.details.fullname = response.data?.data?.account?.customer?.first_name + ' ' + response.data?.data?.account?.customer?.last_name;
                // console.log(this.details.account?.customer?.address);
                // this.items = response.data.data.account.transactions;
                // console.log(this.items);
                // console.log(this.details.tenor);
                // this.handleImageLoad();
                // this.loan = response.data.data;
                //    this.search = "Search";
                //    this.isSearching = false;
                // console.log(this.items[0].after_payments.value = this);
                // this.items[0].payment_terms.value = this.loan.interest_paid
                // this.items[1].payment_terms.value = this.loan.principal_paid
                // this.items[2].payment_terms.value = this.loan.principal_amount
                //   console.log();
            }).catch((error) => {
                this.isLoading = false
                console.log(error);
            })
        },
        ModalOpened() {
            this.getJournalView();
        },
    }
}
</script>