<template>
    <div>
        <!-- <a class="dropdown-item" v-b-modal="data.account_number + transaction">Cheque</a> -->
        <a title="View" class="btn btn-sm akkurate-warning text-white" v-b-modal="data.id + data.name">
            <span class="akkurate-small"><font-awesome-icon :icon="['fas', 'eye']" /></span>
        </a>
       <b-modal
        :id="data.id + data.name"
        ref="modal"
        @shown="modalShown"
        size="xl">
        <template #modal-header="{ close }">
            <!-- Emulate built in modal header close button action -->
            <h5 class="akkurate-green fw-bold">Scheme View </h5>
            <b-button variant="white" @click="close()">
                &times;
            </b-button>
        </template>
        <form ref="form" @submit.stop.prevent="">
            <div class="row">
                <div class="col-12 col-md-4">
                    <label class="akkurate-dark" for="name">Name</label>
                    <b-form-group id="name" label-for="name">
                        <b-form-input class="mb-3 mt-2 field-container fields" type="text" v-model="data.name"
                            disabled></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-4">
                    <label class="akkurate-dark" for="interest_rate">Interest Rate</label>
                    <b-form-group id="interest_rate" label-for="interest_rate">
                        <b-form-input class="mb-3 mt-2 field-container fields" type="text" v-model="data.interest_rate"
                            disabled></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-4">
                    <label class="akkurate-dark" for="min_principal">min principal</label>
                    <b-form-group id="min_principal" label-for="min_principal">
                        <b-form-input class="mb-3 mt-2 field-container fields" type="text" v-model="data.min_principal"
                            disabled></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-4">
                    <label class="akkurate-dark" for="max_principal">Max Principal</label>
                    <b-form-group id="max_principal" label-for="max_principal">
                        <b-form-input class="mb-3 mt-2 field-container fields" type="text" v-model="data.max_principal"
                            disabled></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-4">
                    <label class="akkurate-dark" for="min_duration">min duration</label>
                    <b-form-group id="min_duration" label-for="min_duration">
                        <b-form-input class="mb-3 mt-2 field-container fields" type="text" v-model="data.min_duration"
                            disabled></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-4">
                    <label class="akkurate-dark" for="max_duration">max_duration</label>
                    <b-form-group id="max_duration" label-for="max_duration">
                        <b-form-input class="mb-3 mt-2 field-container fields" v-model="data.max_duration" type="text"
                            disabled></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-4">
                    <label class="akkurate-dark" for="min_membership_duration">Min Membership Duration</label>
                    <b-form-group id="min_membership_duration" label-for="min_membership_duration">
                        <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                            v-model="data.min_membership_duration" disabled></b-form-input>
                    </b-form-group>
                </div>

                <div class="col-12 col-md-4">
                    <label class="akkurate-dark" for="min_share_balance">Min Share Balance</label>
                    <b-form-group id="min_share_balance" label-for="min_share_balance">
                        <b-form-input class="mb-3 mt-2 field-container fields" type="text" v-model="data.min_share_balance"
                            disabled></b-form-input>
                    </b-form-group>
                </div>

                <div class="col-12 col-md-4">
                    <label class="akkurate-dark" for="loan_processing_fee">Loan Processing Fee</label>
                    <b-form-group id="loan_processing_fee" label-for="loan_processing_fee">
                        <b-form-input class="mb-3 mt-2 field-container fields" type="text" v-model="data.loan_processing_fee"
                            disabled></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-4">
                    <label class="akkurate-dark" for="loan_premium_plan">Loan Premium Plan</label>
                    <b-form-group id="loan_premium_plan" label-for="loan_premium_plan">
                        <b-form-input class="mb-3 mt-2 field-container fields" type="text" v-model="data.loan_premium_plan"
                            disabled></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-4">
                    <label class="akkurate-dark" for="branch_approval_limit">Branch Approval Limit</label>
                    <b-form-group id="branch_approval_limit" label-for="branch_approval_limit">
                        <b-form-input class="mb-3 mt-2 field-container fields" type="text" v-model="data.branch_approval_limit"
                            disabled></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-4">
                    <label class="akkurate-dark" for="supervisor_approval_limit">Supervisor Approval Limit</label>
                    <b-form-group id="supervisor_approval_limit" label-for="supervisor_approval_limit">
                        <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                            v-model="data.supervisor_approval_limit" disabled></b-form-input>
                    </b-form-group>
                </div>
            </div>
        </form>
        <div class="card card-shape home-box mt-3">
            <div class="card-header py-3 d-flex flex-row align-items-center">
                <h6 class="m-0 fw-bold text-green">Loan Approval Stages</h6>
            </div>
            <div class="card-body">
                <!-- <vue-good-table
                            styleClass="vgt-table bordered table-dropdown"
                            ref="schemeTable"
                            :columns="columns"
                            :rows="rows">
                        </vue-good-table> -->
                <b-table :items="items" :fields="fields" :busy="isBusy" class="mt-3" outlined>

                    <template #table-busy>
                            <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Loading...</strong>
                            </div>
                        </template>
                </b-table>
            </div>
        </div>

        <template #modal-footer="{ ok }">
               <div class="w-100">
               <!-- begin loader -->
               <!-- <beat-loader-component 
               class="float-left"
               v-if="isLoading"
               :isLoading="isLoading"
               ></beat-loader-component> -->
               <!-- end loader -->
               <!-- Emulate built in modal footer ok and cancel button actions -->
               <b-button @click="$bvModal.hide(data.id + data.name)" type="button" class="akkurate-gray-btn btn-size ms-2 float-right"><font-awesome-icon class="me-2" :icon="['fas', 'close']" />Close</b-button>
               </div>
        </template>
        </b-modal>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import SidebarView from '@/views/main/components/Sidebar.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import ApiService from "@/core/services/api.service";

export default {
    components: {
        SidebarView,
        TopbarView,
        Multiselect,
    },
    props: ['data'],
    data() {
        return {
            isBusy: false,
            fields: [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'loan_product_name',
                    label: 'Scheme Name'
                },
                {
                    key: 'loan_stage',
                    label: 'Loan Stages'
                },
                {
                    key: 'loan_approval_name',
                    label: 'Loan Approval Name'
                },
                {
                    key: 'supervisor_approval_limit',
                    label: 'Supervisor Limit'
                },
               
            ],
            items: []
        }
    },
    methods: {
        async getLoanApproval() {
            this.isBusy = true;
            await ApiService.get(`/loans/loanProductSchemes/${this.data.id}`)
                .then((response) => {
                    this.isBusy = false;
                    this.items = response.data.data.approvals.map((index) => {
                        return {
                            'id': index.id,
                            'loan_product_name' : index.loanproductscheme?.name,
                            'loan_approval_name' :  index.loanapprovallevel?.name,
                            'loan_stage' :  index.loanapprovallevel?.stage,
                            'supervisor_approval_limit' : index.loanproductscheme?.supervisor_approval_limit

                        }
                    });
                    console.log(response);
                }).catch((error) => {
                    this.isBusy = false;

                })
        },
        modalShown() {
            this.getLoanApproval();
        }
    }



}
</script>