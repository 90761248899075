<template>
  <div>
    <div class="container">
      <div class="row">
       <div class="col-12 col-md-6">
        <label class="akkurate-dark" for="first_name">First Name <star></star></label>
          <b-form-group
          id="first_name"
          label-for="first_name"
          class="akkurate-dark">
          <b-form-input
              v-model="form.first_name" 
              id="first_name" 
              class="mb-3 mt-2 field-container fields"
              type="text" 
              placeholder="Enter First Name"
              required>
          </b-form-input>
          <errors v-if="form.errors.first_name">
                  {{ form.errors.first_name[0] }}
          </errors>
          </b-form-group>
       </div>
       <div class="col-12 col-md-6">
        <label class="akkurate-dark" for="middle_name">Middle Name</label>
          <b-form-group
          id="middle_name"
          label-for="middle_name"
          class="akkurate-dark">
          <b-form-input
              v-model="form.middle_name"
              id="middle_name" 
              class="mb-3 mt-2 field-container fields"
              type="text" 
              placeholder="Enter Middle Name"
              >
          </b-form-input>
          <errors v-if="form.errors.middle_name">
                  {{ form.errors.middle_name[0] }}
          </errors>
          </b-form-group>
       </div>
       <div class="col-12 col-md-6">
        <label class="akkurate-dark" for="surname">Surname <star></star></label>
          <b-form-group
          id="surname"
          label-for="surname"
          class="akkurate-dark">
          <b-form-input
              id="surname" 
                  v-model="form.surname"
              class="mb-3 mt-2 field-container fields"
              type="text" 
              placeholder="Enter Surname"
              required>
          </b-form-input>
          <errors v-if="form.errors.surname">
                  {{ form.errors.surname[0] }}
          </errors>
          </b-form-group>
       </div>
       <div class="col-12 col-md-6">
        <label class="akkurate-dark" for="username">Username <star></star></label>
          <b-form-group
              id="username-group"
              label-for="username"
              class="akkurate-dark"
              >
              <b-input-group class=" mb-3 mt-2 field-container">
                  <b-form-input 
                  v-model="form.username" 
                  class="field-main" 
                  :disabled="add_or_edit == 'edit'"
                  required>
                  </b-form-input>
                  <font-awesome-icon :icon="['fas', 'user-secret']" class="field-icon akkurate-dark position-absolute top-50 end-0 translate-middle-y me-3" />
              </b-input-group>
              <errors v-if="form.errors.username">
                  {{ form.errors.username[0] }}
              </errors>
          </b-form-group>
       </div>
       <div class="col-12 col-md-6">
        <label class="akkurate-dark" for="text-password">Email Address</label>
            <b-form-group
              id="email"
              label-for="email">
            <b-form-input
              class="mb-3 mt-2 field-container fields"
              id="email"
              v-model="form.email"
              type="email"
              placeholder="Enter Email Address"
              
            ></b-form-input>
            <errors v-if="form.errors.email">
                  {{ form.errors.email[0] }}
            </errors>
            </b-form-group>
       </div>
       <div class="col-12 col-md-6">
        <label class="akkurate-dark" for="text-password">Contact Number <star></star></label>
            <b-form-group
              id="phone_number"
              label-for="phone_number">
            <b-form-input
              class="mb-3 mt-2 field-container fields"
              id="phone_number"
              v-model="form.phone_number"
              type="text"
              placeholder="Enter Phone Number"
              required
            ></b-form-input>
            <errors v-if="form.errors.phone_number">
                  {{ form.errors.phone_number[0] }}
            </errors>
            </b-form-group>
       </div>
       <div class="col-12 col-md-6">
        <label class="akkurate-dark" for="text-password">
          <span v-if="add_or_edit == 'edit'">Change </span>
          Password <star v-if="add_or_edit != 'edit'"></star></label>
        <b-form-group
              id="password-group"
              label-for="password"
              class="akkurate-dark">
              <b-input-group class="mt-2 mb-4 field-container">
                  <b-form-input v-model="form.password" id="password" class="field-main" type="password" 
                  :required="add_or_edit != 'edit'"></b-form-input>
                  <a v-on:click="passwordView" type="button"> <font-awesome-icon :icon="['fas', 'eye']" class="field-icon awesome-eye akkurate-dark position-absolute top-50 end-0 translate-middle-y me-3" /></a>
              </b-input-group>
              <errors v-if="form.errors.password">
                  {{ form.errors.password[0] }}
              </errors>
          </b-form-group>
       </div>
       <div class="col-12 col-md-6">
        <label class="akkurate-dark" for="text-password">Confirm Password <star v-if="add_or_edit != 'edit'"></star></label>
        <b-form-group
          id="confirm-password-group"
          label-for="confirm-password"
          class="akkurate-dark"
          >
          <b-form-input
            v-model="form.password_confirmation" 
            id="confirm-password" 
            class="mb-3 mt-2 field-container fields"
            type="password" 
            placeholder="Enter Confirm Password"
            :required="add_or_edit != 'edit'">
          </b-form-input>
          <errors v-if="form.errors.password_confirmation">
                  {{ form.errors.password_confirmation[0] }}
            </errors>
          </b-form-group>
       </div>
       <div class="col-12 col-md-6">
        <label class="akkurate-dark">Role <star></star></label>
        <b-form-group>
          <multiselect
            class="field-container mt-2 mb-3"
            v-model="form.selected_roles"
            name="selected_roles"
            placeholder="Search or select a Role"
            label="display_name"
            track-by="id"
            :options="roles"
            :multiple="true"
          ></multiselect>
          <errors v-if="form.errors.selected_roles">
            {{ form.errors.selected_roles[0] }}
          </errors>
        </b-form-group>
       </div>
       <div class="col-12 col-md-2">
                <b-form-group>
                    <template #label>
                        <label class="akkurate-dark" for="status">Status <star></star></label>
                    </template>
                    <b-form-radio v-model="form.status" name="status" value="1">Active</b-form-radio>
                    <b-form-radio v-model="form.status" name="status" value="0">Inactive</b-form-radio>
                    <errors v-if="form.errors.status">
                      {{ form.errors.status[0] }}
                    </errors>
                </b-form-group>
          </div>
          <div class="col-12 col-md-4">
              <label class="akkurate-dark">Branch <star></star></label>
              <b-form-group>
                <multiselect
                  class="field-container mt-2 mb-3"
                  :disabled="add_or_edit == 'edit'"
                  v-model="form.branch"
                  name="branch"
                  placeholder="Search or select a Branch"
                  label="name"
                  :options="branch_options"
                ></multiselect>
                <errors v-if="form.errors.branch">
                  {{ form.errors.branch[0] }}
                </errors>
              </b-form-group>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import ApiService from "@/core/services/api.service";
export default {
  props: ["form", "roles", "add_or_edit", "branch_options"],
  components: { Multiselect },
  // data(){
  //   return {
  //     branch_options:[],
  //   }
  // },
  created(){
    this.loadBranch();
  },
  methods: {
    passwordView() {
      if (this.$el.querySelector("#password").type == 'password' || this.$el.querySelector("#confirm-password").type == 'password') {
        this.$el.querySelector("#password").type = 'text';
        this.$el.querySelector("#confirm-password").type = 'text'
        this.$el.querySelector(".awesome-eye").style.color = 'rgb(1,134,115)'
      } else {
        this.$el.querySelector("#password").type = 'password'
        this.$el.querySelector("#confirm-password").type = 'password'
        this.$el.querySelector(".awesome-eye").style.color = '#212529'
      }
    },
    loadBranch(){
      ApiService.get('/users/dropdown').
      then((response) => {
        // this.branch_options = response.data.branches;
      }).catch((error) => {
        console.log(error);
      })
    },

  },
  
}
</script>
