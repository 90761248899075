<template>
    <div class="container">
           <div class="row vh-100">
               <div class="col-md-6 d-md-flex align-items-md-center">
                   <img class="img-fluid img-visibility" src="../../assets/img/forget-password.jpg">
               </div>
               <ForgetForm/>
           </div>
       </div>
   </template>
   
   <script>
   import ForgetForm from './Forms/ForgetForm.vue'
   export default {
       name: 'ForgetView',
       components: {
           ForgetForm
       }
   }
   </script>