import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
import config from './../../config.js'


/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = config.base_url + "/api/v1";

    Vue.axios.interceptors.request.use(
      function (conf) {
        conf.headers["Accept"] = `application/json`;

        // Check if token exists in localStorage
        const token = JwtService.getToken();
        if (token) {
          // Add token to the request headers
          conf.headers["Authorization"] = `Bearer ${token}`;
        }
        return conf;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    Vue.axios.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if (error?.response?.status == "404") {
          // toast.fire({
          //   icon: "error",
          //   title: "Sorry the resource you requested could not be found"
          // });
        }

        //logout user if unauthenticated
        // if (error.response.status == "401" || error.response.status == "419") {
        if (error?.response?.status == "419") {
          swal.fire({
            title: "Session Expired",
            text: "You've been logged out, please log back in",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
            allowOutsideClick: false
          }).then(result => {
            if (result.value) {
              JwtService.destroyToken();
              window.location.replace('/login');
            }
          });
        }
        else if (error?.response?.status == "401") {
          swal.fire({
            title: "Session Expired",
            text: "You need to be logged in to access this resource",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#018673",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
            allowOutsideClick: false
          }).then(result => {
            if (result.value) {
              JwtService.destroyToken();
              window.location.replace('/login')
            }
          });
        }
        return Promise.reject(error);
      }
    );

  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common["Accept"] = `application/json`;
    // Vue.axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },

  query(resource, params) {
    return Vue.axios.get(resource, params);
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource) {
    return Vue.axios.get(`${resource}`)
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource);
  }
};

export default ApiService;
