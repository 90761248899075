<template>
    <form @submit.prevent="storeComments">
        <div class="row">
        <div class="col-8">
            <b-form-group
                label-for="name"
                class="mb-3">
                <template #label>
                    <label class="akkurate-dark small" for="name">Comment Here</label>
                </template>
                <b-form-textarea
                    id="textarea"
                    placeholder="Enter something..."
                    rows="4"
                    max-rows="6"
                    v-model="form.comment"
                ></b-form-textarea>
            </b-form-group>
        </div>
        <div class="col-4 d-flex align-items-center justify-content-center">
            <button type="submit"  class="btn akkurate-green-btn text-white"><font-awesome-icon class="me-2" :icon="['fas', 'save']" />Post Comment</button>
        </div>
        <div class="row" v-for="(item, index) in comments" :key="index">
            <hr/>
            <div class="col-3">
                <img class="img-fluid" width="50%" alt="Comment_Img" src="@/assets/img/profile.png" />
            </div>
            <div class="col-6">
                <p class="mb-5">{{ item.comment }}</p>
                <p class="mb-0">{{ item.created_at }}</p>
                <!-- <p class="mb-0">{{ this.fetchUser.id }}</p> -->
            </div>
            <div class="col-3 d-flex align-items-center justify-content-center">
                <b-button variant="light"><i class="text-danger fa fa-times"></i></b-button>
            </div>
            <hr/>
        </div>
    </div>
    </form>
   
</template>

<script>
import ApiService from "@/core/services/api.service";
import Multiselect from "vue-multiselect";
export default {
    components: {
        Multiselect,
    },
    data() {
        return {
            ...this.initialState(),
            comments: [],

        }
    },
    mounted() {
        this.getAllComments();
    },
    methods: {
        async storeComments() {
            this.isSaving = true;
            this.$Progress.start();

            ApiService.post('/loans/loanComments', this.form)
                .then((response) => {
                    toast.fire({
                        icon: "success",
                        title: response.data.message,
                    });
                    this.isSaving = false;
                    this.$Progress.finish();
                    this.form = this.initialState().form;
                    this.getAllComments();
                }).catch((error) => {
                    this.isSaving = false;
                    this.$Progress.fail();
                    if (error.response.data.errors) {
                        // this.form.errors = error.response.data.errors;
                        console.log(error)
                    }

                })
        },
        getAllComments() {
            ApiService.get('/loans/loanComments').
                then((response) => {
                    this.comments = response.data.data;
                }).catch((error) => {
                    console.log(error)
                })
        },
        initialState() {
            return {
                form: {
                    loan_id: 12,
                    comment: "",
                    user_id: 11,
                }
            }
        }
    }

}
</script>