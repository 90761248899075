<template>
  <div id="wrapper">
    <SidebarView :reports="true" />
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <TopbarView />
        <div class="container">
          <div class="card card-shape home-box">
            <div class="card-header">
              <div class="row align-items-center">
                <div class="col-6">
                  <h6 class="m-0 fw-bold text-green">Performance Analysis</h6>
                </div>
                <div class="col-6 text-end">
                  <b-form-select
                    v-model="selected_num"
                    @change="changePerPageNumber"
                    :options="numbers_options"
                  ></b-form-select>
                  <b-button @click="generateExcel" class="btn-sm me-2 ms-5 akkurate-green-btn akkurate-small">
                    <font-awesome-icon :icon="['fas', 'file-excel']"/>
                  </b-button>
                  <b-button
                    @click="printPDF"
                    class="me-2 btn-sm akkurate-small akkurate-green-btn"
                    ><font-awesome-icon :icon="['fas', 'book']"
                  /></b-button>
                  <b-button
                    @click="downloadPDF"
                    class="btn-sm akkurate-small akkurate-green-btn"
                    ><font-awesome-icon :icon="['fas', 'download']"
                  /></b-button>
                </div>
              </div>
            </div>
            <div class="card-body mt-0">
              <div class="row" ref="pdfContent">
                <div class="col-12 col-md-12">
                  <statement-institution
                    :institution="institution"
                    :title="'PERFORMANCE ANALYSIS'"
                  ></statement-institution>
                </div>
                <hr/>
                <div class="col-12 col-md-12 trans_details">
                  <b-table
                    :items="items"
                    :fields="fields"
                    striped
                    responsive="sm"
                    :per-page="perPage"
                    :busy.sync="isBusy"
                    :current-page="currentPage"
                    small
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                      </div>
                    </template>
                  </b-table>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 text-end">
                  <b-pagination
                    class="text-end"
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    align="right"
                  ></b-pagination>
                  <!-- <p class="mt-3">Current Page: {{ currentPage }}</p> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer></footer>
    </div>
    <a class="d-inline scroll-to-top" href="#page-top"
      ><i class="fas fa-angle-up"></i
    ></a>
  </div>
</template>
  <script>
  import SidebarView from "@/views/main/components/Sidebar.vue";
  import TopbarView from "@/views/main/components/Topbar.vue";
  import Footer from "@/views/main/components/Footer.vue";
  import StatementInstitution from "@/views/main/components/institution/StatementInstitution.vue";
  import ApiService from "@/core/services/api.service";
  import Multiselect from "vue-multiselect";
  import html2pdf from "html2pdf.js";
  import ExcelJS from 'exceljs';
  export default {
    components: {
      SidebarView,
      TopbarView,
      Footer,
      StatementInstitution,
      Multiselect,
    },
    data() {
      return {
        filter: {
          branch: "",
          start_date: "",
          end_date: "",
          year: "",
        },
        search: "Search",
        isBusy: false,
        isSearching: false,
        selected_num: "10",
        numbers_options: [10, 50, 100, 500],
        isLoadingInstitution: false,
        institution: "",
        fields: [
          {
            key: "customer",
            label: "Ro name",
          },
          {
            key: "reg_date",
            label: "Registration Date",
          },
          {
            key: "no_of_business",
            label: "No of business",
          },
          {
            key: "deposits",
            label: "Total Deposits",
          },
          {
            key: "withdrawals",
            label: "Total Withdrawals",
          },
          {
            key: "balance",
            label: "Balance",
          },
        ],
        perPage: 20,
        currentPage: 1,
        items: [],
      };
    },
    computed: {
      rows() {
        return this.items.length;
      },
    },

    created() {
      this.getInstitution();
      this.getPerformance();
    },
    methods: {
      async getPerformance() {
        this.items = [];
        this.isBusy = true;
        this.search = "Processing...";
        this.isSearching = true;
        await ApiService.get("/reports/controls/performance/analysis")
          .then((response) => {
            this.isBusy = false;
            this.isSearching = false;
            this.search = "Search";
            var res = response.data.users;
            this.items = res.map((index) => {
              return {
                reg_date: index.reg_date,
                no_of_business: index.no_of_business,
                deposits: index.deposits,
                withdrawals: index.withdrawals,
                balance: index.deposits + index.withdrawals,
                customer: index.customer,
              };
            });
            // res.map((index) => {
            //   const data = {
            //     reg_date: index.account?.created_date,
            //     trans_amount: index.trans_amount,
            //     balance: index.account?.account_balance,
            //     transaction_type: index.transaction_type

            //   }
            //   this.items.push(data);
            // });

            // console.log(teller);
          })
          .catch((error) => {
            this.isBusy = false;
            this.isSearching = false;
            this.search = "Search";
            console.log(error);
          });
      },
      downloadPDF() {
        const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
        // console.log("here");
        const pdfOptions = {
          margin: [0.5, 0.2, 0.8, 0.2], //top, left, buttom, right
          filename: "statement.pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          pagebreak: { mode: "avoid-all" },
          jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        };
        html2pdf()
          .from(content)
          .set(pdfOptions)
          .toPdf()
          .get("pdf")
          .then((pdf) => {
            var totalPages = pdf.internal.getNumberOfPages();
            var insit_name = this.institution.name;
            var insit_tele = this.institution.telephone;
            var insit_email = this.institution.email;
            var insit_motto = this.institution.motto;

            for (var i = 1; i <= totalPages; i++) {
              pdf.setPage(i);
              pdf.setFontSize(8);
              pdf.setTextColor(150);
              pdf.text(
                [
                  "For Inquiries, Please Contact " +
                  insit_name +
                  ", Customer Service on " +
                  insit_tele +
                  "",
                  "or email us on " + insit_email + " " + insit_motto + "",
                  "Page " + i + " of " + totalPages,
                ],
                pdf.internal.pageSize.getWidth() / 2,
                pdf.internal.pageSize.getHeight() - 0.6,
                { align: "center" }
              );
            }
          })
          .save();
      },
      printPDF() {
        const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
        // console.log("here");
        const pdfOptions = {
          margin: [0.5, 0.2, 0.8, 0.2], //top, left, buttom, right
          filename: "statement.pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          pagebreak: { mode: "avoid-all" },
          jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        };
        html2pdf()
          .from(content)
          .set(pdfOptions)
          .toPdf()
          .get("pdf")
          .then((pdf) => {
            var totalPages = pdf.internal.getNumberOfPages();
            var insit_name = this.institution.name;
            var insit_tele = this.institution.telephone;
            var insit_email = this.institution.email;
            var insit_motto = this.institution.motto;

            for (var i = 1; i <= totalPages; i++) {
              pdf.setPage(i);
              pdf.setFontSize(8);
              pdf.setTextColor(150);
              pdf.text(
                [
                  "For Inquiries, Please Contact " +
                  insit_name +
                  ", Customer Service on " +
                  insit_tele +
                  "",
                  "or email us on " + insit_email + " " + insit_motto + "",
                  "Page " + i + " of " + totalPages,
                ],
                pdf.internal.pageSize.getWidth() / 2,
                pdf.internal.pageSize.getHeight() - 0.6,
                { align: "center" }
              );
            }
            // Get the blob of the PDF
            return pdf.output("blob");
          })
          .then((pdfBlob) => {
            // Create a URL for the PDF blob
            var pdfUrl = URL.createObjectURL(pdfBlob);

            // Open the URL in a new tab
            const printWindow = window.open(
              pdfUrl,
              "_blank",
              "width=800,height=600"
            );
            printWindow.onload = () => {
              printWindow.print();
              URL.revokeObjectURL(pdfUrl);
            };
          })
          .catch((error) => {
            console.error("Error generating PDF:", error);
          });
      },
      filterTable() {
        this.getPerformance();
      },
      changePerPageNumber(data) {
        this.perPage = data;
        this.getPerformance();
      },
      async getInstitution() {
        this.isLoadingInstitution = true;
        await ApiService.get("/settings/institution")
          .then((response) => {
            this.institution = response.data.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.isLoadingInstitution = false;
          });
      },
      async generateExcel() {
        const fileUrl = '/REPORT_TEMPLATE.xlsx'; // Path to the file in the public folder

        try {
          // Fetch the Excel file from the public URL
          const response = await fetch(fileUrl);
          if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
          }
          const arrayBuffer = await response.arrayBuffer();

          // Load the file into ExcelJS
          const wb = new ExcelJS.Workbook();
          await wb.xlsx.load(arrayBuffer);

          // Access the first sheet
          const ws = wb.getWorksheet(1); // Get the first worksheet

          // Define header row
          const header = [
            "Ro name",
            "Registration Date",
            "No of business",
            "Total Deposits",
            "Total Withdrawals",
            "Balance",
          ];

          // Add header row starting from row 13
          const startingRow = 13;
          let headerRow = ws.getRow(startingRow);
          headerRow.values = header;
          headerRow.font = { bold: true };
          headerRow.commit(); // Commit the row to the worksheet

          // Add individual data rows
          let currentRow = startingRow + 1;

          // Process each item
          this.items.forEach(item => {
            const row = ws.getRow(currentRow);
            // Map item values to the corresponding header columns
            row.values = [
              item.customer,
              item.reg_date,
              item.no_of_business,
              item.deposits,
              item.withdrawals,
              item.balance,

            ];
            row.commit();
            currentRow++;
          });

          const buffer = await wb.xlsx.writeBuffer();
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

          // Create a download link and simulate a click to download the file
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = 'Modified_REPORT_TEMPLATE.xlsx';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (err) {
          console.error('Error fetching, modifying, or downloading the Excel file:', err.message);
        }
      }
    },
  };
</script>