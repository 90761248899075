<template>
    <div class="container">
        <div class="row">
            <div class="col-3 col-md-3">
                <div class="row">
                    <div class="col-12 col-md-12">
                        <p class="fw-bold">{{ getFullName }}</p>
                    </div>
                    <div class="col-12 col-md-12 small">
                        <div class="d-flex">
                            <p class="mb-0 fw-bold me-3">Status:</p>
                            <p class="mb-0">{{ loan.status }}</p>
                        </div>
                        <div class="d-flex">
                            <p class="mb-0 fw-bold me-3">Tenure:</p>
                            <p class="mb-0">{{ loan.tenor }}</p>
                        </div>
                        <div class="d-flex">
                            <p class="mb-0 fw-bold me-3">Tenure Interval:</p>
                            <p class="mb-0">{{ loan.tenor_interval }}</p>
                        </div>
                        <div class="d-flex">
                            <p class="mb-0 fw-bold me-3">Interest Rate:</p>
                            <p class="mb-0">{{ loan.interest_rate }} / {{ loan.tenor_interval }}</p>
                        </div>
                        <div class="d-flex">
                            <p class="mb-0 fw-bold me-3">Interest Method:</p>
                            <p class="mb-0">{{ loan.interest_method }}</p>
                        </div>
                        <div class="d-flex">
                            <p class="mb-0 fw-bold me-3">Interest Expected:</p>
                            <p class="mb-0">{{ Number(loan.expected_interest).toFixed(2) }}</p>
                        </div>
                        <div class="d-flex">
                            <p class="mb-0 fw-bold me-3">Interest Balance:</p>
                            <!-- <p class="mb-0">{{ loan.interest_balance - loan.interest_paid }}</p> -->
                            <p class="mb-0">{{ Number(loan.interest_balance).toFixed(2) }}</p>
                        </div>
                        <div class="d-flex">
                            <p class="mb-0 fw-bold me-3">Interest Paid:</p>
                            <p class="mb-0">{{ Number(loan.interest_paid).toFixed(2) }}</p>
                        </div>
                        <div class="d-flex">
                            <p class="mb-0 fw-bold me-3">Interest Due:</p>
                            <p class="mb-0">{{ Number(loan.interest_due).toFixed(2) }}</p>
                        </div>
                        <div class="d-flex">
                            <p class="mb-0 fw-bold me-3">Payment Due:</p>
                            <p class="mb-0">{{ getDuePayment }}</p>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-3 col-md-3 small">
                <div class="row h-100">
                    <div class="col-md-12">
                        <div> <span class="fw-bold">Loan Type:</span> {{ loan?.loan_product.name }}
                        </div>
                        <div> <span class="fw-bold">Disbursement Date:</span> {{
                            loan?.interest_start_date }}
                        </div>
                        <div> <span class="fw-bold">Maturity Date:</span> {{ loan?.maturity_date }}
                        </div>
                    </div>
                    <div class="col-md-12 mt-auto">
                        <div class="row">
                            <div class="col-md-6 fw-bold">Principal:</div>
                            <div class="col-md-6">{{ Number(loan.principal_amount).toFixed(2) }}</div>
                            <div class="col-md-6 fw-bold">Principal Paid:</div>
                            <div class="col-md-6">{{ Number(loan.principal_paid).toFixed(2) }}</div>
                            <div class="col-md-6 fw-bold">Principal Bal:</div>
                            <div class="col-md-6">{{ Number(loan.principal_balance).toFixed(2) }}</div>
                            <div class="col-md-6 fw-bold">Principal Due:</div>
                            <div class="col-md-6">{{ Number(loan.principal_due).toFixed(2) }}</div>
                        </div>

                    </div>

                </div>
            </div>
            <div class="col-3 col-md-3 small">
                <div class="row h-100">
                    <div class="col-md-12">
                        <div> <span class="fw-bold">Loan Code:</span> {{ loan.loan_number }}</div>
                        <div> <span class="fw-bold">Last Payment Date:</span> {{ loan.last_payment }}
                        </div>
                        <div> <span class="fw-bold">Delinquency:</span> {{ loan.delinquent }}</div>
                    </div>
                    <div class="col-md-12 mt-auto">
                        <div class="row">
                            <div class="col-md-6 fw-bold">Current Value:</div>
                            <div class="col-md-6">
                                GHS {{ getCurrentValue }}
                            </div>
                            <div class="col-md-6 fw-bold">Loan Balance:</div>
                            <div class="col-md-6">
                                GHS {{ getLoanBalance }}
                            </div>
                            <div class="col-md-6 fw-bold">Share Bal:</div>
                            <div class="col-md-6">
                                GHS {{ Number(loan?.shares_account_balances).toFixed(2) }}
                            </div>
                            <div class="col-md-6 fw-bold">Saving Bal:</div>
                            <div class="col-md-6">
                                GHS {{ Number(loan?.savings_account_balances).toFixed(2) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
export default {
    props: ['loan'],
    computed: {
        getFullName() {
            const first_name = this.loan.account.customer.first_name != undefined ? this.loan.account.customer.first_name : "";
            const middle_name = this.loan.account.customer.middle_name != undefined ? this.loan.account.customer.middle_name : "";
            const last_name = this.loan.account.customer.last_name != undefined ? this.loan.account.customer.last_name : "";
            return first_name + ' ' + middle_name + ' ' + last_name;

        },
        getDuePayment() {
            const payment = Number(this.loan?.interest_due ?? 0) + Number(this.loan?.principal_due ?? 0);
            return payment.toFixed(2);
        },
        getCurrentValue() {
            const current = Number(this.loan?.principal_balance ?? 0) + Number(this.loan?.interest_due ?? 0);
            return current.toFixed(2)
        },
        getLoanBalance() {
            const balance = Number(this.loan?.interest_balance ?? 0) + Number(this.loan?.principal_balance ?? 0);
            return balance.toFixed(2)
        },
        getBranch() {
            const branch = this.loan?.account?.customer?.branch?.name ? this.loan?.account?.customer?.branch?.name : ""
            return branch
        },
        total_credit() {
            return this.entries_items
                .filter((trans) => trans.transaction_type == "Loan Repayment")
                .reduce((total, trans) => total + trans.trans_amount, 0);
        },
        total_debit() {
            return this.entries_items
                .filter((trans) => trans.transaction_type != "Loan Repayment")
                .reduce((total, trans) => total + trans.trans_amount, 0);
        },
        total_principal() {
            return (
                this.entries_items
                    .reduce((total, trans) => total + trans.principal, 0)
            );
        },
        total_interest() {
            return this.entries_items.reduce((total, trans) => total + trans.interest, 0);
        },
        getTeller() {
            let first_name = this.user?.first_name != null ? this.user?.first_name : '';
            let middle_name = this.user?.middle_name != null ? this.user?.middle_name : '';
            let surname = this.user?.surname != null ? this.user?.surname : '';
            return first_name + ' ' + middle_name + ' ' + surname;
        },
    },

}
</script>