<template>
  <div id="wrapper">
    <SidebarView :add-users="true" :user-visible="true" :users="true" />
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <TopbarView />
        <div class="container">
          <div class="row">
            <div class="col-12">
              <!-- begin loader -->
              <beat-loader-component v-if="isLoading" :isLoading="isLoading"></beat-loader-component>
              <!-- end loader -->
            </div>
            <div class="col-12">
              <div class="card card-shape home-box">
                <div class="card-header py-3 d-flex flex-row align-items-center">
                  <h6 class="m-0 fw-bold text-green">Create New User</h6>
                </div>
                <div class="card-body">
                  <form class="form" method="POST" @submit.prevent="storeUser">
                    <users-form :roles="roles" :form="form" :add_or_edit="add_or_edit"
                      :branch_options="branches"></users-form>
                    <hr />
                    <div class="text-center py-3">
                      <SaveButton :isSaving="isSaving" />
                      <button type="reset" class="btn akkurate-gray-btn text-white btn-size my-2 ms-3">
                        <font-awesome-icon class="me-2" :icon="['fas', 'rotate-right']" />
                        Reset</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <Footer></Footer>
    </div>
    <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
  </div>

</template>

<script>

import BeatLoaderComponent from "@/views/components/Loader/BeatLoaderComponent.vue";
import UsersForm from "./../forms/UsersForm.vue";
import ApiService from "@/core/services/api.service";
import SidebarView from '@/views/main/components/Sidebar.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import Footer from '@/views/main/components/Footer.vue'
import SaveButton from "@/views/main/components/SaveButton.vue";

export default {
  components: {
    name: 'UsersCreate',
    BeatLoaderComponent,
    UsersForm,
    SidebarView,
    TopbarView,
    Footer,
    SaveButton
  },
  mounted() {
    let token = localStorage.getItem('token');
    if (!token) {
      this.$router.push({ path: '/login' });
    }
    this.getUserOptions();
  },
  // data() {
  //   return 
  //   {
  //         // this.initialState(),
  //         roles: [],
  //   };
  // },
  data() {
    return {
      ...this.initialState(),
      roles: [],
      branches: [],
    };
  },
  methods: {
    getUserOptions() {
      this.$Progress.start();
      this.isLoading = true;
      // axios
      //   .get("roles/dropdown")
      ApiService.get("users/dropdown")
        .then((response) => {
          // console.log(response.data);
          this.roles = response.data.roles;
          this.branches = response.data.branches;
          this.isLoading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.isLoading = false;
          this.$Progress.fail();
        });
    },
    storeUser() {
      this.isSaving = true;
      this.$Progress.start();

      // if(this.form.selected_roles)
      // {
      //   this.form.role_name = this.form.selected_roles.name
      // }
      // else
      // {
      //   this.form.role_name = "";
      // }

      // this.form
      //   .post("/users/store")
      ApiService.post("users/store", this.form)
        .then((response) => {
          this.isLoadingSaving = false;
          this.$Progress.finish();

          toast.fire({
            icon: "success",
            title: response.data.message,
          });
          this.isSaving = false;
          // this.form.reset();
          this.form = this.initialState().form;
        })
        .catch((error) => {
          this.isSaving = false;
          this.$Progress.fail();
          if (error.response.data.errors) {
            this.form.errors = error.response.data.errors;
          }
        });
    },
    initialState() {
      return {
        form: {
          first_name: "",
          surname: "",
          middle_name: "",
          email: "",
          phone_number: "",
          username: "",
          address: "",
          selected_roles: [],
          password: "",
          password_confirmation: "",
          status: 1,
          role_name: "",
          errors: [],
          branch: "",
        },
        isLoading: true,

        isSaving: false,
        add_or_edit: 'add',
      };
    },
  },
};
</script>

<!-- New step!
     Add Multiselect CSS. Can be added as a static asset or inside a component. -->
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
