<template>
    <LineChartGenerator 
      id="lineChart"
      :data="lineChartData"
    />
  </template>
  
  <script>
  import { Line as LineChartGenerator } from 'vue-chartjs'
  import {
    Chart as ChartJS, Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement
  } from 'chart.js'
  
  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement
  )
  
  
  export default {
    name: 'LineChart',
    components: { LineChartGenerator },
    props:{lineChartData:{}},
    data() {
      return {
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: true
              }
            }],
            xAxes: [{
              gridLines: {
                display: false
              }
            }]
          },
          legend: {
            display: true
          },
          responsive: true,
          maintainAspectRatio: false,
        }
      }
    },
  }
  </script>
  