<template>
    <div>
       <a title="Edit" class="btn btn-sm akkurate-blue text-white"  v-b-modal="String(data.id)">
        <span class="akkurate-small"><font-awesome-icon :icon="['fas', 'edit']" /></span> 
       </a>
       <b-modal
        :id="String(data.id)"
        ref="modal"
        size="lg"
        @ok="handleOk"
        >
        <template #modal-title>
            <div class="akkurate-green fw-bold">Edit Account</div>
        </template>
           <form ref="form" @submit.stop.prevent="handleSubmit" >
               <div class="row">
                   <div class="col-md-6">
                       <b-form-group
                           label-for="dr_amount"
                           class="mb-3">
                           <template #label>
                               <label class="akkurate-dark" for="dr_amount">Requested Debit Amount <star></star></label>
                           </template>
                           <b-form-input
                               id="dr_amount"
                               v-model="data.dr_amount"
                               v-validate="{ required: true }"
                               :state="validateState('dr_amount')"
                               name="dr_amount"
                               class="mt-2 field-container fields"
                               type="number"
                               disabled
                               required
                           ></b-form-input>
                           <b-form-invalid-feedback >
                               The Direct Amount is required
                           </b-form-invalid-feedback>
                           <!-- <errors v-if="data.errors.dr_amount">
                                   {{ data.errors.dr_amount[0] }}
                            </errors> -->
                       </b-form-group>
                   </div>

                   <div class="col-md-6">
                       <b-form-group
                           label-for="cr_amount"
                           class="mb-3">
                           <template #label>
                               <label class="akkurate-dark" for="cr_amount">Requested Credit Amount <star></star></label>
                           </template>
                           <b-form-input
                               id="cr_amount"
                               v-model="data.cr_amount"
                               v-validate="{ required: true }"
                               :state="validateState('cr_amount')"
                               name="cr_amount"
                               class="mt-2 field-container fields"
                               type="number"
                               disabled
                               required
                           ></b-form-input>
                           <b-form-invalid-feedback >
                               The Credit Amount is required
                           </b-form-invalid-feedback>
                           <!-- <errors v-if="data.errors.dr_amount">
                                   {{ data.errors.dr_amount[0] }}
                               </errors> -->
                       </b-form-group>
                   </div>

                   <div class="col-md-6">
                       <b-form-group
                           label-for="dr_amount_approved"
                           class="mb-3">
                           <template #label>
                               <label class="akkurate-dark" for="dr_amount_approved">Approved Debit Amount <star></star></label>
                           </template>
                           <b-form-input
                               id="dr_amount_approved"
                               v-model="form.dr_amount_approved"
                               v-validate="{ required: true }"
                               :state="validateState('dr_amount_approved')"
                               name="dr_amount_approved"
                               class="mt-2 field-container fields"
                               type="number"
                               required
                           ></b-form-input>
                           <b-form-invalid-feedback >
                               The Direct Approve Amount is required
                           </b-form-invalid-feedback>
                           <!-- <errors v-if="data.errors.dr_amount">
                                   {{ data.errors.dr_amount[0] }}
                            </errors> -->
                       </b-form-group>
                   </div>

                   <div class="col-md-6">
                       <b-form-group
                           label-for="cr_amount_approved"
                           class="mb-3">
                           <template #label>
                               <label class="akkurate-dark" for="cr_amount_approved">Approved Credit Amount <star></star></label>
                           </template>
                           <b-form-input
                               id="cr_amount_approved"
                               v-model="form.cr_amount_approved"
                               v-validate="{ required: true }"
                               :state="validateState('cr_amount_approved')"
                               name="cr_amount_approved"
                               class="mt-2 field-container fields"
                               type="number"
                               required
                           ></b-form-input>
                           <b-form-invalid-feedback >
                               The Credit Approve Amount is required
                           </b-form-invalid-feedback>
                           <!-- <errors v-if="data.errors.dr_amount">
                                   {{ data.errors.dr_amount[0] }}
                               </errors> -->
                       </b-form-group>
                   </div>

                   <div class="col-md-6">
                       <b-form-group
                           label-for="coa_subhead"
                           class="mb-3">
                           <template #label>
                               <label class="akkurate-dark" for="coa_subhead"> COA Subhead <star></star></label>
                           </template>
                           <multiselect 
                            class="mt-2 field-container" 
                            v-model="form.coa_subhead" 
                            :options="coa_subhead_options"
                            :state="validateState('coa_subhead')"
                            name="coa_subhead"
                            label="name"
                            track-by="name"
                            required
                           ></multiselect>
                           <b-form-invalid-feedback >
                               The coa subhead is required
                           </b-form-invalid-feedback>
                           <!-- <errors v-if="data.errors.coa_subhead">
                                   {{ data.errors.coa_subhead[0] }}
                               </errors> -->
                       </b-form-group>
                   </div>
                   
                   <!-- <div class="col-md-6">
                       <b-form-group
                           label-for="credit_gl"
                           class="mb-3">
                           <template #label>
                               <label class="akkurate-dark" for="credit_gl">Credit GL <star></star></label>
                           </template>
                           <multiselect 
                            id="credit_gl"
                            class="mt-2 field-container" 
                            v-model="form.credit_gl" 
                            :options="coa_subhead_options"
                            :state="validateState('credit_gl')"
                            name="credit_gl"
                            label="name"
                            required
                           ></multiselect>
                           <b-form-invalid-feedback >
                               The Credit GL is required
                           </b-form-invalid-feedback>
                           <errors v-if="form.errors.credit_gl">
                                   {{ form.errors.credit_gl[0] }}
                               </errors>
                       </b-form-group>
                   </div> -->
                   
               </div>
           </form>
          <template #modal-footer="{ ok }">
               <div class="w-100">
               <!-- begin loader -->
               <!-- <beat-loader-component 
               class="float-left"
               v-if="isLoading"
               :isLoading="isLoading"
               ></beat-loader-component> -->
               <!-- end loader -->
               <!-- Emulate built in modal footer ok and cancel button actions -->
               <b-button @click="$bvModal.hide(String(data.id))" type="button" class="akkurate-grey-btn btn-size float-right border-0"><font-awesome-icon class="me-2" :icon="['fas', 'close']" />Close</b-button>
               <b-button @click="ok()" type="submit" class="akkurate-green-btn btn-size me-2 float-right border-0"><font-awesome-icon class="me-2" :icon="['fas', 'save']" />Update</b-button>     
               </div>
           </template>
           
       </b-modal>
    </div>
</template>
<script>
import Multiselect from "vue-multiselect";
// import ApiService from "@/core/services/api.service";
export default {
    name: "AddAccount",
    props: ['data', 'coa_subhead_options'],
    components: {
        Multiselect,
    },
    // mounted() {
    //     console.log(this.data);
    //     if (this.data && this.data.coa_sub_head) {
    //         this.form.coa_subhead = this.data.coa_sub_head;
    //     }
    // },
    data() {
        return {
            form: {
                id: this.data.id,
                dr_amount_approved: this.data.dr_amount,
                cr_amount_approved: this.data.cr_amount,
                coa_subhead: this.data.coa_subhead,
                errors: [],
            },
            // account_types: [],
            //    isSaving: false,
            add_or_edit: "add",
        }
    },
    methods: {
        validateState(ref) {
            if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        //    resetModal() {
        //        this.form.dr_amount = ''
        //        this.form.cr_amount = ''
        //        this.form.coa_subhead = ''
        //        this.form.errors = []
        //    },
        handleOk(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
        },

        handleSubmit() {
            // Exit when the form isn't valid
            this.$validator.validateAll().then(result => {
                if (!result) {
                    return;
                }
                // console.log(this.data.id);
                // console.log(this.form);
                // this.isSaving = true;
                // this.$Progress.start();
                // this.form.errors = [];
                // console.log(this.form);
                // console.log(this.form);
                // console.log(this.form);
                // this.form.coa_subhead.map((index) => {
                //     console.log(index)
                // })
            //    console.log(this.form);

                this.$emit('addAccount', this.form);
                this.$bvModal.hide(String(this.data.id));
            });
            // Hide the modal manually

        }
    }
}
</script>