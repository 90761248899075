
<template>
    <transition name="fade-in-up">
    <router-view></router-view>
 </transition>
</template>

<script>
import SidebarView from '@/views/main/components/Sidebar.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import Footer from '@/views/main/components/Footer.vue'
export default {
 components: {
       SidebarView,
       TopbarView,
       Footer
   },
};
</script>