<template>
  <LineChartGenerator 
    id="lineChart"
    :data="chartData"
  />
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs'
import {
  Chart as ChartJS, Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
)


export default {
  name: 'DepositTrendLineChart',
  components: { LineChartGenerator },
  props: ['labels', 'data','name'],
  data() {
    return {
      chartData: {
        // labels: ["Jan", "Fed", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        labels: this.labels,
        datasets: [
          
          {
            label: this.name,
            // data: [455, 899, 415, 125, 875, 1258, 485, 693, 748, 857, 365, 1478],
            data: this.data,
            fill: false,
            borderColor: '#01a790',
            backgroundColor: '#01a790',
            borderWidth: 2,
          }
        ]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }],
          xAxes: [{
            gridLines: {
              display: false
            }
          }]
        },
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false,
      }
    }
  },
}
</script>
