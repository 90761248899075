<template>
    <!-- begin row  -->
    <div id="wrapper">
        <SidebarView :branch="true" :add-branch="true" :branch-visible="true" />
        <div class="d-flex flex-column" id="content-wrapper">
            <div id="content">
                <TopbarView />
                <b-container>
                    <b-row>
                        <div class="col-12">
                            <!-- begin loader -->
                            <beat-loader-component
                            v-if="isLoading"
                            :isLoading="isLoading"
                            ></beat-loader-component>
                            <!-- end loader -->
                        </div>
                        <div>
                        <b-tabs  justified>
                            <div class="card card-shape home-box">
                                    <div class="card-header py-3 d-flex flex-row align-items-center">
                                        <h6 class="m-0 fw-bold text-green">Edit Branch</h6>
                                    </div>
                                    <div class="card-body">
                                        <form @submit.prevent="updateBranch">
                                        <branch-form :form="form" :add_or_edit="add_or_edit" :options="options"></branch-form>
                                        <hr />
                                        <div class="text-center py-3">
                                            <button type="submit" :disabled="isSaving" class="btn akkurate-green-btn text-white btn-size my-2 me-2"><font-awesome-icon class="me-2" :icon="['fas', 'save']" /> Update</button>
                                            <button type="reset" class="btn akkurate-gray-btn text-white btn-size my-2 me-2"><font-awesome-icon class="me-2" :icon="['fas', 'rotate-right']" /> Reset</button>
                                         </div>
                                        </form>
                                    </div>
                                </div>
                             </b-tabs>
                        </div>
                    </b-row>
                </b-container>
              
            </div>
            <footer></footer>
        </div>
          <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
  </div>
  
    <!-- end row  -->
  </template>
  
<script>
import SidebarView from '@/views/main/components/Sidebar.vue';
// import Footer from '@/views/main/components/Footer.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import BranchForm from '../forms/BranchForm';
import ApiService from '@/core/services/api.service'
import BeatLoaderComponent from "@/views/components/Loader/BeatLoaderComponent.vue";

export default {
    components: {
        SidebarView,
        TopbarView,
        BeatLoaderComponent,
        BranchForm,
    },
    props: ["data"],
    // mounted() {
    //   this.getUserJson();
    // },
    data() {
        return {
            ...this.initialState(),
            isLoading: true,
            isSaving: false,
            add_or_edit: 'edit',
            id: this.$route.params.id,
            options: {
                user_options: [],
                region_options: [],
            },
        };
    },
    mounted() {
        this.getBranchDropDown();
        this.getBranchDetails();
    },
    methods: {
        async getBranchDropDown() {
            this.isLoading = true;
            await ApiService.get('/settings/branches/dropdown')
                .then((response) => {
                    this.isLoading = false;
                    this.options.user_options = response.data?.users;
                    this.options.region_options = response.data?.regions;
                }).catch((error) => {
                    this.isLoading = false;
                })
        },
        async getBranchDetails() {
            // this.isLoading = true;
            await ApiService.get(`/settings/branches/details/${this.id}`)
                .then((response) => {
                    console.log(response.data.branch);
                    this.form.name = response.data?.branch?.name;
                    this.form.address = response.data?.branch?.address;
                    this.form.region = response.data?.branch?.regionfk;
                    this.form.manager = response.data?.branch?.managerfk;
                    this.form.mobile = response.data?.branch?.mobile;
                    this.form.active = response.data?.branch?.status;
                    this.isLoading = false;
                }).catch((error) => {
                    this.isLoading = false;
                })
        },
        async updateBranch() {
            this.isSaving = true;
            this.$Progress.start();
            // console.log(this.form);
            await ApiService.put(`/settings/branches/update/${this.id}`, this.form)
                .then((response) => {
                    this.isLoadingSaving = false;
                    this.$Progress.finish();
                    toast.fire({
                        icon: "success",
                        title: response.data.message,
                    });
                    this.isSaving = false;
                    this.form = this.initialState().form;
                    window.location.href = '/branch';
                })
                .catch((error) => {
                    this.isSaving = false;
                    this.$Progress.fail();
                    if (error.response.data.errors) {
                        this.form.errors = error.response.data.errors;
                    }
                });
        },
        initialState() {
            return {
                form: {
                    name: "",// Required:
                    address: "",
                    region: {},
                    manager: {},
                    mobile: "",
                    active: "",
                    errors: [],
                },
            }

        }
    },

};
</script>
  
  <!-- New step!
       Add Multiselect CSS. Can be added as a static asset or inside a component. -->
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
  