<template>
    <div id="wrapper">
        <SidebarView :branch="true" :add-branch="true" :branch-visible="true" />
        <div class="d-flex flex-column" id="content-wrapper">
            <div id="content">
                <TopbarView />
                <b-container>
                    <b-row>
                        <div class="col-12">
                            <!-- begin loader -->
                            <beat-loader-component v-if="isLoading" :isLoading="isLoading"></beat-loader-component>
                            <!-- end loader -->
                        </div>
                        <div>
                            <b-tabs justified>
                                <div class="card card-shape home-box">
                                    <div class="card-header py-3 d-flex flex-row align-items-center">
                                        <h6 class="m-0 fw-bold text-green">Create New Branch</h6>
                                    </div>
                                    <div class="card-body">
                                        <form @submit.prevent="storeBranch">
                                            <branch-form :form="form" :options="options"></branch-form>
                                            <hr />
                                            <div class="text-center py-3">
                                                <SaveButton :isSaving="isSaving" />
                                                <button type="reset"
                                                    class="btn akkurate-gray-btn text-white btn-size my-2 ms-3">
                                                    <font-awesome-icon class="me-2" :icon="['fas', 'rotate-right']" />
                                                    Reset</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </b-tabs>
                        </div>
                    </b-row>
                </b-container>

            </div>
            <footer></footer>
        </div>

    </div>

</template>
<script>
// import BeatLoaderComponent from "@/views/components/Loader/BeatLoaderComponent.vue";

// import ApiService from "@/core/services/api.service";
import SidebarView from '@/views/main/components/Sidebar.vue';
import Footer from '@/views/main/components/Footer.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import BranchForm from '../forms/BranchForm';
import ApiService from '@/core/services/api.service'
import BeatLoaderComponent from "@/views/components/Loader/BeatLoaderComponent.vue";
import SaveButton from '@/views/main/components/SaveButton.vue';

export default {
    name: "CreateBranch",
    components: {
        SidebarView,
        Footer,
        TopbarView,
        BranchForm,
        BeatLoaderComponent,
        SaveButton
    },
    data() {
        return {
            ...this.initialState(),
            isLoading: false,

            options: {
                user_options: [],
                region_options: [],
            },
            isSaving: false,
        }
    },
    mounted() {
        this.getBranchDropDown();
    },
    methods: {
        async getBranchDropDown() {
            this.isLoading = true;
            await ApiService.get('/settings/branches/dropdown')
                .then((response) => {
                    this.isLoading = false;
                    this.options.user_options = response.data?.users;
                    this.options.region_options = response.data?.regions;
                }).catch((error) => {
                    this.isLoading = false;
                })
        },
        async storeBranch() {
            this.isSaving = true;
            this.$Progress.start();
            await ApiService.post("/settings/branches/store", this.form)
                .then((response) => {
                    this.isLoadingSaving = false;
                    this.$Progress.finish();

                    toast.fire({
                        icon: "success",
                        title: response.data.message,
                    });
                    this.isSaving = false;
                    // this.form.reset();
                    this.form = this.initialState().form;
                })
                .catch((error) => {
                    this.isSaving = false;
                    this.$Progress.fail();
                    if (error.response.data.errors) {
                        this.form.errors = error.response.data.errors;
                    }
                });
        },
        initialState() {
            return {
                form: {
                    region: [],
                    manager: [],
                    active: 1,
                    errors: [],
                },
            }

        }
    }
}

</script>