<template>
    <div id="wrapper">
      <SidebarView :reports="true" />
      <div class="d-flex flex-column" id="content-wrapper">
        <div id="content">
          <TopbarView />
          <div class="container-fluid">
            <div class="row mb-3">
              <!-- <div class="col">
                <label class="akkurate-dark small" for="branch">Branch</label>
                <b-form-group
                  id="branch"
                  label-for="branch"
                  class="akkurate-dark">
                  <b-form-input
                    id="branch"
                    class="mt-2 field-container fields"
                    type="text"
                    v-model="filter.branch"
                    placeholder="Branch">
                  </b-form-input>
                </b-form-group>
              </div> -->

              <div class="col">
                <label class="akkurate-dark small" for="start_date">Start Date</label>
                <b-form-group
                  id="start_date"
                  label-for="start_date"
                  class="akkurate-dark">
                  <b-form-input
                    id="start_date"
                    class="mt-2 field-container fields"
                    type="date"
                    v-model="filter.start_date"
                    placeholder="Start Date">
                  </b-form-input>
                </b-form-group>
              </div>
              <div class="col">
                <label class="akkurate-dark small" for="end_date">End Date</label>
                <b-form-group
                  id="end_date"
                  label-for="end_date"
                  class="akkurate-dark">
                  <b-form-input
                    id="end_date"
                    class="mt-2 field-container fields"
                    type="date"
                    v-model="filter.end_date"
                    placeholder="End Date">
                  </b-form-input>
                </b-form-group>
              </div>
              <div class="col mt-auto">
                <b-button @click="filterTable" :disabled="isSearching" class="w-100 akkurate-green-btn">{{ search }}</b-button>
              </div>

              <div class="col">
              </div>  
            </div>
            <div class="card card-shape home-box">
              <div class="card-header py-3">
                <div class="row align-items-center">
                  <div class="col-6">
                      <h6 class="m-0 fw-bold text-green">EXPECTED LOAN REPAYMENTS REPORT</h6>
                  </div>
                  <div class="col-6 text-end">
                    <b-form-select
                      v-model="selected_num"
                      @change="changePerPageNumber"
                      :options="numbers_options"
                    ></b-form-select>
                    <b-button @click="printPDF" class="me-2 ms-5 btn-sm akkurate-green-btn akkurate-small"><font-awesome-icon :icon="['fas', 'book']"/></b-button>
                    <b-button @click="downloadPDF" class="btn-sm akkurate-green-btn akkurate-small"><font-awesome-icon :icon="['fas', 'download']"/></b-button>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <!-- <div class="row">
                  <div class="col-md-12">
                    
                  </div>
                </div> -->
                <div class="row">
                  <div class="col-12 col-md-12">
                    <statement-institution
                    :institution="institution"
                    :current_date="false"
                    :title="'EXPECTED LOAN REPAYMENTS'"
                    ></statement-institution>
                  </div>
                  <hr/>
                </div>
                <div class="row">
                  <div class="col-9">

                  </div>
                  <div class="col-3" style="text-align: right;font-weight: bolder">
                    <span v-if="filter.start_date">
                      From {{ filter.start_date }} 
                    </span>
                    <span v-if="filter.end_date">
                    To {{ filter.end_date }}
                    </span>
                  </div>
                </div>
                <div class="row" ref="pdfContent">

                  <div class="col-12 col-md-12 trans_details">
                    <b-table
                      :items="items"
                      :fields="fields"
                      striped
                      responsive="sm"
                      :per-page="perPage"
                      :busy.sync="isBusy"
                      :current-page="currentPage"
                      small>
                      <template #table-busy>
                        <div class="text-center text-danger my-2">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong>Loading...</strong>
                        </div>
                      </template>
                      <template #cell(index)="data">
                        {{ ( (currentPage -1 ) * selected_num ) +   (data.index + 1) }}
                      </template>
                      <template #cell(trans_amount)="data">
                        {{displayNumber( data.item.trans_amount) }}
                      </template>
                      <template #cell(interest)="data">
                        {{displayNumber( data.item.interest) }}
                      </template>
                      <template #cell(principal)="data">
                        {{displayNumber( data.item.principal) }}
                      </template>
                      <template #cell(interest_balance)="data">
                        {{displayNumber( data.item.loan.interest_balance) }}
                      </template>
                      <template #cell(principal_balance)="data">
                        {{displayNumber( data.item.loan.principal_balance) }}
                      </template>
                      <template #cell(total_balance)="data">
                        {{displayNumber( data.item.interest_balance + data.item.principal_balance) }}
                      </template>
                      <template #cell(fullname)="data">
                        {{ data.item.loan.customer?.first_name + data.item.loan.customer?.last_name }}
                      </template>
                      <!-- <template #custom-foot>
                        <b-tr>
                          <b-th colspan="4"></b-th>
                          <b-th>Total</b-th>
                          <b-th>{{ displayNumber(total_trans_amount) }}</b-th>
                          <b-th>{{ displayNumber(sumPrincipalPaid) }}</b-th>
                          <b-th>{{ displayNumber(sumInterestPaid) }}</b-th>
                          <b-th></b-th>
                        </b-tr>
                      </template> -->
                    </b-table>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 text-end">
                    <b-pagination
                      class="text-end"
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                      
                    ></b-pagination>
                    <!-- <p class="mt-3">Current Page: {{ currentPage }}</p> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer></footer>
      </div>
      <a class="d-inline scroll-to-top" href="#page-top"
        ><i class="fas fa-angle-up"></i
      ></a>
    </div>
  </template>
  <script>
  import SidebarView from "@/views/main/components/Sidebar.vue";
  import TopbarView from "@/views/main/components/Topbar.vue";
  import Footer from "@/views/main/components/Footer.vue";
  import StatementInstitution from "@/views/main/components/institution/StatementInstitution.vue";
  import ApiService from "@/core/services/api.service";
  import Multiselect from "vue-multiselect";
  import html2pdf from "html2pdf.js";
  export default {
    components: {
      SidebarView,
      TopbarView,
      Footer,
      StatementInstitution,
      Multiselect,
    },
    data() {
      return {
        filter:{
          branch:"",
          start_date: "",
          end_date: "",
          year:"",
        },
        search:"Search",
        isBusy: false,
        isSearching:false,
        selected_num: 20,
        numbers_options: [10,20, 50, 100, 500],
        isLoadingInstitution: false,
        institution: "",
        fields: [
          {
            key: "index",
            label: "S/N",
          },
          {
            key: "loan_number",
            label: "Loan Number",
          },
          {
            key: "fullname",
            label: "Customer",
          },
          {
            key: "amount_due",
            label: "Amount Due",
          },
          {
            key: "due_date",
            label: "Due Date",
          },
          {
            key: "interest_balance",
            label: "Int. Bal.",
          },
          {
            key: "principal_balance",
            label: "Prin. Bal.",
          },
          {
            key: "loan.status",
            label: "Loan Status",
          },
          {
            key: "loan.delinquent",
            label: "Delinquency",
          },
          {
            key: "loan.last_payment",
            label: "Last Payment",
          },
         
        ],
        perPage: 20,
        currentPage: 1,
        items: [],
        sumPrincipalPaid: 0,
        sumInterestPaid: 0,
        sumPrincipalBalance: 0,
        sumInterestBalance: 0,
        sumTotalBalance: 0,
        total_trans_amount: 0,
      };
    },
    computed: {
      rows() {
        return this.items.length;
      },
    },
  
    created() {
      //set start date to 3 months ago
      var date = new Date();
      // var lastMonth = new Date(date.setMonth(date.getMonth() - 3));
      // var thisWeek = 
      this.filter.start_date = this.getStartOfWeek();
      this.filter.end_date = new Date().toISOString().split("T")[0];

      this.getInstitution();
      this.getRepayment();
    },
    methods: {
      async getRepayment() {
        this.items = [];
        this.isBusy = true;
        this.search = "Processing...";
        this.isSearching = true;
        await ApiService.post("/reports/loans/repayments/expected", this.filter)
          .then((response) => {
            this.isBusy = false;
            this.search = "Search"
            this.isSearching = false;
            var res = response.data?.data;
            // this.sumPrincipalPaid = response.data?.principal_paid_total
            
            
            // this.filter.start_date = response.data?.start_date
            // this.filter.end_date = response.data?.end_date
            // console.log(res);
            // res.map((index) => {
            //   const f_name  = index.customer?.first_name != undefined ? index.customer?.first_name : "";
            //   const l_name = index.customer?.last_name != undefined ? index.customer?.last_name : "";
            //   const data = {
            //     // account_number: index.account.account_number,
            //     customer_name: f_name + " " + l_name,
            //     loan_code: index.code,
            //     principal_paid: index.principal_paid,
            //     interest_paid: index.interest_paid,
            //     principal_balance: index.principal_balance,
            //     interest_balance: index.interest_balance,
            //     total_balance: index.principal_balance + index.interest_balance,
               
            //   };
            //   this.items.push(data);
            // });
            this.items = res
          })
          .catch((error) => {
            this.isBusy = false;
            this.search = "Search"
            this.isSearching = false;
            console.log(error);
          });
      },
      downloadPDF() {
        const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
        // console.log("here");
        const pdfOptions = {
          margin: [0.5, 0.2, 0.8, 0.2], //top, left, buttom, right
          filename: "ExpectedLoanRepayments.pdf",
          // filename: "ExpectedLoanRepayments"  + this.getFormattedDateTime() + ".pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          pagebreak: { mode: "avoid-all" },
          jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        };
        html2pdf()
          .from(content)
          .set(pdfOptions)
          .toPdf()
          .get("pdf")
          .then((pdf) => {
            var totalPages = pdf.internal.getNumberOfPages();
            var insit_name = this.institution.name;
            var insit_tele = this.institution.telephone;
            var insit_email = this.institution.email;
            var insit_motto = this.institution.motto;
  
            for (var i = 1; i <= totalPages; i++) {
              pdf.setPage(i);
              pdf.setFontSize(8);
              pdf.setTextColor(150);
              pdf.text(
                [
                  "For Inquiries, Please Contact " +
                    insit_name +
                    ", Customer Service on " +
                    insit_tele +
                    "",
                  "or email us on " + insit_email + " " + insit_motto + "",
                  "Page " + i + " of " + totalPages,
                ],
                pdf.internal.pageSize.getWidth() / 2,
                pdf.internal.pageSize.getHeight() - 0.6,
                { align: "center" }
              );
            }
          })
          .save();
      },
      printPDF() {
        const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
        // console.log("here");
        const pdfOptions = {
          margin: [0.5, 0.2, 0.8, 0.2], //top, left, buttom, right
          filename: "statement.pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          pagebreak: { mode: "avoid-all" },
          jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        };
        html2pdf()
          .from(content)
          .set(pdfOptions)
          .toPdf()
          .get("pdf")
          .then((pdf) => {
            var totalPages = pdf.internal.getNumberOfPages();
            var insit_name = this.institution.name;
            var insit_tele = this.institution.telephone;
            var insit_email = this.institution.email;
            var insit_motto = this.institution.motto;
  
            for (var i = 1; i <= totalPages; i++) {
              pdf.setPage(i);
              pdf.setFontSize(8);
              pdf.setTextColor(150);
              pdf.text(
                [
                  "For Inquiries, Please Contact " +
                    insit_name +
                    ", Customer Service on " +
                    insit_tele +
                    "",
                  "or email us on " + insit_email + " " + insit_motto + "",
                  "Page " + i + " of " + totalPages,
                ],
                pdf.internal.pageSize.getWidth() / 2,
                pdf.internal.pageSize.getHeight() - 0.6,
                { align: "center" }
              );
            }
            // Get the blob of the PDF
            return pdf.output("blob");
          })
          .then((pdfBlob) => {
            // Create a URL for the PDF blob
            var pdfUrl = URL.createObjectURL(pdfBlob);
  
            // Open the URL in a new tab
            const printWindow = window.open(
              pdfUrl,
              "_blank",
              "width=800,height=600"
            );
            printWindow.onload = () => {
              printWindow.print();
              URL.revokeObjectURL(pdfUrl);
            };
          })
          .catch((error) => {
            console.error("Error generating PDF:", error);
          });
      },
      filterTable() {
        this.getRepayment();
      },
      changePerPageNumber(data) {
        this.perPage = data;
        this.getRepayment();
      },
      async getInstitution() {
        this.isLoadingInstitution = true;
        await ApiService.get("/settings/institution")
          .then((response) => {
            this.institution = response.data.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.isLoadingInstitution = false;
          });
      },
    },
  };
  </script>