<template>
    <div class="row">
           <!-- <div class="col-12 col-md-6">
           <label class="akkurate-dark" for="beneficiary_name">Beneficiary Name <star></star></label>
               <b-form-group
               id="beneficiary_name"
               label-for="beneficiary_name">
               <b-form-input
               class="mb-3 mt-2 field-container fields"
               type="text"
               placeholder="Enter First Name"
                v-model="form.beneficiary_name"
               ></b-form-input>
               <errors v-if="form.errors.beneficiary_name">
                  {{ form.errors.beneficiary_name[0] }}
                </errors>
               </b-form-group>
           </div> -->
           <div class="col-12 col-md-6">
               <label class="akkurate-dark" for="middle_name">Branch <star></star></label>
               <b-form-group
               id="middle_name"
               label-for="middle_name">
               <multiselect 
               class="mt-2 field-container" 
               v-model="form.branch" 
               :options="branch_options"
               required
               label="name">
               </multiselect>
               <errors v-if="form.errors.middle_name">
                  {{ form.errors.middle_name[0] }}
                </errors>
               </b-form-group>
           </div>
           <div class="col-12 col-md-6">
               <label class="akkurate-dark" for="amount_requested">Amount Requested <star></star></label>
               <b-form-group
               id="amount_requested"
               label-for="amount_requested">
               <b-form-input
               class="mb-3 mt-2 field-container fields"
               type="number"
               step="any"
               placeholder="Enter Requested Amount"
               v-model="form.amount_requested"
               required
               ></b-form-input>
               <errors v-if="form.errors.amount_requested">
                  {{ form.errors.amount_requested[0] }}
                </errors>
               </b-form-group>
           </div>

           <div class="col-12 col-md-6">
               <label class="akkurate-dark" for="recorded_date">Recorded Date<star></star></label>
               <b-form-group
               id="recorded_date"
               label-for="recorded_date">
               <b-form-input
               class="mb-3 mt-2 field-container fields"
               type="date"
               v-model="form.recorded_date"
               required
               ></b-form-input>
               <errors v-if="form.errors.recorded_date">
                  {{ form.errors.recorded_date[0] }}
                </errors>
               </b-form-group>
           </div>

           

          
           <div class="col-12 col-md-6">
               <label class="akkurate-dark" for="narration">Narration <star></star></label>
               <b-form-group
               id="narration"
               label-for="narration">
               <b-form-textarea
                  class="mt-2 fields"
                  id="narration"
                  placeholder="Enter Narration..."
                  v-model="form.narration"
                ></b-form-textarea>
               <errors v-if="form.errors.narration">
                  {{ form.errors.narration[0] }}
                </errors>
               </b-form-group>
           </div>
        </div>
           
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
export default {
  name: "PersonalDetails",
  props: ['form','branch_options'],
  components: {
    Multiselect
  },
  // created(){
  //   console.log(this.branch_options);
  // }

}
</script>