<template>
    <div class="row">
           <div class="col-12 col-md-6 mb-3">
           <label class="akkurate-dark" for="name">Branch Name <star></star></label>
               <b-form-group
               id="name"
               label-for="name">
               <b-form-input
               class="mb-3 mt-2 field-container fields"
               type="text"
               v-model="form.name"
               placeholder="Enter Branch Name"
               required
               ></b-form-input>
               <errors v-if="form.errors.name">
                      {{ form.errors.name[0] }}
                </errors>
               </b-form-group>
           </div>
           <div class="col-12 col-md-6 mb-3">
               <label class="akkurate-dark" for="mobile">Mobile</label>
               <b-form-group
               id="mobile"
               label-for="mobile">
               <b-form-input
               class="mb-3 mt-2 field-container fields"
               type="text"
               v-model="form.mobile"
               placeholder="Enter Mobile Number"
               required
               ></b-form-input>
               <errors v-if="form.errors.mobile">
                      {{ form.errors.mobile[0] }}
                </errors>
               </b-form-group>
           </div>
           <div class="col-12 col-md-6 mb-3">
               <label class="akkurate-dark" for="region">Region <star></star></label>
               <b-form-group
               id="region"
               label-for="region">
               <multiselect class="mt-2 field-container" 
                v-model="form.region" 
                label="name" 
                :options="options.region_options">
               </multiselect>
               </b-form-group>
               <errors v-if="form.errors.region">
                      {{ form.errors.region[0] }}
                </errors>
           </div>
           <div class="col-12 col-md-6 mb-3">
               <label class="akkurate-dark" for="manager">Manager <star></star></label>
               <b-form-group
               id="manager"
               label-for="manager">
               <multiselect class="mt-2 field-container" 
               :custom-label="customLabel"
                v-model="form.manager" 
                label="name" 
                :options="options.user_options"></multiselect>
               </b-form-group>
               <errors v-if="form.errors.manager">
                      {{ form.errors.manager[0] }}
                </errors>
           </div>
           <div class="col-12 col-md-6 mb-3">
            <b-form-group
                label-for="address"
                class="mb-2"
                >
                <template #label>
                  <label class="akkurate-dark" for="address">Address <star></star></label>
                </template>
                <b-form-textarea
                  class="mt-2 fields"
                  id="address"
                  v-model="form.address"
                  placeholder="Enter Address..."
                ></b-form-textarea>
                <errors v-if="form.errors.address">
                      {{ form.errors.address[0] }}
                </errors>
            </b-form-group>
           </div>

           <div class="col-12 col-md-6">
               <b-form-group>
                   <template #label>
                       <label class="akkurate-dark" for="status">Status <star></star></label>
                   </template>
                   <b-form-radio v-model="form.active" name="active" value="1">Active</b-form-radio>
                   <b-form-radio v-model="form.active" name="active" value="0">Inactive</b-form-radio>
                   <errors v-if="form.errors.status">
                      {{ form.errors.status[0] }}
                    </errors>
               </b-form-group>
           </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
export default {
    name: "BranchForm",
    props: ['form', 'options'],
    components: {
        Multiselect,
    },
    methods: {
        customLabel({ first_name, surname }) {
            return `${first_name}  ${surname}`
        }
    }
}
</script>