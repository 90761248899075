<template>
    <div id="wrapper">
      <SidebarView :reports="true" />
      <div class="d-flex flex-column" id="content-wrapper">
        <div id="content">
          <TopbarView />
          <div class="container">
            <div class="card card-shape home-box">
              <div class="card-header py-3">    
                <div class="row align-items-center">
                  <div class="col-6">
                      <h6 class="m-0 fw-bold text-green">User Coa Transaction Tracker Report</h6>
                  </div>
                  <div class="col-6 text-end">
                    <b-button @click="generateExcel" class="btn-sm me-2 akkurate-green-btn akkurate-small">
                      <font-awesome-icon :icon="['fas', 'file-excel']"/>
                    </b-button>
                    <b-button @click="printPDF" class="me-2 btn-sm akkurate-green-btn akkurate-small"><font-awesome-icon :icon="['fas', 'book']"/></b-button>
                    <b-button @click="downloadPDF" class="btn-sm akkurate-green-btn akkurate-small"><font-awesome-icon :icon="['fas', 'download']"/></b-button>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row" ref="pdfContent">
                  <div class="col-12 col-md-12">
                    <statement-institution
                      :institution="institution"
                      :current_date="false"
                      :title="'USER COA TRACKER REPORT'"
                    ></statement-institution>
                  </div>
                  <hr/>
                </div>
                <div class="row">
                  <div class="col-md-8"></div>
                  <div class="col-md-4 text-end">
                    <span style="font-weight: bold" v-if="start_date && end_date">From {{ start_date }} to {{ end_date }}</span>
                  </div>
                </div>
                <div v-if="isBusy" class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                </div>
                <div class="row">
                <div class="col-12 col-md-12 trans_details">
                  <b-table
                    id="chart-of-account"
                    :items="items"
                    :fields="fields"
                    striped
                    responsive="sm"
                    :per-page="perPage"
                    :busy.sync="isBusy"
                    :current-page="currentPage"
                    small
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                      </div>
                    </template>
                    <!-- <template #custom-foot>
                      <b-tr>
                        <b-th colspan="2"></b-th>
                        <b-th>TOTAL</b-th>
                        <b-th>{{ getDrTotal }}</b-th>
                        <b-th>{{ getCrTotal }}</b-th>
                        <b-th></b-th>
                        <b-th>{{ getTotal }}</b-th>
                      </b-tr>
                    </template> -->
                  </b-table>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 text-end">
                  <b-pagination
                    class="text-end"
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    aria-controls="daily-control-tb"
                    text-align="right"
                  >
                  </b-pagination>
                  <!-- <p class="mt-3">Current Page: {{ currentPage }}</p> -->
                </div>
              </div>
                    
  
              </div>
            </div>
          </div>
        </div>
        <footer></footer>
      </div>
      <a class="d-inline scroll-to-top" href="#page-top"
        ><i class="fas fa-angle-up"></i
      ></a>
    </div>
  </template>
  <script>
  import SidebarView from "@/views/main/components/Sidebar.vue";
  import TopbarView from "@/views/main/components/Topbar.vue";
  import Footer from "@/views/main/components/Footer.vue";
  import StatementInstitution from "@/views/main/components/institution/StatementInstitution.vue";
  import ApiService from "@/core/services/api.service";
  import Multiselect from "vue-multiselect";
  import html2pdf from "html2pdf.js";
  export default {
    components: {
      SidebarView,
      TopbarView,
      Footer,
      StatementInstitution,
      Multiselect,
    },
    data() {
      return {
        form: {
          start_date: this.getBeginningOfYear(),
          end_date: new Date().toISOString().substr(0, 10),
          branches: [],
          products: [],
          branch_options: [],
          product_options: [],
          users: [],
          users_options: [],
          status: [],
          coa: [],
          coa_options: [],
          terms: "Periodic",
          terms_options: ["YTD", "Periodic"],
          categories: [],
        },
        filter: {
          branches: true,
          products: true,
          users: true,
          status: true,
          condition: true,
          terms: false,
          coa: false,
          voucher_status: false
        },
        search: "Search",
        isBusy: false,
        isSearching: false,
        selected_num: "10",
        numbers_options: [10, 50, 100, 500],
        isLoadingInstitution: false,
        institution: "",
        start_date: "",
        end_date: "",
        fields: [
          {
            key: "glcode",
            label: "Code",
          },

          {
            key: "name",
            label: "Name",
          },
          {
            key: "total_debit",
            label: "Total Debit",
          },
          {
            key: "total_credit",
            label: "Total Credit",
          },
          {
            key: "total_balance",
            label: "Total Balance",
          },
          {
            key: "number_of_business",
            label: "Number of business",
          },
          {
            key: "today",
            label: "Today",
          },



        ],
        perPage: 20,
        currentPage: 1,
        items: [],
        // serverParams: {
        //   // a map of column filters example: {name: 'john', age: '20'}
        //   columnFilters: {
        //   },
        //   sort: [
        //     {
        //       field: '', // example: 'name'
        //       type: '' // 'asc' or 'desc'
        //     }
        //   ],
        //   page: 1, // what page I want to show
        //   perPage: 20 // how many items I'm showing per page
        // },
      };
    },
    computed: {
      rows() {
        return this.items.length;
      },
      
      // sumLoanBalance() {
      //   let total = 0;
      //   for (let i = 0; i < this.items.length; i++) {
      //     total = this.items[i].loan_balance;
      //   }
      //   return total;
      // },
      // sumSavingBalance() {
      //   let total = 0;
      //   for (let i = 0; i < this.items.length; i++) {
      //     total = this.items[i].saving_balance;
      //   }

      //   return total;
      // },
    },

    created() {
      this.getInstitution();
      this.getCoaTransction();
      // this.getVoucherListing();
      // this.getProductDropdown();
    },
    methods: {
      async getProductDropdown() {
        this.isBusy = true;
        this.search = "Processing...";
        await ApiService.query("/reports/controls/products/dropdown")
          .then((response) => {
            this.isBusy = false;
            this.search = "Search";
            console.log(response.data.branches)
            this.form.branch_options = response.data.branches;
            // this.form.product_options = response.data.products;
            // this.form.coa_options = response.data.coa_subheads;
          })
          .catch((error) => {
            // console.log(error);
            this.isBusy = false;
            this.search = "Search";
          });
      },
      async getCoaTransction() {
        this.items = [];
        this.isBusy = true;
        this.search = "Processing...";
        this.isSearching = true;
        await ApiService.get("/reports/controls/coa-user-tracker", )
          .then((response) => {
            this.isBusy = false;
            this.search = "Search"
            this.isSearching = false;
            var res = response.data;
            this.items = res
           
          })
          .catch((error) => {
            this.isBusy = false;
            this.search = "Search"
            this.isSearching = false;
            console.log(error);
          });
      },
      
      downloadPDF() {
        const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
        // console.log("here");
        const pdfOptions = {
          margin: [0.5, 0.2, 0.8, 0.2], //top, left, buttom, right
          filename: "statement.pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          pagebreak: { mode: "avoid-all" },
          jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        };
        html2pdf()
          .from(content)
          .set(pdfOptions)
          .toPdf()
          .get("pdf")
          .then((pdf) => {
            var totalPages = pdf.internal.getNumberOfPages();
            var insit_name = this.institution.name;
            var insit_tele = this.institution.telephone;
            var insit_email = this.institution.email;
            var insit_motto = this.institution.motto;

            for (var i = 1; i <= totalPages; i++) {
              pdf.setPage(i);
              pdf.setFontSize(8);
              pdf.setTextColor(150);
              pdf.text(
                [
                  "For Inquiries, Please Contact " +
                  insit_name +
                  ", Customer Service on " +
                  insit_tele +
                  "",
                  "or email us on " + insit_email + " " + insit_motto + "",
                  "Page " + i + " of " + totalPages,
                ],
                pdf.internal.pageSize.getWidth() / 2,
                pdf.internal.pageSize.getHeight() - 0.6,
                { align: "center" }
              );
            }
          })
          .save();
      },
      printPDF() {
        const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
        // console.log("here");
        const pdfOptions = {
          margin: [0.5, 0.2, 0.8, 0.2], //top, left, buttom, right
          filename: "statement.pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          pagebreak: { mode: "avoid-all" },
          jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        };
        html2pdf()
          .from(content)
          .set(pdfOptions)
          .toPdf()
          .get("pdf")
          .then((pdf) => {
            var totalPages = pdf.internal.getNumberOfPages();
            var insit_name = this.institution.name;
            var insit_tele = this.institution.telephone;
            var insit_email = this.institution.email;
            var insit_motto = this.institution.motto;

            for (var i = 1; i <= totalPages; i++) {
              pdf.setPage(i);
              pdf.setFontSize(8);
              pdf.setTextColor(150);
              pdf.text(
                [
                  "For Inquiries, Please Contact " +
                  insit_name +
                  ", Customer Service on " +
                  insit_tele +
                  "",
                  "or email us on " + insit_email + " " + insit_motto + "",
                  "Page " + i + " of " + totalPages,
                ],
                pdf.internal.pageSize.getWidth() / 2,
                pdf.internal.pageSize.getHeight() - 0.6,
                { align: "center" }
              );
            }
            // Get the blob of the PDF
            return pdf.output("blob");
          })
          .then((pdfBlob) => {
            // Create a URL for the PDF blob
            var pdfUrl = URL.createObjectURL(pdfBlob);

            // Open the URL in a new tab
            const printWindow = window.open(
              pdfUrl,
              "_blank",
              "width=800,height=600"
            );
            printWindow.onload = () => {
              printWindow.print();
              URL.revokeObjectURL(pdfUrl);
            };
          })
          .catch((error) => {
            console.error("Error generating PDF:", error);
          });
      },
      filterTable() {
        this.getReceiptListing();
      },
      changePerPageNumber(data) {
        this.perPage = data;
        this.getReceiptListing();
      },
      async getInstitution() {
        this.isLoadingInstitution = true;
        await ApiService.get("/settings/institution")
          .then((response) => {
            this.institution = response.data.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.isLoadingInstitution = false;
          });
      },
      async generateExcel() {
        const fileUrl = '/REPORT_TEMPLATE.xlsx'; // Path to the file in the public folder

        try {
          // Fetch the Excel file from the public URL
          const response = await fetch(fileUrl);
          if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
          }
          const arrayBuffer = await response.arrayBuffer();

          // Load the file into ExcelJS
          const wb = new ExcelJS.Workbook();
          await wb.xlsx.load(arrayBuffer);

          // Access the first sheet
          const ws = wb.getWorksheet(1); // Get the first worksheet

          // Define header row
          const header = [
            "Code",
            "Name",
            "Total Debit",
            "Total Credit",
            "Total Balance",
            "Number of business",
            "Today",
          ];

          // Add header row starting from row 13
          const startingRow = 13;
          let headerRow = ws.getRow(startingRow);
          headerRow.values = header;
          headerRow.font = { bold: true };
          headerRow.commit(); // Commit the row to the worksheet

          // Add individual data rows
          let currentRow = startingRow + 1;

          // Process each item
          this.items.forEach(item => {
            const row = ws.getRow(currentRow);
            // Map item values to the corresponding header columns
            row.values = [
              item.glcode,
              item.name,
              item.total_debit,
              item.total_credit,
              item.total_balance,
              item.number_of_business,
              item.today,

            ];
            row.commit();
            currentRow++;
          });

          const buffer = await wb.xlsx.writeBuffer();
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

          // Create a download link and simulate a click to download the file
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = 'Modified_REPORT_TEMPLATE.xlsx';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (err) {
          console.error('Error fetching, modifying, or downloading the Excel file:', err.message);
        }
      }
    },
  };
</script>