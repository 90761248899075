<template>
     <div class="col-md-6 d-md-flex justify-content-md-center align-items-md-center mobile-form-padding">
                <div class="col-md-9 akkurate-box" >
                    <div class="mb-4">
                        <h2 class="mb-4 akkurate-dark fw-bold" style="display:flex;justify-content: center;">
                            <!-- <font-awesome-icon :icon="['fas', 'layer-group']" />  -->
                            <img height="40px" src="../../../assets/img/akkurate_logo.png" />
                            AKKURATEBanc</h2>
                        <p class="akkurate-light-dark" style="display:flex;justify-content: center;">Welcome</p>
                    </div>
                    <b-form v-on:submit="onSubmit" v-on:reset="onReset">
                        <b-alert
                        class="mt-4"
                        variant="danger"
                        fade
                        :show="showDismissibleAlert">
                        {{ error_msg }}
                        </b-alert>
                    <b-form-group
                        id="username-group"
                        label="Username"
                        label-for="input-1"
                        class="akkurate-dark">
                        <b-input-group class=" mb-3 mt-2 field-container">
                            <!-- <template #append>
                                <b-input-group-text class="field-container field-addon">
                                    <strong>
                                        <font-awesome-icon :icon="['fas', 'user-secret']" class="akkurate-dark" />
                                    </strong>
                                </b-input-group-text>
                            </template> -->
                        <b-form-input v-model="form.username" class="field-main" required>
                        </b-form-input>
                        <font-awesome-icon :icon="['fas', 'user-secret']" class="field-icon akkurate-dark position-absolute top-50 end-0 translate-middle-y me-3" />
                        </b-input-group>
                        <errors v-if="form.errors.username">
                            {{ form.errors.username[0] }}
                        </errors>
                    </b-form-group>
                    <b-form-group
                        id="password-group"
                        label="Password"
                        label-for="password"
                        class="akkurate-dark"
                        >
                        <b-input-group class="mt-2 mb-4 field-container">
                            <!-- <template #append>
                                <b-input-group-text class="field-container field-addon"><strong><a v-on:click="passwordView" type="button">
                                    <font-awesome-icon :icon="['fas', 'eye']" class="awesome-eye akkurate-dark" /></a></strong>
                                </b-input-group-text>
                            </template> -->
                            <b-form-input v-model="form.password" id="password" class="field-main" type="password" required></b-form-input>
                            <a v-on:click="passwordView" type="button"> <font-awesome-icon :icon="['fas', 'eye']" class="field-icon awesome-eye akkurate-dark position-absolute top-50 end-0 translate-middle-y me-3" /></a>
                            <!-- <font-awesome-icon :icon="['fas', 'user-secret']" class="field-icon akkurate-dark position-absolute top-50 end-0 translate-middle-y me-3" /> -->
                        </b-input-group>
                        <errors v-if="form.errors.password">
                            {{ form.errors.password[0] }}
                        </errors>
                    </b-form-group>
                       
                        <!-- <div class="d-flex align-items-center mb-5">
                            <b-form-group id="remember">
                                <b-form-checkbox-group
                                    id="remember"
                                    aria-describedby="ariaDescribedby"
                                    >
                                    <b-form-checkbox value="me"><span class="ms-1">Remember me</span></b-form-checkbox>
                                </b-form-checkbox-group>
                            </b-form-group>
                            <a class="ms-auto text-decoration-none akkurate-link" href="/forget">Forget Password</a>
                        </div> -->
                        <!-- <div class="register">
                            <p>Don't have an account? <a class="text-decoration-none akkurate-link" href="/register">Register </a></p>
                        </div> -->
                        <div class="d-grid gap-2">
                            <b-button class="fw-semibold akkurate-green-btn akkurate-auth-size" :disabled="is_processing" type="submit" variant="primary">
                                <b-spinner class="d-none process-spin" small></b-spinner>
                                <span class="loading">Login</span>
                             </b-button>
                        </div>
                    </b-form>
                </div>
            </div>
</template>
<script>
// import axios from 'axios';
import ApiService from "@/core/services/api.service";
import TitleMixin from '@/mixins/TitleMixin';
export default {
    name: 'LoginForm',
    mixins: [TitleMixin],
    data() {
        return this.initialState();
    },
    methods: {
        passwordView() {
            if (this.$el.querySelector("#password").type == 'password') {
                this.$el.querySelector("#password").type = 'text'
                this.$el.querySelector(".awesome-eye").style.color = 'rgb(1,134,115)'
            } else {
                this.$el.querySelector("#password").type = 'password'
                this.$el.querySelector(".awesome-eye").style.color = '#212529'
            }
        },
        onSubmit(event) {
            event.preventDefault();
            this.doLogin();
        },
        onReset(event) {

        },
        async doLogin() {
            this.is_processing = true;
            this.$Progress.start();
            this.$el.querySelector(".process-spin").classList.remove('d-none');
            this.$el.querySelector('.loading').textContent = ' processing...';
            let result = await ApiService.post("auth/login", {
                'username': this.form.username,
                'password': this.form.password
            })
                .then(message => {
                    this.is_processing = false;
                    this.$Progress.finish();
                    this.$el.querySelector(".process-spin").classList.add('d-none');
                    this.$el.querySelector('.loading').textContent = 'Login';
                    return message;
                }).catch(error => {
                    console.log(error);
                    // this.$el.querySelector(".process-spin").classList.add('d-none');
                    this.$Progress.fail();
                    this.is_processing = false;
                    this.$el.querySelector(".process-spin").classList.add('d-none');
                    this.$el.querySelector('.loading').textContent = 'Login';
                    if (error.response?.data?.errors) {
                        this.form.errors = error.response?.data?.errors;
                    }
                    return error;
                });
            if (result.status == 200 && Object.keys(result.data).length > 0) {
                localStorage.setItem("token", result.data.token);
                this.$router.push({ path: '/' });
            }
        },
        initialState() {
            return {
                form: {
                    username: '',
                    password: '',
                    errors: [],
                },
                showDismissibleAlert: false,
                error_msg: '',
                is_processing: false,
            };
        },
        reset() {
            Object.assign(this.$data, this.initialState());
        },
    },
    mounted() {
        let token = localStorage.getItem('token');
        if (token) {
            this.$router.push({ path: '/' });
        }
    },


}

</script>