<template>
  <div>

    <div id="wrapper">
        <SidebarView />
        <div class="d-flex flex-column" id="content-wrapper">
            <div id="content">
                <TopbarView />

                <div class="container-fluid">

                    <member-view :id="id"></member-view>
                </div>

                   <!-- end row  -->
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import MemberView from './MemberView.vue';
import SidebarView from '@/views/main/components/Sidebar.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
export default {
    components: {
        MemberView,
        SidebarView,
        TopbarView,
    },
    data() {
        return {
            id: this.$route.params.id,
        }
    }
}
</script>

<style>

</style>