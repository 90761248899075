<template>
    <div class="container">
       <div class="row vh-100">
           <div class="col-md-6 d-md-flex align-items-md-center">
               <img class="img-fluid img-visibility" src="../../assets/img/2fa.jpg">
           </div>
          <TwofaForm/>
       </div>
   </div>
</template>
<script>
import TwofaForm from './Forms/TwofaForm.vue';

export default{
   name : 'TwofaView',
   components : {
    TwofaForm
   }
}
</script>