<template>
    <div>
      <b-modal ref="showDirectDebitRepayment" size="xl" title="DIRECT MOMO DEBIT TRANSACTION">
        <template #modal-title>
                <div class="fw-bold akkurate-green">Direct MoMo Transactions</div>
            </template>
        <div class="container-fluid" ref="pdfContent">
            <!-- First Receipt -->
            <div style="border-left: 5px solid #039a67; border-bottom: 5px solid #039a67;">
                    <institution :institution="reportData?.institution" :title="'DIRECT MOMO TRANSACTION RECEIPT'"
                        :report_style="'deposit'"></institution>
                    <div class="row ps-0">
                        <div class="col-md-12 col-12 text-center mt-2">
                            <p class="fw-bold report-l-text">DIRECT MOMO REPAYMENT SLIP</p>
                        </div>
                    </div>
                    <div class="row ms-0 ps-2">
                        <div class="col-md-6">
                            <p class="mb-0 report-l-text text-uppercase"> BRANCH: <span class="fw-bold">{{
                                reportData?.branch_name
                                    }}</span></p>
                        </div>
                        <div class="col-md-6">
                            <p class="mb-0 report-l-text"> RECEIPT NUMBER: <span class="fw-bold">{{
                                reportData?.receipt_no
                                    }}</span></p>
                        </div>
                    </div>

                    <div class="row ms-0 ps-0">
                        <div class="col-md-6">
                            <div class="row pt-3">
                                <div class="col-md-5">
                                    <p class="report-s-text fw-bold mb-2">TRANSACTION DATE</p>
                                </div>
                                <div class="col-md-7 p-0">
                                    <p class="report-s-text mb-2">{{ reportData?.trans_date }}</p>
                                </div>
                                <div class="col-md-5">
                                    <p class="report-s-text fw-bold mb-2">TELLER NAME</p>
                                </div>
                                <div class="col-md-7 p-0">
                                    <p class="report-s-text mb-2 text-uppercase">{{ reportData?.teller }}</p>
                                </div>
                                <div class="col-md-5">
                                    <p class="report-s-text fw-bold mb-2">ACCOUNT NUMBER</p>
                                </div>
                                <div class="col-md-7 p-0">
                                    <p class="report-s-text mb-2">{{ reportData?.account_number }}</p>
                                </div>
                                <div class="col-md-5">
                                    <p class="report-s-text fw-bold mb-2">LOAN NUMBER</p>
                                </div>
                                <div class="col-md-7 p-0">
                                    <p class="report-s-text mb-2">{{ reportData?.loan_number }}</p>
                                </div>
                                <div class="col-md-5">
                                    <p class="report-s-text fw-bold mb-2">ACCOUNT NAME</p>
                                </div>
                                <div class="col-md-7 p-0">
                                    <p class="report-s-text mb-2 text-uppercase">{{ reportData?.fullname }}</p>
                                </div>
                                <div class="col-md-5">
                                    <p class="report-s-text fw-bold mb-2">DESCRIPTION</p>
                                </div>
                                <div class="col-md-7 p-0">
                                    <p class="report-s-text mb-2 text-uppercase">{{ reportData?.transaction_mode }} {{
                                        reportData?.transaction_type }} By Self</p>
                                </div>
                                <div class="col-md-5">
                                    <p class="report-s-text fw-bold mb-2">TOTAL AMOUNT</p>
                                </div>
                                <div class="col-md-7 p-0">
                                    <p class="report-s-text mb-2">{{ reportData?.institution?.currency_code }} {{
                                        displayNumber(reportData?.amount) }}</p>
                                </div>
                                <div class="col-md-5">
                                    <p class="report-s-text fw-bold mb-2">AMOUNT IN WORDS</p>
                                </div>
                                <div class="col-md-7 p-0">
                                    <p class="report-s-text mb-2 text-uppercase">{{
                                        convertMoneyToWords(reportData?.amount)
                                    }} GHANA
                                        CEDIS</p>
                                </div>

                            </div>
                        </div>
                        <div class="col-md-6" style="background-color:#cceae0">
                            <div class="row pt-3 px-3">
                                <div class="col-md-12 p-0">
                                    <p class="report-s-text akkurate-green fw-bold mb-2">DIRECT MOMO DETAILS</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-green mb-2">TRANSACTION ID</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-green mb-2">{{ reportData?.transaction_id }}</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-green mb-2">MOBILE NUMBER</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2 akkurate-green text-uppercase">{{
                                        reportData?.mobile }}</p>
                                </div>


                                <div class="col-md-6 p-0 mt-5">
                                    <p class="report-s-text fw-bold">GRAND TOTAL </p>
                                </div>
                                <div class="col-md-6 p-0 mt-5">
                                    <p class="report-s-text fw-bold">{{ reportData?.institution?.currency_code }} {{
                                        displayNumber(reportData?.amount) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-0">
                        <div class="col-md-12 pe-0">
                            <div class="deposit-line"></div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 mt-4">
                            <p class="text-center fw-bold report-s-text">TELLER
                                SIGN...................................................</p>
                        </div>
                        <div class="col-md-6 mt-4">
                            <p class="text-center fw-bold report-s-text">CUSTOMER
                                SIGN...................................................</p>
                        </div>
                        <div class="col-md-12">
                            <p class="text-center report-s-text"><span class="fw-bold">All Corresponding regarding this
                                    receipt
                                    should be communicated to our customer care line on</span> {{
                                        reportData?.institution?.telephone }} or email at {{ reportData?.institution?.email }}
                            </p>
                        </div>
                    </div>

                </div>
        
            <hr style="border-top: dotted 1px;" class="my-4"/>
             
             <!-- Second Receipt -->
             <div style="border-left: 5px solid #039a67; border-bottom: 5px solid #039a67;">
                    <institution :institution="reportData?.institution" :title="'DIRECT MOMO TRANSACTION RECEIPT'"
                        :report_style="'deposit'"></institution>
                    <div class="row ps-0">
                        <div class="col-md-12 col-12 text-center mt-2">
                            <p class="fw-bold report-l-text">DIRECT MOMO REPAYMENT SLIP</p>
                        </div>
                    </div>
                    <div class="row ms-0 ps-2">
                        <div class="col-md-6">
                            <p class="mb-0 report-l-text text-uppercase"> BRANCH: <span class="fw-bold">{{
                                reportData?.branch_name
                                    }}</span></p>
                        </div>
                        <div class="col-md-6">
                            <p class="mb-0 report-l-text"> RECEIPT NUMBER: <span class="fw-bold">{{
                                reportData?.receipt_no
                                    }}</span></p>
                        </div>
                    </div>

                    <div class="row ms-0 ps-0">
                        <div class="col-md-6">
                            <div class="row pt-3">
                                <div class="col-md-5">
                                    <p class="report-s-text fw-bold mb-2">TRANSACTION DATE</p>
                                </div>
                                <div class="col-md-7 p-0">
                                    <p class="report-s-text mb-2">{{ reportData?.trans_date }}</p>
                                </div>
                                <div class="col-md-5">
                                    <p class="report-s-text fw-bold mb-2">TELLER NAME</p>
                                </div>
                                <div class="col-md-7 p-0">
                                    <p class="report-s-text mb-2 text-uppercase">{{ reportData?.teller }}</p>
                                </div>
                                <div class="col-md-5">
                                    <p class="report-s-text fw-bold mb-2">ACCOUNT NUMBER</p>
                                </div>
                                <div class="col-md-7 p-0">
                                    <p class="report-s-text mb-2">{{ reportData?.account_number }}</p>
                                </div>
                                <div class="col-md-5">
                                    <p class="report-s-text fw-bold mb-2">LOAN NUMBER</p>
                                </div>
                                <div class="col-md-7 p-0">
                                    <p class="report-s-text mb-2">{{ reportData?.loan_number }}</p>
                                </div>
                                <div class="col-md-5">
                                    <p class="report-s-text fw-bold mb-2">ACCOUNT NAME</p>
                                </div>
                                <div class="col-md-7 p-0">
                                    <p class="report-s-text mb-2 text-uppercase">{{ reportData?.fullname }}</p>
                                </div>
                                <div class="col-md-5">
                                    <p class="report-s-text fw-bold mb-2">DESCRIPTION</p>
                                </div>
                                <div class="col-md-7 p-0">
                                    <p class="report-s-text mb-2 text-uppercase">{{ reportData?.transaction_mode }} {{
                                        reportData?.transaction_type }} By Self</p>
                                </div>
                                <div class="col-md-5">
                                    <p class="report-s-text fw-bold mb-2">TOTAL AMOUNT</p>
                                </div>
                                <div class="col-md-7 p-0">
                                    <p class="report-s-text mb-2">{{ reportData?.institution?.currency_code }} {{
                                        displayNumber(reportData?.amount) }}</p>
                                </div>
                                <div class="col-md-5">
                                    <p class="report-s-text fw-bold mb-2">AMOUNT IN WORDS</p>
                                </div>
                                <div class="col-md-7 p-0">
                                    <p class="report-s-text mb-2 text-uppercase">{{
                                        convertMoneyToWords(reportData?.amount)
                                    }} GHANA
                                        CEDIS</p>
                                </div>

                            </div>
                        </div>
                        <div class="col-md-6" style="background-color:#cceae0">
                            <div class="row pt-3 px-3">
                                <div class="col-md-12 p-0">
                                    <p class="report-s-text akkurate-green fw-bold mb-2">DIRECT MOMO DETAILS</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-green mb-2">TRANSACTION ID</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-green mb-2">{{ reportData?.transaction_id }}</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-green mb-2">MOBILE NUMBER</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2 akkurate-green text-uppercase">{{
                                        reportData?.mobile }}</p>
                                </div>


                                <div class="col-md-6 p-0 mt-5">
                                    <p class="report-s-text fw-bold">GRAND TOTAL </p>
                                </div>
                                <div class="col-md-6 p-0 mt-5">
                                    <p class="report-s-text fw-bold">{{ reportData?.institution?.currency_code }} {{
                                        displayNumber(reportData?.amount) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-0">
                        <div class="col-md-12 pe-0">
                            <div class="deposit-line"></div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 mt-4">
                            <p class="text-center fw-bold report-s-text">TELLER
                                SIGN...................................................</p>
                        </div>
                        <div class="col-md-6 mt-4">
                            <p class="text-center fw-bold report-s-text">CUSTOMER
                                SIGN...................................................</p>
                        </div>
                        <div class="col-md-12">
                            <p class="text-center report-s-text"><span class="fw-bold">All Corresponding regarding this
                                    receipt
                                    should be communicated to our customer care line on</span> {{
                                        reportData?.institution?.telephone }} or email at {{ reportData?.institution?.email }}
                            </p>
                        </div>
                    </div>

                </div>
  
            </div>
          <template #modal-footer="{ ok }">
                 <div class="w-100">
                 <!-- begin loader -->
                 <!-- <beat-loader-component 
                 class="float-left"
                 v-if="isLoading"
                 :isLoading="isLoading"
                 ></beat-loader-component> -->
                 <!-- end loader -->
                 <!-- Emulate built in modal footer ok and cancel button actions -->
                 <b-button @click="generatePDF" type="button" class="akkurate-green-btn download-btn ms-2 float-right"><span class="small"><font-awesome-icon :icon="['fas', 'book']" /> Print</span></b-button>
                 <b-button @click="exportToPDF" class="akkurate-green-btn download-btn float-right"><span class="small"><font-awesome-icon :icon="['fas', 'save']" /> Download</span></b-button>     
                 </div>
          </template>     
      </b-modal>
     
  </div>
  </template>
<script>
import html2pdf from "html2pdf.js";
import Institution from '@/views/main/components/institution/Institution.vue'
export default {
    props: ['reportData'],
    components:{
        Institution
    },
    methods: {
        showModal() {
            this.$refs['showDirectDebitRepayment'].show()
        },
        hideModal() {
            this.$refs['showDirectDebitRepayment'].hide()
        },
        convertNumberToWords(number) {
            // Define an array of words for numbers from 0 to 19
            const words = [
                'Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine',
                'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'
            ];

            // Function to convert a number less than 100 to words
            function convertLessThanHundred(num) {
                if (num < 20) return words[num];
                const ten = Math.floor(num / 10);
                // console.log("Ten "+ ten);
                const one = Math.floor(num) % 10;
                const coin = num % 1;

                const coin_val = Math.floor(coin.toFixed(2) * 100)
                // console.log("One "+ coin_val);

                const coin_ten = Math.floor(coin_val / 10);
                const coin_one = Math.floor(coin_val % 10);

                return words[ten] + (one ? ' ' + words[one] : '') + ' ' + words[coin_ten] + (coin_one ? '-' + words[coin_one] + ' Peeswes' : '');
            }

            // Function to convert a number to words
            function convertToWords(num) {
                let value = Math.floor(num.toFixed(2)) * 1;

                if (value === 0) return 'Zero';

                let result = '';



                if (value >= 1000) {
                    // console.log(value);
                    result += convertToWords(Math.floor(value / 1000)) + ' Thousand ';
                    value %= 1000;
                }

                if (value >= 100) {
                    result += words[Math.floor(value / 100)] + ' Hundred ';
                    // console.log('Nuber ' + value);
                    value %= 100;
                }

                if (value > 0) {
                    if (result !== '') result += 'and ';
                    result += convertLessThanHundred(value);
                    // console.log(num.toFixed(2));
                }
                // console.log(result);

                return result;
                //    console.log(convertLessThanHundred(number))

            }
            return convertToWords(number);

        },
        convertNumberToCurrency(number) {
            const formattedCurrency = number.toLocaleString('en-US', {
                style: 'currency',
                currency: 'GHS' // Change this to your desired currency code (e.g., 'EUR', 'GBP', 'JPY', etc.)
            });
            return formattedCurrency;


        },
        exportToPDF() {
            const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
            // console.log("here");
            const pdfOptions = {
                margin: 10,
                filename: 'generated.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            };
            // html2pdf().from(content).set(pdfOptions).outputPdf((pdf) => {

            // });
            html2pdf().from(content).set(pdfOptions).save();
        },
        generatePDF() {
            // Define the element to convert to a PDF
            const element = this.$refs.pdfContent; // Replace with your element's ID or selector
            // console.log(elementToConvert);

            // Define the options for html2pdf
            const pdfOptions = {
                margin: 10,
                filename: 'document.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
                debug: true, // Enable debugging
            };
            html2pdf().from(element).set(pdfOptions).outputPdf('blob').then((pdf) => {
                const blob = new Blob([pdf], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                // console.log(pdf);
                const printWindow = window.open(url, '_blank', 'width=800,height=600');
                printWindow.onload = () => {
                    printWindow.print();
                    URL.revokeObjectURL(url);
                };
            });
        },
    },
}
</script>