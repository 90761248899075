<template>
    <div id="wrapper">
            <SidebarView :loan="true" :loan-scheme="true" :loan-visible="true" />
            <div class="d-flex flex-column" id="content-wrapper">
            <div id="content">
              <TopbarView />
              <div class="container-fluid">
                    <div class="card card-shape home-box">
                      <div class="card-header py-3 d-flex flex-row align-items-center">
                        <h6 class="m-0 fw-bold text-green">Edit Loan Scheme</h6>
                      </div>
                      <div class="card-body">
                        <form class="form" method="POST" @submit.prevent="editData">
                          <scheme-form :form="form" :add_or_edit="add_or_edit" :approval_options="approval_options" :branch_options="branch_options"></scheme-form>
                          <hr />
                          <div class="text-center py-3">
                            <button type="submit" :disabled="isSaving" class="btn akkurate-green-btn text-white btn-size my-2 me-2"><font-awesome-icon class="me-2" :icon="['fas', 'save']" />Update</button>
                            <button type="reset" class="btn akkurate-gray-btn text-white btn-size my-2 me-2"><font-awesome-icon class="me-2" :icon="['fas', 'rotate-right']" />Reset</button>
                          </div>
                        </form>
                      </div>
                    </div>
              </div>
       
            </div>
          <Footer></Footer>
          </div>
            <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
    </div>
    
    </template>
    
<script>

// import BeatLoaderComponent from "@/views/components/Loader/BeatLoaderComponent.vue";
// import UsersForm from "./../forms/UsersForm.vue";
import SchemeForm from "../form/SchemeForm.vue";
import ApiService from "@/core/services/api.service";
import SidebarView from '@/views/main/components/Sidebar.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import Footer from '@/views/main/components/Footer.vue'


export default {
  components: {
    // BeatLoaderComponent,
    // UsersForm,
    SidebarView,
    TopbarView,
    Footer,
    SchemeForm
  },
  mounted() {
    let token = localStorage.getItem('token');
    if (!token) {
      this.$router.push({ path: '/login' });
    }    
  },
  created(){
    // this.getSchemeDropdown();
    this.getSchemeOptions();
  },
  data() {
    return {
      ...this.initialState(),
      approval_options:[],
      branch_options:[],
    };
  },
  methods: {
    // async getSchemeDropdown() {
    //   await ApiService.get('/loans/loanProductSchemes/dropdown')
    //     .then((response) => {
    //       this.approval_options = response.data?.loan_approval_levels
    //     }).catch((error) => {
    //       console.log(error);
    //     })
    // },
    getSchemeOptions() {
      this.$Progress.start();
      this.isLoading = true;
      // console.log(this.$route.params.id)
      // axios
      //   .get("roles/dropdown")
      ApiService.get(`/loans/loanProductSchemes/${this.$route.params.id}`)
        .then((response) => {
          this.approval_options = response.data?.approval_options
          this.branch_options = response.data?.branches;
          // console.log(response.data?.data);
          this.form.name = response.data?.data?.name;
          this.form.interest_rate = response.data?.data?.interest_rate;
          this.form.min_principal = response.data?.data?.min_principal;
          this.form.max_principal = response.data?.data?.max_principal;
          this.form.min_duration = response.data?.data?.min_duration;
          this.form.max_duration = response.data?.data?.max_duration;
          this.form.min_membership_duration = response.data?.data?.min_membership_duration;
          this.form.min_share_balance = response.data?.data?.min_share_balance;
          this.form.loan_processing_fee = response.data?.data?.loan_processing_fee;
          this.form.loan_premium_plan = response.data?.data?.loan_premium_plan;
          this.form.branch = response.data?.data?.branch;
          this.form.loan_approval_level = response.data?.data?.approvals.map(index => {
            return {
              id: index.loanapprovallevel.id,
              name: index.loanapprovallevel.name,
            }
          });
          // this.form.loan_approval_level = response.data?.data?.approvals;
          
          this.form.status = response.data?.data?.status;
          this.form.supervisor_approval_limit = response.data?.data?.supervisor_approval_limit;
          this.form.branch_approval_limit = response.data?.data?.branch_approval_limit;
          this.isLoading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.isLoading = false;
          this.$Progress.fail();
        });
    },
    editData() {
      this.isSaving = true;
      this.$Progress.start();
      // console.log(this.form);

      if(this.form.loan_approval_level)
      {
        this.form.loan_approval_levels = [];
        this.form.loan_approval_level.forEach(element => {
          // console.log(element)
          this.form.loan_approval_levels.push(element.id)
        });
      }
      
      ApiService.put(`loans/loanProductSchemes/${this.$route.params.id}`, this.form)
        .then((response) => {
          this.isLoadingSaving = false;
          this.$Progress.finish();

          toast.fire({
            icon: "success",
            title: response.data.message,
          });
          this.isSaving = false;
          // this.form.reset();
         
          // this.form = this.initialState().form;
        })
        .catch((error) => {
          this.isSaving = false;
          this.$Progress.fail();
          if (error.response.data.errors) {
            this.form.errors = error.response.data.errors;
          }
        });
    },
    initialState() {
      return {
        form: {
          name: "",
          interest_rate: 0,
          min_principal: 0,
          max_principal: 0,
          min_duration: 0,
          max_duration: 0,
          min_membership_duration: 0,
          min_share_balance: 0,
          loan_processing_fee: 0,
          loan_premium_plan: 0,
          loan_approval_levels: [],
          loan_approval_level: [],
          // loan_approval_levels: [
          //   1,
          //   2,
          //   3
          // ],
          status: 1,
          branch: null,
          supervisor_approval_limit: 0,
          branch_approval_limit:0,
          errors:[]
        },
        isLoading: true,
        isSaving: false,
        add_or_edit: 'edit',
      };
    },
  },
};
</script>
    
    <!-- New step!
         Add Multiselect CSS. Can be added as a static asset or inside a component. -->
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
    
    