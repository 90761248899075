<template>
   <div class="col-md-6 d-md-flex justify-content-md-center align-items-md-center mobile-form-padding">
                <div class="col-md-9 akkurate-box">
                        <div class="mb-4">
                            <h2 class="mb-4 akkurate-dark fw-bold">Forget Password ?</h2>
                            <p class="akkurate-light-dark">Welcome to Akkurate Banking Management Application</p>
                        </div>
                        <b-form>
                            <b-form-group
                                id="email-group"
                                label="Email Address"
                                label-for="email"
                                class="akkurate-dark"
                                />
                                <b-input-group class="mb-5 mt-2 field-container">
                                    <!-- <template #append>
                                        <b-input-group-text class="field-container field-addon">
                                            <strong><font-awesome-icon :icon="['fas', 'envelope']" class="akkurate-dark" /></strong>
                                        </b-input-group-text>
                                    </template> -->
                                    <b-form-input id="email"  class="field-main" required></b-form-input>
                                    <font-awesome-icon :icon="['fas', 'envelope']" class="field-icon akkurate-dark position-absolute top-50 end-0 translate-middle-y me-3" />
                                </b-input-group>
                            <div class="d-grid gap-2">
                                <a class="btn btn-primary fw-semibold d-md-flex justify-content-md-center align-items-md-center akkurate-green-btn akkurate-auth-size mb-3" role="button" href="/sent">Send</a>
                                <a class="btn btn-primary fw-semibold d-md-flex justify-content-md-center align-items-md-center akkurate-green-btn akkurate-auth-size" role="button" href="/login">
                                <font-awesome-icon :icon="['fas', 'backward']" />   <span class="ms-2">Back to Login</span></a>
                            </div>
                        </b-form>
                    </div>
                </div>
</template>

<script>
export default {
    name: 'ForgetForm',
    mounted() {
        let token = localStorage.getItem('token');
        if (token) {
            this.$router.push({ path: '/' });
        }
    }
}

</script>