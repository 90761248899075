<template>
    <div>
      <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
            <label class="akkurate-dark" for="account_type">Account Type <star></star></label>
            <b-form-group
                id="account_type"
                label-for="account_type">
                <multiselect 
                class="mt-2 field-container" 
                v-model="form.account_type" 
                :options="account_options"
                label="name">
              </multiselect>
            </b-form-group>
           </div>
           <div class="col-12 col-md-6">
             <label class="akkurate-dark" for="account_balance">Balance <star></star></label>
                <b-form-group
                id="account_balance"
                label-for="account_balance"
                class="akkurate-dark">
                <b-form-input
                id="account_balance" 
                v-model="form.account_balance"
                class="mb-3 mt-2 field-container fields"
                type="number" 
                placeholder="Enter Balance"
                disabled>
                </b-form-input>
            </b-form-group>
           </div>
           <div class="col-12 col-md-6">
            <label class="akkurate-dark" for="member">Member <star></star></label>
            <b-form-group
                id="member"
                label-for="member">
                <multiselect class="mt-2 field-container" 
                  v-model="form.member"
                  :options="member_options" 
                  :custom-label="fullName"
                   placeholder="Select member by name" 
                   label="name"
                   track-by="id"
                   @select="onSelect_member(form.member)"
                >
              </multiselect>
            </b-form-group>
           </div>
           <div class="col-12 col-md-6">
            <label class="akkurate-dark" for="status">Status <star></star></label>
            <b-form-group
                id="status"
                label-for="status">
                <multiselect 
                class="mt-2 field-container" 
                v-model="form.status" 
                :options="form.status_option">
                </multiselect>
            </b-form-group>
           </div>
           <div class="col-12 col-md-6">
             <label class="akkurate-dark" for="balance">Branch <star></star></label>
                <b-form-group
                id="branch"
                label-for="branch"
                class="akkurate-dark">
                <b-form-input
                id="branch" 
                class="mb-3 mt-2 field-container fields"
                type="text" 
                v-model="form.branch"
                placeholder="Enter Branch"
                required
                disabled>
                </b-form-input>
            </b-form-group>
           </div>

           <div class="col-12 col-md-6">
               <b-form-group
                label-for="comment"
                class="mb-2"
                >
                <template #label>
                  <label class="akkurate-dark" for="comment">Comment</label>
                </template>
                  <b-form-textarea
                  class="mt-2 fields"
                  id="comment"
                  v-model="form.comment"
                  placeholder="Enter Comment..."
                ></b-form-textarea>
              </b-form-group>
              
           </div>
        </div>
      </div>
    </div>
  </template>
  
<script>
import Multiselect from "vue-multiselect";
import ApiService from "@/core/services/api.service";

export default {
  name: 'AccountForm',
  components: {
    Multiselect
  },
  methods: {
    fullName({ first_name, last_name, code }) {
      let name = ""
      if(first_name)
      {
        name = name + first_name;
      }

      if(last_name)
      {
        name = name + " " + last_name;
      }

      if(code)
      {
        name = name + ` (${code})`;
      }

      return `${name}`
    },
    async onSelect_member(member) {
      await ApiService.get("/accounts/create/dropdown")
        .then((response) => {
          // this.form.branch = response.data.members[member.id].branch;
          // console.log(response.data.members[0].branch);
          this.form.branch = response.data.members[[member].indexOf(member)].branch.name
          // console.log(response.data.account_types);
          // this.form = this.initialState().form;
        })
        .catch((error) => {
          this.isSaving = false;
          this.$Progress.fail();
          if (error.response.data.errors) {
            this.form.errors = error.response.data.errors;
          }
        });

    },

  },
  props: ["form", "add_or_edit","account_options", "member_options"],
}
</script>
  