<template>
     <div class="row">
            <div class="col">
                <label class="akkurate-dark small">Loan Number</label>
                <b-form-group
                id="loan_code"
                label-for="loan_code">
                <b-form-input
                class="mb-3 mt-2 field-container fields"
                type="text"
                placeholder="Enter Loan Code"
                v-model="filter.loan_code"
                ></b-form-input>
                </b-form-group>
            </div>
            <div class="col">
                <label class="akkurate-dark small">Start Date</label>
                <b-form-group
                id="loan_code"
                label-for="loan_code">
                <b-form-input
                class="mb-3 mt-2 field-container fields"
                type="date"
                v-model="filter.start_date"
                ></b-form-input>
                </b-form-group>
            </div>
            <div class="col">
                <label  class="akkurate-dark small">End Date</label>
                <b-form-group
                id="loan_code"
                label-for="loan_code">
                <b-form-input
                class="mb-3 mt-2 field-container fields"
                type="date"
                v-model="filter.end_date"
                ></b-form-input>
                </b-form-group>
            </div>
            <div class="mt-auto col col">
            <b-button type="submit" :disabled="isSearching" class="akkurate-green-btn akkurate-auth-size w-100 mb-3"><font-awesome-icon class="me-2" :icon="['fas', 'search']" />{{ search }}</b-button>
            </div>
    </div>
</template>
<script>
export default {
    props:['filter', 'isSearching', 'search']
}
</script>