<template>
     <div class="container">
        <div class="row vh-100">
            <div class="col-md-6 d-md-flex align-items-md-center">
                <img class="img-fluid img-visibility" src="../../assets/img/sent-email.jpg">
            </div>
            <div class="col-md-6 d-md-flex justify-content-md-center align-items-md-center mobile-form-padding">
                <div class="col-md-9 akkurate-box">
                    <div class="mb-4">
                        <h2 class="mb-4 akkurate-dark fw-bold">Email Successfully Sent</h2>
                        <p class="akkurate-light-dark">A Recovery Email has been successfully sent to you. please check your inbox!</p>
                    </div>
                    <form>
                        <div class="d-grid gap-2">
                            <a class="btn btn-primary fw-semibold d-md-flex justify-content-md-center align-items-md-center akkurate-green-btn akkurate-auth-size mb-3" role="button"  href="/2fa">Confirm</a>
                            <a class="btn btn-primary fw-semibold d-md-flex justify-content-md-center align-items-md-center akkurate-green-btn akkurate-auth-size" role="button" href="/login">
                                <font-awesome-icon :icon="['fas', 'backward']" />
                                <span class="ms-2">Back to Login</span>
                            </a>
                        </div>
                    </form>
                </div>
            </div>
           
        </div>
    </div>
</template>
<script>
export default{
    name : 'SentView',
    mounted() {
        let token = localStorage.getItem('token');
        if (token) {
            this.$router.push({ path: '/' });
        }
    }
}
</script>