<template>
    <div class="card card-shape home-box">
        <div class="card-header d-flex flex-row align-items-center justify-content-between">
            <h6 class="m-0 fw-bold text-green">
                Membership Imports
            </h6>
            <b-link title="Download Excel Format" @click="downloadFile" class="fw-semibold ms-2 akkurate-green">
                <font-awesome-icon :icon="['fas', 'download']" /></b-link>

        </div>
        <div class="card-body">
            <div class="err-accounts text-center my-2">
                <errors v-if="message">
                    {{ message }}
                </errors>
            </div>
            <div class="card card-shape home-box mb-3 border border-success">
                <div class="card-body py-2">
                    <div class="row mb-3">
                        <div class="col-md-9">
                            <div class="row">
                                <div class="col">
                                    <label class="akkurate-dark small" for="phone">Choose File<star></star></label>
                                    <input type="file" class="form-control mt-2" @change="handleFileUpload"
                                        ref="fileInput" accept=".xlsx,.xls" />
                                </div>
                                <div class="col mt-auto">
                                    <label class="akkurate-dark small" for="total_count">Total Records</label>
                                    <div class="mt-2">{{ total_count }}</div>
                                </div>
                                <!-- <div class="col mt-auto">
                            <label class="akkurate-dark small" for="total_a">Total Amount<star></star></label>
                            <div class="mt-2">{{ total_amount }}</div>
                          </div> -->
                            </div>
                        </div>
                        <div class="col mt-auto">
                            <b-button :disabled="isSaving" class="btn-size w-75 akkurate-green-btn"
                                @click="saveBulkDeposit"><font-awesome-icon class="small" :icon="['fas', 'upload']" />
                                Import Members</b-button>
                        </div>
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="col-md-12">
                    <vue-good-table styleClass="vgt-table bordered striped" mode="remote" ref="allAccountTable"
                        :columns="account_columns" :rows="account_rows" :isLoading.sync="isLoading"
                        :totalRows="totalRecords" :select-options="{
                            enabled: true,
                            disableSelectInfo: true, // disable the select info panel on top
                        }">
                        <!-- Add your custom delete button column -->
                        <!-- <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field == 'member_fullname'">
                          <span>
                            {{ props.row.customer?.first_name }}
                            {{ props.row.customer?.last_name }}
                          </span>
                        </span>
                        <span v-else>
                          {{ props.formattedRow[props.column.field] }}
                        </span>
                      </template> -->
                    </vue-good-table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SidebarView from "@/views/main/components/Sidebar.vue";
import TopbarView from "@/views/main/components/Topbar.vue";
import Footer from "@/views/main/components/Footer.vue";
import ApiService from "@/core/services/api.service";
import config from '@/config.js'
import * as XLSX from 'xlsx';

export default {
    components: {
        SidebarView,
        TopbarView,
        Footer,
    },
    data() {
        return {
            ...this.initialState(),
            message: null,
            // jsonData:null,
            account_columns: [
                {
                    label: "Code(External)",
                    field: "code",
                    width: "150px"
                },
                {
                    label: "Staff no",
                    field: "staffno",
                    type: "string",
                    width: "100px"
                },
                {
                    label: "First Name",
                    field: "firstname",
                    width: "150px"
                },
                {
                    label: "Last Name",
                    field: "lastname",
                    type: "string",
                    width: "150px"
                },
                {
                    label: "Middle Name",
                    field: "middlename",
                    type: "string",
                    width: "150px"
                },
                {
                    label: "Mobile",
                    field: "mobilenumber",
                    type: "string",
                    width: "150px"
                },
                
                {
                    label: "Email",
                    field: "email",
                    type: "string",
                    width: "150px"
                },
                {
                    label: "Postal Address",
                    field: "postaladdress",
                    type: "string",
                    width: "150px"
                },
                {
                    label: "Residental Address",
                    field: "residentaladdress",
                    type: "string",
                    width: "180px"
                },
                {
                    label: "Date of Birth",
                    field: "birthdate",
                    type: "string",
                    width: "150px"
                },
                {
                    label: "Date Registered",
                    field: "dateregistered",
                    type: "Date",
                    width: "180px"
                },
                {
                    label: "Cutomer Type",
                    field: "customertype",
                    type: "string",
                    width: "180px"
                },
                
                {
                    label: "Gender",
                    field: "gender",
                    type: "string",
                },
                
                
                {
                    label: "Marital Status",
                    field: "maritalstatus",
                    type: "string",
                    width: "150px"
                },
                
                {
                    label: "Status",
                    field: "status",
                    type: "string",
                    width: "150px"
                },
                {
                    label: "Branch code",
                    field: "branchcode",
                    type: "string",
                    width: "150px"
                },
                {
                    label: "Nationality",
                    field: "nationality",
                    type: "string",
                    width: "150px"
                },
                {
                    label: "Region",
                    field: "region",
                    type: "string",
                    width: "180px"
                },
                {
                    label: "Religion",
                    field: "religion",
                    type: "string",
                },
                {
                    label: "ID Type",
                    field: "idtype",
                    type: "string",
                     width: "150px"
                },
                {
                    label: "ID Number",
                    field: "idnumber",
                    type: "string",
                    width: "150px"
                },
                {
                    label: "Profession",
                    field: "profession",
                    type: "string",
                    width: "150px"
                },
                {
                    label: "Nature Of Emp",
                    field: "natureofemp",
                    type: "string",
                    width: "150px"
                },
                {
                    label: "Work Place",
                    field: "workplace",
                    type: "string",
                    width: "150px"
                },
                {
                    label: "Rank",
                    field: "rank",
                    type: "string",
                    width: "150px"
                },
                {
                    label: "Created By",
                    field: "createdby",
                    type: "string",
                    width: "150px"
                },
                {
                    label: "Spouse Name",
                    field: "spousename",
                    type: "string",
                    width: "150px"
                },
                {
                    label: "Spouse Phone",
                    field: "spousephone",
                    type: "string",
                    width: "150px"
                },
                {
                    label: "Spouse Address",
                    field: "spouseaddress",
                    type: "string",
                    width: "150px"
                },
                {
                    label: "Purpose Of Account Operation",
                    field: "purposeofaccountoperation",
                    type: "string",
                    width: "280px"
                },
                
            ],
            account_rows: [],
            serverParams: {
                // a map of column filters example: {name: 'john', age: '20'}
                columnFilters: {},
                sort: [
                    {
                        field: "", // example: 'name'
                        type: "", // 'asc' or 'desc'
                    },
                ],
                page: 1, // what page I want to show
                perPage: 20, // how many items I'm showing per page
            },
        };
    },
    mounted() {
        this.loadAllAccount();
    },
    methods: {
        parseDate(dateString) {
            const parts = dateString.split('/');
            // parts[0] is the day, parts[1] is the month, parts[2] is the year
            return new Date(parts[2], parts[1] - 1, parts[0]); // month is 0-based
        },
        handleFileUpload(event) {
            this.excelFile = event.target.files[0];
            if (this.excelFile) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: "array" });
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];
                    const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

                    // Convert to JSON
                    const headers = jsonData[0];
                    const jsonDataArray = [];
                    for (let i = 1; i < jsonData.length; i++) {
                        const row = jsonData[i];
                        const obj = {};
                        for (let j = 0; j < headers.length; j++) {
                            const key = headers[j].replace(/\s/g, "_").toLowerCase(); // Replace spaces with underscores
                            let value = null;
                            if (row[j]) {
                                value = String(row[j]); // Convert value to string
                            }
                            obj[key] = value;
                        }
                        jsonDataArray.push(obj);
                    }
                    this.total_count = jsonDataArray.length

                    // Call showExcelUploads with the processed data
                    this.showExcelUploads(jsonDataArray);
                };
                reader.readAsArrayBuffer(this.excelFile);
            } else {
                this.showExcelUploads([]); // Handle the case where no file is selected
            }
        },


        async loadAllAccount(searchFilter = null) {
            // this.$Progress.start();
            this.isLoading = true;
            // await ApiService.post("/accounts/paginate", {
            //   page: this.serverParams.page,
            //   per_page: this.serverParams.pagePage,
            //   filter: this.form.account_number,
            //   branch_id: branch_id,
            //   account_type_id: acc_id,
            //   account_number: this.form.account_number,
            //   phone_number: this.form.phone,
            //   start_date: this.form.start_date,
            //   end_date: this.form.end_date,
            //   status: this.form.status, //Inactive,	Active, Dormant, Closed
            // })
            //   .then((response) => {
            //     console.log(response.data.data);
            //     this.account_rows = response.data.data;
            //     this.totalRecords = response.data.meta.total;
            //     this.$Progress.finish();
            //   })
            //   .catch((error) => {
            //     console.log(error);
            //   });
            this.isLoading = false;
        },

        // filterTable() {
        //   this.loadAllAccount();
        // },
        async saveBulkDeposit() {
            this.isSaving = true;

            const payload = new FormData();
            payload.append('account_rows', JSON.stringify(this.account_rows)); // Convert to string if needed
            payload.append('excel_upload_id', 'id');
            if (this.excelFile) {
                payload.append('excelFileInput', this.excelFile);
            }
            // console.log(this.excelFile);

            await ApiService.post("/customers/imports/store", payload)
                .then((response) => {
                    this.isLoadingSaving = false;
                    this.$Progress.finish();

                    // toast.fire({
                    //   icon: "success",
                    //   title: response.data.message,
                    // });
                    swal.fire({
                        icon: "success",
                        title: "Success",
                        text: response.data.message,
                        showCancelButton: false, // Show the "Cancel" button
                        confirmButtonText: 'OK', // Customize the text for the "OK" button
                        buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                        customClass: {
                            confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                        }
                    });

                    this.isSaving = false;
                    // this.form.reset();
                    this.form = this.initialState().form;
                    this.account_rows = [];
                    // this.form.gl_debit = "";
                })
                .catch((error) => {
                    this.isSaving = false;
                    this.$Progress.fail();
                    console.log(error);
                    if (error.response.data) {
                        this.message = error.response.data.message
                    }
                });
            console.log(bulk_deposit);
        },
        showExcelUploads(account_rows) {
            console.log("Received account rows:", account_rows);
            this.account_rows = account_rows// Update the account_rows data property
        },

        initialState() {
            return {
                totalRecords: 0,
                searchFilter: "",
                total_count: 0,
                form: {
                    external_code: null,
                    first_name: [],
                    middlename: "",
                    last_name: "",
                    // gl_debit_option: [],
                    email: "",
                    mobile: "",
                    gender: "",
                    date_of_birth: "",
                    marital_status: "",
                    customer_type: "",
                    staff_id: "",
                    address: "",
                    postal_address: "",
                    branch_code: "",
                },
                isLoading: true,
                isSaving: false,
                add_or_edit: "add",
            };
        },
        downloadFile() {
            // Replace 'example.pdf' with the filename you want to download from your public directory
            const fileName = 'BULK_DEPOSITS_UPLOAD_TEMPLATE.xlsx';

            // console.log(fileName);  

            // Construct the URL to the file in the public directory
            const fileUrl = `${config.base_url}/assets/${fileName}`;

            //   console.log(fileUrl);

            // Create a link element
            const link = document.createElement('a');
            link.href = fileUrl;
            link.download = fileName;

            // Append the link to the body
            document.body.appendChild(link);

            // Programmatically click the link to trigger the download
            link.click();

            // Remove the link from the body
            document.body.removeChild(link);
        },
    },
};
</script>