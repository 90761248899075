<template>
    <div class="row">
           <div class="col-12 col-md-6">
            <!-- <label class="akkurate-dark" for="end_date">Start Date <star></star></label>
            <b-form-datepicker id="start-date" class="mb-2"></b-form-datepicker> -->
            <label class="akkurate-dark" for="start-date">Start Date <star></star></label>
                <b-form-group
                id="start-date"
                label-for="start-date">
                <b-form-input
                class="mb-3 mt-2 field-container fields"
                type="date"
                v-model="form.start_date"
                required
                ></b-form-input>
                </b-form-group>
           </div>

           <div class="col-12 col-md-6">
            <label class="akkurate-dark" for="end-date">End Date <star></star></label>
                <b-form-group
                id="end-date"
                label-for="end-date">
                <b-form-input
                class="mb-3 mt-2 field-container fields"
                type="date"
                v-model="form.end_date"
                required
                ></b-form-input>
                </b-form-group>
           </div>
           <div class="col-12 col-md-6">
                <b-form-group>
                    <template #label>
                        <label class="akkurate-dark" for="status">Status <star></star></label>
                    </template>
                    <b-form-radio v-model="form.status"  name="status" value="ACTIVE">Active</b-form-radio>
                    <b-form-radio v-model="form.status"  name="status" value="INACTIVE">Inactive</b-form-radio>
                </b-form-group>
            </div>
    </div>
</template>
<script>
export default {
    name: "FinancialYearCreate",
    props:['form'],
}

</script>