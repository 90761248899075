<template>
  <b-collapse class="py-3" id="my-collapse">
    <div class="container">
      <div class="row text-center">
        <div title="home" class="col-2">
          <b-button href="/" variant="outline-secondary"
            ><font-awesome-icon :icon="['fas', 'house-damage']"
          /></b-button>
        </div>
        <div title="Create Member" class="col-2">
          <b-button href="/mobile/member" variant="outline-secondary"
            ><font-awesome-icon :icon="['fas', 'plus']"
          /></b-button>
        </div>
        <div title="deposit" class="col-2">
          <b-button href="/mobile/deposit" variant="outline-secondary"
            ><font-awesome-icon :icon="['fas', 'forward']"
          /></b-button>
        </div>
        <div title="withdrawal" class="col-2">
          <b-button href="/mobile/withdrawal" variant="outline-secondary"
            ><font-awesome-icon :icon="['fas', 'backward']"
          /></b-button>
        </div>
        <div title="transaction history" class="col-2">
          <b-button href="/mobile/history" variant="outline-secondary"
            ><font-awesome-icon :icon="['fas', 'book']"
          /></b-button>
        </div>
        <div title="user profile" class="col-2">
          <b-button href="/mobile/profile" variant="outline-secondary"
            ><font-awesome-icon :icon="['fas', 'user']"
          /></b-button>
        </div>
      </div>
    </div>
  </b-collapse>
</template>
<script>
export default {};
</script>