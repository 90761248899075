<template>
   <div>
      <a title="View" class="btn btn-sm akkurate-warning text-white broder-0" v-b-modal="String(data.id + 'views')">
         <span class="akkurate-small"> <font-awesome-icon :icon="['fas', 'eye']" /></span>
      </a>
      <b-modal :id="String(data.id + 'views')" size="xxl" @shown="showView">
         <template #modal-title>
            <p class="m-0 fw-bold text-green">Loan View</p>
         </template>
         <div class="row">
            <beat-loader-component v-if="isLoading" :isLoading="isLoading"></beat-loader-component>
         </div>
         <div class="row px-3">
            <div class="card card-shape home-box mb-3 border border-success">
               <div class="card-body py-2">
                  <form @submit.prevent="getSingleLoan">
                     <div class="row">
                        <div class="col">
                           <b-form-group id="filter" name="filter" label-for="filter">
                              <multiselect id="filter" name="filter" class="mt-2 field-container"
                                 aria-describedby="filter" v-model="form_filter.filter" :allow-empty="false"
                                 :options="filter_options">
                              </multiselect>
                           </b-form-group>
                        </div>
                        <div class="col">
                           <b-form-group id="filter_value" label-for="filter_value">
                              <b-form-input id="filter_value" name="filter_value"
                                 class="mb-2 mt-2 field-container fields" type="text"
                                 placeholder="Enter Search Value..." v-model="form_filter.filter_value"
                                 aria-describedby="filter_value" required></b-form-input>
                           </b-form-group>

                        </div>
                        <div class="col-3 my-auto">
                           <b-button type="submit" class="akkurate-green-btn akkurate-auth-size w-100"><i
                                 class="fas fa-search me-2"></i>Search</b-button>
                        </div>
                     </div>

                  </form>

               </div>
            </div>
         </div>
         <div class="row px-3">
            <div class="card card-shape home-box p-0">
               <div class="rounded-0 card-header py-0 pe-0 akkurate-skyblue-bg">
                  <div class="row align-items-center">
                     <div class="col-3 col-md-3">
                        <h6 class="m-0 fw-bold text-white">ACCOUNT PREVIEW</h6>
                     </div>
                     <div class="col-3 col-md-3">
                        <h6 class="m-0 text-white">{{ loan.loan_approval_level ?? "" }}</h6>
                     </div>
                     <div class="col-6 col-md-6 text-end"
                        v-if="loan.status !== 'RUNNING' && loan.status !== 'CLOSED' && loan.status !== 'EXPIRED'">
                        <b-button class="akkurate-green-btn btn-size m-0">
                           <font-awesome-icon class="me-2" :icon="['fas', 'check']" />Approve
                        </b-button>
                        <b-button class="akkurate-danger-btn btn-size m-0">
                           <font-awesome-icon class="me-2" :icon="['fas', 'close']" />Reject
                        </b-button>
                     </div>
                     <div class="col-6 col-md-6 text-end" v-else>
                        <b-button :class="{
                           'akkurate-warning': loan.status === 'RUNNING',
                           'akkurate-danger-btn': loan.status === 'CLOSED' || loan.status === 'EXPIRED',
                           'btn-size': true,
                           'text-center': true,
                        }">
                           <font-awesome-icon class="me-2" :icon="['fas', 'check']" />
                           {{ loan.status === 'RUNNING' ? 'Running' : loan.status }}
                        </b-button>
                        <!-- <div :class="{
                           'akkurate-warning': loan.status === 'RUNNING',
                           'akkurate-danger-btn': loan.status === 'CLOSED' || loan.status === 'EXPIRED',
                           'btn-size': true,
                           'py-2': true,
                           'text-end': true,
                           'px-5': true
                        }">
                           <font-awesome-icon class="me-2" :icon="['fas', 'check']" />
                           {{ loan.status === 'RUNNING' ? 'Running' : loan.status }}
                        </div> -->
                     </div>
                  </div>
               </div>
               <div class="card-body">
                  <div class="row">
                     <div class="col text-center">
                        <img :src="loan.account?.customer?.passport_pic_path" style="height:200px" class="img-fluid"
                           alt="user-profile" />
                        <p class="akkurate-green fw-bold akkurate-small mb-0">Profile Image</p>
                     </div>
                     <div class="col text-center">
                        <img :src="loan.account?.customer?.gh_card_front_path" alt="Default Image" style="height:200px"
                           class="img-fluid text-center" />
                        <p class="akkurate-green fw-bold akkurate-small mb-0">ID Card Front</p>
                     </div>
                     <div class="col text-center">
                        <img :src="loan.account?.customer?.gh_card_back_path" alt="Default Image" style="height:200px"
                           class="img-fluid text-center" />
                        <p class="akkurate-green fw-bold akkurate-small mb-0">ID Card Back</p>
                     </div>
                     <div class="col">
                        <p class="mb-0 fw-bold">{{ getFullName }}<span class="ms-3 fw-light">{{
                           loan.account?.customer?.gender
                              }}</span></p>
                        <p class="mb-0">{{ getBranch }}</p>
                        <p class="mb-0"><span class="fw-bold">ADDRESS: </span> {{ loan.account?.customer?.address }}</p>
                        <p class="mb-0"><span class="fw-bold">TEL: </span>{{ loan.account?.customer?.mobile }}</p>
                        <p class="mb-0"><span class="fw-bold">EMAIL: </span>{{ loan.account?.customer?.email }}</p>
                        <p class="mb-0"><span class="fw-bold">REG DATE: </span>{{ new
                           Date(loan.account?.customer?.registered_date).toDateString() }}</p>
                        <p class="mb-0"><span class="fw-bold">STAFF ID: </span>{{ loan.account?.customer?.staff_id }}
                        </p>
                     </div>
                  </div>
                  <div class="row akkurate-skyblue-bg text-white akkurate-small fw-bold">
                     <div class="col text-center py-2">
                        Shares: {{ displayNumber(loan.shares_account_balances) }}
                     </div>
                     <div class="col text-center py-2">
                        Saving: {{ displayNumber(loan.savings_account_balances) }}
                     </div>
                     <div class="col text-center py-2">
                        Credit: {{ displayNumber(loan.principal_paid + loan.interest_paid) }}
                     </div>
                     <div class="col text-center py-2">
                        Current Loan {{ displayNumber(loan.principal_amount) }}
                     </div>
                     <div class="col text-center py-2">
                        Risk Level
                     </div>
                     <div class="col text-center py-2 akkurate-green-bg">
                        Credit Balance: {{ displayNumber(Number(loan.savings_account_balances) -
                           Number(loan.principal_balance))
                        }}
                     </div>
                  </div>
                  <!-- Tabs Begins -->
                  <div class="row mt-1">
                     <b-tabs content-class="mt-3" justified>
                        <b-tab title="Loan Term" active title-link-class="akkurate-small">
                           <Terms :loan="loan" />
                        </b-tab>
                        <b-tab title="Repayments" title-link-class="akkurate-small">
                           <Repayment :loan="loan" :coa_subheads="coa_subheads" :savings_accounts_options="savings_accounts_options"
                              :institution="institution" />
                        </b-tab>
                        <b-tab title="Statements" title-link-class="akkurate-small">
                           <Statement :institution="institution" :loan="loan" />
                        </b-tab>
                        <b-tab title="Entries" title-link-class="akkurate-small">
                           <Entries :loan="loan"/>
                        </b-tab>
                        <b-tab title="Adjustment" title-link-class="akkurate-small">
                           <Adjustment />
                        </b-tab>
                        <b-tab title="Top up" title-link-class="akkurate-small">
                           <Topup/>
                        </b-tab>
                        <b-tab title="Refinance" title-link-class="akkurate-small">
                          <LoanRefinance/>
                        </b-tab>
                        <b-tab title="Write off" title-link-class="akkurate-small">
                           <Writeoff/>
                        </b-tab>
                        <b-tab title="Status" title-link-class="akkurate-small">
                           <Status/>
                        </b-tab>
                        <b-tab title="Collaterals" title-link-class="akkurate-small">
                           <div class="container">
                              <div class="row">
                                 <b-table striped hover :busy="isBusy" :items="col_items" :fields="col_fields">
                                    <template #table-busy>
                                       <div class="text-center text-danger my-2">
                                          <b-spinner class="align-middle"></b-spinner>
                                          <strong>Loading...</strong>
                                       </div>
                                    </template>
                                    <template #cell(action)="data">
                                       <div class="d-flex">
                                          <picture-view :data="data.item?.collateral_pictures"></picture-view>
                                          <document-view :data="data.item?.collateral_documents"></document-view>
                                       </div>
                                    </template>
                                 </b-table>
                              </div>
                           </div>
                        </b-tab>
                        <b-tab title="Guarantors" title-link-class="akkurate-small">
                           <div class="container">
                              <div class="row">
                                 <b-table striped hover :busy="isBusy" :items="gua_items" :fields="gua_fields">
                                    <template #table-busy>
                                       <div class="text-center text-danger my-2">
                                          <b-spinner class="align-middle"></b-spinner>
                                          <strong>Loading...</strong>
                                       </div>
                                    </template>
                                    <template #cell(name)="data">
                                       <p>{{ data.item?.account?.customer?.first_name }} {{
                                          data.item?.account?.customer?.middle
                                          }} {{ data.item?.account?.customer?.last_name }}</p>
                                    </template>
                                 </b-table>
                              </div>
                           </div>
                        </b-tab>
                        <b-tab title="Attachments" title-link-class="akkurate-small">
                           <b-card-text>Tab contents 3</b-card-text>
                        </b-tab>
                        <b-tab title="Payments" title-link-class="akkurate-small">
                           <div class="container">
                              <div class="row trans_details">
                                 <b-table striped hover :busy="isBusy" :items="payment_items" :fields="payment_fields">
                                    <template #table-busy>
                                       <div class="text-center text-danger my-2">
                                          <b-spinner class="align-middle"></b-spinner>
                                          <strong>Loading...</strong>
                                       </div>
                                    </template>
                                    <!-- A custom formatted column -->
                                    <template #cell(status)="data">
                                       <!-- <div v-if="data.status == 'Unpaid'">
                                          <b-badge pill variant="danger">{{ data.status }}</b-badge>
                                       </div>
                                       <div v-else>
                                          <b-badge pill variant="success">{{ data.status }}</b-badge>
                                       </div> -->
                                       <!-- <span class="badge badge-pill badge-primary"></span> -->
                                       <span v-if="data.item.status == 'Unpaid'" class="badge rounded-pill bg-danger">{{
                                          data.item.status }}</span>
                                       <span v-else class="badge rounded-pill bg-success">{{ data.item.status }}</span>
                                    </template>
                                 </b-table>
                              </div>
                           </div>
                        </b-tab>
                     </b-tabs>
                  </div>
               </div>
            </div>
         </div>


         <template #modal-footer>
            <div class="w-100">
               <!-- begin loader -->
               <!-- <beat-loader-component 
                    class="float-left"
                    v-if="isLoading"
                    :isLoading="isLoading"
                    ></beat-loader-component> -->
               <!-- end loader -->
               <!-- Emulate built in modal footer ok and cancel button actions -->
               <b-button @click="$bvModal.hide(String(data.id + 'views'))" type="submit"
                  class="akkurate-gray-btn btn-size  float-right"><font-awesome-icon class="me-2"
                     :icon="['fas', 'close']" />Close</b-button>
            </div>
         </template>

      </b-modal>
      

   </div>


</template>
<script>
import ApiService from "@/core/services/api.service";
import BeatLoaderComponent from "@/views/components/Loader/BeatLoaderComponent.vue";
import PictureView from './modal/PictureView.vue'
import DocumentView from './modal/DocumentView.vue'
import LoanForm from "../application/LoanForm.vue";
import LoanFilter from "../statements/form/LoanFilter.vue";
import Multiselect from 'vue-multiselect'
import html2pdf from "html2pdf.js";
import Adjustment from "./loan-actions/Adjustment.vue";
import Terms from "./loan-actions/Terms.vue"
import Repayment from "./loan-actions/Repayment.vue"
import Statement from "./loan-actions/Statement.vue"
import Entries from "./loan-actions/Entries.vue"
import Topup from "./loan-actions/Topup.vue";
import LoanRefinance from "../application/form/LoanRefinance.vue";
import Writeoff from "./loan-actions/Writeoff.vue";
import Status from "./loan-actions/Status.vue";
export default {
   props: ['data', 'institution', 'user', 'coa_subheads'],
   components: {
      BeatLoaderComponent,
      PictureView,
      DocumentView,
      LoanForm,
      Multiselect,
      LoanFilter,
      Entries,
      Topup,
      Adjustment,
      Terms,
      Repayment,
      Statement,
      LoanRefinance,
      Writeoff,
      Status
   },
   data() {
      return {
         // ...this.initialState(),
         loan: {
            status: "",
            tenor: "",
            interest_rate: "",
            interest_method: "",
            expected_interest: "",
            interest_balance: "",
            interest_paid: "",
            interest_balance: "",
            loan_number: "",
            principal_amount: "",
            principal_paid: "",
            principal_balance: "",
            principal_due: "",
            loan_product: "",
            account: "",
            start_date: "",
            last_payment: "",
            delinquent: "",
            interest_due: "",
            account: {
               customer: {
                  passport_pic_path: ""
               }
            }
         },
         form_filter: {
            filter: "Loan Code",
            filter_value: this.data.loan?.loan_number,
         },
         filter_options: ['Loan Code'],

         savings_accounts_options: [],
         isLoading: false,
         isBusy: false,
         full_name: "",
         search: "Search",
         isSearching: false,
         isSaving: false,
       
         products: [],


         // note: false,


         fields: [
            { key: 'payment_terms', label: 'Payment Terms' },
            { key: 'after_payments', label: 'After Payment' },
         ],
         items: [
            { payment_terms: { title: 'Interest Amount', value: "" }, after_payments: { title: 'Interest Outstanding', value: "" } },
            { payment_terms: { title: 'Principal Amount', value: "" }, after_payments: { title: 'Principal Outstanding', value: "" } },
            { payment_terms: { title: 'Total Repayment', value: "" }, after_payments: { title: 'Total Outstanding', value: "" } },
         ],
         col_fields: [
            {
               key: 'id',
               label: '#',
            },
            {
               key: 'loan_number',
               label: 'Loan Number',
            },
            {
               key: 'product_name',
               label: 'Product Name',
            },
            {
               key: 'status',
               label: 'Status',
            },
            {
               key: 'address',
               label: 'Address',
            },
            {
               key: 'risk_level',
               label: 'Risk Level',
            },
            {
               key: 'action',
               label: 'Action',
            },
         ],
         col_items: [],
         gua_fields: [
            {
               key: 'id',
               label: '#',
            },
            {
               key: 'loan_number',
               label: 'Loan Number',
            },
            {
               key: 'amount',
               label: 'Amount',
            },
            {
               key: 'entry_date',
               label: 'Entry Date',
            },
            {
               key: 'account.account_number',
               label: 'Account Number',
            },
            {
               key: 'name',
               label: 'Guarantor(s) Name',
            },
         ],
         gua_items: [],
         payment_fields: [
            {
               key: 'due_date',
               label: 'Due Date',
            },
            {
               key: 'loan_number',
               label: 'Loan Number',
            },
            {
               key: 'starting_balance',
               label: 'Starting Balance',
            },
            {
               key: 'amount_due',
               label: 'Amount Due',
            },
            {
               key: 'principal_amount',
               label: 'Principal Amount',
            },
            {
               key: 'interest_amount',
               label: 'Interest Amount',
            },
            {
               key: 'ending_balance',
               label: 'Ending Balance',
            },
            {
               key: 'status',
               label: 'Status',
            },
         ],
         payment_items: [],
         repayment_columns: [
            {
               label: "Reference",
               field: "code",
            },
            {
               label: "Loan Number",
               field: "account.loan.loan_number",
            },
            {
               label: "Phone number",
               field: "customer.mobile",
            },
            {
               label: "Full name",
               //field: 'customer.first_name',
               field: this.getFullname,
               title: this.getFullname,
            },
            {
               label: "Amount",
               field: "trans_amount",
            },
            {
               label: "Interest",
               field: "interest",
            },
            {
               label: "Principal",
               field: "principal",
            },
            {
               label: "Ext. Ref",
               field: "external_reference",
            },
            {
               label: "Status",
               field: "trans_status",
            },
            {
               label: "Date",
               //field: 'created_at',
               // field: this.getCreatedAt,
               field: 'trans_date'
            },
            {
               label: "Source",
               field: "source",
            },
            {
               label: "User",
               field: "user",
            },
            {
               label: "Action",
               field: "action",
               html: true,
               //   formatFn: this.renderActions,
            },
         ],
         repayment_items: [],

       
      }
   },
   computed: {
      getFullName() {
         const first_name = this.loan.account.customer.first_name != undefined ? this.loan.account.customer.first_name : "";
         const middle_name = this.loan.account.customer.middle_name != undefined ? this.loan.account.customer.middle_name : "";
         const last_name = this.loan.account.customer.last_name != undefined ? this.loan.account.customer.last_name : "";
         return first_name + ' ' + middle_name + ' ' + last_name;

      },
      getDuePayment() {
         const payment = Number(this.loan?.interest_due ?? 0) + Number(this.loan?.principal_due ?? 0);
         return payment.toFixed(2);
      },
      getCurrentValue() {
         const current = Number(this.loan?.principal_balance ?? 0) + Number(this.loan?.interest_due ?? 0);
         return current.toFixed(2)
      },
      getLoanBalance() {
         const balance = Number(this.loan?.interest_balance ?? 0) + Number(this.loan?.principal_balance ?? 0);
         return balance.toFixed(2)
      },

     
      getTeller() {
         let first_name = this.user?.first_name != null ? this.user?.first_name : '';
         let middle_name = this.user?.middle_name != null ? this.user?.middle_name : '';
         let surname = this.user?.surname != null ? this.user?.surname : '';
         return first_name + ' ' + middle_name + ' ' + surname;
      },
      getBranch() {
         const branch = this.loan?.account?.customer?.branch?.name ? this.loan?.account?.customer?.branch?.name : ""
         return branch
      },
   },
   methods: {
      // validateState(ref) {
      //    if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
      //       return !this.veeErrors.has(ref);
      //    }
      //    return null;
      // },
      // handleOk(bvModalEvent) {
      //    // Prevent modal from closingisDisabled
      //    bvModalEvent.preventDefault();
      //    // Trigger submit handler
      //    this.handleSubmit();
      // },

      // handleDenomination(denominations) {
      //    this.form.denominations = denominations
      // },
      handleAmountInput() {
         const interest_due = this.getInterestDue;
         // console.log(last_payment_date);
         // console.log(this.loan.delinquent);
         // console.log(this.loan.principal_balance);
         // const interest_due = this.getInterestDue;
         // console.log(interest_due);

         var interest_amt = this.form.amount;
         var total = this.form.amount;
         var principal_amt = 0;

         if (this.form.amount > interest_due) {
            principal_amt = this.form.amount - interest_due;
            interest_amt = this.form.amount - principal_amt;
            total = principal_amt + interest_due;
         }

         this.items[0].payment_terms.value = Math.round(interest_amt * 100) / 100;
         this.items[1].payment_terms.value = Math.round(principal_amt * 100) / 100;
         this.items[2].payment_terms.value = Math.round(total * 100) / 100;
         // const outstanding = this.loan.principal_balance - this.form.amount;

         var interest_outstanding = this.loan.interest_balance - Math.round(interest_amt * 100) / 100;;

         this.items[0].after_payments.value = interest_outstanding;
         this.items[1].after_payments.value = this.loan.interest_balance - interest_outstanding;
         this.items[2].after_payments.value = this.loan.interest_balance;
      },
      async getSingleLoan() {
         this.isLoading = true;
         this.isBusy = true,
            await ApiService.get(`/loans/${this.form_filter.filter_value}`)
               .then((response) => {
                  this.isLoading = false;
                  this.isBusy = false,
                     this.loan = response.data.data;
                  this.col_items = response.data.data.collaterals;
                  this.gua_items = response.data.data.guarantors;
                  this.payment_items = response.data.data.payment_schedules;
                  // this.filterLoanStatement();
                  this.savings_accounts_options = response.data.data.savings_accounts;
                  // this.loadTransactions();
               }).catch((error) => {
                  this.isLoading = false;
                  this.isBusy = false,
                     console.log(error);
               });
      },


      clearForm() {
         this.form = this.initialState().form; // Call the initialState method to get the default form
      },
      // initialState() {
      //    return {
      //       form: {
      //          amount: "",
      //          payment_method: "Cash",
      //          date: new Date().toLocaleDateString('en-CA'),
      //          loan_number: "",
      //          source: "Web",
      //          note: "",
      //          bank: "",
      //          mobile_number: "",
      //          check_number: "",
      //          momo_transaction_id: "",
      //          denominations: [
      //             { denom_amount: '1', denom_frequency: '', sub_total: '' }
      //          ],
      //          coa_subhead_id: null,
      //          product_id: null,
      //          product: null,
      //          errors: [],
      //       },
      //    }
      // },




    
      showView() {
         this.getSingleLoan();
      }
   }
}
</script>
