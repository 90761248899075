<template>
    <div class="col-md-6 d-md-flex justify-content-md-center align-items-md-center mobile-form-padding">
                <div class="col-md-9 akkurate-box">
                    <div class="mb-4">
                        <h2 class="mb-4 akkurate-dark fw-bold">Verify Account</h2>
                        <p class="akkurate-light-dark">A verification code has been sent to your email address, please confirm code below.</p>
                    </div>
                    <form>
                        <div class="form-group first mb-3"><label class="form-label">Verification code</label><div class="input-group mb-5 field-container">
                    <input type="text" class="form-control field-main" placeholder="Please enter verification code" aria-label="code" aria-describedby="code" pattern="\d*" maxlength="5" autofocus />
                    <span class="input-group-text field-addon" id="email">
                        <font-awesome-icon :icon="['fas', 'key']" />
                    </span>
                    </div></div>
                        <div class="d-grid gap-2">
                            <a class="btn btn-primary fw-semibold d-md-flex justify-content-md-center align-items-md-center  mb-3" role="button" style="border-style: none;background: rgb(1,134,115);height: 45px;" href="/login">Verify</a>
                            <a class="btn btn-primary fw-semibold d-md-flex justify-content-md-center align-items-md-center" role="button" style="border-style: none;background: rgb(0,190,164);height: 45px;" href="/login">
                                <font-awesome-icon :icon="['fas', 'backward']" />&nbsp;Back to Login</a>
                            </div>
                    </form>
                </div>
            </div>
</template>

<script>
export default {
    name: "TwofaForm",
    mounted() {
        let token = localStorage.getItem('token');
        if (token) {
            this.$router.push({ path: '/' });
        }
    }
}
</script>