<template>
    <div id="wrapper">
      <SidebarView :reports="true" />
      <div class="d-flex flex-column" id="content-wrapper">
        <div id="content">
          <TopbarView />
          <div class="container">
            <div class="card card-shape home-box">
              <div class="card-header py-3">
                <div class="row align-items-center">
                  <div class="col-6">
                      <h6 class="m-0 fw-bold text-green">Detail Receipts Reports</h6>
                  </div>
                  <div class="col-6 text-end">
                    <b-button @click="printPDF" class="me-2 btn-sm akkurate-green-btn akkurate-small"><font-awesome-icon :icon="['fas', 'book']"/></b-button>
                    <b-button @click="downloadPDF" class="btn-sm akkurate-green-btn akkurate-small"><font-awesome-icon :icon="['fas', 'download']"/></b-button>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <b-form-select
                      v-model="selected_num"
                      @change="changePerPageNumber"
                      :options="numbers_options"
                    ></b-form-select>
                  </div>
                </div>
                <div class="row" ref="pdfContent">
                  <div class="col-12 col-md-12">
                    <statement-institution
                      :institution="institution"
                      :title="'DETAIL RECEIPT REPORT'"
                    ></statement-institution>
                  </div>
                  <div class="col-12 col-md-12 trans_details">
                    <b-table
                      :items="items"
                      :fields="fields"
                      striped
                      responsive="sm"
                      :per-page="perPage"
                      :busy.sync="isBusy"
                      :current-page="currentPage"
                      small>
                      <template #table-busy>
                        <div class="text-center text-danger my-2">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong>Loading...</strong>
                        </div>
                      </template>
                      <!-- <template #custom-foot>
                        <b-tr>
                          <b-th colspan="4"></b-th>
                          <b-th>Total</b-th>
                          <b-th>{{ sumLoanBalance }}</b-th>
                          <b-th>{{ sumSavingBalance }}</b-th>
                          <b-th></b-th>
                        </b-tr>
                      </template> -->
                    </b-table>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 text-end">
                    <b-pagination
                      class="text-end"
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                      align="right"
                    ></b-pagination>
                    <!-- <p class="mt-3">Current Page: {{ currentPage }}</p> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer></footer>
      </div>
      <a class="d-inline scroll-to-top" href="#page-top"
        ><i class="fas fa-angle-up"></i
      ></a>
    </div>
  </template>
  <script>
  import SidebarView from "@/views/main/components/Sidebar.vue";
  import TopbarView from "@/views/main/components/Topbar.vue";
  import Footer from "@/views/main/components/Footer.vue";
  import StatementInstitution from "@/views/main/components/institution/StatementInstitution.vue";
  import ApiService from "@/core/services/api.service";
  import Multiselect from "vue-multiselect";
  import html2pdf from "html2pdf.js";
  export default {
    components: {
      SidebarView,
      TopbarView,
      Footer,
      StatementInstitution,
      Multiselect,
    },
    data() {
      return {
        filter:{
          branch:"",
          start_date: "",
          end_date: "",
          year:"",
        },
        search:"Search",
        isBusy: false,
        isSearching:false,
        selected_num: "10",
        numbers_options: [10, 50, 100, 500],
        isLoadingInstitution: false,
        institution: "",
        fields: [
          {
            key: "account_number",
            label: "Account Number",
          },
          {
            key: "customer_name",
            label: "Customer Name",
          },
          {
            key: "amount",
            label: "Amount",
          },
          {
            key: "min_balance",
            label: "Minimum Balance",
          },
          {
            key: "total",
            label: "Total",
          },
          
        ],
        perPage: 20,
        currentPage: 1,
        items: [],
      };
    },
    computed: {
      rows() {
        return this.items.length;
      },
      sumLoanBalance(){
        let total = 0;
        for(let i = 0; i < this.items.length; i++){
          total = this.items[i].loan_balance;
        }
        return total;
      },
      sumSavingBalance(){
        let total = 0;
        for(let i = 0; i < this.items.length; i++){
          total = this.items[i].saving_balance;
        }
        
        return total;
      },
    },
  
    created() {
      this.getInstitution();
      this.getReceiptListing();
    },
    methods: {
      async getReceiptListing() {
        this.items = [];
        this.isBusy = true;
        this.search = "Processing...";
        this.isSearching = true;
        await ApiService.get("/reports/detail/receipt/listing")
          .then((response) => {
            this.isBusy = false;
            this.search = "Search"
            this.isSearching = false;
            var res = response.data?.data;
            console.log(res);
            res.map((index) => {
              const f_name  = index.first_name != null ? index.first_name : "";
              const m_name = index.customer_middlename != null ? index.customer_middlename : ""
              const l_name = index.customer_last_name != null ? index.customer_last_name : ""
              const data = {
                account_number: index.account_number,
                customer_name: f_name + " " + m_name + " " + l_name,
                amount: index.amount,
                min_balance: index.minimum_balance,
                total: index.total
              };
              this.items.push(data);
            });
          })
          .catch((error) => {
            this.isBusy = false;
            this.search = "Search"
            this.isSearching = false;
            console.log(error);
          });
      },
      downloadPDF() {
        const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
        // console.log("here");
        const pdfOptions = {
          margin: [0.5, 0.2, 0.8, 0.2], //top, left, buttom, right
          filename: "statement.pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          pagebreak: { mode: "avoid-all" },
          jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        };
        html2pdf()
          .from(content)
          .set(pdfOptions)
          .toPdf()
          .get("pdf")
          .then((pdf) => {
            var totalPages = pdf.internal.getNumberOfPages();
            var insit_name = this.institution.name;
            var insit_tele = this.institution.telephone;
            var insit_email = this.institution.email;
            var insit_motto = this.institution.motto;
  
            for (var i = 1; i <= totalPages; i++) {
              pdf.setPage(i);
              pdf.setFontSize(8);
              pdf.setTextColor(150);
              pdf.text(
                [
                  "For Inquiries, Please Contact " +
                    insit_name +
                    ", Customer Service on " +
                    insit_tele +
                    "",
                  "or email us on " + insit_email + " " + insit_motto + "",
                  "Page " + i + " of " + totalPages,
                ],
                pdf.internal.pageSize.getWidth() / 2,
                pdf.internal.pageSize.getHeight() - 0.6,
                { align: "center" }
              );
            }
          })
          .save();
      },
      printPDF() {
        const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
        // console.log("here");
        const pdfOptions = {
          margin: [0.5, 0.2, 0.8, 0.2], //top, left, buttom, right
          filename: "statement.pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          pagebreak: { mode: "avoid-all" },
          jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        };
        html2pdf()
          .from(content)
          .set(pdfOptions)
          .toPdf()
          .get("pdf")
          .then((pdf) => {
            var totalPages = pdf.internal.getNumberOfPages();
            var insit_name = this.institution.name;
            var insit_tele = this.institution.telephone;
            var insit_email = this.institution.email;
            var insit_motto = this.institution.motto;
  
            for (var i = 1; i <= totalPages; i++) {
              pdf.setPage(i);
              pdf.setFontSize(8);
              pdf.setTextColor(150);
              pdf.text(
                [
                  "For Inquiries, Please Contact " +
                    insit_name +
                    ", Customer Service on " +
                    insit_tele +
                    "",
                  "or email us on " + insit_email + " " + insit_motto + "",
                  "Page " + i + " of " + totalPages,
                ],
                pdf.internal.pageSize.getWidth() / 2,
                pdf.internal.pageSize.getHeight() - 0.6,
                { align: "center" }
              );
            }
            // Get the blob of the PDF
            return pdf.output("blob");
          })
          .then((pdfBlob) => {
            // Create a URL for the PDF blob
            var pdfUrl = URL.createObjectURL(pdfBlob);
  
            // Open the URL in a new tab
            const printWindow = window.open(
              pdfUrl,
              "_blank",
              "width=800,height=600"
            );
            printWindow.onload = () => {
              printWindow.print();
              URL.revokeObjectURL(pdfUrl);
            };
          })
          .catch((error) => {
            console.error("Error generating PDF:", error);
          });
      },
      filterTable() {
        this.getReceiptListing();
      },
      changePerPageNumber(data) {
        this.perPage = data;
        this.getReceiptListing();
      },
      async getInstitution() {
        this.isLoadingInstitution = true;
        await ApiService.get("/settings/institution")
          .then((response) => {
            this.institution = response.data.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.isLoadingInstitution = false;
          });
      },
    },
  };
  </script>