<template>
    <div>
      <div class="container">
        <div class="row">
         <div class="col-12 col-md-6">
          <label class="akkurate-dark" for="name">Name <star></star></label>
            <b-form-group
            id="name"
            label-for="name"
            class="akkurate-dark">
            <b-form-input 
                v-model="form.name" 
                id="name" 
                class="mb-3 mt-2 field-container fields"
                type="text" 
                placeholder="Enter Name"
                required>
            </b-form-input>
            </b-form-group>
         </div>
         <div class="col-12 col-md-6">
            <label class="akkurate-dark" for="base_type">Base Type <star></star></label>
            <b-form-group
                id="base_type"
                label-for="base_type">
                <multiselect v-if="add_or_edit == 'edit'" disabled class="mt-2 field-container" v-model="form.base_type" :options="base_type_options"></multiselect>
                <multiselect v-else class="mt-2 field-container" v-model="form.base_type" :options="base_type_options"></multiselect>
                <errors v-if="errors.base_type">
                  {{ errors.base_type[0] }}
                </errors>
            </b-form-group>
           </div>
         <div class="col-12 col-md-6">
            <b-form-group>
                <template #label>
                    <label class="akkurate-dark" for="withdrawal_allowed">Withdrawal Allowed <star></star></label>
                </template>
                <b-form-radio v-model="form.withdrawal_allowed" name="withdrawal_allowed" value="1">Yes</b-form-radio>
                <b-form-radio v-model="form.withdrawal_allowed" name="withdrawal_allowed" value="0">No</b-form-radio>
                <errors v-if="errors.withdrawal_allowed">
                  {{ errors.withdrawal_allowed[0] }}
                </errors>
            </b-form-group>
         </div>
         <div class="col-12 col-md-6">
            <b-form-group>
                <template #label>
                    <label class="akkurate-dark" for="deposit_allowed">Deposit Allowed <star></star></label>
                </template>
                <b-form-radio v-model="form.deposit_allowed" name="deposit_allowed" value="1">Yes</b-form-radio>
                <b-form-radio v-model="form.deposit_allowed" name="deposit_allowed" value="0">No</b-form-radio>
                <errors v-if="errors.deposit_allowed">
                  {{ errors.deposit_allowed[0] }}
                </errors>
            </b-form-group>
         </div>
         <div class="col-12 col-md-6">
          <label class="akkurate-dark mt-2" for="minimum_balance">Minimum Balance <star></star></label>
            <b-form-group
            id="minimum_balance"
            label-for="minimum_balance"
            class="akkurate-dark">
            <b-form-input 
                v-model="form.minimum_balance" 
                id="minimum_balance" 
                class="mb-3 mt-2 field-container fields"
                type="number" 
                placeholder="Enter Minimum Balance"
                required>
            </b-form-input>
            <errors v-if="errors.minimum_balance">
                  {{ errors.minimum_balance[0] }}
            </errors>
            </b-form-group>
         </div>
         <div class="col-12 col-md-6">
            <b-form-group>
                <template #label>
                    <label class="akkurate-dark mt-2" for="send_sms">Send Sms <star></star></label>
                </template>
                <b-form-radio v-model="form.send_sms" name="send_sms" value="1">Yes</b-form-radio>
                <b-form-radio v-model="form.send_sms" name="send_sms" value="0">No</b-form-radio>
                <errors v-if="errors.send_sms">
                  {{ errors.send_sms[0] }}
                </errors>
            </b-form-group>
         </div>
         <div class="col-12 col-md-6">
          <label class="akkurate-dark mt-2" for="daily_deposit_amount_limit">Daily Deposit Amount Limit (GHS)<star></star></label>
            <b-form-group
            id="daily_deposit_amount_limit"
            label-for="daily_deposit_amount_limit"
            class="akkurate-dark">
            <b-form-input 
                v-model="form.daily_deposit_amount_limit" 
                id="daily_deposit_amount_limit" 
                class="mb-3 mt-2 field-container fields"
                type="number" 
                placeholder="Enter Deposit Amount Limit"
                required>
            </b-form-input>
            <errors v-if="errors.daily_deposit_amount_limit">
                  {{ errors.daily_deposit_amount_limit[0] }}
            </errors>
            </b-form-group>
         </div>
         <div class="col-12 col-md-6">
          <label class="akkurate-dark mt-2" for="daily_deposit_frequency_limit">Daily Deposit Frequency Limit <star></star></label>
            <b-form-group
            id="daily_deposit_frequency_limit"
            label-for="daily_deposit_frequency_limit"
            class="akkurate-dark">
            <b-form-input 
                v-model="form.daily_deposit_frequency_limit" 
                id="daily_deposit_frequency_limit" 
                class="mb-3 mt-2 field-container fields"
                type="number" 
                placeholder="Enter Daily Deposit Frequency Limit"
                required>
            </b-form-input>
            <errors v-if="errors.daily_deposit_frequency_limit">
                  {{ errors.daily_deposit_frequency_limit[0] }}
            </errors>
            </b-form-group>
         </div>

         <div class="col-12 col-md-6">
          <label class="akkurate-dark mt-2" for="daily_withdrawal_amount_limit">Daily Withdrawal Amount Limit (GHS)<star></star></label>
            <b-form-group
            id="daily_withdrawal_amount_limit"
            label-for="daily_withdrawal_amount_limit"
            class="akkurate-dark">
            <b-form-input 
                v-model="form.daily_withdrawal_amount_limit" 
                id="daily_deposit" 
                class="mb-3 mt-2 field-container fields"
                type="number" 
                placeholder="Enter Withdrawal Amount Limit"
                required>
            </b-form-input>
            <errors v-if="errors.daily_withdrawal_amount_limit">
                  {{ errors.daily_withdrawal_amount_limit[0] }}
            </errors>
            </b-form-group>
         </div>

         <div class="col-12 col-md-6">
          <label class="akkurate-dark mt-2" for="daily_withdrawal_frequency_limit">Daily Withdrawal Frequency Limit <star></star></label>
            <b-form-group
            id="daily_withdrawal_frequency_limit"
            label-for="daily_withdrawal_frequency_limit"
            class="akkurate-dark">
            <b-form-input 
                v-model="form.daily_withdrawal_frequency_limit" 
                id="daily_withdrawal_frequency_limit" 
                class="mb-3 mt-2 field-container fields"
                type="number" 
                placeholder="Enter Daily Withdrawal Frequency Limit"
                required>
            </b-form-input>
            <errors v-if="errors.daily_withdrawal_frequency_limit">
                  {{ errors.daily_withdrawal_frequency_limit[0] }}
            </errors>
            </b-form-group>
         </div>

         <div class="col-12 col-md-6">
          <label class="akkurate-dark mt-2" for="unapproved_withdrawal_max_amount">Withdraw max threshold </label>
            <b-form-group
            id="unapproved_withdrawal_max_amount"
            label-for="unapproved_withdrawal_max_amount"
            class="akkurate-dark">
            <b-form-input 
                v-model="form.unapproved_withdrawal_max_amount" 
                id="unapproved_withdrawal_max_amount" 
                class="mb-3 mt-2 field-container fields"
                type="number" 
                placeholder="Enter Withdraw max threshold"
                max="100">
            </b-form-input>
            <errors v-if="errors.unapproved_withdrawal_max_amount">
                  {{ errors.unapproved_withdrawal_max_amount[0] }}
            </errors>
            </b-form-group>
         </div>

         <div class="col-12 col-md-6">
          <label class="akkurate-dark mt-2" for="coa_subheads">COA Head <star></star></label>
            <b-form-group
            id="coa_subheads"
            label-for="coa_subheads"
            class="akkurate-dark">
            <multiselect 
              :disabled="add_or_edit == 'edit'" 
              class="mt-2 field-container"
              v-model="form.coa_head" 
              :options="coa_heads_options"
              label="display_name">
            </multiselect>
            <errors v-if="errors.coa_head">
                  {{ errors.coa_head[0] }}
            </errors>
            </b-form-group>
         </div>
         
        </div>
      </div> 
    </div>
  </template>
  
<script>
import Multiselect from "vue-multiselect";
import ApiService from "@/core/services/api.service";
export default {
    components: { Multiselect },
    props: ["form","errors","add_or_edit"],
    mounted(){
    this.getCOAHead();
    },
    data() {
        return {
            base_type_options: ['Savings', 'Shares', 'Current', 'Group', 'Corporate'],
            coa_heads_options: [],
        }
    },
    methods:{
      async getCOAHead(){
        this.$Progress.start();
          await ApiService.post('/accounts/account-types/dropdown')
          .then((response) => {
            this.$Progress.finish();
            // console.log(response.data.data)
            this.coa_heads_options = response.data.coa_heads.map(index => {
              return {
                'id':index.id,
                'display_name':index.display_name
              }
            });
          }).catch((error) => {
            this.$Progress.fail();
            // console.log(error)
          })
      }
    }

   
}
</script>
  