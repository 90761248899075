<template>
    <div>
        <b-modal 
            :id="String(data.id + disbursement)"
            ref="modal"
            size="xl"
            @shown="modalShown"
            @ok="handleOk">
            <template #modal-title>
                <p class="m-0 fw-bold text-green">Loan Disbursement</p>
            </template>
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <form @submit.prevent="filterLoan">
                            <div class="row">
                                <div class="col-12 col-md-8">
                                    <b-form-group id="loan_code" label-for="loan_code">
                                        <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                            placeholder="Enter Loan Code" v-model="filter.loan_code" required></b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="mt-auto col">
                                    <b-button type="submit" :disabled="isSearching"
                                        class="akkurate-green-btn akkurate-auth-size w-100 mb-3"><font-awesome-icon class="me-2" :icon="['fas', 'search']" />{{ search }}</b-button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="card card-shape home-box">

                        <div class="card-header py-3">
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <h6 class="m-0 fw-bold text-green">Loan Account Preview</h6>
                                </div>
                            </div>
                        </div>
                        <div class="card-body" style="background-color:#eaeff2">
                            <div class="row">
                                <div class="col-3 col-md-3">
                                    <img class="img-fluid img-visibility ghana-police w-100 h-100"
                                        src="@/assets/img/user-avater.jpg">
                                </div>
                                <div class="col-3 col-md-3">
                                    <div class="row">
                                        <div class="col-12 col-md-12">
                                            <p class="fw-bold">{{ getFullNameAndId }}</p>
                                        </div>
                                        <!-- <div class="col-7 col-md-7">
                                                                <p class="mb-0 fw-bold"></p>
                                                                <p class="mb-0 fw-bold">Tenure</p>
                                                                <p class="mb-0 fw-bold">Interest Rate</p>
                                                                <p class="mb-0 fw-bold">Interest Method</p>
                                                                <p class="mb-0 fw-bold">Interest Expected</p>
                                                                <p class="mb-0 fw-bold">Interest Paid</p>
                                                                <p class="mb-0 fw-bold">Interest Due</p>

                                                                </div> -->
                                        <div class="col-12 col-md-12 small">
                                            <div class="d-flex">
                                                <p class="mb-0 fw-bold me-3">Status</p>
                                                <p class="mb-0">{{ loan.status }}</p>
                                            </div>
                                            <div class="d-flex">
                                                <p class="mb-0 fw-bold me-3">Tenure</p>
                                                <p class="mb-0">{{ loan.tenor }}</p>
                                            </div>
                                            <div class="d-flex">
                                                <p class="mb-0 fw-bold me-3">Interest Rate</p>
                                                <p class="mb-0">{{ loan.interest_rate }}</p>
                                            </div>
                                            <div class="d-flex">
                                                <p class="mb-0 fw-bold me-3">Interest Method</p>
                                                <p class="mb-0">{{ loan.interest_method }}</p>
                                            </div>
                                            <div class="d-flex">
                                                <p class="mb-0 fw-bold me-3">Interest Expected</p>
                                                <p class="mb-0">{{ loan.interest_balance }}</p>
                                            </div>
                                            <div class="d-flex">
                                                <p class="mb-0 fw-bold me-3">Interest Balance</p>
                                                <p class="mb-0">{{ loan.interest_balance - loan.interest_paid }}</p>
                                            </div>
                                            <div class="d-flex">
                                                <p class="mb-0 fw-bold me-3">Interest Paid</p>
                                                <p class="mb-0">{{ loan.interest_paid }}</p>
                                            </div>
                                            <div class="d-flex">
                                                <p class="mb-0 fw-bold me-3">Interest Due</p>
                                                <p class="mb-0">{{ loan.interest_due }}</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-3 col-md-3 small">
                                    <div class="row h-100">
                                        <div class="col-md-12">
                                            <div> <span class="fw-bold">Loan Type:</span> {{ loan?.loan_product.name }}</div>
                                            <div class="small"> <span class="fw-bold">Disbursement Date:</span> {{ loan.start_date
                                                }}
                                            </div>
                                            <div class="small"> <span class="fw-bold">Account No:</span>
                                                {{ loan.account.account_number }}</div>
                                        </div>
                                        <div class="col-md-12 mt-auto">
                                            <div class="row">
                                                <div class="col-md-6 fw-bold">Principal</div>
                                                <div class="col-md-6">{{ loan.principal_amount }}</div>
                                                <div class="col-md-6 fw-bold">Principal Paid</div>
                                                <div class="col-md-6">{{ loan.principal_paid }}</div>
                                                <div class="col-md-6 fw-bold">Principal Bal</div>
                                                <div class="col-md-6">{{ loan.principal_balance }}</div>
                                                <div class="col-md-6 fw-bold">Principal Due</div>
                                                <div class="col-md-6">{{ loan.principal_due }}</div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3 col-md-3 small">
                                    <div class="row h-100">
                                        <div class="col-md-12">
                                            <div> <span class="fw-bold">Loan Code:</span> {{ loan.loan_number }}</div>
                                            <div> <span class="fw-bold">Last Payment Date:</span> {{ loan.last_payment }}</div>
                                            <div> <span class="fw-bold">Delinquency:</span> {{ loan.delinquent }}</div>
                                        </div>
                                        <div class="col-md-12 mt-auto">
                                            <div class="row">
                                                <div class="col-md-6 fw-bold">Share Bal</div>
                                                <div class="col-md-6">{{ loan?.loan_product.min_share_balance }}</div>
                                                <div class="col-md-6 fw-bold">Saving Bal</div>
                                                <div class="col-md-6">{{ loan.account?.account_balance }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="text-end my-2">
                                                            <b-button @click="ok()" type="submit" class="akkurate-green-btn me-2 btn-size float-right">Statement</b-button>
                                                    </div> -->
                        <div class="card-header akkurate-green fw-bold">
                            Loan Disbursement
                        </div>
                        <hr class="m-0" />
                        <div class="card-body">
                            <div class="container">
                                <div class="row">
                                    <div class="col-12 col-md-4 mt-2">
                                        <label class="akkurate-dark" for="loan_code">Release Date<star></star></label>
                                        <b-form-group id="date" label-for="date">
                                            <b-form-input class="mt-2 field-container fields" type="date" v-model="form.release_date"
                                                ></b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="col-12 col-md-4 mt-2">
                                        <label class="akkurate-dark" for="amount">Amount <star></star></label>
                                        <b-form-group id="amount" label-for="amount">
                                            <b-form-input id="amount" name="amount" class="mt-2 field-container fields" type="text"
                                                v-model="loan.principal_amount" disabled></b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="col-12 col-md-4 mt-2">
                                        <label class="akkurate-dark" for="note">Note</label>
                                        <b-form-group id="note" label-for="note">
                                            <b-form-input v-model="form.note" class="mt-2 field-container fields"
                                                type="text"></b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="col-12 col-md-4 mt-2">
                                        <label class="akkurate-dark" for="payment_method">Payment method</label>
                                        <b-form-group id="payment_method" label-for="payment_method">
                                            <multiselect class="mt-2 field-container" v-model="form.payment_method"
                                                @select="setPaymentMethod" :allow-empty="false" :options="payment_method_options">
                                            </multiselect>
                                        </b-form-group>
                                    </div>
                                    <div class="col-12 col-md-4 mt-2" >
                                        <label class="akkurate-dark" for="product">Receiving Account</label>
                                        <b-form-group id="product" name="product" :state="validateState('product')"
                                            label-for="product">
                                            <multiselect id="product" name="product" class="mt-2 field-container" v-model="product"
                                                @select="setBalance" label="name"
                                                :options="product_options"
                                                :disabled="form.payment_method!='Transfer'">
                                                >
                                            </multiselect>
                                        </b-form-group>
                                        <b-form-invalid-feedback>
                                            {{ veeErrors.first('product') }}
                                        </b-form-invalid-feedback>

                                    </div>
                                    <div class="col-12 col-md-4 mt-2">
                                        <label class="akkurate-dark" for="balance">Balance</label>
                                        <b-form-group id="balance" label-for="balance">
                                            <b-form-input class="mt-2 field-container fields" type="text" v-model="balance"
                                                disabled></b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="col-12 col-md-4 mt-2" v-show="cheque">
                                        <label class="akkurate-dark" for="bank">Bank</label>
                                        <b-form-group id="bank" label-for="bank">
                                            <b-form-input class="mt-2 field-container fields" type="text"
                                                v-model="form.bank"></b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="col-12 col-md-4 mt-2" v-show="cheque">
                                        <label class="akkurate-dark" for="cheque_no">Cheque No.</label>
                                        <b-form-group id="cheque_no" label-for="cheque_no">
                                            <b-form-input class="mt-2 field-container fields" type="text"
                                                v-model="form.cheque_no"></b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="col-12 col-md-4 mt-2" v-show="momo">
                                        <label class="akkurate-dark" for="mobile_no">Mobile Number</label>
                                        <b-form-group id="mobile_no" label-for="mobile_no">
                                            <b-form-input class="mt-2 field-container fields" type="text"
                                                v-model="form.mobile_no"></b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="col-12 col-md-4 mt-2" v-show="momo">
                                        <label class="akkurate-dark" for="transaction_ref">Transaction Reference</label>
                                        <b-form-group id="transaction_ref" label-for="transaction_ref">
                                            <b-form-input class="mt-2 field-container fields" type="text"
                                                v-model="form.transaction_ref"></b-form-input>
                                        </b-form-group>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <hr class="m-0" />
                        <div class="card-body">
                            <div class="row">

                                <div class="col-md-10">
                                    <div class="row fw-bold">
                                        <div class="col-md-3"></div>
                                        <div class="col-md-5">
                                            <label>GL Name</label>
                                        </div>
                                        <div class="col-md-2 border-end">
                                            <label>DR Amount</label>
                                        </div>
                                        <div class="col-md-2">
                                            <label>CR Amount</label>
                                        </div>

                                    </div>
                                    <!-- begin loader -->
                                    <beat-loader-component v-if="isLoading" :isLoading="isLoading"></beat-loader-component>
                                    <!-- end loader -->

                                    <div v-for="(field, index) in form.coas" :key="index"
                                        class="row align-items-center justify-content-center">
                                        <div class="col-md-3">
                                            {{ field.name }}
                                        </div>
                                        <div class="col-md-5">
                                            <b-form-group id="debit_gl" label-for="debit_gl">
                                                <multiselect class="mt-2 field-container" v-model="field.coa_sub" label="name"
                                                    :options="field.coa_subheads_options" :disabled="field.disable_coa">
                                                </multiselect>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-2 border-end">
                                            <b-form-group id="text" label-for="text">
                                                <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                                    v-model="field.dr_amount" disabled></b-form-input>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-2">
                                            <b-form-group id="text" label-for="text">
                                                <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                                    v-model="field.cr_amount" disabled></b-form-input>
                                            </b-form-group>
                                        </div>
                                    </div>

                                    <div class="row mt-2 fw-bold">
                                        <div class="col-md-3"></div>
                                        <div class="col-md-5">
                                            <label>Total</label>
                                        </div>
                                        <div class="col-md-2 border-end">
                                            <label>{{ parseFloat(dr_total).toFixed(2) }}</label>
                                        </div>
                                        <div class="col-md-2">
                                            <label>{{ parseFloat(cr_total).toFixed(2) }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <template #modal-footer="{ ok }">
                    <div class="w-100">
                    <!-- begin loader -->
                    <!-- <beat-loader-component 
                    class="float-left"
                    v-if="isLoading"
                    :isLoading="isLoading"
                    ></beat-loader-component> -->
                    <!-- end loader -->
                    <!-- Emulate built in modal footer ok and cancel button actions -->
                    <b-button @click="ok()" :disabled="isSaving" type="submit" class="akkurate-green-btn me-2 btn-size float-right"><font-awesome-icon class="me-2" :icon="['fas', 'save']" />Disburse</b-button>     
                    </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import BeatLoaderComponent from "@/views/components/Loader/BeatLoaderComponent.vue";
import Multiselect from 'vue-multiselect'
import ApiService from "@/core/services/api.service";
export default {
    props: ['data', 'disbursement','loan_account'],
    components: {
        Multiselect,
        BeatLoaderComponent,
    },
    data() {
        return {
            ...this.initialState(),
            filter: {
                loan_code: "",
            },
            loan: {
                status: "",
                tenor: "",
                interest_rate: "",
                interest_method: "",
                expected_interest: "",
                interest_balance: "",
                interest_paid: "",
                interest_balance: "",
                loan_number: "",
                principal_amount: "",
                principal_paid: "",
                principal_balance: "",
                principal_due: "",
                loan_product: "",
                account: "",
                start_date: "",
                last_payment: "",
                interest_due: "",
                account_number: "",
                entry_date: "",
            },
            product: "",
            balance: "",
            cash: true,
            transfer: false,
            cheque: false,
            momo: false,
            payment_method_options: ['Cash', 'Cheque', 'Transfer', 'DirectMoMoCredit'],
            product_options: [],
            search: "Search",
            isSearching: false,
            isSaving: false,
            isLoading: false,
            total: "",
            payment_arr: [],
            dr_total: 0,
            cr_total: 0,
        }
    },
    computed: {
        getFullNameAndId() {
            const first_name = this.loan?.account?.customer?.first_name != undefined ? this.loan?.account?.customer?.first_name : "";
            const middle_name = this.loan?.account?.customer?.middle_name != undefined ? this.loan?.account?.customer?.middle_name : "";
            const last_name = this.loan?.account?.customer?.last_name != undefined ? this.loan?.account?.customer?.last_name : "";
            const code = this.loan?.account?.customer?.code != undefined ? this.loan?.account?.customer?.code : "";
            return first_name + ' ' + middle_name + ' ' + last_name + ' - ' + code;

        },
    },
    created() {
            
        },
    methods: {
        
        filterLoan() {
            this.search = "processing...";
            this.isSearching = true;
            ApiService.get(`/loans/${this.filter.loan_code}`)
                .then((respp) => {
                    // this.loan_account = response.data.data;
                    // console.log(respp.data)
                    this.form.loan_number = this.filter.loan_code;
                    this.loan.principal_amount = respp?.data?.data?.principal_amount;
                    this.getLoanDisbursement(this.filter.loan_code);
                }).catch((error) => {
                    this.search = "Search";
                    this.isSearching = false;
                    if (error.response.status == 404) {
                        swal.fire({
                            icon: "error",
                            title: error.response.statusText,
                            text: "Something went wrong!",
                            showCancelButton: false, // Show the "Cancel" button
                            confirmButtonText: 'OK', // Customize the text for the "OK" button
                            buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                            customClass: {
                                confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                            }
                            // footer: '<a href="#">Why do I have this issue?</a>'
                        });
                    }
                })
        },
        async getLoanDisbursement(loan_id) {
            const product_arr = [];
            this.isLoading = true;
            await ApiService.query(`/loans/loanDisbursement/dropdown/${loan_id}`, {
                params: {
                    'loan_kind': 'NEW_LOAN'
                }
            }).then((response) => {
                response.data?.accounts.map((index) => {
                    product_arr.push({ 'id': index.id, 'name': index.account_type?.name, 'balance': index.account_balance });
                })
                this.product_options = product_arr;
                this.form.coas = response.data?.coas;
                this.subheads = response.data?.coa_subheads;
                if (this.form?.payment_method == 'Cash') {
                    this.subheads.map((index) => {
                        if (index.master == 1) {
                            this.payment_arr.push(index);
                        }
                    });

                }
                this.form.coas.map((index) => {
                    this.dr_total += index.dr_amount;
                    this.cr_total += index.cr_amount;
                    switch (index.name) {
                        case "Source of Funds":
                            index.coa_sub.name = "";
                            index.coa_subheads_options = this.payment_arr;
                            break;
                        default:
                            index.coa_subheads_options = response.data?.coa_subheads;
                            break;
                    }

                });
                this.total = this.calculateSum(response.data.data.account.transactions, "trans_amount");
                this.search = "Search";
                this.isSearching = false;
                this.isLoading = false;
            }).catch((error) => {
                this.search = "Search";
                this.isSearching = false;
                this.isLoading = false;
            })
        },
        calculateSum(array, property) {
            const total = array.reduce((accumulator, object) => {
                return accumulator + object[property];
            }, 0);
            // console.log(total);
            return total;
        },
        setBalance(index) {
            this.form.product_id = this.product?.id;
            this.balance = index.balance;
        },
        setPaymentMethod(index) {
            switch (index) {
                case "Cash":
                    this.payment_arr = [];
                    this.transfer = false;
                    this.cheque = false;
                    this.cash = true;
                    this.momo = false;
                    this.subheads.map((index) => {
                        if (index.master == 1) {
                            this.payment_arr.push(index);
                        }
                    });
                    this.form.coas.map((index) => {
                        switch (index.name) {
                            case "Source of Funds":
                                index.coa_sub = "";
                                index.coa_subheads_options = this.payment_arr;
                                break;
                            default:
                                index.coa_subheads_options = index.coa_subheads_options;
                                break;
                        }

                    });
                    break;
                case "Cheque":
                    this.payment_arr = [];
                    this.transfer = false;
                    this.cheque = true;
                    this.cash = false;
                    this.momo = false;
                    this.subheads.map((index) => {
                        if (index.master == 2) {
                            this.payment_arr.push(index);
                        }
                    });
                    this.form.coas.map((index) => {
                        switch (index.name) {
                            case "Source of Funds":
                                index.coa_sub = "";
                                index.coa_subheads_options = this.payment_arr;
                                break;
                            default:
                                index.coa_subheads_options = index.coa_subheads_options;
                                break;
                        }

                    });
                    break;
                case "Transfer":
                    this.payment_arr = [];
                    this.transfer = true;
                    this.cheque = false;
                    this.cash = false;
                    this.momo = false;
                    this.subheads.map((index) => {
                        if (index.master == 9) {
                            this.payment_arr.push(index);
                        }
                    });
                    this.form.coas.map((index) => {
                        switch (index.name) {
                            case "Source of Funds":
                                index.coa_sub = "";
                                index.coa_subheads_options = this.payment_arr;
                                break;
                            default:
                                index.coa_subheads_options = index.coa_subheads_options;
                                break;
                        }

                    });

                    break;
                case "DirectMoMoCredit":
                    this.payment_arr = [];
                    this.transfer = false;
                    this.cheque = false;
                    this.cash = false;
                    this.momo = true;
                    this.subheads.map((index) => {
                        if (index.master == 2) {
                            this.payment_arr.push(index);
                        }
                    });
                    this.form.coas.map((index) => {
                        switch (index.name) {
                            case "Source of Funds":
                                index.coa_sub = "";
                                index.coa_subheads_options = this.payment_arr;
                                break;
                            default:
                                index.coa_subheads_options = index.coa_subheads_options;
                                break;
                        }

                    });
                    break;
            }
            // switch (index) {
            //     case "Transfer":
            //         this.payment_arr = [];
            //         this.transfer = true;
            //         this.cheque = false;
            //         this.cash = false;
            //         this.momo = false;
            //         this.subheads.map((index) => {
            //             if (index.master == 9) {
            //                 this.payment_arr.push(index);
            //             }
            //         });
            //         this.form.coas.map((index) => {
            //             switch (index.name) {
            //                 case "Source of Funds":
            //                     index.coa_sub = "";
            //                     index.coa_subheads_options = this.payment_arr;
            //                     break;
            //                 default:
            //                     index.coa_subheads_options = index.coa_subheads_options;
            //                     break;
            //             }

            //         });

            //         break;
            //     case "Cheque":
            //         this.payment_arr = [];
            //         this.transfer = false;
            //         this.cheque = true;
            //         this.cash = false;
            //         this.momo = false;
            //         this.subheads.map((index) => {
            //             if (index.master == 2) {
            //                 this.payment_arr.push(index);
            //             }
            //         });
            //         this.form.coas.map((index) => {
            //             switch (index.name) {
            //                 case "Source of Funds":
            //                     index.coa_sub = "";
            //                     index.coa_subheads_options = this.payment_arr;
            //                     break;
            //                 default:
            //                     index.coa_subheads_options = index.coa_subheads_options;
            //                     break;
            //             }

            //         });
            //         break;
            //     case "Cash":
            //         this.payment_arr = [];
            //         this.transfer = false;
            //         this.cheque = false;
            //         this.cash = true;
            //         this.momo = false;
            //         this.subheads.map((index) => {
            //             if (index.master == 1) {
            //                 this.payment_arr.push(index);
            //             }
            //         });
            //         this.form.coas.map((index) => {
            //             switch (index.name) {
            //                 case "Source of Funds":
            //                     index.coa_sub = "";
            //                     index.coa_subheads_options = this.payment_arr;
            //                     break;
            //                 default:
            //                     index.coa_subheads_options = index.coa_subheads_options;
            //                     break;
            //             }

            //         });
            //         break;
            //     case "DirectMoMoCredit":
            //         this.payment_arr = [];
            //         this.transfer = false;
            //         this.cheque = false;
            //         this.cash = false;
            //         this.momo = true;
            //         this.subheads.map((index) => {
            //             if (index.master == 2) {
            //                 this.payment_arr.push(index);
            //             }
            //         });
            //         this.form.coas.map((index) => {
            //             switch (index.name) {
            //                 case "Source of Funds":
            //                     index.coa_sub = "";
            //                     index.coa_subheads_options = this.payment_arr;
            //                     break;
            //                 default:
            //                     index.coa_subheads_options = index.coa_subheads_options;
            //                     break;
            //             }

            //         });
            //         break;
            // }
        },
        validateState(ref) {
            if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        handleOk(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault();
            // Trigger submit handler
            this.handleSubmit()
        },
        handleSubmit() {
            // Exit when the form isn't valid
            this.$validator.validateAll().then(result => {
                if (!result) {
                    // console.log(result);
                    return;
                }
                this.storeLoanDisbursement();

            });
        },
        async storeLoanDisbursement() {
            this.isSaving = true;
            this.$Progress.start();
            console.log(this.form);
            await ApiService.post("loans/loanDisbursement", this.form)
                .then((response) => {
                    this.isSaving = false;
                    this.$Progress.finish();
                    // toast.fire({
                    //     icon: "success",
                    //     title: response.data.message,
                    // });
                    swal.fire({
                        title: response.data.message,
                        icon: "success",
                        showCancelButton: false,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Ok",
                        allowOutsideClick: false
                        }).then(result => {
                        if (result.value) {
                              window.location.reload();
                        }
                    });
                    
                    // this.form.reset();
                    this.form = this.initialState().form;
                })
                .catch((error) => {
                    this.isSaving = false;
                    this.$Progress.fail();
                    // if (error.response.status == 422) {
                        swal.fire({
                            icon: "error",
                            title: error.response.statusText,
                            text: error.response?.data?.message,
                            showCancelButton: false, // Show the "Cancel" button
                            confirmButtonText: 'Close', // Customize the text for the "OK" button
                            buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                            customClass: {
                                confirmButton: 'btn akkurate-gray-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                            }
                            // footer: '<a href="#">Why do I have this issue?</a>'
                        });
                    // }
                    if (error.response.data.errors) {
                        this.form.errors = error.response.data.errors;
                    }
                });
        },
        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        },
        modalShown() {
            if (this.data?.id != 'app_disbursement') {
                // this.filter.loan_code = this.data?.loan?.loan_number;
                this.filter.loan_code = this.loan_account?.loan_number;
                this.filterLoan();
            }
        },
        initialState() {
            return {
                form: {
                    loan_number: "",
                    date: this.formatDate(Date.now()),
                    note: "",
                    source: "Web",
                    bank: "",
                    cheque_number: "",
                    payment_method: "Cash",
                    product_id: "",
                    coas: [],
                    errors: [],
                    release_date: this.formatDate(Date.now()),
                },
            }
        }
    }

}
</script>