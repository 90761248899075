<template>
     <div class="row">
            <div class="col-12 col-md-4 mt-4">
            <label class="akkurate-dark" for="first_name">First Name <star></star></label>
                <b-form-group
                id="first_name"
                label-for="first_name">
                <b-form-input
                class="mb-3 mt-2 field-container fields"
                id="first_name"
                type="text"
                placeholder="Enter First Name"
                required
                :value="form.first_name"
                ></b-form-input>
                </b-form-group>
            </div>
            <div class="col-12 col-md-4 mt-4">
                <label class="akkurate-dark" for="middle_name">Middle Name <star></star></label>
                <b-form-group
                id="middle_name"
                label-for="middle_name">
                <b-form-input
                class="mb-3 mt-2 field-container fields"
                id="middle_name"
                type="text"
                placeholder="Enter Middle Name"
                :value="form.middle_name"
                required
                ></b-form-input>
                </b-form-group>
            </div>
            <div class="col-12 col-md-4 mt-4">
                <label class="akkurate-dark" for="surname">Surname <star></star></label>
                <b-form-group
                id="surname"
                label-for="surname">
                <b-form-input
                class="mb-3 mt-2 field-container fields"
                id="surname"
                type="text"
                placeholder="Enter Surname"
                :value="form.surname"
                required
                ></b-form-input>
                </b-form-group>
            </div>
            <div class="col-12 col-md-4 mt-4">
                <label class="akkurate-dark" for="phone">Phone Number <star></star></label>
                <b-form-group
                id="phone"
                label-for="phone">
                <b-form-input
                class="mb-3 mt-2 field-container fields"
                id="phone"
                type="text"
                placeholder="Enter Phone Number"
                required
                :value="form.phone_number"
                ></b-form-input>
                </b-form-group>
            </div>
            <div class="col-12 col-md-4 mt-4">
                <label class="akkurate-dark" for="email">Email Address <star></star></label>
                <b-form-group
                id="email"
                label-for="email">
                <b-form-input
                class="mb-3 mt-2 field-container fields"
                id="email"
                type="email"
                placeholder="Enter Email Address"
                required
                :value="form.email"
                readonly
                disabled
                ></b-form-input>
                </b-form-group>
            </div>
            <div class="col-12 col-md-4 mt-4">
                <label class="akkurate-dark" for="username">Username <star></star></label>
                <b-form-group
                id="username"
                label-for="username">
                <b-form-input
                class="mb-3 mt-2 field-container fields"
                id="username"
                type="text"
                placeholder="Enter Username"
                required
                :value="form.username"
                readonly
                disabled
                ></b-form-input>
                </b-form-group>
            </div>
            <div class="col-12 col-md-4 mt-4">
                <label class="akkurate-dark" for="address">Address <star></star></label>
                <b-form-textarea
                id="address"
                placeholder="Enter Address..."
                rows="3"
                max-rows="6"
                :value="form.address"
                required
                ></b-form-textarea>
            </div>
            <div class="col-12 col-md-4 mt-4">
                <label class="akkurate-dark" for="roles">Roles <star></star></label>
                <b-form-group
                id="roles"
                label-for="roles">
                <b-form-input
                class="mb-3 mt-2 field-container fields"
                id="roles"
                type="text"
                placeholder="Enter Roles"
                required
                readonly
                disabled
                :value="roles.display_name"
                ></b-form-input>
                </b-form-group>
            </div>
            <div class="col-12 col-md-4 mt-4">
                <b-form-group>
                    <template #label>
                        <label class="akkurate-dark" for="status">Status <star></star></label>
                    </template>
                    <b-form-radio v-model="form.is_active" name="status" value="1">Active</b-form-radio>
                    <b-form-radio v-model="form.is_active" name="status" value="2">Inactive</b-form-radio>
                </b-form-group>
            </div>
     </div>
</template>
<script>
export default {
    name: "OverviewForm",
    props: ['form', 'roles'],
}
</script>