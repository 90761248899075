<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 col-xl-3 mb-4">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
            </b-card>
          </template>
          <div class="card border-start-primary py-2 card-shape home-box">
            <div class="card-body">
              <div class="row align-items-center no-gutters">
                <div class="col-12 col-md-12">
                  <div class="text-uppercase fw-bold text-sm mb-1 akkurate-green">
                    <span>Deposits</span>
                  </div>
                </div>
                <div class="col-4">
                  <div class="text-uppercase text-xs mb-1 akkurate-green">
                    <span>Today</span>
                  </div>
                </div>
                <div class="col-4">
                  <div class="text-uppercase text-xs mb-1 akkurate-green">
                    <span>{{ today_deposit_count }}</span>
                  </div>
                </div>
                <div class="col-4">
                  <div
                    class="text-uppercase fw-bold text-xs mb-1 akkurate-green"
                  >
                    <span>{{ convertNumberToCurrency(today_deposit) }}</span>
                  </div>
                </div>
                <div class="col-4">
                  <div class="text-uppercase text-xs mb-1 akkurate-green">
                    <span>Month</span>
                  </div>
                </div>
                <div class="col-4">
                  <div class="text-uppercase text-xs mb-1 akkurate-green">
                    <span>{{ month_deposit_count }}</span>
                  </div>
                </div>
                <div class="col-4">
                  <div
                    class="text-uppercase fw-bold text-xs mb-1 akkurate-green"
                  >
                    <span>{{ convertNumberToCurrency(month_deposit) }}</span>
                  </div>
                </div>
                <div class="col-4">
                  <div class="text-uppercase text-xs mb-1 akkurate-green">
                    <span>Annual</span>
                  </div>
                </div>
                <div class="col-4">
                  <div class="text-uppercase text-xs mb-1 akkurate-green">
                    <span>{{ yearly_deposit_count }}</span>
                  </div>
                </div>
                <div class="col-4">
                  <div
                    class="text-uppercase fw-bold text-xs mb-1 akkurate-green"
                  >
                    <span>{{ convertNumberToCurrency(yearly_deposit) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>
      <div class="col-md-6 col-xl-3 mb-4">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
            </b-card>
          </template>
          <div class="card border-start-success py-2 card-shape home-box">
            <div class="card-body">
              <div class="row align-items-center no-gutters">
                <div class="col-12 col-md-12">
                  <div
                    class="text-uppercase fw-bold text-sm mb-1 akkurate-danger"
                  >
                    <span>Withdrawals</span>
                  </div>
                </div>
                <div class="col-4">
                  <div class="text-uppercase text-xs mb-1 akkurate-danger">
                    <span>Today</span>
                  </div>
                </div>
                <div class="col-4">
                  <div class="text-uppercase text-xs mb-1 akkurate-danger">
                    <span>{{ today_withdrawal_count }}</span>
                  </div>
                </div>
                <div class="col-4">
                  <div
                    class="text-uppercase fw-bold text-xs mb-1 akkurate-danger"
                  >
                    <span>{{ convertNumberToCurrency(today_withdrawal) }}</span>
                  </div>
                </div>
                <div class="col-4">
                  <div class="text-uppercase text-xs mb-1 akkurate-danger">
                    <span>Month</span>
                  </div>
                </div>
                <div class="col-4">
                  <div class="text-uppercase text-xs mb-1 akkurate-danger">
                    <span>{{ month_withdrawal_count }}</span>
                  </div>
                </div>
                <div class="col-4">
                  <div
                    class="text-uppercase fw-bold text-xs mb-1 akkurate-danger">
                    <span>{{
                      convertNumberToCurrency(month_withdrawal)
                    }}</span>
                  </div>
                </div>
                <div class="col-4">
                  <div class="text-uppercase text-xs mb-1 akkurate-danger">
                    <span>Annual</span>
                  </div>
                </div>
                <div class="col-4">
                  <div class="text-uppercase text-xs mb-1 akkurate-danger">
                    <span>{{ yearly_withdrawal_count }}</span>
                  </div>
                </div>
                <div class="col-4">
                  <div
                    class="text-uppercase fw-bold text-xs mb-1 akkurate-danger">
                    <span>{{
                      convertNumberToCurrency(yearly_withdrawal)
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>
      <div class="col-md-6 col-xl-3 mb-4">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
            </b-card>
          </template>
          <div class="card border-start-success py-2 card-shape home-box">
            <div class="card-body">
              <div class="row align-items-center no-gutters">
                <div class="col-12 col-md-12">
                  <div
                    class="text-uppercase fw-bold text-sm mb-1 akkurate-gray"
                  >
                    <span>Account Opened Today</span>
                  </div>
                </div>
                <div class="col-6">
                  <div class="text-uppercase text-xs mb-1 akkurate-gray">
                    <span>Pending</span>
                  </div>
                </div>
                <div class="col-6">
                  <div class="text-uppercase text-xs mb-1 akkurate-gray">
                    <span>{{  pending_today }}</span>
                  </div>
                </div>
                <!-- <div class="col-4">
                  <div
                    class="text-uppercase fw-bold text-xs mb-1 akkurate-gray"
                  >
                    <span>{{ convertNumberToCurrency(today_withdrawal) }}</span>
                  </div>
                </div> -->
                <div class="col-6">
                  <div class="text-uppercase text-xs mb-1 akkurate-gray">
                    <span>Approved</span>
                  </div>
                </div>
                <div class="col-6">
                  <div class="text-uppercase text-xs mb-1 akkurate-gray">
                    <span>{{ approved_today }}</span>
                  </div>
                </div>
                <!-- <div class="col-4">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-gray">
                    <span>{{
                      convertNumberToCurrency(approved)
                    }}</span>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>
    </div>
    <!-- <MobileMenu :mbhome="true" v-show="mobile_menu" /> -->
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
export default {
  data() {
    return {
      today_deposit: 0,
      today_deposit_count: 0,
      month_deposit: 0,
      month_deposit_count: 0,
      yearly_deposit: 0,
      yearly_deposit_count: 0,
      today_withdrawal: 0,
      today_withdrawal_count: 0,
      month_withdrawal: 0,
      month_withdrawal_count: 0,
      yearly_withdrawal: 0,
      yearly_withdrawal_count: 0,
      pending_today:0,
      approved_today:0,
      loading: false,
    };
  },
  mounted() {
    this.getGenMangDashboardApi();
  },
  methods: {
    convertNumberToCurrency(number) {
      const formattedCurrency = number?.toLocaleString("en-US", {
        style: "currency",
        currency: "GHS", // Change this to your desired currency code (e.g., 'EUR', 'GBP', 'JPY', etc.)
      });
      return formattedCurrency;
    },
    async getGenMangDashboardApi() {
      // Your API call logic goes here
      //   console.log("GEN Man API called!");
      this.loading = true;
      await ApiService.query("/dashboard/mobile-banker")
        .then((response) => {
          console.log(response.data);
          const result = response.data;
          this.today_deposit = result?.today_deposit;
          this.today_deposit_count = result?.today_deposit_count;
          this.month_deposit = result?.month_deposit;
          this.month_deposit_count = result?.month_deposit_count;
          this.yearly_deposit = result?.year_deposit;
          this.yearly_deposit_count = result?.year_deposit_count;

          this.today_withdrawal = result?.today_withdrawal;
          this.today_withdrawal_count = result?.today_withdrawal_count;
          this.month_withdrawal = result?.month_withdrawal;
          this.month_withdrawal_count = result?.month_withdrawal_count;
          this.yearly_withdrawal = result?.year_withdrawal;
          this.yearly_withdrawal_count = result?.year_withdrawal_count;

          this.pending_today = result?.pending_today;
          this.approved_today = result?.approved_today;
         
        })
        .catch(function (error) {
          // this.isLoading = false;
          // return error;
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>