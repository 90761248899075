<template>
         <div>
            <b-modal 
            :id="String(data.id + authorization)"
            ref="modal"
            size="xl"
            @shown="modalShown"
            @ok=""
            >
            <template #modal-title>
                <p class="m-0 fw-bold text-green">Authorization Hub</p>
            </template>
            <div class="row">
               <div class="col-12">
                  <div class="row">
                     <form @submit.prevent="filterLoan">
                        <div class="row">
                              <!-- <div class="col-12 col-md-4">
                                 <b-form-group
                                 id="loan-re-date"
                                 label-for="loan-re-date">
                                 <multiselect class="mt-2 field-container" v-model="loan" :options="loan_options"></multiselect>
                                 </b-form-group>
                              </div> -->
                              <div class="col-12 col-md-8">
                                    <b-form-group
                                    id="loan_code"
                                    label-for="loan_code">
                                    <b-form-input
                                    class="mb-3 mt-2 field-container fields"
                                    type="text"
                                    placeholder="Enter Loan Number"
                                    v-model="filter.loan_number"
                                    required
                                    ></b-form-input>
                                    </b-form-group>
                              </div>
                              <div class="mt-auto col">
                              <b-button type="submit" :disabled="isSearching" class="akkurate-green-btn akkurate-auth-size w-100 mb-3"><font-awesome-icon class="me-2" :icon="['fas', 'search']" />{{ search }}</b-button>
                              </div>
                           </div>
                     </form>
                  </div>
                  <div class="card card-shape home-box">
                     
                        <div class="card-header py-3">
                              <div class="row">
                                 <div class="col-12 col-md-12">
                                    <h6 class="m-0 fw-bold text-green">Loan Account Preview</h6>
                                 </div>
                              </div>
                           </div>
                           <div class="card-body" style="background-color:#eaeff2">
                              <div class="row">
                                 <div class="col-3 col-md-3">
                                    <img class="img-fluid img-visibility  w-100 h-100" src="@/assets/img/user-avater.jpg">
                                 </div>
                                 <div class="col-3 col-md-3">
                                    <div class="row">
                                       <div class="col-12 col-md-12">
                                          <p class="fw-bold">{{ getFullName }}</p>
                                       </div>
                                       <!-- <div class="col-7 col-md-7">
                                          <p class="mb-0 fw-bold"></p>
                                          <p class="mb-0 fw-bold">Tenure</p>
                                          <p class="mb-0 fw-bold">Interest Rate</p>
                                          <p class="mb-0 fw-bold">Interest Method</p>
                                          <p class="mb-0 fw-bold">Interest Expected</p>
                                          <p class="mb-0 fw-bold">Interest Paid</p>
                                          <p class="mb-0 fw-bold">Interest Due</p>

                                       </div> -->
                                       <div class="col-12 col-md-12 small">
                                          <div class="d-flex"> 
                                             <p class="mb-0 fw-bold me-3">Status</p>
                                             <p class="mb-0">{{ loan.status }}</p>
                                          </div>
                                          <div class="d-flex">
                                             <p class="mb-0 fw-bold me-3">Tenure</p>
                                             <p class="mb-0">{{ loan.tenor }}</p>
                                          </div>
                                          <div class="d-flex">
                                             <p class="mb-0 fw-bold me-3">Interest Rate</p>
                                             <p class="mb-0">{{ loan.interest_rate }}</p>
                                          </div>
                                          <div class="d-flex">
                                             <p class="mb-0 fw-bold me-3">Interest Method</p>
                                             <p class="mb-0">{{ loan.interest_method }}</p>
                                          </div>
                                          <div class="d-flex">
                                             <p class="mb-0 fw-bold me-3">Interest Expected</p>
                                             <p class="mb-0">{{ loan.interest_balance }}</p>
                                          </div>
                                          <div class="d-flex">
                                             <p class="mb-0 fw-bold me-3">Interest Balance</p>
                                             <p class="mb-0">{{ loan.interest_balance - loan.interest_paid }}</p>
                                          </div>
                                          <div class="d-flex">
                                             <p class="mb-0 fw-bold me-3">Interest Paid</p>
                                             <p class="mb-0">{{ loan.interest_paid }}</p>
                                          </div>
                                          <div class="d-flex">
                                             <p class="mb-0 fw-bold me-3">Interest Due</p>
                                             <p class="mb-0">{{ loan.interest_due }}</p>
                                          </div>
                                       </div>
                                    
                                    </div>
                                 </div>
                                 <div class="col-3 col-md-3 small">
                                    <div class="row h-100">
                                       <div class="col-md-12">
                                          <div> <span class="fw-bold">Loan Type:</span> {{ loan?.loan_product.name }}</div>
                                          <div class="small"> <span class="fw-bold">Disbursement Date:</span> {{ loan.start_date }}</div>
                                       </div>
                                       <div class="col-md-12 mt-auto">
                                          <div class="row">
                                             <div class="col-md-6 fw-bold">Principal</div>
                                             <div class="col-md-6">{{ loan.principal_amount }}</div>
                                             <div class="col-md-6 fw-bold">Principal Paid</div>
                                             <div class="col-md-6">{{ loan.principal_paid }}</div>
                                             <div class="col-md-6 fw-bold">Principal Bal</div>
                                             <div class="col-md-6">{{ loan.principal_balance }}</div>
                                             <div class="col-md-6 fw-bold">Principal Due</div>
                                             <div class="col-md-6">{{ loan.principal_due }}</div>

                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-3 col-md-3 small">
                                    <div class="row h-100">
                                       <div class="col-md-12">
                                          <div> <span class="fw-bold">Loan Code:</span> {{ loan.loan_code }}</div>
                                          <div> <span class="fw-bold">Last Payment Date:</span> {{ loan.last_payment }}</div>
                                          <div> <span class="fw-bold">Delinquency:</span> {{ this.loan.delinquent }}</div>
                                       </div>
                                       <div class="col-md-12 mt-auto">
                                          <div class="row">
                                             <div class="col-md-6 fw-bold">Share Bal</div>
                                             <div class="col-md-6">{{ loan?.loan_product.min_share_balance }}</div>
                                             <div class="col-md-6 fw-bold">Saving Bal</div>
                                             <div class="col-md-6">{{ loan.account?.account_balance }}</div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="d-flex align-content-center w-100 akkurate-green-bg">
                              <div class="me-auto px-2 py-2 text-white">
                                    Current Stage: <span class="text-uppercase">{{ loan.loan_approval_level.name }}</span>
                              </div>
                                 <b-button :disabled="!canUpdateStatus"  class="akkurate-danger-btn btn-size">Reject</b-button>
                                 <!-- <b-button :disabled="!canUpdateStatus" v-if="loan.loan_approval_level.name == 'AWAITING DISBURSEMENT'" @click="disburseLoan" class="akkurate-green-btn btn-size">Approve</b-button> -->

                                 <div class="" v-if="loan.loan_approval_level?.name?.toUpperCase() == 'AWAITING DISBURSEMENT'" >
                                    <!-- <loan-disbursement :filter="{loan_code:filter.loan_number}" :loan="loan" :institution="institution" :data="disbursement" :disbursement="'disbursement'"></loan-disbursement> -->
                                    <loan-disbursement :loan_account="loan" :institution="institution" :data="disbursement" :disbursement="'disbursement'"></loan-disbursement>

                                    <b-button class="akkurate-green-btn btn-size" v-b-modal="disbursement.id + 'disbursement'">Disburse</b-button>
                                 </div>
                                 <b-button :disabled="!canUpdateStatus" v-else @click="approveLoan" class="akkurate-green-btn btn-size">Approve</b-button>

                           </div>
                           <div class="container mt-4">
                              <div class="row">
                                 <div class="col-12 col-md-4">
                                    <label class="akkurate-dark" for="name">Interest Method </label>
                                       <b-form-group
                                       id="name"
                                       label-for="name">
                                       <b-form-input
                                       class="mb-3 mt-2 field-container fields"
                                       type="text"
                                       placeholder="Enter Interest Method"
                                       disabled
                                       v-model="loan.interest_method"
                                       ></b-form-input>
                                       </b-form-group>
                                 </div>
                                 <div class="col-12 col-md-4">
                                    <label class="akkurate-dark" for="name">Amount </label>
                                       <b-form-group
                                       id="name"
                                       label-for="name">
                                       <b-form-input
                                       class="mb-3 mt-2 field-container fields"
                                       type="number"
                                       placeholder="Enter Amount"
                                       v-model="loan.principal_amount"
                                       disabled
                                       ></b-form-input>
                                       </b-form-group>
                                 </div>
                                 <div class="col-12 col-md-4" v-show="hybrid">
                                    <label class="akkurate-dark" for="name">Interest Rate </label>
                                       <b-form-group
                                          label-for="interest_rate"
                                          class="mb-3">
                                          <b-form-input
                                             id="interest_rate"
                                             name="interest_rate"
                                             class="mt-2 field-container fields"
                                             type="number"
                                             v-model="loan.interest_rate"
                                             disabled
                                          ></b-form-input>
                                       </b-form-group>
                                 </div>
                                 
                                 
                                 <div class="col-12 col-md-4" v-show="hybrid">
                                       <b-form-group
                                          label-for="interest_rate_frequency"
                                          class="mb-3">
                                          <template #label>
                                             <label class="akkurate-dark small" for="interest_rate_frequency">Interest Rate Frequency <star></star></label>
                                          </template>
                                          <b-form-input
                                             id="interest_rate_frequency"
                                             name="interest_rate_frequency"
                                             class="mt-2 field-container fields"
                                             type="text"
                                             v-model="loan.interest_rate_frequency"
                                             disabled
                                          ></b-form-input>
                                       </b-form-group>
                                 </div>
                           
                              
                                 <div class="col-12 col-md-4">
                                       <b-form-group
                                          label-for="interest_start_date"
                                          class="mb-3">
                                          <template #label>
                                                <label class="akkurate-dark small" for="interest_start_date">Interest Start Date <star></star></label>
                                          </template>
                                          <b-form-input
                                                id="interest_start_date"
                                                name="interest_start_date"
                                                class="mt-2 field-container fields"
                                                type="date"
                                                v-model="loan.interest_start_date"
                                          ></b-form-input>
                                       </b-form-group>
                                 </div>

                                 <div class="col-12 col-md-4" v-show="straight_line">
                                          <b-form-group
                                             label-for="expected_interest"
                                             class="mb-3">
                                             <template #label>
                                                   <label class="akkurate-dark small" for="expected_interest">Expected Interest <star></star></label>
                                             </template>
                                             <b-form-input
                                                   id="expected_interest"
                                                   name="expected_interest"
                                                   class="mt-2 field-container fields"
                                                   type="number"
                                                   v-model="loan.expected_interest"
                                                   disabled
                                             ></b-form-input>
                                          </b-form-group>
                                 </div>

                                 <div class="col-12 col-md-4" v-show="straight_line">
                                          <b-form-group
                                             label-for="fixed_interest_rate"
                                             class="mb-3">
                                             <template #label>
                                                   <label class="akkurate-dark small" for="fixed_interest_rate">Fixed Interest Rate <star></star></label>
                                             </template>
                                             <b-form-input
                                                   id="fixed_interest_rate"
                                                   name="fixed_interest_rate"
                                                   class="mt-2 field-container fields"
                                                   type="number"
                                                   v-model="loan.fixed_interest_rate"
                                                   disabled
                                             ></b-form-input>
                                          </b-form-group>
                                 </div>

                                 <div class="col-12 col-md-4" v-show="straight_line">
                                       <b-form-group
                                          label-for="fixed_interest_rate_frequency"
                                          class="mb-3">
                                          <template #label>
                                                <label class="akkurate-dark small" for="fixed_interest_rate_frequency">Fixed Interest Rate Frequency <star></star></label>
                                          </template>
                                          <b-form-input
                                                id="fixed_interest_rate_frequency"
                                                name="fixed_interest_rate_frequency"
                                                class="mt-2 field-container fields"
                                                type="number"
                                                v-model="loan.fixed_interest_rate_frequency"
                                                disabled
                                          ></b-form-input>
                                       </b-form-group>
                                          
                                 </div>

                                 <div class="col-12 col-md-4" v-show="straight_line">
                                       <b-form-group
                                          label-for="fixed_interval"
                                          class="mb-3">
                                          <template #label>
                                             <label class="akkurate-dark small" for="fixed_interval">Fixed Interval <star></star></label>
                                          </template>
                                          <b-form-input
                                                id="fixed_interval"
                                                name="fixed_interval"
                                                class="mt-2 field-container fields"
                                                type="number"
                                                v-model="loan.fixed_interval"
                                                disabled
                                          ></b-form-input>
                                       </b-form-group>
                                 </div>

                                 <div class="col-12 col-md-4" v-show="straight_line">
                                       <b-form-group
                                          label-for="fixed_duration"
                                          class="mb-3">
                                          <template #label>
                                             <label class="akkurate-dark small" for="fixed_duration">Fixed Duration<star></star></label>
                                          </template>
                                          <b-form-input
                                             id="fixed_duration"
                                             name="fixed_duration"
                                             class="mt-2 field-container fields"
                                             type="number"
                                             v-model="loan.fixed_duration"
                                             disabled
                                          ></b-form-input>
                                       </b-form-group>
                                 </div>

                                 <div class="col-12 col-md-4" v-show="straight_line">
                                       <b-form-group
                                          label-for="fixed_duration"
                                          class="mb-3">
                                          <template #label>
                                             <label class="akkurate-dark small" for="variable_interest_rate">Variable Interest Rate<star></star></label>
                                          </template>
                                          <b-form-input
                                             id="variable_interest_rate"
                                             name="variable_interest_rate"
                                             class="mt-2 field-container fields"
                                             type="number"
                                             v-model="loan.variable_interest_rate"
                                             disabled
                                          ></b-form-input>
                                       </b-form-group>
                                 </div>

                                 <div class="col-12 col-md-4" v-show="straight_line">
                                       <b-form-group
                                          label-for="variable_interest_rate_frequency"
                                          class="mb-3">
                                          <template #label>
                                             <label class="akkurate-dark small" for="variable_interest_rate_frequency">Variable Interest Rate Frequency <star></star></label>
                                          </template>
                                          <b-form-input
                                             id="variable_interest_rate_frequency"
                                             name="variable_interest_rate_frequency"
                                             class="mt-2 field-container fields"
                                             type="number"
                                             v-model="loan.variable_interest_rate_frequency"
                                             disabled
                                          ></b-form-input>
                                       </b-form-group>
                                 </div>

                                 <div class="col-12 col-md-4" v-show="straight_line">
                                       <b-form-group
                                          label-for="variable_interval"
                                          class="mb-3">
                                          <template #label>
                                             <label class="akkurate-dark small" for="variable_interval">Variable Interval <star></star></label>
                                          </template>
                                          <b-form-input
                                             id="variable_interval"
                                             name="variable_interval"
                                             class="mt-2 field-container fields"
                                             type="number"
                                             v-model="loan.variable_interval"
                                             disabled
                                          ></b-form-input>
                                       </b-form-group>
                                       
                                 </div>

                                 <div class="col-12 col-md-4" v-show="straight_line">
                                       <b-form-group
                                          label-for="variable_duration"
                                          class="mb-3">
                                          <template #label>
                                             <label class="akkurate-dark small" for="variable_duration">Variable Duration <star></star></label>
                                          </template>
                                          <b-form-input
                                             id="variable_duration"
                                             name="variable_duration"
                                             class="mt-2 field-container fields"
                                             type="number"
                                             v-model="loan.variable_duration"
                                             disabled
                                          ></b-form-input>
                                       </b-form-group>
                                 </div>

                                 <div class="col-12 col-md-4 mb-3">
                                       <label class="akkurate-dark small">Tenure <star></star></label>
                                       <b-form-group>
                                          <b-form-input
                                             id="tenor"
                                             name="tenor"
                                             class="mt-2 field-container fields"
                                             type="number"
                                             v-model="loan.tenor"
                                             disabled
                                          ></b-form-input>
                                       </b-form-group>
                                 </div>

                                 <div class="col-12 col-md-4 mb-3">
                                       <b-form-group
                                             label-for="tenor_interval"
                                             class="mb-3">
                                             <template #label>
                                                   <label class="akkurate-dark small" for="tenor_interval">Tenure Interval <star></star></label>
                                             </template>
                                             <b-form-input
                                             id="tenor_interval"
                                             name="tenor_interval"
                                             class="mt-2 field-container fields"
                                             type="text"
                                             v-model="loan.tenor_interval"
                                             disabled
                                          ></b-form-input>
                                       </b-form-group>
                                 </div>

                                 <div class="col-12 col-md-4">
                                       <b-form-group
                                          label-for="repayment_cycle"
                                          class="mb-3">
                                          <template #label>
                                             <label class="akkurate-dark small" for="repayment_cycle">Repayment Cycle <star></star></label>
                                          </template>
                                          <b-form-input
                                             id="repayment_cycle"
                                             name="repayment_cycle"
                                             class="mt-2 field-container fields"
                                             type="text"
                                             v-model="loan.repayment_cycle"
                                             disabled
                                          ></b-form-input>
                                       </b-form-group>
                                 </div>

                                 <div class="col-12 col-md-4">
                                       <b-form-group
                                          label-for="maturity_date"
                                          class="mb-3">
                                          <template #label>
                                             <label class="akkurate-dark small" for="maturity_date">Maturity Date</label>
                                          </template>
                                          <b-form-input
                                             id="maturity_date"
                                             name="maturity_date"
                                             v-model="loan.maturity_date"
                                             class="mt-2 field-container fields"
                                             type="date"
                                             disabled
                                          ></b-form-input>
                                       </b-form-group>
                                 </div>

                                 <div class="col-12 col-md-4">
                                       <b-form-group
                                          label-for="penalty_rate"
                                          class="mb-3">
                                          <template #label>
                                             <label class="akkurate-dark small" for="penalty_rate">Penalty Rate <star></star></label>
                                          </template>
                                          <b-form-input
                                             id="penalty_rate"
                                             name="penalty_rate"
                                             class="mt-2 field-container fields"
                                             type="number"
                                             v-model="loan.penalty_rate"
                                             disabled
                                          ></b-form-input>
                                       </b-form-group>
                                 </div>

                                 <div class="col-12 col-md-4">
                                       <b-form-group
                                          label-for="no_of_payment"
                                          class="mb-3">
                                          <template #label>
                                             <label class="akkurate-dark small" for="reason">Reason <star></star></label>
                                          </template>
                                          <b-form-textarea
                                             class="mt-2 fields"
                                             id="reason"
                                             v-model="loan.reason"
                                             placeholder="Enter Reason..."
                                             disabled
                                          ></b-form-textarea>
                                       </b-form-group>
                                 </div>

                                 <div class="col-12 col-md-4">
                                       <b-form-group
                                          label-for="insurance"
                                          class="mb-3">
                                          <template #label>
                                             <label class="akkurate-dark small" for="insurance">Insurance <star></star></label>
                                          </template>
                                          <b-form-input
                                             id="insurance"
                                             name="insurance"
                                             class="mt-2 field-container fields"
                                             type="number"
                                             v-model="loan.insurance"
                                             disabled
                                          ></b-form-input>
                                       </b-form-group>
                                 </div>

                                 <div class="col-12 col-md-4">
                                       <b-form-group
                                          label-for="supervisor_approval_limit"
                                          class="mb-3">
                                          <template #label>
                                             <label class="akkurate-dark small" for="supervisor_approval_limit">Supervisor Approval Limit <star></star></label>
                                          </template>
                                          <b-form-input
                                             id="supervisor_approval_limit"
                                             name="supervisor_approval_limit"
                                             class="mt-2 field-container fields"
                                             type="number"
                                             v-model="loan.supervisor_approval_limit"
                                             disabled
                                          ></b-form-input>
                                       </b-form-group>
                                 </div>

                                 <div class="col-12 col-md-4">
                                       <b-form-group
                                          label-for="branch_approval_limit"
                                          class="mb-3">
                                          <template #label>
                                             <label class="akkurate-dark small" for="branch_approval_limit">Branch Approval Limit <star></star></label>
                                          </template>
                                          <b-form-input
                                             id="branch_approval_limit"
                                             name="branch_approval_limit"
                                             class="mt-2 field-container fields"
                                             type="number"
                                             v-model="loan.branch_approval_limit"
                                             disabled
                                          ></b-form-input>
                                       </b-form-group>
                                 </div>
                              </div>
                           </div>
                     <!-- <div class="text-end my-2">
                              <b-button @click="ok()" type="submit" class="akkurate-green-btn me-2 btn-size float-right">Statement</b-button>
                     </div> -->
                     <!-- <div class="card-body">
                        <b-table responsive :fields="fields" :items="items">
                              <template #cell(amount_paid)="data">
                                 <div style="width:100px">{{ data.value }}</div>
                              </template>
                              <template #cell(interest_paid)="data">
                                 <div style="width:100px">{{ data.value }}</div>
                              </template>
                              <template #cell(principal_paid)="data">
                                 <div style="width:100px">{{ data.value }}</div>
                              </template>
                              <template #cell(interest_bal)="data">
                                 <div style="width:100px">{{ data.value }}</div>
                              </template>
                              <template #cell(principal_bal)="data">
                                 <div style="width:100px">{{ data.value }}</div>
                              </template>
                              <template #cell(action)="data">
                                 <div class="d-flex">
                                 <button class="btn btn-sm akkurate-warning text-white" @click="confirmDeleteUser(props.row)">
                                    <span class="akkurate-small"> <font-awesome-icon :icon="['fas', 'eye']" /></span> 
                                 </button>
                                 <button class="btn btn-sm akkurate-danger-btn text-white" @click="confirmDeleteUser(props.row)">
                                    <span class="akkurate-small"><font-awesome-icon :icon="['fas', 'exchange-alt']" /></span> 
                                 </button>
                                 <button class="btn btn-sm akkurate-green-btn text-white" @click="confirmDeleteUser(props.row)">
                                    <span class="akkurate-small"> <font-awesome-icon :icon="['fas', 'book']" /></span>
                                 </button>
                                 </div>
                              </template>
                              <template #custom-foot>
                                 <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td class="fw-bold">Total</td>
                                    <td class="fw-bold">{{ total }}</td>
                                 </tr>
                                 </template>
                              </b-table>
                        
                     </div> -->
                  </div>
               </div>
            </div>
            <template #modal-footer="{ ok }">
                    <div class="w-100">
                    <!-- begin loader -->
                    <!-- <beat-loader-component 
                    class="float-left"
                    v-if="isLoading"
                    :isLoading="isLoading"
                    ></beat-loader-component> -->
                    <!-- end loader -->
                    <!-- Emulate built in modal footer ok and cancel button actions -->
                    <b-button @click="String(data.id + authorization)"  class="akkurate-grey me-2 btn-size float-right"><font-awesome-icon class="me-2" :icon="['fas', 'close']" />Close</b-button>     
                    </div>
                </template>
            </b-modal>
            
        </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import ApiService from "@/core/services/api.service";
import LoanDisbursement from './../disbursement/LoanDisbursement.vue';
export default {
   props:['data','authorization'],
   components: {
      Multiselect,
      LoanDisbursement,
   },
   data() {
      return {
         ...this.initialState(),
         search: "Search",
         isSearching: false,
         isSaving: false,
         total: "",
         hybrid: false,
         straight_line: false,
         canUpdateStatus: false,
         disbursement: {
               id: 'loan_disbursement'
         },
         // fields: [
         //     {
         //         key: 'trans_date',
         //         label: 'Date',
         //     },

         //     {
         //         key: 'transaction_mode',
         //         label: 'Description',
         //     },
         //     {
         //         key: 'trans_amount',
         //         label: 'Amount Paid',
         //     },
         //     {
         //         key: 'principal',
         //         label: 'Principal',
         //     },

         //     {
         //         key: 'interest_paid',
         //         label: 'Interest Amount',
         //     },
         //     {
         //         key: 'principal_paid',
         //         label: 'Principal Amount',
         //     },
         //     {
         //         key: 'interest_balance',
         //         label: 'Interest Balance',
         //     },
         //     {
         //         key: 'principal_balance',
         //         label: 'Principal Balance',
         //     },
         //     {
         //         key: 'action',
         //         label: 'Action',
         //     },
         //     // {
         //     //     key: 'age',
         //     //     label: 'Person age',
         //     //     sortable: true,
         //     //     // Variant applies to the whole column, including the header and footer
         //     //     variant: 'danger'
         //     // }
         // ],
         // items: [
         //     // { data: 'test', description: 'Testing', principal: 'Testing', amount_paid: 'Testing', interest_paid: 'Testing', principal_paid: 'Testing', interest_bal: 'Testing', principal_bal: 'Testing', teller: 'Testing' },
         // ]
      }
   },
   computed: {
      // getInterestDue() {
      //     // const interest_due = this.loan.daily_interest * this.loan.delinquent * this.loan.principal_balance;
      //     const interest_rate = this.loan.interest_rate / 100 / 30;
      //     const interest_due = this.loan.principal_balance * interest_rate * this.getDelinquent;
      //     return Math.round(interest_due * 100) / 100;
      // },
      // getDelinquent() {
      //     const last_payment = new Date(this.loan.last_payment);
      //     const current_date = new Date(Date.now());
      //     const diffTime = Math.abs(current_date - last_payment);
      //     const delinquent = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      //     return delinquent;
      // }
      getFullName() {
         const first_name = this.loan.account?.customer?.first_name != undefined ? this.loan.account?.customer?.first_name : "";
         const middle_name = this.loan.account?.customer?.middle_name != undefined ? this.loan.account?.customer?.middle_name : "";
         const last_name = this.loan.account?.customer?.last_name != undefined ? this.loan.account?.customer?.last_name : "";
         return first_name + ' ' + middle_name + ' ' + last_name;
      },
   },
   methods: {
      filterLoan() {
         this.search = "processing...";
         this.isSearching = true;
         ApiService.get(`/loans/${this.filter.loan_number}`)
            .then((response) => {
               this.search = "Search";
               this.isSearching = false;
               console.log(response.data)
               this.loan = response.data.data;
               let the_status = response?.data?.data?.status;
               if (response.data?.data?.interest_method == 'straight-line') {
                  this.straight_line = false;
                  this.hybrid = true;
                  // console.log("Straight Line");
               } else if (response.data?.data?.interest_method == 'reducing-balance') {
                  this.straight_line = true;
                  this.hybrid = true;
               } else if (response.data?.data?.interest_method == 'hybrid') {
                  this.straight_line = true;
                  this.hybrid = false;
               }
               if (the_status == "RUNNING" || the_status == "EXPIRED" || the_status == "CLOSED" || the_status == "REJECTED" || the_status == "DISBURSED" ) {
                  this.canUpdateStatus = false
               } else {
                  this.canUpdateStatus = true
               }

               //   this.getLoanEntiries(this.filter.loan_number);
               // console.log(this.items[0].after_payments.value = this);
               // this.items[0].payment_terms.value = this.loan.interest_paid
               // this.items[1].payment_terms.value = this.loan.principal_paid
               // this.items[2].payment_terms.value = this.loan.principal_amount
               //   console.log();
            }).catch((error) => {
               this.search = "Search";
               this.isSearching = false;
               if (error.response.status == 404) {
                  swal.fire({
                     icon: "error",
                     title: error.response.statusText,
                     text: "Something went wrong!",
                     showCancelButton: false, // Show the "Cancel" button
                     confirmButtonText: 'OK', // Customize the text for the "OK" button
                     buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                     customClass: {
                        confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                     }
                     // footer: '<a href="#">Why do I have this issue?</a>'
                  });
               }
            })
      },
      async approveLoan() {
         this.isSaving = true;
         this.$Progress.start();
         await ApiService.post('/loans/approveLoan', this.filter)
            .then((response) => {
               this.isLoadingSaving = false;
               this.$Progress.finish();
               toast.fire({
                  icon: "success",
                  title: response.data.message,
               });
               this.isSaving = false;
               // this.form.reset();
               this.loan = this.initialState().loan;
               this.filter = this.initialState().filter;
               this.$emit('approval');
               this.$bvModal.hide(this.data.id + this.authorization);
               this.canUpdateStatus = false;

            }).catch((error) => {
               this.isSaving = false;
               this.$Progress.fail();
               if (error.response.data.errors) {
                  this.form.errors = error.response.data.errors;
               }
            })
      },
      modalShown(){
         if(this.data?.id != 'app_authorization'){
            this.filter.loan_number = this.data?.loan?.loan_number;
            this.filterLoan();
         }

      },
      initialState() {
         return {
            filter: {
               loan_number: "",
            },
            loan: {
               status: "",
               tenor: "",
               interest_rate: "",
               interest_method: "",
               expected_interest: "",
               interest_balance: "",
               interest_paid: "",
               interest_balance: "",
               loan_code: "",
               principal_amount: "",
               principal_paid: "",
               principal_balance: "",
               principal_due: "",
               loan_product: "",
               account: "",
               start_date: "",
               last_payment: "",
               interest_due: "",
               interest_method: "",
               interest_rate_frequency: "",
               interest_start_date: "",
               fixed_interest_rate: "",
               fixed_interest_rate_frequency: "",
               fixed_interval: "",
               fixed_duration: "",
               variable_interest_rate: "",
               variable_interest_rate_frequency: "",
               variable_interval: "",
               variable_duration: "",
               tenor_interval: "",
               repayment_cycle: "",
               maturity_date: "",
               penalty_rate: "",
               reason: "",
               insurance: "",
               supervisor_approval_limit: "",
               branch_approval_limit: "",
               loan_approval_level: {},
            },
         }
      }
   }

}
</script>