<template>
  <div class="programs_table">
    <!-- <pulse-loader-component :loading="true"></pulse-loader-component> -->
    <beat-loader-component
      v-if="isLoading"
      :isLoading="isLoading"
    ></beat-loader-component>

    <div class="" v-else>
      <div class="mt-2">
        <table class="table table-hover">
          <tbody>
            <tr>
              <th>Module</th>
              <th>Permissions</th>
            </tr>

            <tr v-for="(permission, index) in permissions" :key="index">
              <td>{{ permission.module_display_name }}</td>
              <td>
                <b-form-group>
                  <b-form-checkbox
                    v-for="(
                      apermission, apermission_index
                    ) in permission.permissions"
                    :key="apermission_index"
                    v-model="apermission.is_checked"
                    inline
                  >
                    <span v-html="apermission.display_name"></span>
                  </b-form-checkbox>
                </b-form-group>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="display_flex flex_end">
        <div>
          <button
            :disabled="!form.selected_role || is_Updating"
            @click="$emit('updatePermissionsNow')"
            class="btn text-white akkurate-green-btn"
          >
          <font-awesome-icon class="me-2" :icon="['fas', 'save']" />Save Changes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BeatLoaderComponent from "@/views/components/Loader/BeatLoaderComponent.vue";
export default {
  components: {
    BeatLoaderComponent,
  },
  props: ["users", "form", "permissions", "is_Updating", "isLoading"],
  data() {
    return {
      selected: "first",
    };
  },
  mounted() {
    // this.$Progress.start();
  },
  methods: {
    checkboxClicked(apermission, module_id) {
      // console.log(apermission)
      // var module_  = this.permissions.find(one_module=> one_module.module_id == module_id)
      // if(module_)
      // {
      //     console.log(module_)
      //     // this.donors.splice(this.donors.indexOf(donorAlreadyAssigned), 1);
      //     // var permisn_ankasa = this.permissions[this.permissions.indexOf(module_)].permissions;
      //     // var found = permisn_ankasa.find(one_permi=>one_permi.id == apermission.id );
      //     // alert(permisn_ankasa.indexOf(permisn_ankasa).is_checked);
      // }
      // // console.log(apermission.is_checked);
      // this.$emit("permissionChecked",apermission.id,apermission.is_checked);
    },
  },
};
</script>
