// TitleMixin.js
export default {
  created() {
    this.updateTitle();
  },
  methods: {
    updateTitle() {
      const pageTitle = this.$route.meta.title || 'AKKURATEBanc';
      // document.title = 'AKKURATEBanc ' + pageTitle;
      document.title = pageTitle;
    },
  }
};