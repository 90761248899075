<template>
    <div class="row">
        <div class="col-12 col-md-6 mt-4">
        <label class="akkurate-dark" for="current-password">Current Password <star></star></label>
          <b-form-group
            id="password-group"
            label-for="current-password"
            class="akkurate-dark"
            >
            <b-input-group class="mt-2 mb-4 field-container">
                <template #append>
                    <b-input-group-text class="field-container field-addon">
                        <strong>
                            <a type="button">
                            <font-awesome-icon :icon="['fas', 'eye']" class="awesome-eye akkurate-dark" />
                            </a>
                        </strong>
                    </b-input-group-text>
                </template>
                <b-form-input id="current-password" class="field-main" placeholder="Enter Current Password" type="password" required></b-form-input>
            </b-input-group>
            </b-form-group>
       </div>
        
       <div class="col-12 col-md-6 mt-4">
        <label class="akkurate-dark" for="new-password">New Password <star></star></label>
          <b-form-group
            id="password-group"
            label-for="new-password"
            class="akkurate-dark"
            >
            <b-input-group class="mt-2 mb-4 field-container">
                <template #append>
                    <b-input-group-text class="field-container field-addon">
                        <strong>
                            <a type="button">
                            <font-awesome-icon :icon="['fas', 'eye']" class="awesome-eye akkurate-dark" />
                            </a>
                        </strong>
                    </b-input-group-text>
                </template>
                <b-form-input id="password" class="field-main" placeholder="Enter New Password" type="password" required></b-form-input>
            </b-input-group>
            </b-form-group>
       </div>

        <div class="col-12 col-md-6 mt-4">
            <label class="akkurate-dark" for="text-password">Confirm Password <star></star></label>
            <b-form-group
            id="confirm-password-group"
            label-for="confirm-password"
            class="akkurate-dark">
            <b-form-input
                id="confirm-password" 
                class="mb-3 mt-2 field-container fields"
                type="password" 
                placeholder="Enter Confirm Password"
                required>
            </b-form-input>
            </b-form-group>
        </div>
     </div>

</template>
<script>
export default {
    name: "ChangePassword"
}
</script>