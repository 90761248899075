<template>
    <form @submit.prevent="basicSearch">
        <div class="row">
           
            <div class="col">
                    <b-form-group
                    id="loan_code"
                    label-for="loan_code">
                    <b-form-input
                    class="mb-3 mt-2 field-container fields"
                    type="text"
                    placeholder="Enter Fixed Deposit Code e.g FD123456"
                    v-model="filter.code"
                    ></b-form-input>
                    </b-form-group>
            </div>
            <div class="mt-auto col-md-3 col-3">
              <b-button type="submit" :disabled="action.disabled_search"  class="akkurate-green-btn w-100 mb-3"><font-awesome-icon class="me-2" :icon="['fas', 'search']" />{{ action.search_btn }}</b-button>
            </div>
        </div>
    </form>
</template>
<script>
import Multiselect from 'vue-multiselect'
export default{
components:{
    Multiselect
},
props:['filter','basicSearch','action'],
data(){
    return{
        search:"Account Number",
        search_options:["Account Number","Code","ID"]

    }
}
}
</script>