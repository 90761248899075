<template>
    <div class="row">
           <div class="col">
               <label>Account Number</label>
               <b-form-group
               id="account_number"
               label-for="account_number">
               <b-form-input
               class="mb-3 mt-2 field-container fields"
               type="text"
               placeholder="Enter Account Number"
               v-model="filter.account_number"
               ></b-form-input>
               </b-form-group>
           </div>
           <div class="col">
               <label>Start Date</label>
               <b-form-group
               id="start_date"
               label-for="start_date">
               <b-form-input
               class="mb-3 mt-2 field-container fields"
               type="date"
               v-model="filter.start_date"
               ></b-form-input>
               </b-form-group>
           </div>
           <div class="col">
               <label>End Date</label>
               <b-form-group
               id="end_date"
               label-for="end_date">
               <b-form-input
               class="mb-3 mt-2 field-container fields"
               type="date"
               v-model="filter.end_date"
               ></b-form-input>
               </b-form-group>
           </div>
           <div class="mt-auto col col">
           <b-button type="submit" :disabled="isSearching" class="akkurate-green-btn akkurate-auth-size w-100 mb-3"><font-awesome-icon class="me-2" :icon="['fas', 'search']" />{{ search }}</b-button>
           </div>
   </div>
</template>
<script>
export default {
   props:['filter', 'isSearching', 'search']
}
</script>