<template>
  <span class="form-text red_color">
    *
  </span>
</template>

<script>
export default {
  mounted() {}
};
</script>

<style>
.red_color {
  color: red !important;
}
</style>