<template>
    <div class="row">
           <div class="col-12 col-md-6">
           <label class="akkurate-dark" for="source_of_funds">Source Of Funds</label>
               <b-form-group
               id="source_of_funds"
               label-for="source_of_funds">
               <b-form-input
               class="mb-3 mt-2 field-container fields"
               type="text"
               placeholder="Enter Source Of Funds"
                v-model="form.source_of_funds"
                :disabled="add_or_edit == 'view'"
               ></b-form-input>
               <errors v-if="form.errors && form.errors.source_of_funds">
                  {{ form.errors.source_of_funds[0] }}
                </errors>
               </b-form-group>
           </div>

           <div class="col-12 col-md-6">
               <label class="akkurate-dark" for="bankers_name">Banker's Name</label>
               <b-form-group
               id="bankers_name"
               label-for="bankers_name">
               <b-form-input
               class="mb-3 mt-2 field-container fields"
               type="text"
               placeholder="Enter Banker's Name"
                v-model="form.bankers_name"
                :disabled="add_or_edit == 'view'"
               ></b-form-input>
               <errors v-if="form.errors && form.errors.bankers_name">
                  {{ form.errors.bankers_name[0] }}
                </errors>
               </b-form-group>
           </div>
           <div class="col-12 col-md-6">
               <b-form-group
                label-for="bankers_address"
                class="mb-2">
                <template #label>
                  <label class="akkurate-dark" for="bankers_address">Banker's Address</label>
                </template>
                  <b-form-textarea
                  class="mt-2 fields"
                  id="bankers_address"
                  v-model="form.bankers_address"
                :disabled="add_or_edit == 'view'"
                  placeholder="Enter Banker's Address..."
                ></b-form-textarea>
              </b-form-group>
              
           </div>
           
           <div class="col-12 col-md-6">
               <label class="akkurate-dark" for="bankers_location">Banker's Location</label>
               <b-form-group
               id="bankers_location"
               label-for="bankers_location">
               <b-form-input
               class="mb-3 mt-2 field-container fields"
               type="text"
               placeholder="Enter Banker's Location"
                v-model="form.bankers_location"
                :disabled="add_or_edit == 'view'"
               ></b-form-input>
               <errors v-if="form.errors && form.errors.bankers_location">
                  {{ form.errors.bankers_location[0] }}
                </errors>
               </b-form-group>
           </div>


           <div class="col-12 col-md-6">
               <label class="akkurate-dark" for="monthly_salary">Monthly Salary</label>
               <b-form-group
               id="monthly_salary"
               label-for="monthly_salary">
               <b-form-input
               class="mb-3 mt-2 field-container fields"
               type="number"
               placeholder="Enter monthly salary"
                v-model="form.monthly_salary"
                :disabled="add_or_edit == 'view'"
                step=".01"
               ></b-form-input>
               <errors v-if="form.errors && form.errors.monthly_salary">
                  {{ form.errors.monthly_salary[0] }}
                </errors>
               </b-form-group>
           </div>

           <div class="col-12 col-md-6">
               <label class="akkurate-dark" for="bank_account_number">Bank Account Number</label>
               <b-form-group
               id="bank_account_number"
               label-for="bank_account_number">
               <b-form-input
               class="mb-3 mt-2 field-container fields"
               type="text"
               placeholder="Enter bank account number"
                v-model="form.bank_account_number"
                :disabled="add_or_edit == 'view'"
               ></b-form-input>
               <errors v-if="form.errors && form.errors.bank_account_number">
                  {{ form.errors.bank_account_number[0] }}
                </errors>
               </b-form-group>
           </div>
    </div>
</template>
<script>
// import Multiselect from 'vue-multiselect'
// import ApiService from "@/core/services/api.service";
export default {
  name: "FinancialInformation",
  props: ['form', 'add_or_edit'],
  components: {
    // Multiselect
  },
  data() {
    return {
      back_text_visible: false,
    }
  },
  methods: {
    
  }
}
</script>