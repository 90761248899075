<template>
    <div class="row mt-3">
        <div class="col-md-6 mb-3">
            <label class="akkurate-dark small" for="status">Status</label>
            <b-form-group id="loan-re-date" label-for="loan-re-date">
                <multiselect class="mt-2 field-container" v-model="form.status" :options="status_options">
                </multiselect>
            </b-form-group>
        </div>
        <div class="col-md-6 mb-3">
            <label class="akkurate-dark small" for="reason">Reason</label>
            <b-form-group id="reason" label-for="reason">
                <b-form-textarea class="mt-2 fields" id="reason" v-model="form.reason"
                    placeholder="Enter Reason..."></b-form-textarea>
            </b-form-group>
        </div>
        <hr />
        <div class="col-12 text-end">
            <b-button @click="clearForm" class="akkurate-gray-btn me-2 btn-size"><font-awesome-icon class="me-2"
                    :icon="['fas', 'close']" />Clear</b-button>
            <button type="submit" :disabled="isSaving"
                class="btn akkurate-green-btn text-white btn-size me-2"><font-awesome-icon class="me-2"
                    :icon="['fas', 'save']" />{{ save }}</button>

        </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import ApiService from "@/core/services/api.service"
export default {
    components: {
        Multiselect
    },
    data() {
        return {
            ...this.initialState(),
            status_options: ["Pending", "Under Review", "Pending Loan Committee Approval", "Pending Sub Committee Approval", "Pending Chairman Approval", "Pending Disbursement", "Approved", "Released", "Running", "Closed", "Overdue", "Rejected", "Refinanced"],
            save:"Save",
            isSaving:false,

        }
    },
    methods: {
        validateState(ref) {
            if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        handleOk(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault();
            // Trigger submit handler
            this.handleSubmit()
        },
        handleSubmit() {
            // Exit when the form isn't valid
            this.$validator.validateAll().then(result => {
                if (!result) {
                    // console.log(result);
                    return;
                }
                this.changeLoanStatus();



            });
            // Hide the modal manually

        },
        async changeLoanStatus() {
            this.isSaving = true;
            this.$Progress.start();
            await ApiService.post('/loans/changeStatus', this.form)
                .then((response) => {
                    this.isLoadingSaving = false;
                    this.$Progress.finish();
                    toast.fire({
                        icon: "success",
                        title: response.data.message,
                    });
                    this.isSaving = false;
                    // this.form.reset();
                    this.form = this.initialState().form;
                }).catch((error) => {
                    this.isSaving = false;
                    this.$Progress.fail();
                    if (error.response.data.errors) {
                        this.form.errors = error.response.data.errors;
                    }
                })
        },
        initialState() {
            return {
                form: {
                    status: "",
                    reason: "",
                }

            }
        },
        clearForm() {
            this.form = this.initialState().form; // Call the initialState method to get the default form
        },
    }
}
</script>