<template>
  <div id="wrapper">
    <SidebarView :home="true" :mobile="is_mobilebanker" />
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <TopbarView />
        
        <div class="text-center" v-if="isLoading">
          <beat-loader-component :isLoading="isLoading"></beat-loader-component>
        </div>
        
        <div v-else>
          <MobileMenu />
          <BoardChairman v-if="is_board_chairman"/>
          <GeneralManager v-if="is_general_manager"/>
          <LoanOfficer v-else-if="is_loan_officer"/>
          <BranchManager v-else-if="is_branch_manager"/>
          <Supervisory v-else-if="is_supervisory"/>
          <Treasurer v-else-if="is_treasurer"/>
          <Teller v-else-if="is_teller"/>
          <MobileBanker :mobile_menu="mobile_menu" v-else-if="is_mobilebanker"/>
          <Auditor v-else-if="is_auditor" />
          <Operation v-else-if="is_operation" />
          <Administrator v-else-if="is_administrator"/>
          <Accountant v-else-if="is_accountant"/>
          <LoanCommittee v-else-if="is_loancommittee"/>
          <FrontDesk v-else-if="is_frontdesk"/>
          <!-- <GeneralManager v-else /> -->
        </div>
      </div>
      <footer class="bg-white sticky-footer">
        <div class="container my-auto">
          <div class="text-center my-auto copyright">
            <span>Copyright © Akkurate 2024</span>
          </div>
        </div>
      </footer>
    </div>
    <a class="d-inline scroll-to-top" href="#page-top"
      ><i class="fas fa-angle-up"></i
    ></a>
  </div>
</template>

<script>
import SidebarView from "./components/Sidebar.vue";
import TopbarView from "./components/Topbar.vue";
import GeneralManager from "./components/dashboard/GeneralManager.vue";
import LoanOfficer from "./components/dashboard/LoanOfficer.vue";
import BranchManager from "./components/dashboard/BranchManager.vue";
import Supervisory from "./components/dashboard/Supervisory.vue";
import Teller from "./components/dashboard/Teller.vue";
import MobileBanker from './components/dashboard/MobileBanker.vue'
import ApiService from "@/core/services/api.service";
import BeatLoaderComponent from "@/views/components/Loader/BeatLoaderComponent.vue";
// import { faBullseye } from "@fortawesome/free-solid-svg-icons";
// import { faBullseye } from "@fortawesome/free-solid-svg-icons";
import BoardChairman from "./components/dashboard/BoardChairman.vue";
import Treasurer from './components/dashboard/Treasurer.vue'
import Auditor from "./components/dashboard/Auditor.vue";
import Operation from "./components/dashboard/Operation.vue";
import MobileMenu from "./components/MobileMenu.vue";
import Administrator from "./components/dashboard/Administrator.vue";
import Accountant from "./components/dashboard/Accountant.vue";
import LoanCommittee from "./components/dashboard/LoanCommittee.vue";
import FrontDesk from "./components/dashboard/FrontDesk.vue";

export default {
  name: "Home",
  data() {
    return {
      roles: "",
      isLoading: false,
      is_loan_officer: false,
      is_general_manager: false,
      is_general_manager: false,
      is_board_chairman: false,
      is_branch_manager: false,
      is_supervisory: false,
      is_treasurer: false,
      is_mobilebanker: false,
      is_teller: false,
      is_auditor: false,
      is_operation: false,
      mobile_menu: false,
      is_administrator: false,
      is_accountant : false,
      is_loancommittee:false,
      is_frontdesk:false
    };
  },
  components: {
    SidebarView,
    TopbarView,
    GeneralManager,
    LoanOfficer,
    BeatLoaderComponent,
    BranchManager,
    Supervisory,
    BoardChairman,
    Treasurer,
    Teller,
    MobileBanker,
    Auditor,
    Operation,
    MobileMenu,
    Administrator,
    Accountant,
    LoanCommittee,
    FrontDesk
  },
  mounted() {
    let token = localStorage.getItem("token");
    if (!token) {
      this.$router.push({ path: "/login" });
    }
    this.getUserProfile();
  },
  methods: {
    async getUserProfile() {
      this.isLoading = true;
      await ApiService.get("/users/profile")
        .then((response) => {
          const res = response.data?.user?.roles.map((index) => {
            return index.name;
          });
          // console.log(res);
          const userRoles = res[0];
          // console.log(res);
          this.is_general_manager = userRoles.includes('general-manager');
          this.is_board_chairman = userRoles.includes('board-chairman');
          this.is_loan_officer = userRoles.includes('loan-officer');
          this.is_branch_manager = userRoles.includes('branch-manager');
          this.is_supervisory = userRoles.includes('supervisory');
          this.is_treasurer = userRoles.includes('treasurer');
          this.is_mobilebanker = userRoles.includes('mobile-banker')
          this.is_teller = userRoles.includes('teller');
          this.is_auditor = userRoles.includes('auditor-compliance');
          this.is_operation = userRoles.includes('operations-manager');
          this.is_administrator = userRoles.includes('system-administrator')
          this.is_accountant = userRoles.includes('accountant')
          this.is_loancommittee = userRoles.includes('loan-committee-secretary')
          this.is_frontdesk = userRoles.includes('frontdesk-officer')

          // switch (this.roles) {
          //   case "loan-officer":
          //     this.is_loan_officer = true;
          //     this.is_branch_manager = false;
          //     this.is_general_manager = false;
          //     this.is_supervisory = false;
          //     break;
          //   case "loan-officer":
          //     this.is_loan_officer = true;
          //     this.is_branch_manager = false;
          //     this.is_general_manager = false;
          //     this.is_supervisory = false;
          //     break;
          //   case "branch-manager":
          //     this.is_loan_officer = false;
          //     this.is_branch_manager = true;
          //     this.is_general_manager = false;
          //     this.is_supervisory = false;
          //     break;
          //   case "general-manager":
          //     this.is_loan_officer = false;
          //     this.is_branch_manager = false;
          //     this.is_general_manager = true;
          //     this.is_supervisory = false;
          //     break;
          //   case "supervisory":
          //     this.is_loan_officer = false;
          //     this.is_branch_manager = false;
          //     this.is_general_manager = false;
          //     this.is_supervisory = true;
          //     break;
          // }
          // return response.data;
        })
        .catch((error) => {
          this.isLoading = false;
          return error;
        }).finally(() => {
          this.isLoading = false;
        });
    },
    mobileToggle() {
      console.log('mobile-togle')
      this.mobile_menu = !this.mobile_menu
    }
  },
};
</script>
<style scoped>
.default-chart-size {
  height: 20rem;
}
</style>