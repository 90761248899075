<template>
  <div>
    <b-modal :id="String(data.id + entries)" ref="modal" size="xxxl" @show="modalShown">
      <template #modal-title>
        <p class="m-0 fw-bold text-green">Loan Entries</p>
      </template>
      <div class="row">
        <div class="col-12">
          <div class="row">
            <form @submit.prevent="filterLoanStatement">
              <loan-filter :filter="filter" :is-searching="isSearching" :search="search"></loan-filter>
            </form>
          </div>
          <div class="card card-shape home-box">
            <div class="card-header py-3">
              <div class="row">
                <div class="col-12 col-md-12">
                  <h6 class="m-0 fw-bold text-green">Loan Account Preview</h6>
                </div>
              </div>
            </div>
            <div class="card-body" style="background-color: #eaeff2">
              <div class="row">
                <div class="col-12 col-md-3 col-sm-12">
                  <img class="img-fluid img-visibility  w-100 h-100" src="@/assets/img/user-avater.jpg" />
                </div>
                <div class="col-12 col-md-3 col-sm-12 row" style="
                    display: flex;
                    flex-direction: column !important;
                    justify-content: space-between;
                  ">
                  <div class="col-12 col-md-12">
                    <p class="fw-bold">{{ getFullNameAndID }}</p>
                  </div>
                  <div class="col-12 col-md-12 small">
                    <div class="d-flex">
                      <p class="mb-0 fw-bold me-3">Status</p>
                      <p class="mb-0">{{ loan.status }}</p>
                    </div>
                    <div class="d-flex">
                      <p class="mb-0 fw-bold me-3">Tenure</p>
                      <p class="mb-0">{{ loan.tenor }} {{ loan.tenor_interval }}</p>
                    </div>
                    <div class="d-flex">
                      <p class="mb-0 fw-bold me-3">Interest Rate</p>
                      <p class="mb-0">
                        {{ loan.interest_rate }}% /
                        {{ loan.interest_rate_frequency }}
                      </p>
                    </div>
                    <div class="d-flex">
                      <p class="mb-0 fw-bold me-3">Tenure Interval:</p>
                      <p class="mb-0">{{ loan.tenor_interval }}</p>
                    </div>
                    <div class="d-flex">
                      <p class="mb-0 fw-bold me-3">Interest Method</p>
                      <p class="mb-0">{{ loan.interest_method }}</p>
                    </div>
                    <div class="d-flex">
                      <p class="mb-0 fw-bold me-3">Interest Expected</p>
                      <p class="mb-0">{{ displayNumber(loan.expected_interest) }}</p>
                    </div>
                    <div class="d-flex">
                      <p class="mb-0 fw-bold me-3">Interest Balance</p>
                      <p class="mb-0">
                        {{ displayNumber(loan.interest_balance) }}
                      </p>
                    </div>
                    <div class="d-flex">
                      <p class="mb-0 fw-bold me-3">Interest Paid</p>
                      <p class="mb-0">{{ displayNumber(loan.interest_paid) }}</p>
                    </div>
                    <div class="d-flex">
                      <p class="mb-0 fw-bold me-3">Interest Due</p>
                      <p class="mb-0">{{ displayNumber(loan.interest_due) }}</p>
                    </div>
                    <div class="d-flex">
                      <p class="mb-0 fw-bold me-3">Payment Due</p>
                      <p class="mb-0">{{ displayNumber(loan.loan_payment_due) }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-3 small col-sm-12">
                  <div class="row h-100">
                    <div class="col-md-12">
                      <div>
                        <span class="fw-bold">Loan Type:</span>
                        {{ loan?.loan_product.name }}
                      </div>
                      <div class="">
                        <span class="fw-bold">Disbursement Date:</span>
                        {{ loan.start_date }}
                      </div>
                    </div>
                    <div class="col-md-12 mt-auto">
                      <div class="row">
                        <div class="col-md-6 fw-bold">Principal</div>
                        <div class="col-md-6">{{ loan.principal_amount }}</div>
                        <div class="col-md-6 fw-bold">Principal Paid</div>
                        <div class="col-md-6">{{ loan.principal_paid }}</div>
                        <div class="col-md-6 fw-bold">Principal Bal</div>
                        <div class="col-md-6">{{ loan.principal_balance }}</div>
                        <div class="col-md-6 fw-bold">Principal Due</div>
                        <div class="col-md-6">{{ loan.principal_due }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-3 col-md-3 small">
                  <div class="row h-100">
                    <div class="col-md-12">
                      <div>
                        <span class="fw-bold">Loan Code:</span>
                        {{ loan.loan_number }}
                      </div>
                      <div>
                        <span class="fw-bold">Last Payment Date:</span>
                        {{ loan.last_payment }}
                      </div>
                      <div>
                        <span class="fw-bold">Delinquency:</span>
                        {{ this.loan.delinquent }}
                      </div>
                    </div>
                    <div class="col-md-12 mt-auto">
                      <div class="row">
                        <div class="col-md-6 fw-bold">Current Value:</div>
                        <div class="col-md-6">
                          GHS {{ Number(loan?.principal_balance + loan?.interest_due).toFixed(2) }}
                        </div>
                        <div class="col-md-6 fw-bold">Loan Balance:</div>
                        <div class="col-md-6">
                          GHS {{ Number(loan?.interest_balance + loan?.principal_balance).toFixed(2) }}
                        </div>
                        <div class="col-md-6 fw-bold">Share Bal:</div>
                        <div class="col-md-6">
                          GHS {{ loan?.shares_balance }}
                        </div>
                        <div class="col-md-6 fw-bold">Saving Bal:</div>
                        <div class="col-md-6">
                          GHS {{ loan?.savings_balance }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-header py-3">
              <div class="d-flex align-items-center">
                <h6 class="m-0 fw-bold text-green me-4">Transactions</h6>
                <p class="mb-0 akkurate-small me-2 akkurate-orange-text">
                  <font-awesome-icon :icon="['fas', 'circle']" />
                </p>
                <span class="small me-3">PP(Principal Paid)</span>
                <p class="mb-0 akkurate-small me-2 akkurate-danger">
                  <font-awesome-icon :icon="['fas', 'circle']" />
                </p>
                <span class="small me-3">PB(Principal Balance)</span>
                <p class="mb-0 akkurate-small me-2 akkurate-green">
                  <font-awesome-icon :icon="['fas', 'circle']" />
                </p>
                <span class="small me-3">IP(Interest Paid)</span>
                <p class="mb-0 akkurate-small me-2 akkurate-blue-text">
                  <font-awesome-icon :icon="['fas', 'circle']" />
                </p>
                <span class="small me-3">IB(Interest Balance)</span>
                <p class="mb-0 akkurate-small me-2 akkurate-purple">
                  <font-awesome-icon :icon="['fas', 'circle']" />
                </p>
                <span class="small me-3">ID(Interest Due)</span>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <b-table responsive :fields="fields" :items="items" :busy.sync="isBusy">
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                      </div>
                    </template>

                    <template #cell(debit)="data">
                      <p class="mb-0" v-if="data.item.transaction_type == 'Loan Disbursed'">
                        {{ displayNumber(data.item.trans_amount) }}
                      </p>
                    </template>

                    <template #cell(credit)="data">
                      <p class="mb-0" v-if="data.item.transaction_type == 'Loan Repayment'">
                        {{ displayNumber(data.item.trans_amount) }}
                      </p>
                    </template>

                    <template #cell(description)="data">
                      <p class="mb-0">
                        {{ data.item.transaction_type }} By
                        {{ data.item.transaction_mode }}
                      </p>
                    </template>


                    <template #cell(interest)="data">
                      <span> {{ displayNumber(data.item.interest) }} </span>
                    </template>

                    <template #cell(action)="data">
                      <div class="d-flex">
                        <a title="Receipts" class="btn btn-sm akkurate-green-btn text-white"
                          @click="printReceipt(data)">
                          <span class="akkurate-small">
                            <font-awesome-icon :icon="['fas', 'book']" />
                          </span>
                        </a>
                        <div title="Cheque" class="dropdown no-arrow">
                          <a title="Cheque" class="btn btn-sm akkurate-gray-btn text-white dropdown-toggle"
                            id="dropdownMenu" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="akkurate-small">
                              <font-awesome-icon :icon="['fas', 'money-bill']" />
                            </span>
                          </a>
                          <div class="dropdown-menu shadow animated--fade-in" aria-labelledby="dropdownMenu">
                            <div class="dropdown-header">Statments</div>
                            <button class="dropdown-item" @click="chequePrintout(data)">Cheque Printout</button>
                            <button class="dropdown-item" @click="chequeTemplate(data)">Cheque Template</button>
                            <!-- <account-entries :data="props.row" :institution="institution" :user="user"></account-entries> -->

                            <!-- <a class="dropdown-item" v-b-modal="String(props.row?.id + 'account_statement')">Account Statement</a>
                                                    <account-statement :data="props.row" :institution="institution"></account-statement> -->
                          </div>
                        </div>
                        <a title="Reverse" class="btn btn-sm akkurate-danger-btn text-white" @click="loanReverse(data)">
                          <span class="akkurate-small"><font-awesome-icon :icon="['fas', 'exchange-alt']" /></span>
                        </a>
                      </div>
                    </template>

                    <template #custom-foot>
                      <tr>
                        <td class="fw-bold">Total</td>
                        <td class="fw-bold"></td>
                        <td class="fw-bold">{{ displayNumber(total_debit) }}</td>
                        <td class="fw-bold">{{ displayNumber(total_credit) }}</td>
                        <td class="fw-bold"></td>
                        <td class="fw-bold"></td>
                        <td class="fw-bold"></td>
                        <td class="fw-bold">{{ displayNumber(total_principal) }}</td>
                        <td class="fw-bold"></td>
                        <td class="fw-bold"></td>
                        <td class="fw-bold">{{ displayNumber(total_interest) }}</td>
                        <td class="fw-bold"></td>
                      </tr>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <template #modal-footer>
        <div class="w-100">
          <!-- begin loader -->
          <!-- <beat-loader-component 
                class="float-left"
                v-if="isLoading"
                :isLoading="isLoading"
                ></beat-loader-component> -->
          <!-- end loader -->
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <b-button @click="$bvModal.hide(String(data.id + entries))"
            class="akkurate-grey me-2 btn-size float-right"><font-awesome-icon class="me-2" :icon="['fas', 'close']" />Close</b-button>
        </div>
      </template>
      <cash-repayment ref="showCashRepayment" :report_data="report_data"></cash-repayment>
      <cash-disbursement ref="showCashDisbursement" :report_data="report_data"></cash-disbursement>
      <cheque-printout ref="showChequePrintout" :report_data="report_data"></cheque-printout>
      <cheque-template ref="showChequeTemplate" :report_data="report_data"></cheque-template>
      <cheque-disbursement ref="showChequeDisbursement" :report_data="report_data"></cheque-disbursement>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import ApiService from "@/core/services/api.service";
import ChequePrintout from "../reports/printout/ChequePrintout";
import ChequeTemplate from "../reports/printout/ChequeTemplate";
import CashRepayment from "../reports/cash/CashRepayment.vue";
import CashDisbursement from "../reports/cash/CashDisbursement.vue";
import ChequeDisbursement from "../reports/cheque/ChequeDisbursement.vue";

import LoanFilter from "./form/LoanFilter.vue";

export default {
  props: ["data", "institution", "branch", "user", "entries"],
  components: {
    Multiselect,
    CashRepayment,
    CashDisbursement,
    LoanFilter,
    ChequePrintout,
    ChequeTemplate,
    ChequeDisbursement,
  },
  data() {
    return {
      filter: {
        loan_code: "",
        start_date: this.getOneMonthBeforeDate(),
        end_date: new Date().toISOString().substr(0, 10),
      },
      isBusy: false,
      loan: {
        status: "",
        tenor: "",
        interest_rate: "",
        interest_method: "",
        expected_interest: "",
        interest_balance: "",
        interest_paid: "",
        interest_balance: "",
        loan_number: "",
        principal_amount: "",
        principal_paid: "",
        principal_balance: "",
        principal_due: "",
        loan_product: "",
        account: "",
        start_date: "",
        last_payment: "",
        interest_due: "",
      },
      search: "Search",
      isSearching: false,
      isSaving: false,
      total: "",
      report_data: {},
      fields: [
        {
          key: "trans_date",
          label: "Date",
        },

        {
          key: "description",
          label: "Description",
        },
        {
          key: "debit",
          label: "Debit",
        },
        {
          key: "credit",
          label: "Credit",
        },

        {
          key: "loan_interest_due",
          label: "ID",
        },
        {
          key: "loan_principal_due",
          label: "PD",
        },
        {
          key: "previous_balance",
          label: "Previous Balance",
        },
        {
          key: "principal",
          label: "Principal",
        },
        {
          key: "principal_paid",
          label: "PP",
        },
        {
          key: "principal_balance",
          label: "PB",
        },
        {
          key: "interest",
          label: "Interest",
        },
        {
          key: "interest_paid",
          label: "IP",
        },
        {
          key: "interest_balance",
          label: "IB",
        },
        {
          key: "action",
          label: "Action",
        },
      ],
      items: [],
    };
  },
  computed: {
    getFullNameAndID() {
      const first_name =
        this.loan.account?.customer?.first_name != undefined
          ? this.loan.account?.customer?.first_name
          : "";
      const middle_name =
        this.loan.account?.customer?.middle_name != undefined
          ? this.loan.account?.customer?.middle_name
          : "";
      const last_name =
        this.loan.account?.customer?.last_name != undefined
          ? this.loan.account?.customer?.last_name
          : "";
      const code =
        this.loan.account?.customer?.code != undefined
          ? this.loan.account?.customer?.code
          : "";
      return first_name + " " + middle_name + " " + last_name + " - " + code;
    },
    getTeller() {
      let first_name =
        this.user?.first_name != null ? this.user?.first_name : "";
      let middle_name =
        this.user?.middle_name != null ? this.user?.middle_name : "";
      let surname = this.user?.surname != null ? this.user?.surname : "";
      return first_name + " " + middle_name + " " + surname;
    },
    total_credit() {
      return this.items
        .filter((trans) => trans.transaction_type == "Loan Repayment")
        .reduce((total, trans) => total + trans.trans_amount, 0);
    },
    total_debit() {
      return this.items
        .filter((trans) => trans.transaction_type != "Loan Repayment")
        .reduce((total, trans) => total + trans.trans_amount, 0);
    },
    total_principal() {
      return (
        this.items
          // .filter(trans => trans.transaction_type != 'Loan Repayment')
          .reduce((total, trans) => total + trans.principal, 0)
      );
    },
    total_interest() {
      return this.items.reduce((total, trans) => total + trans.interest, 0);
    },
  },
  methods: {
    filterLoanStatement() {
      // console.log(this.filter.start_date);
      this.search = "processing...";
      this.isSearching = true;
      this.isBusy = true;
      ApiService.query("/loans/loanStatements", {
        params: {
          loan_number: this.filter.loan_code,
          start_date: this.filter.start_date,
          end_date: this.filter.end_date,
        },
      })
        .then((response) => {
          // console.log(response.data.data);
          this.loan = response.data.data;
          this.items = this.loan?.account?.transactions.map((index) => {
            return {
              id: index.id,
              trans_date: index.trans_date,
              transaction_type: index.transaction_type,
              transaction_mode: index.transaction_mode,
              trans_amount: index.trans_amount,
              debit: Math.round(index.debit * 100) / 100,
              credit: Math.round(index.credit * 100) / 100,
              previous_balance: Math.round(index.previous_balance * 100) / 100,
              principal: Math.round(index.principal * 100) / 100,
              principal_paid: Math.round(index.principal_paid * 100) / 100,
              principal_balance:
              Math.round(index.principal_balance * 100) / 100,
              interest_paid: Math.round(index.interest_paid * 100) / 100,
              interest_balance: Math.round(index.interest_balance * 100) / 100,
              interest: index.interest,
              denominations: index.denominations,
              loan_interest_due: index.loan_interest_due,
              loan_principal_due: index.loan_principal_due,
            };
          });
          this.search = "Search";
          this.isSearching = false;
          this.isBusy = false;
        })
        .catch((error) => {
          this.search = "Search";
          this.isSearching = false;
          this.isBusy = false;
          if (error.response.status == 404) {
            swal.fire({
              icon: "error",
              title: error.response.statusText,
              text: "Something went wrong!",
              showCancelButton: false, // Show the "Cancel" button
              confirmButtonText: "OK", // Customize the text for the "OK" button
              buttonsStyling: false, // Disable SweetAlert2 styling for buttons
              customClass: {
                confirmButton:
                  "btn akkurate-green-btn modal-btn-width text-white me-2", // Custom class for the "OK" button
              },
              // footer: '<a href="#">Why do I have this issue?</a>'
            });
          }
        });
    },

    calculateSum(array, property) {
      const total = array.reduce((accumulator, object) => {
        return accumulator + object[property];
      }, 0);
      // console.log(total);

      return total;
    },
    getOneMonthBeforeDate() {
      // Get today's date
      let currentDate = new Date();

      // Remove one month to the current date
      currentDate.setMonth(currentDate.getMonth() - 1);

      // Format the date as 'YYYY-MM-DD'
      return currentDate.toISOString().substr(0, 10);
    },
    async transactionReverse(data) {
      // console.log(data.item);
      switch (data.item?.transaction_type) {
        case "Deposit":
          swal
            .fire({
              // title: response.data.transaction.trans_status,
              title: "Reverse Deposit Transaction",
              // text: 'CODE : ' + response.data.transaction.code,
              text: "Are you sure?",
              confirmButtonColor: "#018673",
              icon: "question",
              allowOutsideClick: false,
              allowEscapeKey: false,
              showCancelButton: true, // Show the "Cancel" button
              confirmButtonText: "Yes", // Customize the text for the "OK" button
              cancelButtonText: "No", // Text for the custom button
              buttonsStyling: false, // Disable SweetAlert2 styling for buttons
              customClass: {
                confirmButton:
                  "btn akkurate-green-btn modal-btn-width text-white me-2", // Custom class for the "OK" button
                cancelButton: "btn akkurate-ash modal-btn-width text-white", // Custom class for the custom button
              },
            })
            .then((result) => {
              this.isLoading = true;
              this.$Progress.start();
              if (result.isConfirmed) {
                this.reverseDeposit(data.item?.code);
                this.getAccountStatement();
                this.isLoading = false;
                this.$Progress.finish();
                // this.$bvModal.hide(String(this.loan_no + this.statement));
              } else if (result.isDismissed) {
                this.isLoading = false;
                this.$Progress.finish();
                this.$bvModal.hide(String(this.loan_no + this.statement));
              }
            });
          break;
        case "Withdrawal":
          swal
            .fire({
              // title: response.data.transaction.trans_status,
              title: "Reverse Withdrawal Transaction",
              // text: 'CODE : ' + response.data.transaction.code,
              text: "Are you sure?",
              confirmButtonColor: "#018673",
              icon: "question",
              allowOutsideClick: false,
              allowEscapeKey: false,
              showCancelButton: true, // Show the "Cancel" button
              confirmButtonText: "Yes", // Customize the text for the "OK" button
              cancelButtonText: "No", // Text for the custom button
              buttonsStyling: false, // Disable SweetAlert2 styling for buttons
              customClass: {
                confirmButton:
                  "btn akkurate-green-btn modal-btn-width text-white me-2", // Custom class for the "OK" button
                cancelButton: "btn akkurate-ash modal-btn-width text-white", // Custom class for the custom button
              },
            })
            .then((result) => {
              this.isLoading = true;
              this.$Progress.start();
              if (result.isConfirmed) {
                this.reverseWithdrawal(data.item?.code);
                this.getAccountStatement();
                this.isLoading = false;
                this.$Progress.finish();
                // this.$bvModal.hide(String(this.loan_no + this.statement));
              } else if (result.isDismissed) {
                this.isLoading = false;
                this.$Progress.finish();
                this.$bvModal.hide(String(this.loan_no + this.statement));
              }
            });

          break;
      }
    },
    async reverseDeposit(data) {
      await ApiService.post("/transactions/deposits/reverse", {
        code: data,
        source: "Web",
      })
        .then((response) => {
          toast.fire({
            icon: "success",
            title: response.data.message,
          });
          this.form.reset();
          this.form = this.initialState().form;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$Progress.finish();
          // console.log(error.response.data)
          if (error.response.status == 404) {
            swal.fire({
              icon: "error",
              title: error.response.statusText,
              text: "Something went wrong!",
              showCancelButton: false, // Show the "Cancel" button
              confirmButtonText: "OK", // Customize the text for the "OK" button
              buttonsStyling: false, // Disable SweetAlert2 styling for buttons
              customClass: {
                confirmButton:
                  "btn akkurate-green-btn modal-btn-width text-white me-2", // Custom class for the "OK" button
              },
              // footer: '<a href="#">Why do I have this issue?</a>'
            });
          }
        });
    },
    async reverseWithdrawal(data) {
      await ApiService.post("/transactions/withdrawals/reverse", {
        code: data,
        source: "Web",
      })
        .then((response) => {
          toast.fire({
            icon: "success",
            title: response.data.message,
          });
          this.form.reset();
          this.form = this.initialState().form;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$Progress.finish();
          if (error.response.status == 404) {
            swal.fire({
              icon: "error",
              title: error.response.statusText,
              text: error.response?.data?.message,
              showCancelButton: false, // Show the "Cancel" button
              confirmButtonText: "OK", // Customize the text for the "OK" button
              buttonsStyling: false, // Disable SweetAlert2 styling for buttons
              customClass: {
                confirmButton:
                  "btn akkurate-green-btn modal-btn-width text-white me-2", // Custom class for the "OK" button
              },
              // footer: '<a href="#">Why do I have this issue?</a>'
            });
          }
        });
    },
    printReceipt(data) {
      console.log(data.item);
      this.report_data = {
        trans_date: data.item?.trans_date,
        teller: this.getTeller,
        account_number: this.loan?.account_number,
        loan_number: this.loan.loan_number,
        fullname: this.getFullNameAndID,
        transcation_mode: data.item.transaction_mode,
        transaction_type: data.item.transaction_type,
        amount: data.item?.trans_amount,
        denomination: data.item?.denominations,
        branch_name: this.loan.account?.customer?.branch?.name,
        receipt_no: data.item.id,
        institution: this.institution,
        description: data.item.transaction_type + ' By ' + data.item.transaction_mode,
      };
      // console.log(this.report_data);
      switch (data.item.transaction_mode) {
        case "Cash":
          if (data.item.transaction_type == "Loan Repayment") {
            this.$refs.showCashRepayment.showModal();
          } else if (data.item.transaction_type == "Loan Disbursed") {
            this.$refs.showCashDisbursement.showModal();
          }
          break;
        case "Cheque":
          if (data.item.transaction_type == "Loan Repayment") {
            this.$refs.showChequeRepayment.showModal();
          } else if (data.item.transaction_type == "Loan Disbursed") {
            this.$refs.showChequeDisbursement.showModal();
          }
          break;
        case "Momo Merchant":
          if (data.item.transaction_type == "Loan Repayment") {
            this.$refs.showMomoRepayment.showModal();
          } else if (data.item.transaction_type == "Loan Disbursed") {
            this.$refs.showMomoDisbursement.showModal();
          }
          break;
        case "Direct Momo Debit":
          if (data.item.transaction_type == "Loan Repayment") {
            this.$refs.showDirectDebitRepayment.showModal();
          } else if (data.item.transaction_type == "Loan Disbursed") {
            this.$refs.showDirectDebitDisbursement.showModal();
          }
          break;
        case "Set-Off":
          if (data.item.transaction_type == "Loan Repayment") {
            this.$refs.showSetOffRepayment.showModal();
          } else if (data.item.transaction_type == "Loan Disbursed") {
            this.$refs.showSetOffDisbursement.showModal();
          }
          break;
      }
    },
    chequePrintout() {
      this.report_data = {
        // 'trans_date': data.item?.trans_date,
        // 'teller': this.getTeller,
        // 'account_number': this.loan?.account_number,
        // 'loan_number': this.loan.loan_number,
        // 'fullname': this.getFullNameAndID,
        // 'transcation_mode': data.item.transaction_mode,
        // 'transaction_type': data.item.transaction_type,
        // 'amount': data.item?.trans_amount,
        // 'denomination': data.item?.denominations,
        // 'branch_name': this.loan.account?.customer?.branch?.name,
        // 'receipt_no': data.item.id,
        institution: this.institution,
      };
      this.$refs.showChequePrintout.showModal();
    },
    chequeTemplate() {
      this.report_data = {
        // 'trans_date': data.item?.trans_date,
        // 'teller': this.getTeller,
        // 'account_number': this.loan?.account_number,
        // 'loan_number': this.loan.loan_number,
        // 'fullname': this.getFullNameAndID,
        // 'transcation_mode': data.item.transaction_mode,
        // 'transaction_type': data.item.transaction_type,
        // 'amount': data.item?.trans_amount,
        // 'denomination': data.item?.denominations,
        // 'branch_name': this.loan.account?.customer?.branch?.name,
        // 'receipt_no': data.item.id,
        institution: this.institution,
      };
      this.$refs.showChequeTemplate.showModal();
    },
    modalShown() {
      if (this.data?.id != "app_entries") {
        this.filter.loan_code = this.data?.loan?.loan_number;
        this.filterLoanStatement();
      }
    },
  },
};
</script>

<style>
@media (min-width: 1200px) {
  .modal-xxl {
    --bs-modal-width: 1300px;
  }
}
</style>